.skeleton {
  display: flex;
  width: 95%;
  z-index: 0;
}
.ExerciseSkeleton {
  display: flex;
  width: 95%;
  height: 250px;
}
.skeleton-1 {
  display: flex;
  width: 95%;
  z-index: 0;
  height: 350px;
  border-radius: 15px;
}
.Eximg-and-details-div {
  width: 90%;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  display: flex;
}
.Eximg-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.Exdetails-div {
  flex-direction: column;
  display: flex;
  width: 70%;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
}
.ExerciseVideosContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 15px; */
}
.SingleExerciseDiv {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.ExerciseImageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* // marginTop: "20px", */
  margin-bottom: 20px;
}
.ExerciseDetailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ExCircuitSkeleton {
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (max-width: 1240px) {
  .SingleExerciseDiv {
    width: 25%;
  }
  .ExerciseSkeleton {
    height: 250px;
  }
}

@media only screen and (max-width: 1156px) {
  .SingleExerciseDiv {
    width: 25%;
  }
  .ExerciseSkeleton {
    height: 250px;
  }
}

@media only screen and (max-width: 1024px) {
  .SingleExerciseDiv {
    width: 35%;
  }
  .ExerciseSkeleton {
    height: 250px;
  }
}
@media only screen and (max-width: 840px) {
  .SingleExerciseDiv {
    width: 40%;
  }
  .ExerciseSkeleton {
    height: 250px;
  }
}
@media only screen and (max-width: 767px) {
  .SingleExerciseDiv {
    width: 40%;
  }
  .ExerciseSkeleton {
    height: 200px;
  }
}
@media only screen and (max-width: 666px) {
  .SingleExerciseDiv {
    width: 40%;
  }
  .ExerciseSkeleton {
    height: 200px;
  }
  .Eximg-and-details-div {
    flex-direction: column;
    width: 100%;
  }
  .ExCircuitSkeleton {
    width: 96%;
    margin-top: 20px;
  }
  .Exdetails-div {
    width: 95%;
  }
  .Eximg-div {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .SingleExerciseDiv {
    width: 100%;
  }
  .ExCircuitSkeleton {
    width: 100%;
    margin-top: 20px;
  }
  .ExerciseSkeleton {
    height: 180px;
  }
}
@media only screen and (max-width: 320px) {
  .SingleExerciseDiv {
    width: 100%;
  }
  .ExerciseSkeleton {
    height: 180px;
  }
}
