@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url(./fonts/Manrope/Manrope-Regular.ttf) format("truetype");
  font-display: auto;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
  font-display: auto;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
  font-display: auto;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./fonts/Inter/Inter-Regular.ttf) format("truetype");
  font-display: auto;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
@layer base {
  html,
  body {
    /* display: flex; */
    /* height: 100%; */
    /* max-height: 100vh; */
    /* max-width: 100vw; */
    /* overflow-x: hidden; */
    /* scroll-behavior: smooth; */
    /* scroll-snap-type: y mandatory; */
    /* scroll-padding: 20px; */
  }
  .panel {
    scroll-snap-align: center;
    padding-top: 30px;
  }
}
