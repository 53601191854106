.background-workout-div {
  height: 450px;
  width: 100%;
  transform: skew(0, -4deg);
  /* margin-bottom: 20vw; */
  margin-top: -10vh;
  background: linear-gradient(141.23deg, #000000 6.35%, #4f4f4f 139.03%);
}
.workout-image-section {
  width: 55%;
}
.workout-details-image-div {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  flex-direction: row;
  /* border: 1px solid yellow; */
}
.share-and-fav-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  /* border: 1px solid yellow; */
}
.circular-div {
  background: #1a1a1a;
  border-radius: 50%;
  padding-left: 18px;
  padding-right: 18px;
  /* border: 1px solid white; */
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  /* box-shadow: 0 0 2px 0px white inset, 0 0 2px 0px white; */
}

.circular-div:hover {
  background-color: red;
}
.circular-div-colored {
  border-radius: 50%;
  padding-left: 18px;
  padding-right: 18px;
  background-color: red;
  /* box-shadow: 0 0 2px 0px white inset, 0 0 2px 0px white; */
  cursor: pointer;
  margin-right: 5px;
  /* margin-left: 5px; */
}

.start-workout-btn,
.start-workout-btn:hover,
.start-workout-btn:active,
.start-workout-btn:focus {
  background: linear-gradient(110.19deg, #ff1e1e 36.55%, #ff6f1e 103.92%);
  color: white;
  margin-right: 10px;
  font-weight: 500;
  font-size: 20px;
  border-radius: 50px;
  height: 55px;
  width: 60%;
  border: none;
  outline: 0;
  justify-content: center;
  align-items: center;
}
.trending-videos-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 84%;
}
.trending-lable {
  font-size: 1.5em;
  justify-self: flex-start;
  align-self: flex-start;
  font-weight: 700;
}
.workout-details-paragraph {
  width: 84%;
  margin-top: 20px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  margin-bottom: 20px;
  /* border: 1px solid yellow; */
}
.workout-details-title-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.workout-details-title-text {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  width: 100%;
  color: white;
  margin-bottom: -1px;
  display: -webkit-box;
  /* max-width: 25vw; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.workoutDetails-details-div {
  width: 80%;
  /* margin-left: 5%; */
  padding-left: 10px;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  /* border: 1px solid yellow; */
}
.workout-tag {
  display: flex;
  background: #1a1a1a;
  color: rgba(255, 255, 255, 0.65);
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  border-radius: 12px;
  /* width: 22%; */
  text-align: center;
  font-weight: 500;
  font-size: 85%;
}
.workout-tags-div {
  display: flex;
  /* border: 1px solid yellow; */
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-self: start;
  /* justify-content: space-between; */
}
.workout-img-and-details-div {
  display: flex;
  flex-direction: row;
  width: 84%;
  /* border: 1px solid yellow; */
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 10px;
}
.indicator-workout {
  position: absolute;
  border: 0px;
  width: 50%;
  left: 0px;
  z-index: 1;
  bottom: 0px;
  height: 30px;
  transform: skew(0deg, 0deg);
  background: linear-gradient(276.14deg, #ff1e1e -32.94%, #ff0101 100%);
}
.indicator2-workout {
  position: absolute;
  right: 0px;
  border: 0px;
  width: 50%;
  z-index: 1;
  bottom: -1px;
  height: 30px;
  transform: skew(-1deg, -0.5deg);
  background: linear-gradient(276.14deg, #ff0101 -32.94%, #ff1e1e 100%);
}
.container-div-workout {
  background-color: #000;
}
.workout-page-content-div-container {
  background-color: #000000;
  height: 100%;
  width: 100%;
  color: white;
  /* transform: skew(0, -4deg); */
  margin-top: -2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
}
.workout-page-content-div {
  /* transform: skew(0, 4deg); */
  padding-bottom: 100px;
  margin-top: 50px;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.filter-cats {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  flex-wrap: wrap;
  width: 100%;
  justify-self: center;
  justify-content: center;
  /* align-items: center; */
  align-self: center;
}
.videos-div {
  width: 100%;
  /* border: 1px solid yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search-and-sort-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* padding-left: 50px; */
  margin-bottom: 20px;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  color: white;
  font-size: 18px;
}
.search-div {
  width: 40vw;
  border: 1px solid #949191;
  margin-right: 1vw;
}
.filter-button {
  border-radius: 5px;
  background-color: rgba(255, 30, 30, 1);
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12;
  height: 60;
  justify-content: center;
  border: rgba(255, 30, 30, 1);
  margin-right: 3;
  margin-left: 70;
  cursor: pointer;
  width: 20%;
}
.filter-section {
  width: 250px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  text-align: left;
  margin-bottom: 20px;
  /* border: 1px solid blue; */
}

.filters-div {
  width: 80vw;
  /* border: 1px solid pink; */
  /* background: linear-gradient(141.23deg, #4f4f4f 6.35%, #000000 139.03%); */
  /* height: 100px; */
  margin-left: 80px;
  margin-top: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
}
.dont-show-filter-div {
  display: none;
}
.filters-div {
  display: flex;
  /* border: 1px solid green; */
  flex-wrap: wrap;
}

.dropdown-style {
  border: 1px solid #949191;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 5px 14px;
  display: flex;
  /* margin-left: 80px; */
}
.trending-videos-grid {
  margin-top: 20px;
  display: grid;
  /* border: 1px solid yellow; */
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: 100%;
  /* margin-left: 80px; */
  margin-bottom: 150px;
}
.trending-videos-grid-x {
  margin-top: 20px;
  display: grid;
  /* border: 1px solid yellow; */
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: 100%;
  /* margin-left: 80px; */
  margin-bottom: 150px;
}
.workout-videos-grid {
  margin-top: 20px;
  display: grid;
  /* border: 1px solid yellow; */
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: 80vw;
  margin-left: 80px;
  /* margin-bottom: 150px; */
}
.catagory-and-view-more {
  display: flex;
  flex-direction: row;
  width: 96%;
  /* border: 1px solid green; */
  justify-content: space-between;
  background: #000000;
  padding: 10px;
}
.workout-video-card,
.workout-video-card:hover,
.workout-video-card:focus {
  display: flex;
  flex-direction: column;
  width: 96%;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 3px;
}

.workout-video-card-x,
.workout-video-card-x:hover,
.workout-video-card-x:focus {
  display: flex;
  flex-direction: column;
  width: 96%;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 3px;
}
.workout-video-card-s,
.workout-video-card-s:hover,
.workout-video-card-s:focus {
  display: flex;
  flex-direction: column;
  width: 320px;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 15px;
}
.workout-video-card-f,
.workout-video-card-f:hover,
.workout-video-card-f:focus {
  display: flex;
  flex-direction: column;
  width: 310px;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 3px;
}

.remove-link-color {
  text-decoration: none;
  color: red;
  margin-left: -20%;
}
.banner-image {
  margin-top: -300px;
  padding-bottom: 100px;
  width: 80%;
  height: 570px;
}
.circular-btns-div {
  display: flex;
  flex-direction: row;
  height: 55px;
}
.workout-catagory-text {
  margin-left: 5px;
  font-size: 18px;
  text-align: right;
  font-weight: 600;
  margin-top: 10px;
  color: #ff0101;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.workout-title-text {
  font-size: 17px;
  text-align: left;
  font-weight: 600;
  margin-top: 15px;
  width: 90%;
  margin-bottom: -1px;
  color: white;
  display: -webkit-box;
  /* max-width: 25vw; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.duration-and-views-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2px;
  align-items: flex-start;
  margin-top: 5px;
}
.duration-and-views-text {
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  justify-self: center;
  align-self: center;
}
.filter-big-title {
  font-size: 28px;
  font-weight: 600;
  text-align: left;
}
.filter-small-title {
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  margin-top: -10px;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
  margin-top: 3px;
}
.clock-n-eye {
  filter: invert(18%) sepia(91%) saturate(5407%) hue-rotate(360deg)
    brightness(110%) contrast(128%);
}
.saturate {
  filter: saturate(3);
}
.grayscale {
  filter: grayscale(100%);
}
.contrast {
  filter: contrast(160%);
}
.brightness {
  filter: brightness(0.25);
}
.blur {
  filter: blur(3px);
}
.invert {
  filter: invert(100%);
}
.sepia {
  filter: sepia(100%);
}
.huerotate {
  filter: hue-rotate(180deg);
}
.rss.opacity {
  filter: opacity(50%);
}
.white-workout-container {
  width: 100vw;
  height: auto;
  transform: skew(0, 5deg);
  /* border: 1px solid green; */
  background-color: #fff;
  /* border-bottom: 2px solid black; */
}

.white-workout-content {
  transform: skew(0, -5deg);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.getworkout-div-white {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 50vh; */
  background-color: #fff;
  width: 100%;
  color: #000;
  transform: skew(0deg, -5deg);
}

.workout-left-section {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  justify-content: flex-start;
  justify-self: center;
  align-self: center;
  align-items: flex-start;
  padding: 50px;
  padding-left: 100px;
  width: 50%;
}
.workout-right-section {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 50px;
  justify-content: flex-end;
  align-items: flex-end;
  /* border: 1px solid red; */
  width: 50%;
}
.white-workout-title {
  font-size: 25px;
  font-weight: 700;
  width: 60%;
  /* border: 1px solid red; */
  text-align: left;
  /* padding-left: 40px; */
}
.white-workout-text {
  font-size: 18px;
  font-weight: 500;
  width: 60%;
  margin-top: -10px;
  text-align: left;
  /* padding-left: 40px; */
}
.all-workout-catagories-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-self: center;

  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  /* border: 1px solid red; */
}
.workout-category-and-video-div {
  display: flex;
  width: 100%;
  /* border: 1px solid yellow; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.workout-catagory-videos {
  display: flex;
  width: 95%;
  /* border: 1px solid red; */
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-self: flex-start;
  background: #000000;
}
.workout-catagory-videos::-webkit-scrollbar {
  height: 1px;
}

.workout-category-videos {
  display: flex;
  width: 95%;
  /* border: 1px solid red; */
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  /* overflow-x: scroll; */
  overflow-y: hidden;
  justify-self: flex-start;
  background: #000000;
}
.workout-category-videos::-webkit-scrollbar {
  height: 1px;
}

/* .workout-catagory-videos::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 10px;
  width: 2px;
}
.workout-catagory-videos::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
} */
.blogs-viewmore {
  display: flex;
  background-color: white;
  flex-direction: column;
  /* padding-bottom: 100px; */
  margin-top: 20px;
  color: white;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
  width: 99.8%;
  /* border: 1px solid green; */
}
.category-name-videos-container-div {
  display: flex;
  background-color: black;
  flex-direction: column;
  /* padding-bottom: 100px; */
  margin-top: 20px;
  color: white;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
  width: 85%;
  /* border: 1px solid green; */
}
.category-name-div {
  display: flex;
  width: 100%;
  justify-self: center;
  align-self: center;
  /* border: 1px solid yellow; */
}

.category-videos-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-self: center;
  align-self: center;

  /* border: 1px solid red; */
}
.dont-show {
  display: none;
  font-size: 0px;
}
.show {
  border: 1px solid green;
}
.react-workout-player {
  width: 100%;
  height: 50%;
}
.red-color {
  color: red;
}
.bottom-drawer .ant-drawer-title {
  margin: 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 22px;
  background-color: rgb(0, 0, 0);
}
.bottom-drawer .ant-drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  color: rgba(255, 255, 255, 0.85);
  background: rgb(0, 0, 0);
}
.bottom-drawer .ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}

.apply-filter-btn,
.apply-filter-btn:hover,
.apply-filter-btn:active,
.apply-filter-btn:focus {
  border-radius: 5px;
  background-color: rgba(255, 30, 30, 1);
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12;
  height: 60;
  justify-content: center;
  border: rgba(255, 30, 30, 1);
  margin-right: 3;
  margin-left: -70px;
  cursor: pointer;
}
.post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  margin: 20px auto;
  padding: 15px 10px;
  background: #fff;
}

small {
  border: 2px solid #777;
  border-radius: 7px;
  padding: 5px 12px;
  color: #777;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationItem {
  background: #fff;
  border: 2px solid #666;
  padding: 10px 15px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: blue;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
}

.paginationItem.active {
  border: 1px solid #888;
  color: #888;
  pointer-events: none;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}
.dataContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.react-player__preview {
  background-size: contain !important;
  background-repeat: no-repeat;
  width: 100% !important;
}

@media only screen and (max-width: 1150px) {
  .filter-button {
    font-size: 12px;
    width: 15% !important;
    margin-left: 40px !important;
  }
  .workout-catagory-videos {
    justify-content: flex-start;
  }
  /* .workout-video-card,
  .workout-video-card:hover,
  .workout-video-card:focus {
    width: 350px;
    padding: 5px;
  } */
  .workout-category-videos {
    justify-content: flex-start;
  }
  /* .workout-video-card,
  .workout-video-card:hover,
  .workout-video-card:focus {
    width: 350px;
    padding: 5px;
  } */
  .workout-img-and-details-div {
    width: 90%;
  }
  /* .workout-tag {
    width: 22%;
    font-size: 80%;
  } */
  .share-and-fav-div {
    flex-wrap: nowrap;
  }
  .start-workout-btn,
  .start-workout-btn:hover,
  .start-workout-btn:active,
  .start-workout-btn:focus {
    margin-right: 6px;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    width: 60%;
  }
  .workout-details-paragraph {
    width: 90%;
  }
  .trending-videos-div {
    width: 90%;
  }
  .trending-videos-grid {
    width: 100%;
  }
  .trending-videos-grid-x {
    width: 100%;
  }
  .react-player__preview {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .filter-button {
    font-size: 12px;
    width: 15% !important;
    margin-left: 35px !important;
  }
  .workout-img-and-details-div {
    width: 90%;
  }
  /* .workout-tag {
    width: 22%;
    font-size: 66%;
  } */
  .share-and-fav-div {
    flex-wrap: nowrap;
  }
  .start-workout-btn,
  .start-workout-btn:hover,
  .start-workout-btn:active,
  .start-workout-btn:focus {
    margin-right: 6px;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    width: 60%;
  }
  .workout-details-paragraph {
    width: 90%;
  }
  .trending-videos-div {
    width: 90%;
  }
  .trending-videos-grid {
    width: 100%;
  }
  .trending-videos-grid-x {
    width: 100%;
  }
  .react-player__preview {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100% !important;
  }
}

@media only screen and (max-width: 800px) {
  .workout-img-and-details-div {
    width: 90%;
  }
  /* .workout-tag {
    width: 22%;
    font-size: 55%;
  } */
  .share-and-fav-div {
    flex-wrap: nowrap;
  }
  .start-workout-btn,
  .start-workout-btn:hover,
  .start-workout-btn:active,
  .start-workout-btn:focus {
    margin-right: 6px;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    width: 60%;
  }
  .workout-details-paragraph {
    width: 90%;
  }
  .trending-videos-div {
    width: 90%;
  }
  .trending-videos-grid {
    width: 100%;
  }
  .trending-videos-grid-x {
    width: 100%;
  }
  .react-player__preview {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100% !important;
  }
  .getworkout-div-white {
    flex-direction: column-reverse;
  }
  .ipad-image-hide {
    display: none;
  }
  .duration-and-views-div {
    width: 100%;
    margin-top: 10px;
  }
  .indicator-workout {
    display: none;
  }
  .indicator2-workout {
    display: none;
  }
  .banner-image {
    margin-top: -100px;
    margin-bottom: -50px;
    width: 100%;
    height: auto;
  }
  .background-workout-div {
    height: 200px;
  }
  .filter-button {
    font-size: 12px;
    width: 25% !important;
    margin-top: 40px !important;
    margin-left: 25px !important;
  }
  .icon-in-filter {
    font-size: 12px;
  }
  .search-and-sort-div {
    padding-left: 0px;
    margin-bottom: 10px;
    margin-top: -15px;
  }
  .workout-catagory-text {
    font-size: 13px;
    padding-left: 0px;
  }
  .workout-title-text {
    font-size: 14px;
  }
  .duration-and-views-text {
    font-size: 12px;
  }
  .workout-catagory-videos {
    width: 95%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow: scroll;
    justify-self: center;
    align-self: center;
  }
  .workout-category-videos {
    width: 95%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow: scroll;
    justify-self: center;
    align-self: center;
  }

  .workout-page-content-div {
    justify-self: center;
    width: 100%;
  }
  .workout-category-and-video-div {
    width: 100%;
  }
  .hide-this {
    display: none;
  }
  .filters-div {
    width: 100%;
    margin: 0;
  }
  .filter-cats {
    width: 100%;
    padding: 10px;
    justify-content: space-between;
  }
  .filter-section {
    width: 180px;
  }
  .filter-heading {
    font-size: 18px;
  }
  .workout-video-card,
  .workout-video-card:focus,
  .workout-video-card:hover {
    width: 220px;
    padding: 5px;
  }
  .workout-video-card-s,
  .workout-video-card-s:hover,
  .workout-video-card-s:focus {
    flex-direction: column;
    width: 220px;
  }
  /* .workout-image-section {
    width: 100%;
  } */
}
@media only screen and (max-width: 666px) {
  .workout-video-card,
  .workout-video-card:focus,
  .workout-video-card:hover {
    width: 220px;
    padding: 5px;
  }
  .workout-video-card-x,
  .workout-video-card-x:focus,
  .workout-video-card-x:hover {
    width: 380px;
    padding: 5px;
  }
  .workout-video-card-f,
  .workout-video-card-f:focus,
  .workout-video-card-f:hover {
    width: 220px;
    padding: 5px;
  }
  .filter-button {
    font-size: 12px;
    width: 25% !important;
  }
  .workout-img-and-details-div {
    flex-direction: column;
  }
  .workoutDetails-details-div {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }
  .trending-videos-grid {
    grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
  }
  .trending-videos-grid-x {
    grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
  }
  .workout-image-section {
    width: 100%;
  }
  /* .workout-tag {
    width: 20%;
  } */
}
@media only screen and (max-width: 470px) {
  .remove-link-color {
    margin-left: 0;
  }
  .filter-button {
    font-size: 12px;
    width: 30% !important;
  }
  .workout-video-card,
  .workout-video-card:focus,
  .workout-video-card:hover {
    width: 220px;
    padding: 5px;
  }
  .workout-video-card-x,
  .workout-video-card-x:focus,
  .workout-video-card-x:hover {
    width: 300px;
    padding: 5px;
  }
  .workout-video-card-f,
  .workout-video-card-f:focus,
  .workout-video-card-f:hover {
    width: 220px;
    padding: 5px;
  }
  .background-workout-div {
    height: 300px;
  }
  .workout-title-text {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .duration-and-views-text {
    font-size: 12px;
    white-space: nowrap;
  }
  /* .workout-tag {
    font-size: 70%;
    font-weight: bold;
  } */

  .workout-img-and-details-div {
    width: 95%;
    flex-direction: column;
  }
  .workout-details-image-div {
    width: 100%;
  }
  .workout-image-section {
    width: 100%;
  }

  .workoutDetails-details-div {
    width: 100%;
  }
  .workout-details-title-text {
    width: 100%;
    white-space: nowrap;
    overflow: scroll;
  }
  .workout-tags-div {
    width: 100%;
  }
  .duration-and-views-div {
    margin-top: 10px;
    /* margin-top: 10px; */
  }
  .workout-details-paragraph {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .start-workout-btn,
  .start-workout-btn:hover,
  .start-workout-btn:active,
  .start-workout-btn:focus {
    font-size: 12px;
    height: 40px;
    width: 100%;
    border-radius: 9px;
  }
  .circular-btns-div {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .trending-videos-grid {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    flex-wrap: wrap;
  }
  .trending-videos-grid-x {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    flex-wrap: nowrap;
  }
  .trending-videos-div {
    width: 100%;
  }
  .react-player__preview {
    height: 360px !important;
  }
}

@media only screen and (max-width: 350px) {
  .filter-button {
    font-size: 12px;
    width: 35% !important;
    margin-left: 20px !important;
  }
  /* .workout-tag {
    width: 30%;
    font-size: 60% !important;
    font-weight: bold;
  } */
  .home-workout-tags-div {
    justify-content: center;
  }
}

/* @media only screen and (max-width: 1600px) {
  .catagory-and-view-more {
    width: 96%;
  }
  .workout-catagory-videos {
    width: 100%;
    flex: auto;
    justify-content: flex-start;
  }
  .workout-video-card {
    width: 350px;
  }
} */
/* @media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1200px) {
} */
/* @media only screen and (max-width: 1024px) {
  .filter-button {
    width: 15% !important;
  }
} */
/* @media only screen and (max-width: 768px) {
  .filter-button {
    width: 25% !important;
    margin-top: 30px !important;
    margin-left: 25px !important;
  }
} */
/* @media only screen and (max-width: 480px) {
  .filter-button {
    width: 35% !important;
  }
} */
/* @media only screen and (max-width: 320px) {
  .filter-button {
    width: 35% !important;
  }
} */
