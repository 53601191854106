.Cs-Container-Div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #000;
  min-height: 100vh;
  overflow: hidden;
}
.skeletonBtn {
  width: 50%;
}
.fitflexLogoImage {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg)
    brightness(200%) contrast(103%);
}
.Left-Cs-Div {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Left-Cs-Text-Div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;
}
.Cs-text {
  color: #fff;
  text-align: left;
  margin-bottom: 2px;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
}
.Cs-Trial-Btn {
  border-radius: 20px;
  color: #ff5858;
  font-weight: 600;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
}
.Cs-Trial-Btn :hover {
  color: #ff5858;
}
.Right-Cs-Div {
  display: flex;
  width: 40%;
  align-items: flex-start;
  justify-content: flex-start;
}

@media screen and (max-width: 840px) {
  .Left-Cs-Div {
    width: 45%;
  }
  .skeletonBtn {
    width: 55%;
  }
}

@media screen and (max-width: 768px) {
  .Left-Cs-Div {
    width: 40%;
  }
}

@media screen and (max-width: 666px) {
  .Cs-Container-Div {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15px;
  }
  .Left-Cs-Div {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .Left-Cs-Text-Div {
    align-items: center;
  }
  .Right-Cs-Div {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .Cs-Container-Div {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15px;
  }
  .Left-Cs-Div {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .Left-Cs-Text-Div {
    align-items: center;
  }
  .Right-Cs-Div {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 320px) {
  .Cs-Container-Div {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15px;
  }
  .Left-Cs-Div {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .Left-Cs-Text-Div {
    align-items: center;
  }
  .Right-Cs-Div {
    width: 100%;
    justify-content: center;
  }
}
