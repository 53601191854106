@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");

.startGamediv {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/game/startGame.png");
  background-size: 100% 100%;
  background-position: 75%;
  background-repeat: no-repeat;
  /* object-fit: cover; */
}
.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/game/mainBg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.selectLevelImg {
  width: 30%;
  margin-top: 20px;
}
.levels-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
}
.level-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  background-image: url("../assets/game/level-img.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20%;
  height: 15vh;
  margin-top: 30px;
  color: #737373;
  font-size: 40px;
  font-family: "Itim", cursive;
}
.questionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  background: #fff;
  height: 80vh;
  border-radius: 10px;
  padding-bottom: 50px;
  position: relative;
  margin-top: 60px;
}
.question-text {
  color: #515151;
  font-size: 30px;
  margin: 10px;
  font-family: "Itim", cursive;
  z-index: 1;
  /* margin-top: 140px; */
}

.multipleChoiceImg {
  position: absolute;
  top: 0;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  width: 15%;
  margin-bottom: 100px;
}
.answer-container {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.answer-button {
  width: 40%;
  color: #515151;
  font-family: "Itim", cursive;
  font-size: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px 2px;
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;
}
.answer-button:hover {
  background: #fd866d;
  color: #fff;
}

.congrats-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  background: #fff;
  /* height: 50vh; */
  border-radius: 10px;
  opacity: 0.8;
  color: #515151;
  position: relative;
}
.congrats-primary-text {
  font-size: 25px;
  font-family: "Itim", cursive;
  margin: 5px;
}
.congrats-secondary-text {
  font-size: 35px;
  font-family: "Itim", cursive;
  margin: 0px;
}
.congrats-last-text {
  font-size: 25px;
  font-family: "Itim", cursive;
  margin: 2px;
}

.button-container {
  width: 20%;
  color: #515151;
  font-family: "Itim", cursive;
  font-size: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px 2px;
  background: #fff;
  border-radius: 10px;
  margin-top: 50px;
  cursor: pointer;
}

.skipAndHintButton-container {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 50px;
}
.skipAndHintButton {
  width: 35%;
  font-family: "Itim", cursive;
  background: linear-gradient(96.12deg, #ef3511 1.04%, #ff7253 100%);
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 7px;
  position: relative;
  font-size: 20px;
  cursor: pointer;
}

.successImg {
  margin-top: 30px;
}
.star-img {
  position: absolute;
  left: 50%;
  top: 0;
  right: 50%;
  width: 15%;
  transform: translate(-50%, -50%);
}
.lockimg {
  width: 30%;
}

@media screen and (max-width: 480px) {
  .startGamediv {
    background-image: url("../assets/game/startGameMobile.png");
  }
  .mainContainer {
    background-image: url("../assets/game/mainBgMobile.png");
    overflow-y: scroll;
  }
  .levels-container {
    width: 80%;
    margin-bottom: 20px;
  }
  .level-box {
    width: 30%;
    background: #fff;
  }
  .selectLevelImg {
    width: 90%;
    margin-top: 20px;
  }
  .questionContainer {
    margin-top: 40px;
    width: 90%;
    height: 100%;
    padding-bottom: 0;
  }
  .multipleChoiceImg {
    width: 30%;
  }
  .question-text {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .answer-container {
    flex-direction: column;
    width: 100%;
  }
  .answer-button {
    font-size: 18px;
    width: 90%;
    margin-left: 0;
  }
  .skipAndHintButton-container {
    margin-top: 10px;
    width: 80%;
  }
  .congrats-div {
    width: 90%;
  }
  .button-container {
    width: 90%;
  }
  .successImg {
    width: 70%;
  }
  .congrats-primary-text {
    margin: 0px;
  }
  .congrats-secondary-text {
    margin-bottom: 40px;
  }
  .star-img {
    transform: translate(5%, 280%);
    /* bottom: auto; */
    top: auto;
    left: auto;
    right: auto;
    width: 35%;
  }
  .lock {
    width: 50%;
  }
}
