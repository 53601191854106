.training-plans-container {
  background-color: black;
  color: white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.training-plans-container2 {
  background-color: black;
  color: white;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
}
.training-img-and-description-div {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 83%;
  /* border: 1px solid green; */
  color: white;
  align-items: flex-start;
}
.training-plan-thumbnail-div {
  width: 55%;
  display: flex;
  margin-right: 10px;
  height: 100%;
}
.remove-margin-tp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.training-plan-details-div {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
}
.share-and-fav-training-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.circular-training-div {
  border-radius: 50%;
  padding-left: 18px;
  padding-right: 18px;
  /* width: 10%; */
  border: 1px solid white;
  cursor: pointer;
  /* background: linear-gradient(80.19deg, #ff1e1e 36.55%, #ff6f1e 103.92%); */
  margin-right: 5px;
  margin-left: 5px;
}
.add-training-plan-btn,
.add-training-plan-btn:hover,
.add-training-plan-btn:focus,
.add-training-plan-btn:active {
  background: linear-gradient(110.19deg, #ff1e1e 36.55%, #ff6f1e 103.92%);
  color: white;
  margin-right: 30px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 50px;
  height: 55px;
  width: 100%;
  border: none;
  outline: 0;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
}
.training-plan-description {
  width: 83%;
  text-align: justify;
  font-size: 1.2em;
  color: white;
  justify-self: center;
  align-self: center;
  margin-bottom: 20px;
}
.training-plan-small-title-text {
  color: white;
  font-weight: 600;
  font-size: 2em;
  text-align: left;
  /* margin-left: 140px; */
}
.more-training-plans-div {
  display: flex;
  width: 83%;
  justify-self: center;
  margin-bottom: 150px;
  align-self: center;
  /* border: 1px solid yellow; */
  flex-direction: column;
}
.other-training-plans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  /* margin-top: 20px; */
  width: 100%;
  align-self: center;
  /* margin-left: 20px; */
}
.red-text {
  color: #ff1e1e;
  font-size: 1.5em;
  text-align: left;
  /* margin-top: 30px; */
  margin-bottom: 0px;
}
.training-plan-title-text {
  color: white;
  font-weight: 700;
  font-size: 2em;
  text-align: left;
}
.views-and-time-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: -10px;
  align-items: flex-start;
}
.views-and-time-text {
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  justify-self: center;
  align-self: center;
}
.training-plans-tags-div {
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  /* border: 1px solid yellow; */
  flex-direction: row;
  flex-wrap: wrap;
}
.training-plans-banner-div {
  height: 350px;
  width: 100%;
  transform: skew(0, -4deg);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding-top: 90px;
  margin-bottom: 15px;
  margin-top: -10vh;
  background: linear-gradient(141.23deg, #000000 6.35%, #4f4f4f 139.03%);
}
.training-plans-banner-content-div {
  transform: skew(0, 4deg);
}
.search-and-date-container-div {
  width: 84%;
  display: flex;
  height: 70px;
  margin-left: 0;
  align-items: center;
}
.search-and-date-div {
  width: 80%;
  justify-self: center;
  align-self: center;
  /* height: 90px; */
  flex-direction: row;
  display: flex;
  /* border: 1px solid green; */
  /* justify-content: center; */
  align-items: center;
}
.search-training-plans-div {
  width: 60%;
  border: 1px solid #949191;
  height: 100%;
  margin-right: 1vw;
}
.days-type-div {
  height: 100%;
  display: flex;
  width: 43%;
  justify-self: center;
  align-self: center;
  /* border: 1px solid red; */
  justify-content: space-evenly;
  align-items: center;

  margin-left: 20px;
}
.dropdown-style2 {
  border: 1px solid #949191;
  height: 43px;
  justify-content: center;
  align-items: center;
  padding: 5px 14px;
  margin-right: 10px;
  width: 30%;
  /* flex-wrap: nowrap; */
  display: flex;
}

.display-all-training-plans-outer-div {
  display: flex;
  width: 100%;
  height: 10px;
  /* border: 1px solid yellow; */
  justify-self: center;
  align-items: center;
  justify-content: center;
}
.display-all-training-plans-div {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  /* height: 10px; */
  /* margin-left: 10%;
  margin-right: 3%; */
  /* border: 1px solid red; */
  justify-self: center;
  align-self: center;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 140px;
}
.add-tp-container {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
}
.content-container-tp {
  width: 80%;
  /* border: 1px solid green; */
  display: flex;
  justify-self: center;
  align-self: center;
  margin-top: 15px;
}
.training-text-and-bell-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
.navbar-tp {
  width: 80%;
  justify-self: center;
  align-self: center;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #ff1e1e;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 3px solid rgb(255, 0, 0);
}
.planner-and-calander-div {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-self: center;
  align-self: center;
  margin-bottom: 70px;
}
.planner-div {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  /* height: 65vh; */
  overflow: hidden;
}
.calander-div {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.add-trainings-btn,
.add-trainings-btn:focus,
.add-trainings-btn:hover,
.add-trainings-btn:visited {
  width: 30%;
  justify-self: center;
  align-self: center;
  height: 40px;
  color: white;
  background: #ff0101;
  border: none;
  border-radius: 6px;
}
.site-calendar-demo-card {
  width: 100%;
  z-index: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: 65px;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;

  border-radius: 0;
  transition: background 0.3s;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
  background-color: #000000;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
  color: #fff;
  font-weight: 700;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
  color: #ff1e1e;
  font-weight: 600;
  font-size: 20px;
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value {
  color: #ff1e1e;
}
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover {
  background: #374044;
}
.ant-picker-calendar-date-today,
.ant-picker-calendar-date-today {
  background-color: #303336;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
  background: #000;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #000;
  color: #fff;
  /* border: 1px solid #d9d9d9; */
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  line-height: 30px;
  background: rgb(0, 0, 0);
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000;
  color: white;
}
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  border-color: #ffffff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #ffffff;
  background: #fff;
  border-color: #ffffff;
}
.ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border-right-color: #ffffff;
}
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value {
  color: rgb(255 255 255 / 85%);
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: #374044;
}
.datepicker-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ff1e1e;
}

.custom-modal-styles .ant-modal-header {
  padding: 16px 24px;
  color: rgb(255 0 0 / 85%);
  background: black;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.custom-modal-styles .ant-modal-title {
  margin: 0;
  color: #ff1e1e;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 1px;
  word-wrap: break-word;
}
.custom-modal-styles > .ant-modal-content {
  position: relative;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid #342d2d;
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
}
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  color: #fff;
  border-color: #ff1818;
  background: #ff1e1e;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  display: flex;
  padding: 0;
  color: rgb(0 0 0 / 85%);
  font-size: 24px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  padding: 8px 11px 8px;
  position: relative;
  align-items: center;
  background: #000000;
  border: 1px solid #979797;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
  border-radius: 8px;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: white;
  font-size: 18px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-calendar-header {
  display: none;
}
.display-false {
  display: none;
}

.my-subscribed-plan-card-div {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  width: 105%;
  margin-left: -3%;
}
.subed-plan-container-div {
  width: 100%;
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.subed-plan-title {
  color: #ff1e1e;
  font-size: 18px;
  font-weight: 700;
  margin-top: 5px;
}
.subed-plan-name {
  font-size: 18px;
  font-weight: 700;
  color: white;
  margin-top: -20px;
  margin-bottom: 0px;
}
.modal-confirm-button,
.modal-confirm-button:hover,
.modal-confirm-button:focus,
.modal-confirm-button:active {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  width: 100%;
  height: 40px;
  justify-self: center;
  align-self: center;
  color: white;
  font-size: 18px;
  letter-spacing: 2px;
  border-radius: 8px;
  border: 1px solid #f83f3f;
  /* outline: none ; */
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: transparent;
  color: #979797;
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  font-size: 18px;
  border-radius: 8px 0px 0px 8px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: transparent;
  color: #979797;
  height: 45px;
  font-size: 18px;
  border-radius: 0px 8px 8px 0px;
}
.modal-cancel-button,
.modal-cancel-button:hover,
.modal-cancel-button:focus,
.modal-cancel-button:active {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 48%;
  height: 35px;

  justify-self: center;
  align-self: center;
  color: black;
  font-size: 18px;
  letter-spacing: 2px;

  /* outline: none ; */
}
.btns-div-confirm-cancle {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 40px;
  margin-bottom: -30px;
}
.modal-heading-custom {
  color: white;
  justify-self: center;
  display: flex;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  color: white;
}
.select-time-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.select-hrs {
  width: 30%;
}
.Capsule-card-div {
  display: flex;
  flex-direction: row;
  border: 1px solid #2e2e2e;
  border-radius: 14px;
  height: 80px;
  margin-bottom: 10px;
  /* cursor: pointer; */
  background-color: #2e2e2e;
}
.setActivity-style a {
  color: #d9d9d9;
  background-color: transparent;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  width: 102%;
  display: flex;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #979797;
}
.training-plan-card,
.training-plan-card:hover,
.training-plan-card:focus {
  display: flex;
  flex-direction: column;
  width: 24%;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 5px;
}
.trainingplan-card-image {
  width: 130%;
  height: 200px;
}
@media only screen and (max-width: 1300px) {
  .search-and-date-container-div {
    width: 85%;
  }
  .display-all-training-plans-div {
    width: 86%;
  }
}
@media only screen and (max-width: 1156px) {
  .search-and-date-container-div {
    width: 87%;
  }
  .display-all-training-plans-div {
    width: 88%;
  }
}

@media only screen and (max-width: 1024px) {
  .training-plan-title-text {
    font-size: 1.5em;
  }
  .training-plans-tags-div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .other-training-plans {
    width: 100%;
    justify-content: flex-start;
  }
  .training-plan-card,
  .training-plan-card:focus,
  .training-plan-card:hover {
    width: 32%;
    padding: 10px;
  }
  .training-plan-thumbnail-div {
    height: 100%;
  }
  .display-all-training-plans-div {
    width: 87%;
  }
  .search-and-date-container-div {
    width: 85%;
  }
}

@media only screen and (max-width: 840px) {
  .search-and-date-div {
    flex-direction: column;
    margin-bottom: 40px;
  }
  .add-trainings-btn,
  .add-trainings-btn:focus,
  .add-trainings-btn:hover,
  .add-trainings-btn:visited {
    width: 100%;
  }
  .search-training-plans-div {
    width: 80%;
  }
  .training-plans-banner-div {
    height: 200px;
  }
  .dropdown-style2 {
    padding: 5px 35px;
    font-size: 12px;
  }

  .days-type-div {
    width: 80%;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 10px;
  }
  .training-text-and-bell-div {
    flex-direction: row;
    justify-content: space-between;
  }
  .navbar-tp {
    display: none;
  }
  .planner-and-calander-div {
    flex-direction: column-reverse;
    width: 100%;
    justify-content: center;
    /* align-items: center ; */
  }
  .calander-div {
    margin-left: 10%;
    width: 80%;
  }
  .planner-div {
    width: 100%;
    margin: 0;
    border-right: 0;
    /* padding: 20px; */
  }
  .Capsule-card-div {
    width: 100%;
    padding-right: 20px;
    /* height: 60px; */
  }
  .training-planner-heading {
    font-size: 16px;
    margin-right: 50px;
  }
  .add-activity-modal {
    font-size: 10px;
    margin-top: -20px;
  }
  .capsule-text {
    font-size: 12px;
  }
  .edit-modal-icon {
    margin-top: 15px;
  }
  .edit-modal-icon-activity {
    margin: 0 auto;
  }
  .training-img-and-description-div {
    flex-direction: column;
    width: 90%;
    margin-top: 30px;
  }
  .training-plan-thumbnail-div {
    width: 100%;
    margin: 0;
    height: 100%;
    justify-self: center;
    /* margin-left: -20px;
    margin-top: 20px; */
  }
  .training-plan-details-div {
    width: 100%;
    margin: 0px;
  }
  .more-training-plans-div {
    width: 90%;
  }
}

@media screen and (max-width: 666px) {
  .trainingplan-card-image {
    width: 100%;
    height: auto;
  }
  .remove-margin-tp {
    margin-top: 10px;
  }
  .training-plan-card,
  .training-plan-card:focus,
  .training-plan-card:hover {
    margin-left: 5px;
    width: 100%;
    height: auto;
  }
  .display-all-training-plans-div {
    flex-direction: row;
    justify-content: space-around;
    /* flex-wrap: nowrap ; */
    overflow: scroll;
    width: 80%;
    height: auto;
    margin: 10px;
    margin-bottom: 50px;
  }
  .search-and-date-container-div {
    width: 80%;
  }
  .training-img-and-description-div {
    flex-direction: column;
    width: 90%;
    margin-top: 30px;
  }
  .training-plan-thumbnail-div {
    width: 100%;
    margin: 0;
    height: 100%;
    justify-self: center;
    /* margin-left: -20px;
    margin-top: 20px; */
  }
  .training-plan-details-div {
    width: 100%;
    margin: 0px;
  }
  .more-training-plans-div {
    width: 90%;
  }
}
@media screen and (max-width: 470px) {
  .search-and-date-container-div {
    width: 90%;
  }
  .display-all-training-plans-div {
    width: 90%;
  }
  .training-img-and-description-div {
    flex-direction: column;
    width: 90%;
    margin-top: 30px;
  }
  .other-training-plans {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: -50px;
    overflow-x: scroll;
  }
  .training-plan-thumbnail-div {
    width: 100%;
    margin: 0;
    height: 100%;
    justify-self: center;
    /* margin-left: -20px;
    margin-top: 20px; */
  }
  .training-plan-details-div {
    width: 100%;
    margin: 0px;
  }
  .training-plan-title-text {
    font-size: 18px;
    /* margin-top: -270px;
    margin-bottom: 270px;
    margin-left: -30px; */
  }

  .training-plans-tags-div {
    flex-wrap: nowrap;
    /* margin-top: -55px;
    margin-left: -30px; */
  }
  /* .views-and-time-div {
    margin-top: -270px;
    margin-bottom: 270px;
    margin-left: -30px;
  } */
  .training-plan-description {
    /* margin-top: -180px; */
    /* margin-bottom: 100px; */
    width: 90%;
    font-size: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .add-training-plan-btn,
  .add-training-plan-btn:active,
  .add-training-plan-btn:focus,
  .add-training-plan-btn:hover {
    /* margin-left: -30px; */
    /* justify-self: center ;
    align-self: center ; */
    height: 50px;
    /* margin-top: 80px; */
    width: 100%;
    border-radius: 5px;
    font-size: 14px;
  }
  .red-text {
    display: none;
  }
  .workout-tag {
    white-space: nowrap;
    font-size: 65%;
    font-weight: bold;
  }
  .training-plan-small-title-text {
    font-size: 20px;
    margin-left: -0px;
    margin-bottom: -15px;
  }
  .training-plan-card,
  .training-plan-card:focus,
  .training-plan-card:hover {
    /* padding: 15px; */
    /* padding-right: 0px;
    padding-right: 0px; */
    margin: 0px;
  }
  .more-training-plans-div {
    width: 90%;
  }
  .share-and-fav-training-div {
    justify-content: space-between;
  }
}
