.main-container-ar-div {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url("../assets/images/manJumping.webp");
  background-size: contain;
  background-position: -4% 50%;
  background-repeat: no-repeat;
}

.main-right-container-ar-div {
  width: 80%;
  /* padding: 50px; */
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  min-height: 100vh;
  z-index: 0;
  background-repeat: no-repeat;
  object-fit: cover;
  /* background-position: 50% 50%; */
}
.main-left-container-ar-div {
  width: 52%;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  background: #fff;
  align-items: center;
  padding: 60px;
  min-height: 100vh;
  position: relative;
}
.main-left-heading-ar-one {
  color: rgba(239, 53, 17, 1);
}
.main-left-heading-ar-two {
  margin: 40px 0px 40px 0px;
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 92.6%;
}
.main-left-heading-ar-three {
  color: #434343;
  text-align: center;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  width: 75%;
  line-height: normal;
}
.input-ar-div {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  border: 2px solid #fff;
  background: #fff;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
  margin-left: 19px;
}
.inputfield-ar,
.inputfield-ar:focus {
  background-color: transparent;
  width: 100%;
  align-self: center;
  margin-left: 1vw;
  padding: 5px;
  border: none;
  /* border-left: 1px solid #707070; */
  font-size: 20px;
  outline: none !important;
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  color: #6f6f6f;
  font-size: 16px;
}
.continueButton-ar-new {
  color: #fff;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 71%;
  margin-top: 20px;
  background: linear-gradient(63.69deg, #eb3d30 28.36%, #ec6736 80.63%);
  border-radius: 12px;
  padding: 15px;
  margin-left: 14px;
  border: 0;
}
.enter-number-and-otp-ar-text {
  color: #3f3f3f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
.termsAndConditionBtn-ar {
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 7px;
  /* padding: 8px; */
  border-color: #fff;
  margin-top: 40px;
  width: 33%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.resend-ar-text {
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  border: none;
  margin-top: 15px;
  /* margin-left: 9vw; */
  /* margin-top: 20px; */
}
.otpinputDiv-ar {
  background-color: transparent;
  color: #6f6f6f;
  border: 1px solid #6f6f6f;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 4.5vw !important;
  height: 4.5vw !important;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25) !important;
}
.otp-ar-box {
  width: 26vw;
  justify-content: center !important;
  margin-left: 40px !important;
  /* border: 1px solid green; */
}
.package-ar-text {
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 11.9px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 10px;
  /* width: 20%; */
  /* margin-left: 15px; */
}
.error-ar-text {
  color: rgba(239, 53, 17, 1);
  text-align: center;
  font-family: Manrope;
  font-size: 11.9px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.dropdown-ar-ln,
.dropdown-ar-ln:hover {
  color: #000;
  border: 1px solid white;
  border-radius: 5px;
  padding: 2px;
  /* background-color: #ff1e1e; */
  font-weight: 600;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  width: 15%;
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fff;
}
.ant-modal-close {
  color: #0f0f0f;
}
.ant-modal-content {
  border-radius: 20px;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .main-container-ar-div {
    background-position: -27% 50%;
  }
}

@media screen and (max-width: 480px) {
  .main-container-ar-div {
    flex-direction: column;
    position: relative;
    background-image: url("../assets/images/girl-dancing.webp");
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 15px;
    min-height: 215px;
    padding-bottom: 215px;
    background-position: 20% 50%;
  }
  .main-left-container-ar-div {
    width: 95%;
    z-index: 1;
    margin-top: 20px;
    /* position: absolute; */
    padding: 0px;
    min-height: 0;
    margin-top: 130px;
    padding: 10px;
  }
  .main-right-container-ar-div {
    position: absolute;
    display: none;
    /* z-index: -1; */
  }
  .main-left-heading-ar-two {
    font-size: 20px;
    margin: 20px 0px 20px 0px;
  }
  .main-left-heading-ar-three {
    font-size: 12px;
  }
  .continueButton-ar-new {
    margin-top: 10px;
    width: 90%;
    margin-left: 0;
  }
  .termsAndConditionBtn-ar {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 45%;
  }
  .input-ar-div {
    width: 90%;
    margin-left: 5px;
    padding: 4px;
  }
  .otpinputDiv-ar {
    width: 12.5vw !important;
    height: 12.5vw !important;
  }
  .otp-ar-box {
    margin-left: 5px !important;
  }
  .dropdown-ar-ln,
  .dropdown-ar-ln:hover {
    top: -130px;
    right: 0px;
    width: 20%;
  }
}
@media screen and (max-width: 320px) {
  .main-container-ar-div {
    flex-direction: column;
    position: relative;
    /* background-image: url("../assets/images/girlJumping."); */
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 15px;
    min-height: 215px;
    padding-bottom: 140px;
    background-position: 20% 50%;
  }
  .main-left-container-ar-div {
    width: 100%;
    z-index: 1;
    margin-top: 20px;
    /* position: absolute; */
    padding: 0px;
    min-height: 0;
    margin-top: 50px;
    padding: 10px;
  }
  .main-right-container-ar-div {
    position: absolute;
    display: none;
    /* z-index: -1; */
  }
  .main-left-heading-ar-two {
    font-size: 20px;
    margin: 20px 0px 20px 0px;
  }
  .main-left-heading-ar-three {
    font-size: 12px;
  }
  .continueButton-ar-new {
    margin-top: 10px;
    width: 90%;
    margin-left: 0;
  }
  .termsAndConditionBtn-ar {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 45%;
  }
  .input-ar-div {
    width: 90%;
    margin-left: 5px;
    padding: 4px;
  }
  .otpinputDiv-ar {
    width: 12.5vw !important;
    height: 12.5vw !important;
  }
  .otp-ar-box {
    margin-left: 20px !important;
  }
}
