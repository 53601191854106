.outer-container {
  /* width: 100%; */
  display: flex;
  /* border: 1px solid green; */
  /* margin-top: -10vh; */
  /* padding-top: 40; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-self: center;
  align-self: center;
  align-content: center;
  margin-bottom: 10vh;
  margin-top: -10vh;
}
.steps {
  /* border: 1px solid green; */
  /* margin-left: -25px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #ff1e1e;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #000000;
}
.player {
  /* border: 1px solid green; */
  /* border-radius: 10px; */
  justify-self: center;
  align-items: center;
  width: 100;
  /* border-radius: 40px !important; */
  height: 100;
  margin: 0 auto;
}

.left-div {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin-top: -200px;
  /* margin: 0 auto; */
  padding-left: 12vw;
}

.right-div {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-right: 40px;
}
.small-heading {
  color: #ff5858;
  font-size: 1.2vw;
  font-family: Montserrat;
}
.big-heading {
  color: black;
  font-weight: 700;
  font-size: 30px;
  width: 550px;
  font-family: Montserrat;
}
.left-align {
  text-align: left;

  /* border: 1px solid yellow; */
}
.para-text {
  font-family: Montserrat;
  width: 550px;
  font-size: 16px;
  color: black;
  text-transform: capitalize;
  /* font-weight: 300; */
}
.mobile-vid {
  width: 295px;
  height: 595px;
  border-radius: 40px;
  margin-left: 4em;
  box-shadow: 6px 10px 50px black;
}
.picture {
  filter: drop-shadow(0 0 0.75rem rgb(75, 10, 23));
}
@media screen and (max-width: 1200px) {
  .small-heading {
    font-size: 16px;
    margin-top: 8vh;
  }
}
@media screen and (max-width: 750px) {
  .small-heading {
    margin-top: 600px !important;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}
@media screen and (max-width: 780px) {
  .small-heading {
    margin-top: 300px !important;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}
@media screen and (max-width: 1100px) {
  .outer-container {
    margin-top: 100px;
  }
}
@media screen and (max-width: 1100px) {
  .outer-container {
    margin-top: 100px;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}
@media screen and (max-width: 460px) {
  .outer-container {
    margin-top: -130px !important;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}
@media screen and (max-width: 950px) {
  .outer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}

@media screen and (min-width: 420px) {
  .small-heading {
    margin-top: 200px;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}
@media screen and (max-width: 450px) {
  .small-heading {
    margin-top: 430px !important;
  }
  .mobile-vid {
    width: 150%;
    margin-left: -1em;
  }
}
@media screen and (max-width: 370px) {
  .small-heading {
    margin-top: 300px !important;
  }
}
@media screen and (max-width: 650px) {
  .outer-container {
    flex-direction: column;
    /* border: 1px solid green; */
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .left-align {
    display: flex;
    flex-direction: column;
    /* margin: 0 auto; */
    width: 86vw;
  }
  .big-heading {
    width: 90vw;
    /* border: 1px solid yellow; */
    margin-left: -15px;
    font-size: 20px;
  }
  .small-heading {
    width: 90vw;
    /* border: 1px solid yellow; */
    /* margin-top: 20vh; */

    margin-left: -15px;
    font-size: 15px;
  }
  .picture {
    margin: 0 auto;
    margin-left: 40px;
  }
  .para-text {
    width: 90vw;
    /* border: 1px solid yellow; */
    margin-left: -15px;
    font-size: 20px;
  }
}
@media screen and (max-width: 435px) {
  .small-heading {
    /* margin-top: 500px !important; */
  }
}
@media screen and (max-width: 545px) {
  .big-heading {
    width: 70vw;
  }
}
