.site-layout-content {
  min-height: 650px;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  /* transform: skew(0, 165deg); */
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 20vw;
}
.video-class {
  width: 600px;
  height: 350px;
  /* border: 1px solid green; */
  margin-top: 100px;
  margin-right: 100px;
  margin-left: 10px;
  border-radius: 30px;
  box-shadow: rgba(256, 256, 256, 0.4) 26px 7px 131px 22px;
}

.header-content {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  width: 70vw;
}
.ant-drawer-title {
  margin: 0;
  color: #ff1e1e;
  font-weight: 500;
  font-size: 6vw;
  line-height: 22px;
  background-color: #000;
}
.rings {
  width: 5%;
  position: absolute;
  left: 0;
}

.player-wrapper {
  position: relative;
  padding-top: 20.8%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 10vw;
  margin-left: 50px;
  margin-top: 30px;
  /* margin-bottom: -10px; */
}

/* .react-player__preview {
  border-radius: 20px !important;
} */
.btnDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: -45px;
}
.bton-simple,
.bton-simple:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  border-radius: 9px;
  height: 4vw;
  width: 12vw;
  font-size: 1.2vw;
  background-color: #fff;
}
.icon-div {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: -280px;
  margin-bottom: -20px;
  display: flex;
  flex-direction: column;
}
.rounded {
  margin-bottom: 10px;
  height: 64px;
  width: 64px;

  transform: rotate(-60deg);
  border-top-left-radius: -2px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 12px;
  /* transform: rotate(
-80deg); */
  height: 12px;
  /* border: 1px solid black; */
  border-radius: 50px;
  background-color: #ff1e1e;
  /* align-self: center; */
  /* float: right; */
  margin-left: 24px;
  margin-top: 23px;
}

.react-player {
  position: absolute;
  top: 0;
  height: 320px !important;
  width: 90% !important;
  left: 0;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 6px 10px 70px black;
  margin-top: 80px;
  margin-left: 50px;
}
.play-icon {
  width: 20%;
}
.ant-image-preview-operations-operation:not(:first-child) {
  display: none;
}

/* .ant-card-cover img {
  border-radius: 2px 2px 0 0;
  height: 26vw;
  border-radius: 20px;
} */

.workout-card > .ant-card-cover img {
  border-radius: 2px 2px 0 0;
  height: 250px;
  width: 200px;
  /* border-radius: 10px; */
}

/* .play-icon {
  position: absolute;
  top: 7.6vw;
  right: 14.6vw;
  width: 23%;
  align-self: center;
} */

.videoCard {
  width: 600px;
  height: 400px;
  background-color: rgb(255, 255, 255);
  top: -85px;
  transform: skewY(10deg);
  float: right;
  margin-right: 139px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  border-radius: 20px;
  border: 0px;
}

.banner-text {
  position: absolute;
  left: 4.06%;
  top: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.home-workout-tags-div {
  display: flex;
  /* border: 1px solid yellow; */
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.layout1 {
  color: #fff;
  display: flex;
  flex-direction: row;
  padding-left: 200px;
  background-color: #000;
  height: 210px;
  padding-top: 35px;
  justify-content: space-between;
  /* transform: skewY(15deg); */
}
.title {
  /* width: 50%; */
  width: 100%;

  font-style: normal;
  font-weight: bold;
  /* font-size: 35px; */
  font-size: 30px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: left;
  /* line-height: 50.5px; */
  color: #ffffff;
  margin-bottom: 20px;
  margin-top: -20px;
}

.indicator {
  position: absolute;
  border: 0px;
  width: 14.1%;
  left: 0px;
  z-index: -1;
  bottom: 0px;
  height: 30px;
  transform: skew(0deg, 0deg);
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.indicator2 {
  position: absolute;
  right: 0px;
  border: 0px;
  width: 10.1%;
  z-index: -1;
  bottom: -27px;
  height: 30px;
  transform: skew(-1deg, -1deg);
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.title-secondary {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  /* line-height: 30px; */
  word-wrap: break-word;
  letter-spacing: -0.02em;
  /* text-transform: capitalize; */
  color: #a9a9a9;
  /* width: 100%; */
  width: 90%;
  margin-bottom: 50px;
  /* width: 30%; */
}
.title-tertiary {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  text-align: left;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #ff1e1e;
}

.logo {
  float: left;

  font-family: "Gotham";
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  color: #ff1e1e;
  margin: 18px 24px 16px 0;
}

.logo-right {
  float: right;
  font-family: "Gotham";
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  color: #ff1e1e;
  margin: 18px 24px 16px 0;
}
/* .ant-row-rtl .logo {
  float: left;
  margin: 30px 0 16px 24px;
} */

.ant-layout-header {
  background-color: rgba(0, 0, 0, 1);
  height: 100px;
  width: 100%;
  z-index: 1;
  align-self: center;
}

.ant-menu.ant-menu-dark {
  background-color: rgba(0, 0, 0, 1);
  display: flex;

  /* justify-content: center; */
  justify-content: space-evenly;
  margin-top: 20px;
  z-index: 1;
  text-transform: uppercase;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  /* line-height: 20px; */
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu.ant-menu-dark .ant-menu-item {
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}

.workout-section {
  margin-top: 30px;
  margin-bottom: -50px;
  padding-left: 15vw;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.workout-heading1 {
  font-weight: 500;
  margin-top: -30px;
  font-size: 1.2vw;
  line-height: 137%;
  text-transform: uppercase;
  color: #ff5858;
  text-align: left;
}

.workout-heading2 {
  font-style: normal;
  font-weight: 700;
  font-size: 5vh;
  width: 55%;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: left;
  margin-bottom: 0px;
}

.workout-heading3 {
  font-style: normal;
  font-style: 500;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: left;
  margin-bottom: 30px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  height: 400px;
  width: 90%;
  margin-bottom: 21px;
  border: 0px solid rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  box-shadow: 2px;
  justify-self: center;
  align-self: center;
  box-shadow: 0px 30px 40px grey;
  margin-right: 10vw;
}

.line {
  border-right: 1px solid rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 0.3);
  /* border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; */
  position: relative;
}

.line:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.line:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.myText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.myDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 20px;
  padding-top: 60px;
}

.categories {
  padding-left: 14vw;
  margin-top: 12vw;
  margin-bottom: 5vw;
}

.training-plans {
  display: flex;
  /* text-align: flex-start; */
  margin-top: 30;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-left: 15vw; */
}

.arrow {
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 2px;
  right: 85%;
}
.sidebar-menu-text,
.sidebar-menu-text:active,
.sidebar-menu-text:hover {
  color: white;
  font-size: 4vw;
  font-weight: 600;
}
.hidden-sidebar {
  display: none;
}
@media only screen and (max-width: 470px) {
  .hide-header {
    display: none;
  }
  .hidden-sidebar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px solid green; */
  }
  .rings {
    display: none !important;
  }
}
@media only screen and (max-width: 370px) {
  .title {
    width: 100% !important;
    font-size: 20px !important;
    line-height: 25px !important;

    /* border: 1px solid green; */
  }
  .title-secondary {
    width: 100% !important;
    font-size: 16px !important;
    line-height: 25px !important;
  }
}
@media only screen and (max-width: 533px) {
  .wrapper {
    width: 90% !important;
  }
}

@media only screen and (min-height: 1300px) {
  .react-player {
    height: 390px !important;
  }
}
@media only screen and (min-height: 1400px) {
  .react-player {
    height: 355px !important;
  }
}
@media only screen and (min-height: 1205px) {
  .react-player {
    height: 370px !important;
  }
}
@media only screen and (min-height: 600px) {
  .react-player {
    height: 370px !important;
  }
}
@media only screen and (min-height: 900px) {
  .workout-heading2 {
    font-style: normal;
    font-weight: 700;
    font-size: 3vh;
    width: 70%;
    line-height: 70px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: left;
    margin-bottom: 0px;
  }
  .react-player {
    margin-left: 70px !important;
    /* width: 95% !important; */
    height: 325px !important;
  }
}
@media only screen and (min-height: 775px) {
  .react-player {
    margin-left: 70px !important;
    /* width: 95% !important; */
    height: 300px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .wrapper {
    height: 800px;
    width: 47%;
  }

  .line {
    border-right: 0px solid;
    border-bottom: 1px solid #fff;
  }
  .line:first-child {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 20px;
  }
  .line:last-child {
    border-bottom-left-radius: 20px;
  }
}

@media only screen and (min-width: 1600px) {
  .site-layout-content {
    /* transform: skew(0, 0deg); */
    /* height: 65vh; */
  }
  .layout1 {
    transform: skew(0, 0deg);
  }
  .subheader-download-links {
    width: 180px !important;
  }
}

@media only screen and (min-width: 1500px) {
  .site-layout-content {
    /* transform: skew(0, 0deg); */
    height: 65vh;
  }
  .catagories-div {
    width: 80% !important;
  }

  .icon-div {
    margin-top: 10px;
    transform: skew(0, 0deg);
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-left: 100px;
    right: 0;
    /* top: 10; */
  }
  .header-btn,
  .header-btn:hover,
  .header-btn:focus {
    width: 20vw !important;
  }
}

.carousel-image {
  width: 100%;
  height: 550px;
  border-radius: 25px;
}
.Next-Button,
.Next-Button:hover,
.Next-Button:focus {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  font-size: 20px;
  outline: none;
  border: none;
  color: white;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deactivate-Button,
.deactivate-Button:hover,
.deactivate-Button:focus {
  background: transparent;
  font-size: 1vw;
  border-radius: 15px;
  padding: 20px;
  width: 42%;
  outline: none;
  border: 1px solid #ff1e1e;
  color: #ff1e1e;
  text-transform: capitalize;

  display: flex;
  justify-content: center;
  align-items: center;
}
.training-plan-scroll-div {
  margin-top: 700px;
}
@media only screen and (max-width: 950px) {
  .video-class {
    margin-left: 10px !important;
  }
  .carousel-image {
    height: 320px !important;
  }
  .training-plan-scroll-div {
    margin-top: 500px !important;
  }
}
@media only screen and (max-width: 790px) {
  .video-class {
    margin-left: 40px !important;
    width: 600px;
  }
}
@media only screen and (max-width: 665px) {
  .video-class {
    margin-left: 80px !important;
    border-radius: 3px;
    /* justify-self: center; */
    /* align-self: center; */
    width: 100vw;
    margin-top: 10px;
  }
  .deactivate-Button,
  .deactivate-Button:hover,
  .deactivate-Button:focus {
    font-size: 3vw;
    width: 85%;
    margin-left: 30px;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 322px) {
  .video-class {
    margin-left: 70px !important;
    width: 95vw !important;
    height: 300px !important;
    margin-top: 60px;
  }
  .deactivate-Button,
  .deactivate-Button:hover,
  .deactivate-Button:focus {
    font-size: 3vw;
    width: 85%;
    margin-left: 30px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .site-layout-content {
    width: 100%;
    height: 800px;
    background: rgba(0, 0, 0, 1);
    transform: skew(0, 165deg);
    margin-bottom: 20vw;
  }
  .header-content {
    width: 80vw;
  }
  .video-class {
    justify-self: center;
    align-self: center;
    display: flex;
    margin-left: 170px;
    /* margin-top: 150px; */
  }

  .layout1 {
    color: #fff;
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    background-color: #000;
    padding-top: 35px;
    justify-content: space-between;
    transform: skewY(15deg);
  }

  .title {
    width: 80%;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    text-align: left;
    line-height: 40.5px;
    color: #ffffff;
    margin-bottom: 12px;
  }
  .title-secondary {
    font-size: 16px;
    text-align: left;
    line-height: 30px;
    word-wrap: break-word;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #ffffff;
    width: 70%;
  }
  .title-tertiary {
    font-size: 12px;
    line-height: 30px;
    text-align: left;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #ff1e1e;
  }
  .player-wrapper {
    position: relative;
    padding-top: 23.25%;
    width: 60%;
    height: 400px;
    margin-right: 5vw;
    margin-top: 40px;
    margin-bottom: 100px;
  }
  .react-player {
    margin-left: 200px;
  }

  .workout-heading1 {
    font-size: 16px;
    margin-top: -22px;
  }

  /* .react-player__preview {
    border-radius: 20px !important;
  } */
  .icon-div {
    z-index: 1;
    position: absolute;
    display: block;
    /* left: 40%; */
    /* transform: skewY(
13deg); */

    right: 0;
    bottom: -430px;
  }
  .rounded {
    margin-bottom: 10px;
    height: 50px;
    width: 50px;
    transform: rotate(-60deg);
    border-top-left-radius: -2px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
  }

  .dot {
    width: 6px;
    /* transform: rotate( 
-80deg); */
    height: 6px;
    border: 1px solid #ff1e1e;
    border-radius: 50px;
    background-color: #ff1e1e;
    /* align-self: center; */
    /* float: right; */
    margin-left: 21px;
    margin-top: 20px;
  }

  .arrow {
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 2px;
    right: 80%;
  }
}
.cliped {
  height: 500px;
  width: 500px;
  background: red;
  clip-path: polygon(0 50%, 100% 0, 100% 100%, 98% 100%, 95% 50%, 30% 80%);
}

.trainingCardText1 {
  font-style: normal;
  font-weight: 800;
  font-size: 3vh;
  text-align: left;
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 0px;
  /* margin-top: 5px; */
  /* width: 20vw; */
  /* height: 20vw; */
}

.trainingCardText2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: left;
  /* or 150% */

  letter-spacing: -0.02em;

  /* Primary/Grey */

  color: #707070;
}

.content-card {
  min-height: 4vw;
  background-color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  transform: skewY(-13deg);
  /* border: 1px solid blue; */
}
.catagories-div {
  transform: skewY(13deg);
  margin-top: -40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  width: 80vw;
  /* border: 1px solid red; */
  padding: 60px;
  justify-self: center;
  align-self: center;
  /* grid-template-columns: auto auto auto auto; */
  justify-content: center;
  align-items: center;
  width: 100%;
}

.training-indicator {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4vh;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  width: 51vw;
  color: rgb(255, 255, 255);
  float: left;
  transform: skewY(-13deg);
  margin-top: 5.6vw;
}

.slideShow {
  position: relative;
  transform: skewY(13deg);
  /* border: 1px solid green; */
  /* box-shadow: inset 0 0 12px black; */
  width: 80vw;
  justify-self: center;
  align-self: center;
  display: flex;
  overflow-y: hidden;
}
/* ::-webkit-scrollbar {
  width: 7px;
} */
/* ::-webkit-scrollbar-track {
  background-color: #000;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
} */

.workout-card > .ant-card-body {
  padding: 0px;
}

.cards-section {
  justify-content: center;
  /* border: 1px solid #ff1e1e; */
  /* animation: slide infinite 10s alternate; */
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.cards-section:hover {
  animation-play-state: paused;
}
/* .img-shadow {
  -webkit-mask-image: linear-gradient(to top, transparent 5%, black 100%);
  mask-image: linear-gradient(to top, transparent 5%, black 100%);
} */

/* .img-shadow {
  position: relative;
  max-width: 100%;
  float: left;
  border-radius: 0px 0px 15px 15px;
}

.img-shadow::before {
  content: "";
  position: absolute;
  border-radius: 15px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 -90px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 -90px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 -90px 5px rgba(0, 0, 0, 0.2);
}

.img-shadow img {
  float: left;
} */
.header-btn,
.header-btn:hover,
.header-btn:focus {
  background-color: #ff1e1e;
  color: #fff;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px 80px;
  border: none;
  border-radius: 9px;
  height: 3vw;
  width: 8vw;
  font-size: 1vw;
  font-weight: 700;
  margin-top: 0px;
  width: 20vw !important;
}

.workout-card {
  height: 300px;
  background-color: transparent;
  position: relative;
  /* border: 1px solid red; */
  width: 250px;
  border-radius: 15px;

  margin-right: 20px;
  margin-bottom: 20px;
  z-index: 1;
}
.trainCardPic {
  width: 24vw;
  height: 18vw;
}
.trainCardDiv {
  display: flex;
  flex-direction: row;
  padding-left: 0vw;
  padding-top: 50;
  justify-content: center;
  /* border: 1px solid green; */
}

.linear-gradient {
  position: absolute;
  bottom: 0px;
  width: 302px;
}
.trainCardTextDiv {
  width: 22vw;
}

.small-signup-btn,
.small-signup-btn:active,
.small-signup-btn:visited {
  border-radius: 5px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 11px;
  /* border: 1px solid red; */
  place-self: center;
  align-items: center;
  justify-content: center;
  margin-left: 30vw;
  padding: 10px 15px;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  text-decoration: none;
  color: #fff;
  display: flex;
  height: 40%;
}
.new-categories-img-name {
  background-image: linear-gradient(90deg, #ff7c2a, #bf330f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 35px;
  text-align: left;
  position: absolute;
}
.card-heading {
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  position: absolute;
  bottom: -28px;
  left: 2px;
  /* bottom: 0px;
  left: 25px; */
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #ffffff;
  /* text-shadow: 1px 1px #000; */
  margin-bottom: 10px;
  background-image: linear-gradient(90deg, #ff7c2a, #bf330f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 750px) {
  .react-player {
    margin-top: -30px !important;
  }
}
@media screen and (max-width: 430px) {
  .react-player {
    margin-left: -65px;
  }
  .player-wrapper {
    margin-left: -5wv;
  }
  .icon-div {
    display: none;
  }
  .carousel-image {
    height: 180px !important;
    margin-bottom: 50px;
  }

  /* .workout-heading1 {
    margin-top: 11vh;
  } */
}

@media screen and (max-width: 360px) {
  .video-class {
    justify-self: center;
    align-self: center;
    border-radius: 3px;
    display: flex;
    width: 95%;
    height: 400px;
  }
}
@media screen and (max-width: 450px) {
  .carousel-image {
    height: 180px !important;
  }
  .carousel-image-class {
    width: 90% !important;
    left: 5% !important;
  }
}
@media screen and (max-width: 610px) {
  .icon-div {
    position: absolute;
    margin-top: 150px;
  }
  .new-catgories-container {
    flex-direction: column !important;
    margin-left: 0 !important;
  }
  .new-cat-left-container {
    width: 100% !important;
    padding-left: 5px !important ;
    padding-right: 5px !important ;
    margin-top: -80px !important;
  }
  .CTA-new-cat {
    margin: 0 !important;
  }
  .new-categories-tiles-div {
    flex-direction: column !important;
  }
  .new-categories-column {
    flex-direction: row !important;
    margin-top: 10px !important;
    margin-bottom: -80px !important;
    justify-content: space-between !important;
    /* : space-between !important; */
  }
  .new-cat-right-container {
    flex-direction: column !important;
    width: 90% !important;
    padding-left: 5px;
    margin-bottom: 140px;
    /* place-self: center !important; */
  }
  .new-img-style-category {
    width: 120px !important ;
    height: 180px !important;
    border-radius: 0px !important;
  }
  .new-categories-description {
    font-size: 16px !important;
    margin-top: -20px !important;
  }
  .new-workout-card {
    width: 10% !important;
  }
  .new-img-style-category {
    width: 100px !important;
    height: 120px !important;
    /* margin-left: -0px !important; */
    margin-right: -20px !important;
  }
  .card-heading {
    font-size: 10px !important;
    bottom: 59px !important;
    left: 2px;
  }
}

@media screen and (max-width: 433px) {
  /* .training-plans {
    margin-top: -150px;
  } */

  .player-wrapper {
    padding-top: 28%;
  }
}
@media screen and (max-width: 768px) {
  .workout-section {
    padding-left: 4vw;
    margin-bottom: 150px;
  }
  .header-btn,
  .header-btn:hover,
  .header-btn:focus {
    width: 30vw !important;
    font-size: 18px;
  }
  /* .hidden {
    display: none;
  } */
  .categories {
    padding-left: 4vw;
  }
  .training-plans {
    padding-left: 4vw;
  }

  .workout-heading2 {
    font-style: normal;
    font-weight: 700;
    font-size: 3vh;
    width: 100%;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: left;
    margin-bottom: 45px;
  }
  .trainCardDiv {
    flex-direction: column;
    justify-self: center;
    align-self: center;
  }
  .trainCardPic {
    width: 75vw;
    height: 200px;
  }
  .trainCardTextDiv {
    width: 75vw;
  }
  .layout1 {
    padding-left: 20px;
  }
  .player-wrapper {
    position: relative;
    padding-top: 50%;
    width: 80%;
    height: 400px;
    margin-right: 5vw;
  }

  .header-btn,
  .header-btn:hover,
  .header-btn:focus {
    width: 80vw !important;
    height: 40px;
  }
  .rounded {
    margin-bottom: 10px;
    margin-right: 10px;
    height: 50px;
    flex-direction: row;
    width: 50px;
    transform: rotate(0deg);
    border-top-left-radius: -2px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
  }
}
.weight-dropdown .ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: rgb(6, 183, 62);
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #171717;
}
.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100%;
}
/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
.container-div-workout-videos-homepage {
  width: 100%;
  margin-top: 40px;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.heading-div-workout {
  width: 89%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.workout-home-videos {
  display: flex;
  flex-wrap: nowrap;
  width: 93%;
  overflow-x: scroll;

  /* border: 1px solid red; */
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  padding-left: 30px;
}
.workout-home-videos::-webkit-scrollbar {
  display: none;
}

/* .workout-home-videos::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 10px;
  width: 2px;
}
.workout-home-videos::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
} */
.all-goals-div {
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-self: center;
  align-self: center;
}
.goal-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border: 2px solid grey;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  background-color: transparent;
}
.goal-card:hover,
.goal-card-active {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border: 2px solid #ff603b;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  background-color: #582a29;
}
.carousel-image-class {
  width: 70%;
  position: absolute;
  left: 15%;
  top: 20%;
}
.header-menu-item {
  font-weight: 600;
  font-size: 10px;
  color: white;
}
.trending-videos-like-blogs-container {
  margin-top: 20px;
  display: flex;
  /* padding-bottom: 20px; */
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 60px;
  width: 84%;
}
.left-trending-video {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.right-trending-video {
  width: 45%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 500px;
  margin-left: 10px;
}
.right-trending-video::-webkit-scrollbar {
  display: none;
}

/* .right-trending-video::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 10px;
}
.right-trending-video::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
} */

.trending-thumbnail-big {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.eye-and-clock-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.scrollbar-trending-image {
  width: 45%;
  border-radius: 10px;
}
.trending-scrollbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}
.homescreen-title-div {
  width: 84%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.homescreen-title-text {
  color: #ff5858;
  justify-self: flex-start;
  align-self: flex-start;
  display: flex;
  margin-top: 30px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
}
.trending-video-title {
  color: #fff;
  justify-self: flex-start;
  align-self: flex-start;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
}
.trending-title-sidebar {
  color: #fff;
  justify-self: flex-start;
  align-self: flex-start;
  display: flex;
  text-align: left;
  margin-left: 6px;
  font-size: 12px;
  font-weight: 600;
}
.sideScroll-trending-tags {
  display: flex;
  background: #1a1a1a;
  color: rgba(255, 255, 255, 0.65);
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  border-radius: 12px;
  /* width: 22%; */
  text-align: center;
  font-weight: 500;
  font-size: 85%;
}
@media only screen and (max-width: 470px) {
  .sideScroll-trending-tags {
    display: none;
  }
  .workout-home-videos {
    padding: 0px;
  }
}
@media only screen and (max-width: 850px) {
  .homescreen-title-div {
    width: 90%;
  }
  .trending-videos-like-blogs-container {
    width: 90%;
    height: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .sideScroll-trending-tags {
    display: none;
  }
  .left-trending-video {
    width: 100% !important;
  }
  .right-trending-video {
    width: 100% !important;
    margin-left: 0px;
    margin-top: 30px;
    /* margin-bottom: 50px; */
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    height: 100% !important;
    justify-content: space-between;
    align-items: center;
  }

  .featured-trending-vid {
    width: 100%;
  }
  /* .right-trending-video {
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    overflow: scroll !important;
    margin-bottom: -300px !important;
  } */
  .trending-scrollbar {
    flex-direction: column !important;
    display: flex;
    flex-wrap: nowrap !important;
    width: 100%;
    align-items: center;
    /* margin-left: 10px; */
    padding-right: 10px;
  }
  .scrollbar-trending-image {
    width: 16.875em;
    height: 150px;
    object-fit: cover;
  }
  .clock-img-class {
    width: 20px;
    height: auto;
  }
  .duration-view-text {
    font-size: 10px;
  }
  .trending-title-sidebar {
    width: 90%;
    /* margin-left: -5px !important; */
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
  .eye-and-clock-div {
    /* margin: 0; */
    justify-content: flex-start !important;
    align-items: center !important;
  }
}
.new-cat-right-container {
  display: flex;
  flex-direction: row;
}
.new-cat-left-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 120px;
}
.new-catgories-container {
  display: flex;
  flex-direction: row;
  margin-top: 200px;
  margin-left: 14%;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid red; */
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: -200px;
}
.new-categories-title {
  font-size: 22px;
  font-weight: 700;
  color: #ff5858;
  text-align: center;
  margin-left: -55px;
  margin-bottom: 20px;
}
.new-categories-description {
  font-size: 28px;
  font-weight: 600;
  width: 90%;
  margin-top: 50px;
  color: black;
  text-align: center;
}
.new-categories-tiles-div {
  /* border: 1px solid green; */
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.new-categories-column {
  display: flex;
  flex-direction: column;

  /* border: 1px solid yellow; */
}
.new-workout-card {
  width: 150px;
  margin-bottom: 20px;
  margin-right: 45px;
  height: 200px;
}
.new-img-style-category {
  width: 185px;
  height: 210px;
  border-radius: 0px;
}
.CTA-div-new-cat {
  position: absolute;
  left: 250px;
  top: 400px;
}
.background-style-div {
  position: absolute;
  height: 200px;
  background-color: black;
  width: 100%;
}
