.mainContainerDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}
.calenderAndMealsDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 2;
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
  background: #000;
}
.NutritionFavDiv {
  display: flex;
  width: 75%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.calenderDiv {
  display: flex;
  flex-direction: column;
  width: 85%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
  background-color: rgb(22, 22, 22);
  border-radius: 8px;
  padding: 30px;
}
.CaloriesAndDateDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Calories-Div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
}
.DateDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.weekListDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}
.singleDateDiv {
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
}
.MealsDiv {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
  flex-wrap: wrap;
  margin-left: 80px;
}
.mealCardDiv {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 30px;
}
.titleAndViewMoreDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.dishImageAndContentDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
}
.macroNutrientsDiv {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.nameAndGramDiv {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.nutritionNameText {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.calorieImg {
  width: 3%;
  margin-right: 15px;
}
.horizontalLine {
  display: flex;
  width: 100%;
  border: 2px solid grey;
  margin: 30px 0px;
}
.recommendedMealsText {
  color: rgb(255, 255, 255);
  width: 85%;
  text-align: left;
  padding: 10px;
  margin-top: 20px;
}
.mealImg {
  width: 90%;
  border-radius: 10px;
  cursor: pointer;
}
@media screen and (max-width: 950px) {
  .calenderDiv {
    margin-top: 15px;
    padding: 15px;
    width: 90%;
  }
  .recommendedMealsText {
    font-size: 15px;
  }
  .mealImg {
    width: 100%;
  }
  .horizontalLine {
    margin: 10px 0px;
  }
  .calorieImg {
    width: 5%;
  }
  .DateDiv {
    display: none;
  }
  .weekListDiv {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .singleDateDiv {
    margin-right: 25px;
  }
  .MealsDiv {
    flex-direction: column;
    margin-left: 20px;
  }
  .mealCardDiv {
    width: 95%;
  }
  .dishImageAndContentDiv {
    padding: 10px;
    width: 100%;
  }
  .MealsDiv {
    width: 95%;
  }
  .nutritionNameText {
    width: 100%;
    margin: 2px;
  }
}

@media screen and (max-width: 767px) {
  .calenderDiv {
    margin-top: 15px;
    padding: 15px;
    width: 90%;
  }
  .recommendedMealsText {
    font-size: 15px;
  }
  .mealImg {
    width: 100%;
  }
  .horizontalLine {
    margin: 10px 0px;
  }
  .calorieImg {
    width: 6%;
  }
  .DateDiv {
    display: none;
  }
  .weekListDiv {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .singleDateDiv {
    margin-right: 25px;
  }
  .MealsDiv {
    flex-direction: column;
    margin-left: 20px;
  }
  .mealCardDiv {
    width: 95%;
  }
  .dishImageAndContentDiv {
    padding: 10px;
    width: 100%;
  }
  .MealsDiv {
    width: 95%;
  }
  .nutritionNameText {
    width: 100%;
    margin: 2px;
  }
  .NutritionFavDiv {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 666px) {
  .calenderDiv {
    margin-top: 15px;
    padding: 15px;
    width: 90%;
  }
  .recommendedMealsText {
    font-size: 15px;
  }
  .mealImg {
    width: 100%;
  }
  .horizontalLine {
    margin: 10px 0px;
  }
  .calorieImg {
    width: 10%;
  }
  .DateDiv {
    display: none;
  }
  .weekListDiv {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .singleDateDiv {
    margin-right: 25px;
  }
  .MealsDiv {
    flex-direction: column;
    margin-left: 0px;
  }
  .mealCardDiv {
    width: 95%;
  }
  .dishImageAndContentDiv {
    padding: 10px;
    width: 100%;
  }
  .MealsDiv {
    width: 95%;
  }
  .nutritionNameText {
    width: 100%;
    margin: 2px;
  }
  .NutritionFavDiv {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 480px) {
  .calenderDiv {
    margin-top: 15px;
    padding: 15px;
    width: 90%;
  }
  .recommendedMealsText {
    font-size: 15px;
    width: 90%;
  }
  .mealImg {
    width: 100%;
  }
  .horizontalLine {
    margin: 10px 0px;
  }
  .calorieImg {
    width: 10%;
  }
  .DateDiv {
    display: none;
  }
  .weekListDiv {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .singleDateDiv {
    margin-right: 25px;
  }
  .MealsDiv {
    flex-direction: column;
    margin-left: 0px;
  }
  .mealCardDiv {
    width: 95%;
  }
  .dishImageAndContentDiv {
    padding: 10px;
    width: 100%;
  }
  .MealsDiv {
    width: 92%;
  }
  .nutritionNameText {
    width: 100%;
    margin: 2px;
  }
  .NutritionFavDiv {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 320px) {
  .NutritionFavDiv {
    overflow-x: scroll;
  }
  .calenderDiv {
    margin-top: 15px;
    padding: 15px;
    width: 90%;
  }
  .recommendedMealsText {
    font-size: 15px;
  }
  .mealImg {
    width: 100%;
  }
  .horizontalLine {
    margin: 10px 0px;
  }
  .calorieImg {
    width: 10%;
  }
  .DateDiv {
    display: none;
  }
  .weekListDiv {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .singleDateDiv {
    margin-right: 25px;
  }
  .MealsDiv {
    flex-direction: column;
    margin-left: 0px;
  }
  .mealCardDiv {
    width: 95%;
  }
  .dishImageAndContentDiv {
    padding: 10px;
    width: 100%;
  }
  .MealsDiv {
    width: 95%;
  }
  .nutritionNameText {
    width: 100%;
    margin: 2px;
  }
}
