p {
  font-family: "Inter var", sans-serif;
}
label {
  font-family: "SF Pro Display", "Montserrat", sans-serif;
}
h1 {
  font-family: "Inter var", sans-serif;
}
.AboutYouContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  /* justify-content: center;
    align-items: center; */
}
.leftImageContainer {
  background-image: linear-gradient(
      180deg,
      rgba(49, 49, 49, 0.38) 0%,
      #0c0c0c 94.09%
    ),
    url("https://fitflexapp.com/static/media/sigma.25212cf6.png");
  width: 60%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.RightDiv {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* background: linear-gradient(180deg, #1e1e1e 0%, #0c0c0c 94.09%); */
  background: #1e1e1e;
  padding: 20px;
}
.RightDivHeadings {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.RightDivTitle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 10px;
}
.RightDivTitleText {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0;
}
.RightDivSc {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 7px;
}
.RightDivScText {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #878787;
}
.InputsDiv {
  flex-direction: column;
  display: flex;
  width: 95%;
  margin-left: 20px;
}
.imgAndInputDiv {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.titleAndInputDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  margin-left: 5px;
}
.nameDiv {
  background: #0e0e0e;
  border-radius: 12px;
  width: 90%;
  border: 0;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nameInput {
  background: #161616;
  border-radius: 12px;
  width: 100%;
  border: 0;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  color: #fff;
  /* margin-top: 30px; */
  /* pointer-events: none; */
}
.birthDiv {
  background: #0e0e0e;
  border-radius: 12px;
  width: 44%;
  border: 0;
  padding: 11.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
input.ant-input {
  /* background: #161616; */
  /* font-size: 14px; */
  /* padding: 16px 12px 4px 11px; */
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #878787;
  opacity: 1; /* Firefox */
  font-size: 16px;
  /* font-family: "Poppins"; */
  font-weight: 500;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #878787;
  font-size: 16px;
  font-weight: 500;
  /* font-family: "Poppins"; */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #878787;
  font-size: 16px;
  font-weight: 500;
  /* font-family: "Poppins"; */
}
.genderAndBirthDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.genderInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #0e0e0e;
  border-radius: 12px;
  width: 44%;
  padding: 10px;
  padding-right: 25px;
  margin-right: 10px;
}
.birthInput {
  border: 0;
  background: rgb(22, 22, 22);
  border-radius: 8px;
  padding: 6px 8px;
  width: 95%;
  font-family: "Inter";
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  /* margin-left: 4px; */
}
.ant-picker-input > Input {
  font-size: 14px;
}
.heightInput {
  background: #0e0e0e;
  border-radius: 12px;
  width: 90%;
  border: 0;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.weightInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #0e0e0e;
  border-radius: 12px;
  width: 44%;
  padding: 8px;
  padding-right: 10px;
  margin-right: 10px;
}
.heightSlider {
  display: flex;
  width: 15%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: height 0.3s ease-in-out;
}
.physicalActivityDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  background: #0e0e0e;
  border-radius: 12px;
  margin-left: 20px;
  padding: 20px;
  margin-top: 20px;
}
.mealActivityDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  background: #0e0e0e;
  border-radius: 12px;
  margin-left: 20px;
  padding: 20px;
}
.GoalTitle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 10px;
}
.GoalTitleText {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #878787;
  margin-bottom: 4px;
}
.GoalDiv {
  width: 92%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.GoalCard {
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0e0e0e;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: #fff;
  padding-top: 30px;
  padding-right: 35px;
  padding-left: 35px;
  padding-bottom: 30px;
  /* padding-bottom: 35px; */
  margin-right: 10px;
}
.GoalCardText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
}
.continueButton {
  width: 85%;
  margin-left: 20px;
  background: linear-gradient(63.69deg, #eb3d30 28.36%, #ec6736 80.63%);
  border-radius: 12px;
  border: 0;
  color: #fff;
  height: 48px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}

/* Float label Css Start */
.floating-label-input {
  position: relative;
  /* display: flex; */
}

.floating-label-input label {
  position: absolute;
  top: 10px;
  left: 35px;
  font-size: 16px;
  padding: 0 5px;
  transition: all 0.2s;
  color: #fff;
  display: block;
  z-index: 2;
}
.floating-label-input:hover label {
  /* apply the :hover selector to the parent container */
  transform: translateY(-12px);
  font-size: 12px;
  color: #fff;
  z-index: 2;
}

.floating-label-input input:focus + label,
.floating-label-input select:focus + label,
.floating-label-input .ant-picker-input:focus + label,
.floating {
  top: 0;
  font-size: 12px;
  color: #fff;
  z-index: 1;
}

/* .floating-label-input .floating {
    top: -20px;
    font-size: 12px;
    color: #fff;
    z-index: 1;
  } */
.floating-label-input .floating-x {
  position: absolute;
  top: 30px;
  left: 40px;
  font-size: 16px;
  padding: 0 5px;
  transition: all 0.2s;
  color: #fff;
  display: block;
  z-index: 2;
}
/* Float Label Css End */

@media screen and (max-width: 840px) {
  .leftImageContainer {
    display: none;
  }
  .RightDiv {
    width: 100%;
    padding: 30px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .InputsDiv {
    margin-left: 10px;
    margin-top: 60px;
    /* justify-content: center;
      align-items: center; */
  }
  .nameInput {
    width: 100%;
  }
  .heightInput {
    width: 100%;
    padding: 20px;
  }
  .weightInput {
    width: 50%;
    margin-right: 2px;
    padding: 20px;
  }
  .GoalDiv {
    width: 100%;
  }
  .continueButton {
    width: 94%;
    margin-left: 10px;
    margin-top: 120px;
  }
  .genderInput {
    width: 50%;
    margin-bottom: 0px;
  }
  .birthDiv {
    width: 47%;
  }
  .nameDiv {
    width: 100%;
    padding: 20px;
  }
  .genderDiv {
    padding: 20px;
  }
  .birthDiv {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .leftImageContainer {
    display: none;
  }
  .RightDiv {
    width: 100%;
    padding: 30px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .InputsDiv {
    margin-left: 10px;
    margin-top: 30px;
    /* justify-content: center;
      align-items: center; */
  }
  .nameInput {
    width: 100%;
  }
  .heightInput {
    width: 100%;
    padding: 20px;
  }
  .weightInput {
    width: 50%;
    margin-right: 2px;
    padding: 20px;
  }
  .GoalDiv {
    width: 100%;
  }
  .continueButton {
    width: 94%;
    margin-left: 10px;
    margin-top: 120px;
  }
  .genderInput {
    width: 50%;
    margin-bottom: 0px;
  }
  .birthDiv {
    width: 47%;
  }
  .nameDiv {
    width: 100%;
    padding: 20px;
  }
  .genderDiv {
    padding: 20px;
  }
  .birthDiv {
    padding: 20px;
  }
  .mealActivityDiv {
    margin-left: 0;
    width: 100%;
  }
  .physicalActivityDiv {
    margin-left: 0px;
    width: 100%;
  }
  .GoalDiv {
    margin-left: 10px;
    padding-bottom: 0px;
  }
  .GoalTitle {
    padding-left: 0px;
  }
  .GoalTitleText {
    font-size: 18px;
  }
  .GoalCard {
    width: 25%;
    height: 25vh;
  }
}

@media screen and (max-width: 666px) {
  .leftImageContainer {
    display: none;
  }
  .RightDiv {
    width: 100%;
    padding: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .InputsDiv {
    margin-left: 10px;
    /* justify-content: center;
      align-items: center; */
  }
  .nameInput {
    width: 100%;
  }
  .heightInput {
    width: 100%;
  }
  .weightInput {
    width: 50%;
    margin-right: 2px;
  }
  .GoalDiv {
    width: 100%;
  }
  .continueButton {
    width: 94%;
    margin-left: 10px;
    margin-top: 50px;
  }
  .genderInput {
    width: 50%;
    margin-bottom: 0px;
  }
  .birthDiv {
    width: 47%;
  }
  .nameDiv {
    width: 100%;
  }
  .mealActivityDiv {
    margin-left: 0;
    width: 100%;
  }
  .physicalActivityDiv {
    margin-left: 0px;
    width: 100%;
  }
  .GoalDiv {
    margin-left: 10px;
    padding-bottom: 0px;
  }
  .GoalTitle {
    padding-left: 0px;
  }
  .GoalTitleText {
    font-size: 18px;
  }
  .GoalCard {
    width: 32%;
  }
}

@media screen and (max-width: 480px) {
  .leftImageContainer {
    display: none;
  }
  .RightDiv {
    width: 100%;
    padding: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .InputsDiv {
    margin-left: 10px;
    margin-top: 0px;
    /* justify-content: center;
      align-items: center; */
  }
  .nameInput {
    width: 100%;
  }
  .heightInput {
    width: 100%;
    padding: 14px;
    margin-top: 0px;
  }
  .weightInput {
    width: 50%;
    margin-right: 2px;
  }
  .GoalDiv {
    width: 100%;
  }
  .continueButton {
    width: 94%;
    margin-left: 10px;
    margin-top: 12px;
  }
  .genderInput {
    width: 50%;
    margin-bottom: 0px;
  }
  .birthDiv {
    width: 47%;
  }
  .nameDiv {
    width: 100%;
    padding: 12px;
    margin-top: 0px;
  }
  /* .genderDiv {
    padding: 10px;
  } */
  .birthDiv {
    padding: 11.5px;
    margin-top: 10px;
  }
  .genderInput {
    margin-top: 0px;
  }
  .mealActivityDiv {
    margin-left: 0;
    width: 100%;
  }
  .physicalActivityDiv {
    margin-left: 0px;
    width: 100%;
  }
  .GoalDiv {
    margin-left: 10px;
    padding-bottom: 10px;
  }
  .GoalTitle {
    padding-left: 0px;
  }
  .GoalTitleText {
    font-size: 18px;
  }
  .GoalCard {
    width: 32%;
  }
}
@media screen and (max-width: 320px) {
  .leftImageContainer {
    display: none;
  }
  .RightDiv {
    width: 100%;
    padding: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .InputsDiv {
    margin-left: 10px;
    /* justify-content: center;
      align-items: center; */
  }
  .nameInput {
    width: 100%;
  }
  .heightInput {
    width: 100%;
  }
  .weightInput {
    width: 50%;
    margin-right: 2px;
  }
  .GoalDiv {
    width: 100%;
  }
  .continueButton {
    width: 94%;
    margin-left: 10px;
    margin-top: 50px;
  }
  .genderInput {
    width: 50%;
    margin-bottom: 0px;
  }
  .birthDiv {
    width: 45%;
  }
  .nameDiv {
    width: 100%;
  }
}
