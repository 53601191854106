.container {
  min-height: 500px;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  transform: skew(0, 168deg);
  margin-bottom: 20vw;
}

.input-columns {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.ccv {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.child1 {
  color: #fff;
  display: flex;
  flex-direction: row;
  padding-left: 200px;
  background-color: #000;
  height: 300px;
  padding-top: 35px;
  justify-content: space-between;
  transform: skewY(12deg);
}

.train-wrapper {
  /* min-width: 100vw; */
  /* min-height: 600px; */
  /* border: 1px solid red; */
  position: relative;
  justify-self: center;
  align-self: center;
  /* margin-top: -85vh; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 10px;
}
.Bgcard {
  margin-top: 50px;
  padding-top: 5vh;
  width: 350px;
  /* height: 100%; */
  min-height: 70vh;
  margin-bottom: 10px;
  margin-right: 15px;
  border: 1px solid #707070;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 42px 96px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 0px;
  padding-left: 10px;
  padding-right: 10px;
  /* background-image: url("/src/assets/images/bubbels.png"); */
}

.heading1 {
  font-weight: bold;
  font-size: 34px;
  margin-bottom: -10px;
  margin-top: -10px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.heading2 {
  margin-top: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #fff;
}

.content-text1 {
  font-weight: 800;
  font-size: 20px;
  /* line-height: 59px; */
  text-align: center;
  letter-spacing: -0.02em;
  color: #ff1e1e;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90% !important;
}

.type {
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
  /* line-height: 59px; */
  text-align: center;
  letter-spacing: -0.02em;
  color: #fff;
  text-transform: capitalize;
}
.Stype {
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 59px; */
  text-align: center;
  letter-spacing: -0.02em;
  color: #fff;
  text-transform: capitalize;
}

.paragraph {
  font-size: 14px;
  margin-bottom: 4vh; /* line-height: 57px; */
  width: 70%;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.02em;
  color: #cecbbc;
}
/* 
.Bgcard:hover {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.Bgcard:hover .paragraph {
  color: #fff;
}
.Bgcard:hover .content-text1 {
  color: #fff;
}
.Bgcard:hover .type {
  color: #fff;
}
.Bgcard:hover .Stype {
  color: #fff;
}

.Bgcard:hover .check > p {
  color: #fff;
}
.Bgcard:hover .check > img {
  filter: brightness(0) invert(1);
} */

.check {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.check > img {
  margin-right: 10px;
  margin-bottom: 10px;
  height: 25px;
  width: 25px;
  /* font-size: 100px; */
  fill: #ff1e1e;
  color: yellowgreen;
}

.check > p {
  font-style: normal;
  /* font-weight: 600; */
  font-size: 13x;
  line-height: 15px;
  /* or 150% */

  letter-spacing: -0.02em;

  /* Primary/Grey */

  color: #cecbbc;
}

.subscribe-btn {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  margin-bottom: 25px;
  border-radius: 12px;
  border: 0px;
  color: #fff;
  font-weight: 600;
  height: 60px;
  font-size: 16px;
  width: 80%;
  letter-spacing: -0.02em;
}

.Bgcard:hover .subscribe-btn {
  background: #fff;
  color: #ff1e1e;
}

.crossLine {
  position: absolute;
  right: 0px;
  border: 0px;
  width: 60%;
  bottom: -30px;
  height: 30px;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}
@media screen and (max-width: 666px) {
  .Bgcard {
    margin-top: 20px !important;
    padding-top: 0px !important;
    /* background-color: #000; */
    display: flex;
    justify-content: flex-start;
  }
  .content-text1 {
    font-size: 2.3vh !important;
    width: 90% !important;
    color: gainsboro !important;
    flex-direction: row;
  }
  .Stype {
    margin: 0px !important;
    padding-left: 10px !important;
    color: gainsboro !important;
  }
  .paragraph {
    margin: 0px !important;
    color: white !important;
    width: 100% !important;
    /* font-weight: 900; */
    border-radius: 10px;
    margin-bottom: 8px !important;
    background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  }
  .country {
    margin: 0px !important;
  }
  .check-list {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    /* margin-left: 130px !important; */
    /* width: 100% !important; */
    align-items: center;
    /* justify-content: space-evenly; */
    width: 100% !important;
    /* flex-direction: row; */
  }
}
@media screen and (max-width: 330px) {
  .content-text1 {
    font-size: 3.2vh !important;
    width: 100% !important;
    color: gainsboro !important;
    flex-direction: row;
  }
  .paragraph {
    font-size: 3vh !important;
    width: 70% !important;
    /* color: white !important; */
    /* font-weight: 900; */
    /* border-radius: 10px;
    margin-bottom: 8px !important; */
    /* background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%); */
  }
  .Stype {
    font-size: 3vh !important;
    color: gainsboro !important;
    /* width: 100% !important; */
  }
  .check-list > p {
    font-size: 100px !important;
    width: 70% !important;
    color: gainsboro !important;
  }
  .Bgcard {
    padding: 0px !important;
    width: 100% !important;
    background: rgba(255, 255, 255, 0.2);
  }
}
@media screen and (max-width: 480px) {
  .content-text1 {
    font-size: 2.7vh !important;
    margin: 0px !important;
    width: 100% !important;
    color: gainsboro !important;
    flex-direction: row;
  }
  .paragraph {
    font-size: 2.5vh !important;
    color: white !important;
    width: 97% !important;
    /* font-weight: 900; */
    /* font-weight: 900; */
    border-radius: 10px;
    margin-bottom: 8px !important;
    background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
    text-align: center;
  }
  .Stype {
    font-size: 2.4vh !important;
    color: gainsboro !important;
  }
  .check-list > p {
    font-size: 1.5vh !important;
    color: gainsboro !important;
    /* width: 95% !important; */
    text-align: left;
  }
  .check-list > img {
    margin-left: 6px !important;
    margin-right: 5px !important;
  }
  .Bgcard {
    padding: 0px !important;
    width: 100% !important;
    background: rgba(255, 255, 255, 0);
  }
}

@media screen and (max-width: 1120px) {
  .container {
    background-color: #fff;
  }
  .input-columns {
    flex-direction: column;
  }
  .ccv {
    width: 100%;
  }
  .child1 {
    height: max-content;
  }
  .train-wrapper {
    flex-direction: column;
    flex: 1;
    margin-top: -10px;
    /* margin-top: 10px; */
  }
  .check {
    width: 100%;
  }
  .Bgcard {
    margin-right: 0px;
    width: 100%;
    padding: 20px;
    margin-left: -5px;
  }
  .crossLine {
    display: none;
  }
  .content-text1 {
    font-size: 2vh;
  }
}

/*
  * {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
  } */
