.skeleton {
  display: flex;
  width: 95%;
  z-index: 0;
  /* height: 140px; */
}
.skeleton-1 {
  display: flex;
  width: 95%;
  z-index: 0;
  height: 350px;
  border-radius: 15px;
}
.Trimg-and-details-div {
  width: 83%;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  display: flex;
  margin-top: 50px;
}
.Trdetails-div {
  flex-direction: column;
  display: flex;
  width: 70%;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
}
.Trimg-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
}

.TrVideosContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.TrVideosAndDetails {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.TrVideoImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.TrDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.trainingPlanCardSkeleton {
  display: flex;
  width: 100%;
  z-index: 0;
  justify-content: flex-start;
  /* border-radius: 15px; */
}
.trainingPlanCardDiv {
  display: flex;
  width: 70%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
}

@media only screen and (max-width: 1024px) {
  .TrVideosAndDetails {
    width: 30%;
  }
}

@media only screen and (max-width: 840px) {
  .trainingPlanCardSkeleton {
    justify-content: center;
  }
  .trainingPlanCardDiv {
    width: 100%;
  }
  .TrVideosAndDetails {
    width: 32%;
  }
  .Trimg-and-details-div {
    flex-direction: column;
  }
  .Trdetails-div {
    align-self: center;
    width: 100%;
  }
  .Trimg-div {
    width: 100%;
  }
}
@media only screen and (max-width: 666px) {
  .TrVideosAndDetails {
    width: 100%;
    /* height: 200px; */
  }
  /* .skeleton {
    height: 200px;
  } */
  .Trimg-and-details-div {
    width: 95%;
  }
}
@media only screen and (max-width: 480px) {
  .skeleton-1 {
    height: 250px !important;
  }
}
