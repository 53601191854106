.MainContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.LandingPageContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 60%;
}
.heading-one-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 116%;
  color: #db3832;
  text-transform: uppercase;
  margin: 10px 0px;
}
.heading-two-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 116%;
  text-transform: uppercase;
  margin: 10px 0px;
}
.trial-one-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 116%;
  /* text-align: center; */
  color: #1e1e1e;
  text-transform: uppercase;
  margin: 2px 0px;
}
.trial-two-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 116%;
  text-align: center;
  color: #ffffff;
  background: #ff1e1e;
  border-radius: 22px;
  padding: 5px;
  /* width: 17%; */
  padding: 5px 10px 5px 10px;
  margin: 2px 0px;
  text-transform: uppercase;
}
.subscribeUserContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 40%;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 40px;
  margin: 20px 0px 0px 0px;
}
.subscribe-one-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 116%;
  color: #db3832;
}
.subscribe-input-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 116%;
  color: #878787;
}
.subscribe-input {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #878787;
  border-bottom: 1px solid #878787;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 60%;
}
.subscribe-otp-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 116%;
  margin-bottom: 0;
  margin-top: 20px;

  color: #878787;
}
.subscribe-otp-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 55%;
  margin-top: 15px;
  color: #1e1e1e;
}
.subscribe-otp-input {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  border-bottom: 1px solid #878787;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  color: #000000 !important;
  width: 20%;
}
.checkbox {
  flex: 1;
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  align-items: center;
  width: 100%;
}
.checkbox-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #878787;
}
.subscribe-contine-button {
  background: #db3832;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  width: 100%;
  border: 0px;
  margin: 20px 0px 0px 0px;
  color: #ffffff;
}
.final-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 116%;
  letter-spacing: 0.01em;
  width: 50%;
  text-align: center;
  color: #878787;
  margin-top: 15px;
}
.modal {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  /* height: 100%; */
  /* overflow: auto; */
  /* background-color: rgba(0, 0, 0, 0.4); */
}
#modal-btn {
  background: #fff;
  font-family: "Poppins";
  border: 0;
  font-size: 12px;
  line-height: 16px;
  display: inline;
  color: #db3832;
  cursor: pointer;
}
.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 20%;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  border: 0;
}
.modal-Heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 116%;
  /* or 21px */

  color: #db3832;
}
.modal-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */

  color: #878787;
}
@media screen and (min-width: 1100px) {
  .MainContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .LandingPageContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 60%;
  }
  .heading-one-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 116%;
    color: #db3832;
    text-transform: uppercase;
    margin: 10px 0px;
  }
  .heading-two-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    text-transform: uppercase;
    margin: 10px 0px;
  }
  .trial-one-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 116%;
    /* text-align: center; */
    color: #1e1e1e;
    text-transform: uppercase;
    margin: 2px 0px;
  }
  .trial-two-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 116%;
    text-align: center;
    color: #ffffff;
    background: #ff1e1e;
    border-radius: 22px;
    padding: 5px;
    /* width: 17%; */
    padding: 5px 10px 5px 10px;
    margin: 2px 0px;
    text-transform: uppercase;
  }
  .subscribeUserContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 40%;
    background: #ffffff;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 40px;
    margin: 20px 0px 0px 0px;
  }
  .subscribe-one-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 116%;
    color: #db3832;
  }
  .subscribe-input-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 116%;
    color: #878787;
  }
  .subscribe-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: #878787;
    border-bottom: 1px solid #878787;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    width: 65%;
  }
  .subscribe-otp-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 116%;
    margin-bottom: 0;
    margin-top: 20px;

    color: #878787;
  }
  .subscribe-otp-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    width: 60%;
    margin-top: 15px;
  }
  .subscribe-otp-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    border-bottom: 1px solid #878787;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    color: #1e1e1e;
    width: 20%;
  }
  .checkbox {
    flex: 1;
    margin: 20px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
    width: 100%;
  }
  .checkbox-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #878787;
  }
  .subscribe-contine-button {
    background: #db3832;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    width: 100%;
    border: 0px;
    margin: 20px 0px 0px 0px;
    color: #ffffff;
  }
  .final-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 116%;
    letter-spacing: 0.01em;
    width: 50%;
    text-align: center;
    color: #878787;
  }
  .modal-content {
    width: 20%;
    text-align: left;
  }
  /* .modal {
    top: 110px;
  } */
}
@media screen and (max-width: 950px) {
  .MainContainer {
    width: 100%;
    /* padding:20px; */
  }
  .LandingPageContainer {
    width: 60%;
    padding: 20px;
  }
  .heading-one-text {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-two-text {
    font-size: 24px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 85%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 17px;
  }
  .subscribe-input {
    width: 65%;
  }
  .subscribe-otp-div {
    width: 65%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    margin-bottom: 20px;
  }
  .subscribe-otp-text {
    font-size: 14px;
  }
  .subscribe-input-text {
    font-size: 14px;
  }
  .modal-content {
    width: 40%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}
@media screen and (max-width: 840px) {
  .MainContainer {
    width: 100%;
    /* padding:20px; */
  }
  .LandingPageContainer {
    width: 60%;
    padding: 20px;
  }
  .heading-one-text {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-two-text {
    font-size: 24px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 85%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 17px;
  }
  .subscribe-input {
    width: 65%;
  }
  .subscribe-otp-div {
    width: 65%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    margin-bottom: 20px;
  }
  .subscribe-otp-text {
    font-size: 14px;
  }
  .subscribe-input-text {
    font-size: 14px;
  }
  .modal-content {
    width: 40%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}
@media screen and (max-width: 768px) {
  .MainContainer {
    width: 100%;
    /* padding:20px; */
  }
  .LandingPageContainer {
    width: 60%;
    padding: 20px;
  }
  .heading-one-text {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-two-text {
    font-size: 24px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 85%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 17px;
  }
  .subscribe-input {
    width: 65%;
  }
  .subscribe-otp-div {
    width: 65%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    margin-bottom: 20px;
  }
  .subscribe-otp-text {
    font-size: 14px;
  }
  .subscribe-input-text {
    font-size: 14px;
  }
  .modal-content {
    width: 40%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}
@media screen and (max-width: 666px) {
  .MainContainer {
    width: 100%;
    /* padding:20px; */
  }
  .LandingPageContainer {
    width: 100%;
    padding: 20px;
  }
  .heading-one-text {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-two-text {
    font-size: 24px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 85%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 17px;
  }
  .subscribe-input {
    width: 85%;
  }
  .subscribe-otp-div {
    width: 80%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 17px;
  }
  .subscribe-otp-text {
    font-size: 14px;
  }
  .subscribe-input-text {
    font-size: 14px;
  }
  .final-text {
    width: 100%;
  }
  .modal-content {
    width: 80%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}

@media screen and (max-width: 480px) {
  .MainContainer {
    width: 100%;
    /* padding:20px; */
  }
  .LandingPageContainer {
    width: 100%;
    padding: 20px;
  }
  .heading-one-text {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-two-text {
    font-size: 24px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 95%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 17px;
  }
  .subscribe-input {
    width: 85%;
  }
  .subscribe-otp-div {
    width: 80%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 17px;
  }
  .subscribe-otp-text {
    font-size: 14px;
  }
  .subscribe-input-text {
    font-size: 14px;
  }
  .modal-content {
    width: 80%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}

@media screen and (max-width: 320px) {
  .MainContainer {
    width: 100%;
  }
  .LandingPageContainer {
    width: 100%;
  }
  .heading-one-text {
    font-size: 20px;
  }
  .heading-two-text {
    font-size: 20px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 85%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 15px;
  }
  .subscribe-input {
    width: 85%;
  }
  .subscribe-otp-div {
    width: 80%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 15px;
  }
  .modal-content {
    width: 80%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}
