/* All Exercise Screen Css Start */
body {
  height: 100%;
  margin: 0;
  /* background: #000000; */
}
.exercise-container-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: black;
  width: 100%;

  /* position: fixed; */
}
.exercises-container-div {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  background: black;
  width: 100%;

  /* position: fixed; */
}
.exerciseTitleDiv {
  width: 95%;
  background: #000;
}
.exerciseCategories {
  display: flex;
  width: 95%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(141.23deg, #000000 6.35%, #4f4f4f 139.03%);
  margin: 0;
  padding: 50px;
  color: white;
  background: #000000;
  height: auto;
}
.categoriesTitle {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.categoryTitleText {
  margin-left: 2%;
  font-size: 18px;
  text-align: left;
  font-weight: 600;
  margin-top: 10px;
  color: #ff0101;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.exercise-category-and-video-div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background: #000000;
  height: auto;
}
.category-and-view-more {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.exercise-category-text {
  color: #ff0101;
}
.exercise-category-videos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
}
.exercise-category-videos::-webkit-scrollbar {
  display: none;
}

/* .exercise-category-videos::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 10px;
  width: 2px;
}
.exercise-category-videos::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
} */
.filterDiv {
  width: 95%;
  display: flex;
  justify-content: flex-start;
}
.filter-button {
  border-radius: 5px;
  background-color: rgba(255, 30, 30, 1);
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12;
  height: 60;
  justify-content: center;
  border: rgba(255, 30, 30, 1);
  margin-right: 3;
  margin-left: 50px;
  cursor: pointer;
  width: 10%;
  display: flex;
  margin-top: 20px;
}
.filters-div {
  width: 80vw;
  /* border: 1px solid pink; */
  /* background: linear-gradient(141.23deg, #4f4f4f 6.35%, #000000 139.03%); */
  /* height: 100px; */
  margin-left: 80px;
  margin-top: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  display: flex;
  /* border: 1px solid green; */
  flex-wrap: wrap;
}
.dont-show-filter-div {
  display: none;
}

/* All Exercise Screen Css End */

/* Single Exercise Screen Css Start */

.single-exercise-container {
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 50px;
}
.exercise-img-and-details-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 91%;
}
.exerciseDetails-div {
  width: 42%;
  /* margin-left: 5%; */
  /* padding-left: 20px; */
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* border: 1px solid yellow; */
}
.exerciseImageDiv {
  width: 55%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.exercise-image {
  width: 280px;
  height: 350px;
  background: white;
  /* marginLeft: 50px; */
  padding-bottom: 50px;
}

.exercise-details-title-text {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  width: 60%;
  margin-bottom: -1px;
  color: white;
  display: -webkit-box;
  /* max-width: 25vw; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.exercise-duration-and-views-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2px;
  align-items: flex-start;
  margin-top: 5px;
  padding-top: 5px;
}

.exercise-duration-and-views-text {
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  justify-self: center;
  align-self: center;
}
.exercise-clock-n-eye {
  filter: invert(18%) sepia(91%) saturate(5407%) hue-rotate(360deg)
    brightness(110%) contrast(128%);
}

.exercise-tags-div {
  display: flex;
  /* border: 1px solid yellow; */
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-self: start;
  padding-top: 10px;
  padding-bottom: 10px;
  /* justify-content: space-between; */
}
.exercise-tag {
  display: flex;
  background: #1a1a1a;
  color: rgba(255, 255, 255, 0.65);
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  border-radius: 12px;
  /* width: 22%; */
  text-align: center;
  font-weight: 500;
  font-size: 85%;
}
.exercise-description-text {
  text-align: left;
  font-family: "Montserrat", sans-serif;
}
.exercise-share-and-fav-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  /* border: 1px solid yellow; */
}

/* Single Exercise Screen Css End */

/* Exercise Video Card Css Start */

.exercise-title-text {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 0px;
}
.exercise-video-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 270px;
}
.duration-and-views-text {
  color: white;
}
.exerciseVideoImg {
  border-radius: 10px;
  margin-left: 2px;
  background-size: cover;
  width: 260px;
  height: 5%;
  /* // borderBottom: "1px solid grey ", */
  background-color: white;
}

/* Exercise Video Card Css Ends */

/* Exercise Circuit Card Css Starts */

.circuits {
  width: 91%;
  background: #000000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding-bottom: 60%; */
  border-radius: 8px;
  height: 20%;
  margin-top: 20px;
}

.circuitCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
  width: 100%;
  margin-top: 20px;
}

.circuit-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0px;
}
.circuit-img-and-time-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.SubCircuits-img-and-time-details {
  width: 95%;
  height: 100px;
  background: white;
  /* //   borderRightColor: "red", */
  border-radius: 5px;
  border-left: 5px solid red;
  background: rgba(60, 60, 60, 0.4);
  margin-top: 15px;
  margin-bottom: 20px;
  justify-content: space-around;
  align-items: center;
  -webkit-animation: fadein 0.2s;
  animation: fadein 0.2s;
}
.circuit-number-and-rounds-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  margin: 0;
}
.circuit-phase-name {
  font-weight: 900;
  font-size: 15px;
}
.subCircuits {
  display: flex;
  flex-direction: column;
  /* justifyContent: "space-evenly", */
  align-items: center;
  height: 100%;
  width: 40%;
  position: relative;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Exercise Player Css Start */

.congratulationsAndContinueDiv {
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
}

.congratulationsDiv {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #1a1a1a;
  height: 100%;
  padding: 30px;
  color: "white";
  border-radius: 16px;
}
.exerciseVideoDiv {
  background: black;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 50px 20px 50px;
  width: 100%;
  /* position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; */
}
.videoGifDiv {
  width: 100%;
  height: 20%;
  background: white;
  opacity: 0.7;
  display: flex;
  justify-content: center;
}
.videoGif {
  opacity: 0.7;
  width: 50%;
  height: 100%;
  background-color: white;
  justify-content: center;
  display: flex;
  object-fit: cover;
}
.exerciseNameDiv {
  display: flex;
  position: absolute;
  top: 130px;
  left: 115px;
  text-transform: uppercase;
}
.exerciseAndRoundsDiv {
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 630px;
}
.crossButton {
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  top: 80px;
  right: 29px;
  cursor: pointer;
  width: 8%;
  display: flex;
  justify-content: center;
}
.timerDiv {
  top: 11%;
  position: absolute;
  left: 83%;
}
.ExerciseNameDiv {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 80px;
}
.timerImage {
  width: 20%;
}
.controlButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 20%;
  position: absolute;
  top: 630px;
}
.playButton {
  width: 30px !important;
  height: 30px !important;
}
.nextButton {
  color: gray;
  background: gray;
  cursor: pointer;
  border: none;
  width: 20%;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pauseAndPlayButton {
  /* // color: "black; */
  background: gray;
  cursor: pointer;
  border-radius: 50%;
  width: 30%;
  padding: 30px;
  border: none;
}
.reactPlayerDiv {
  background: #ffffff;
  width: 100%;
  height: 780px;
  display: flex;
}
.backButton {
  color: gray;
  background: gray;
  cursor: pointer;
  border: none;
  width: 20%;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exerciseNameCongDiv {
  margin-top: 15px;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #1a1a1a;
  height: 100%;
  padding: 20px;
  color: "white";
  border-radius: 16px;
  text-transform: uppercase;
}
.timeAndCaloriesDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  background: black;
  width: 54%;
  margin-top: 15px;
  border-radius: 16px;
}
.timeDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background: #1a1a1a;
  width: 45%;
  border-radius: 16px;
}
.CaloriesDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background: #1a1a1a;
  width: 45%;
  border-radius: 16px;
}
.continue-Button {
  border: 1px solid red;
  background: rgb(255, 30, 30);
  display: flex;
  justify-self: center;
  padding: 15px;
  margin-top: 17px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: white;
  width: 50%;
  border-radius: 10px;
  height: 100px;
  line-height: 20px;
}
.continueButton:hover {
  background: rgb(255, 30, 30);
  border: 1px solid red;
  color: white;
}
/* Exercise Player Css End */

@media only screen and (max-width: 820px) {
  .hide-this {
    display: none !important;
  }
}
@media only screen and (max-width: 1024px) {
  .single-exercise-container {
    padding: 30px;
  }
  .exercise-img-and-details-div {
    width: 90%;
  }
  .exercise-share-and-fav-div {
    flex-wrap: nowrap;
    align-items: center;
  }
  .exercise-details-title-text {
    width: 100%;
  }
  .exercise-description-text {
    width: 100%;
    text-align: left;
  }
  .circuits {
    width: 90%;
  }
  .circuit-number-and-rounds-div {
    width: 65%;
  }
}
@media only screen and (max-width: 840px) {
  .exercise-img-and-details-div {
    flex-direction: column;
    width: 90%;
  }
  .exerciseImageDiv {
    width: 100%;
  }
  .exerciseDetails-div {
    width: 100%;
    margin-top: 15px;
  }
  .exercise-tag {
    width: 22%;
  }
  .circuits {
    margin: 50px;
    width: 90%;
  }
  .circuit-content {
    width: 100%;
  }
  .circuitCard {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .circuit-img-and-time-details {
    width: 100%;
    height: 100%;
  }
  .subCircuits {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .exercise-img-and-details-div {
    flex-direction: column;
    width: 90%;
  }
  .exerciseImageDiv {
    width: 100%;
  }
  .exerciseDetails-div {
    width: 100%;
    margin-top: 15px;
  }
  .exercise-tag {
    width: 22%;
  }
  .circuits {
    margin: 50px;
    width: 100%;
  }
  .circuit-content {
    width: 100%;
  }
  .circuitCard {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .circuit-img-and-time-details {
    width: 100%;
    height: 100%;
  }
  .subCircuits {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 666px) {
  .single-exercise-container {
    flex-direction: column;
    width: 100%;
  }
  .exerciseDetails-div {
    width: 100%;
  }
  .exercise-img-and-details-div {
    flex-direction: column;
    width: 100%;
  }
  .exerciseImageDiv {
    width: 100%;
  }
  .exercise-image {
    width: 100%;
    padding-bottom: 0px;
  }
  .exercise-details-title-text {
    width: 100%;
  }
  .exercise-share-and-fav-div {
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
  }
  .exercise-tag {
    width: 30%;
  }
  .filter-button {
    width: 30vw;
  }

  /* Exercise Circuits Css Start */

  .circuits {
    margin: 50px;
    width: 100%;
  }
  .circuit-content {
    width: 100%;
  }
  .circuitCard {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .circuit-img-and-time-details {
    width: 100%;
    height: 100%;
  }
  .subCircuits {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 480px) {
  /* All Exercise Screen Css Start */
  .category-and-view-more {
    width: 100%;
  }
  .filter-button {
    width: 40vw;
    margin-left: 20px;
    margin-top: 50px;
  }
  .exerciseCategories {
    padding: 15px;
  }

  /* All Exercise Screen Css End */

  /* Exercise Video Card Css Start */

  .exercise-video-card {
    width: 230px;
  }
  .exerciseVideoImg {
    width: 92%;
  }
  /* Exercise Video Card Css End */

  /* Single Exercise Details Css Start */

  .single-exercise-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 25px;
    flex-wrap: nowrap;
    width: 100%;
  }
  .exercise-img-and-details-div {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  .exerciseDetails-div {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 7px;
  }
  .exerciseImageDiv {
    width: 100%;
  }
  .exercise-image {
    width: 100%;
    height: 20%;
    border-radius: 15px;
    padding-bottom: 0px;
  }
  .exercise-details-title-text {
    width: 100%;
  }
  .exercise-share-and-fav-div {
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
  }
  .exercise-tag {
    width: 30%;
  }

  /* Single Exercise Details Css End */

  /* Exercise Circuits Css Start */

  .circuits {
    margin: 50px;
    width: 100%;
  }
  .circuit-content {
    width: 100%;
  }
  .circuitCard {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .circuit-img-and-time-details {
    width: 100%;
    height: 100%;
  }
  .subCircuits {
    width: 100%;
    height: 100%;
  }
  .circuit-number-and-rounds-div {
    width: 100%;
  }
  /*  */
  /* Exercise Player Css Start */

  .exerciseAndRoundsDiv {
    top: 60%;
    width: 85%;
  }
  .crossButton {
    top: 14%;
    right: 14%;
  }
  .timerDiv {
    width: 85%;
    position: absolute;
    /* border: 1px solid white; */
    top: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 2px white;
    padding: 8px;
    left: 8%;
  }
  .ExerciseNameDiv {
    top: 65%;
  }
  .timerText {
    color: white !important;
    font-size: 16px !important;
  }
  .timer1Text {
    color: white !important;
    font-size: 35px !important;
    margin: 0;
  }
  .timerImage {
    filter: invert(0%) sepia(0%) saturate(7469%) hue-rotate(281deg)
      brightness(88%) contrast(106%) !important;
  }
  .reactPlayerDiv {
    height: 53% !important;
    width: 100% !important;
    margin-top: 7px;
    background: black;
    /* margin-top: 2%; */
  }
  .videoGif {
    height: 100%;
    /* padding-top: 50px; */
    width: 100%;
    align-self: center;
  }
  .videoGifDiv {
    height: 53%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
  }
  .controlButtons {
    width: 85%;
    top: 83%;
  }
  .exerciseVideoDiv {
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 80px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .exerciseNameDiv {
    width: 85%;
    top: 9%;
    left: 25px;
    justify-content: center;
  }

  /* Exercise Player Congratulations Css start */
  .congratulationsAndContinueDiv {
    justify-content: flex-start;
    padding: 0px;
  }
  .congratulationsDiv {
    margin-top: 80px;
    padding: 30px;
    height: 28%;
    width: 90%;
  }
  .timeAndCaloriesDiv {
    width: 100%;
  }
  .exerciseNameCongDiv {
    height: 11%;
    width: 90%;
    padding: 20px;
  }
  .CaloriesDiv > p {
    font-size: 11px;
  }
  .timeDiv > p {
    font-size: 11px;
  }
  .continueButton {
    height: 49px;
    width: 75%;
  }
  .exercise-category-videos::-webkit-scrollbar {
    display: none;
  }
  .continue-Button {
    border: 1px solid red;
    background: rgb(255, 30, 30);
    display: flex;
    justify-self: center;
    padding: 15px;
    margin-top: 17px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: white;
    width: 90%;
    border-radius: 10px;
    height: 55px;
    line-height: 20px;
  }
  /* Exercise Player Congratulations Css End */
}

@media only screen and (max-width: 340px) {
  .single-exercise-container {
    width: 100%;
    padding: 7px;
  }
  .exercise-img-and-details-div {
    flex-direction: column;
  }
  .exercise-image {
    height: 250px;
  }
  .exercise-tag {
    width: 30%;
    font-size: 12px;
  }
  .circuit-content {
    width: 100%;
  }
  .circuits {
    width: 100%;
  }
  .circuit-number-and-rounds-div {
    width: 100%;
  }
  .subCircuits {
    width: 100%;
  }
  /* Exercise Player Css Start */

  .exerciseAndRoundsDiv {
    top: 60%;
    width: 85%;
  }
  .crossButton {
    top: 16%;
    right: 19%;
  }
  .ExerciseNameDiv {
    top: 65%;
  }
  .timerDiv {
    padding: 0px;
  }
  .timerText {
    color: white !important;
    font-size: 12px !important;
  }

  .timerImage {
    filter: invert(0%) sepia(0%) saturate(7469%) hue-rotate(281deg)
      brightness(88%) contrast(106%) !important;
  }
  .reactPlayerDiv {
    height: 53% !important;
    width: 100% !important;
    background: black;
    margin-top: 10px;
  }
  .timer1Text {
    font-size: 27px !important ;
  }
  .videoGif {
    height: 100%;
    width: 100%;
    /* padding: 25px; */
  }
  .videoGifDiv {
    height: 53%;
    margin-top: 15px;
    margin-left: 10px;
    width: 90%;
  }
  .controlButtons {
    width: 90%;
    top: 80%;
    /* height: 30px; */
    /* background: black; */
    /* padding-bottom: 20px; */
  }
  .playButton {
    width: 25px !important;
    height: 25px !important;
  }
  .exerciseVideoDiv {
    padding: 25px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 70px;
    /* position: absolute; */
    height: 110vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    /* padding-bottom: 25px; */
  }
  .exerciseNameDiv {
    width: 90%;
    top: 12.5%;
    justify-content: center;
    left: 16px;
  }
  /* Exercise Player Css End */

  /* Exercise Player Congratulations Css start */
  .congratulationsAndContinueDiv {
    justify-content: flex-start;
    padding: 20px;
    position: relative;
    height: 100vh;
  }
  .congratulationsDiv {
    margin-top: 5px;
    padding: 20px;
    height: 28%;
    width: 100%;
    justify-content: center;
  }
  .congratulationsDiv > h1 {
    font-size: 15px;
  }
  .congratulationsDiv > p {
    font-size: 11px;
    width: 100%;
  }
  .timeAndCaloriesDiv {
    width: 100%;
    justify-content: space-between;
    margin-top: 0;
  }
  .exerciseNameCongDiv {
    height: 11%;
    width: 100%;
    padding: 20px;
  }
  .exerciseNameCongDiv > p {
    font-size: 12px;
  }
  .CaloriesDiv {
    width: 45%;
  }
  .timeDiv {
    width: 45%;
  }
  .CaloriesDiv > p {
    font-size: 8px;
  }
  .timeDiv > p {
    font-size: 8px;
  }
  .continueButton {
    height: 49px;
    width: 75%;
    margin-top: 2px;
  }

  /* Exercise Player Congratulations Css End */
}
