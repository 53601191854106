.skeleton {
  display: flex;
  width: 95%;
  z-index: 0;
  /* height: 140px; */
}
.Workoutskeleton {
  display: flex;
  height: 165px;
  width: 95%;
  z-index: 0;
}
.Singleskeleton {
  display: flex;
  width: 95%;
  z-index: 0;
  height: 350px;
  border-radius: 15px;
}
.img-and-details-div {
  width: 84%;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  display: flex;
  padding-top: 20px;
}
.img-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
}
.details-div {
  flex-direction: column;
  display: flex;
  width: 70%;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.paragraph-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}
.workoutVideosContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  /* overflow: scroll; */
}
.singleWorkoutVideosDiv {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.WorkoutImgDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* // marginTop: "20px", */
  margin-bottom: 20px;
}
.WorkoutDetailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .workoutVideosContainer {
    width: 100%;
  }
  .singleWorkoutVideosDiv {
    width: 35%;
  }
}

@media only screen and (max-width: 840px) {
  .singleWorkoutVideosDiv {
    width: 40%;
  }
}

@media only screen and (max-width: 666px) {
  .img-and-details-div {
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: 70px;
  }
  .img-div {
    justify-content: flex-start;
    width: 100%;
    height: 250px;
  }
  .details-div {
    width: 100%;
  }
  .paragraph-div {
    width: 90%;
  }
  .singleWorkoutVideosDiv {
    width: 45%;
  }
  .Singleskeleton {
    height: 250px;
  }
}
@media only screen and (max-width: 480px) {
  .Singleskeleton {
    height: 250px;
  }
  .singleWorkoutVideosDiv {
    width: 50%;
  }
  .Workoutskeleton {
    height: 100px;
  }
}

@media only screen and (max-width: 320px) {
  .Singleskeleton {
    height: 180px;
    width: 100%;
  }
  .img-and-details-div {
    margin-top: 40px;
  }
  .img-div {
    margin-bottom: 20px;
  }
}
