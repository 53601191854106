.homeSkeleton {
  display: flex;
  width: 95%;
  z-index: 0;
}
.leftTrendingVideoSkeleton {
  display: flex;
  width: 95%;
  z-index: 0;
  height: 350px;
}
.rightTrendingVideoSkeleton {
  display: flex;
  width: 95%;
  z-index: 0;
  height: 140px;
}
.rightTrendingSkeleton {
  display: flex;
  width: 90%;
  z-index: 0;
}
.HomeTrendingContainer {
  display: flex;
  width: 86%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 10%; */
  padding-top: 2%;
}
.leftTrendingVideoDiv {
  display: flex;
  width: 56%;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  flex-direction: column;
}
.leftTrVideoContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.leftTrVideoDetailsDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rightTrendingVideoDiv {
  display: flex;
  width: 44%;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}
.VideoContainer {
  display: flex;
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 10px;
}
.rightVideoDetailsDiv {
  width: 86%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 1040px) {
  .HomeTrendingContainer {
    width: 90%;
    /* padding-left: 8%; */
  }
  .leftTrendingVideoSkeleton {
    height: 250px;
  }
  .rightTrendingVideoSkeleton {
    height: 120px;
  }
  .rightTrendingVideoDiv {
    width: 45%;
  }
}

@media only screen and (max-width: 840px) {
  .HomeTrendingContainer {
    flex-direction: column;
    width: 90%;
  }
  .leftTrVideoContainer {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .leftTrendingVideoDiv {
    width: 100%;
    margin-bottom: 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .leftTrVideoDetailsDiv {
    align-items: flex-start;
  }
  .rightTrendingVideoDiv {
    width: 100%;
    flex-direction: row;
  }
  .VideoContainer {
    flex-direction: column;
  }
  .rightVideoDetailsDiv {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 666px) {
  .leftTrendingVideoSkeleton {
    height: 250px;
  }
  .rightTrendingVideoSkeleton {
    height: 140px;
  }
}
@media only screen and (max-width: 480px) {
  .leftTrendingVideoSkeleton {
    height: 200px;
  }
  .rightTrendingVideoSkeleton {
    height: 100px;
  }
}
@media only screen and (max-width: 320px) {
  .leftTrendingVideoSkeleton {
    height: 170px;
  }
  .rightTrendingVideoSkeleton {
    height: 80px;
  }
}
