.container {
  min-height: 500px;
  width: 100% !important;
  background: rgba(0, 0, 0, 1);
  transform: skew(0, 168deg);
  margin-bottom: 20vw;
  margin-top: 55px;
  max-width: 100%;
}

.alert-modal .ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  color: black;
}

.input-columns {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.ccv {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.terms-div {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 400px;
}

.child1 {
  color: #fff;
  display: flex;
  flex-direction: row;
  padding-left: 200px;
  background-color: #000;
  height: 300px;
  padding-top: 35px;
  justify-content: space-between;
  transform: skewY(12deg);
  width: 100%;
}

.train-wrapper {
  /* min-width: 100vw; */
  /* min-height: 600px; */
  /* border: 1px solid red; */
  position: relative;
  justify-self: center;
  align-self: center;
  /* margin-top: -85vh; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 10px;
}
.train-card {
  padding-top: 10vh;
  /* width: 350px; */
  max-width: 350px;
  min-height: 60vh;
  margin-bottom: 10px;
  margin-right: 10px;
  border: 1px solid #707070;
  background: #ffffff;
  box-shadow: 0px 42px 96px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

/* @media screen and (min-width: 1121px) {
  .train-card:first-child {
    margin-left: 15px;
  }

  .train-card:last-child {
    margin-right: 7px;
  }
} */

.train-card > .ant-btn[disabled] {
  color: #fff;
  border-color: #d9d9d9;
  background: grey;
  text-shadow: none;
}

.heading1 {
  font-weight: bold;
  font-size: 34px;
  margin-bottom: -10px;
  margin-top: -10px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.heading2 {
  margin-top: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #fff;
}

.content-text1 {
  font-weight: 800;
  font-size: 1.5vw;
  /* line-height: 59px; */
  text-align: center;
  letter-spacing: -0.02em;
  color: #ff1e1e;
  margin-bottom: 5px;
}

.content-text2 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 59px; */
  text-align: center;
  letter-spacing: -0.02em;
  color: #000;
}

.terms-parent {
  display: flex;
  width: 90%;
  margin-right: 20px;
}

.terms-text {
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 59px; */
  align-self: flex-start;
  letter-spacing: -0.02em;
  color: #000;
}

.content-paragraph {
  font-size: 12px;
  margin-bottom: 7vh; /* line-height: 57px; */
  width: 70%;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.02em;
  color: #707070;
}

.train-card:hover {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.train-card:hover .content-paragraph {
  color: #fff;
}
.train-card:hover .content-text1 {
  color: #fff;
}
.train-card:hover .content-text2 {
  color: #fff;
}

.train-card:hover .check-list > p {
  color: #fff;
}
.train-card:hover .check-list > img {
  filter: brightness(0) invert(1);
}

.check-list {
  display: none;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.check-list > img {
  margin-right: 10px;
  margin-bottom: 10px;
  fill: #ff1e1e;
  color: yellowgreen;
}

.check-list > p {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  /* or 150% */

  letter-spacing: -0.02em;

  /* Primary/Grey */

  color: #707070;
}

.subscribe-btn {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  margin-bottom: 25px;
  border-radius: 12px;
  border: 0px;
  color: #fff;
  font-weight: 600;
  margin-top: auto;
  height: 60px;
  font-size: 16px;
  width: 80%;
  letter-spacing: -0.02em;
}

.train-card:hover .subscribe-btn {
  background: #fff;
  color: #ff1e1e;
}

.crossLine {
  position: absolute;
  right: 0px;
  border: 0px;
  width: 60%;
  bottom: -30px;
  height: 30px;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.dropdown-arrow {
  color: #ff1e1e;
  margin-left: 10px;
  margin-top: -2px;
  font-size: 15px;
  display: inline;
}

.conditions-div {
  padding-left: 15px;
}

.dropdown-terms-arrow {
  color: #ff1e1e;
  margin-left: 10px;
  margin-top: 3px;
  font-size: 15px;
}

.dropdown-terms-arrow:hover {
  font-size: 20px;
  margin-bottom: 10px;
}

.train-card:hover .dropdown-arrow {
  color: #fff;
}

@media screen and (max-width: 1120px) {
  .container {
    background-color: #fff;
  }
  .input-columns {
    flex-direction: column;
  }
  .ccv {
    width: 100%;
  }
  .child1 {
    height: max-content;
  }
  .train-wrapper {
    flex-direction: column;
    flex: 1;
    margin-top: -10px;
    /* margin-top: 10px; */
  }
  .check-list {
    width: 60%;
    display: none;
  }
  .dropdown-arrow {
    display: inline;
  }
  .dropdown-terms-arrow {
    display: inline;
  }
  .terms-div {
    align-self: center;
    align-items: center;
    margin-left: 0px;
  }
  .train-card {
    margin-right: 0px;
    /* width: 100%; */
    width: 350px;
    padding: 40px 20px 40px 20px;
    margin-left: -15px;
    min-height: 0px;
  }
  .crossLine {
    display: none;
  }
  .content-text1 {
    font-size: 26px;
  }
  .terms-parent {
    justify-content: center;
  }
  .conditions-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70%;
    padding-left: 0px;
  }
  .terms-text {
    align-self: center;
  }
}

/*
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
