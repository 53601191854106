/* Nutrition Confirmation Modal Css Start */
/* .NutritonConfirmationModal .ant-scrolling-effect {
  width: 100%;
} */
/* .body {
  background: #000;
} */
.NutritonConfirmationModal .ant-modal-wrap {
  background: #000;
}
.NutritonConfirmationModal .ant-modal-content {
  border-radius: 15px;
}

.NutritonConfirmationModal .ant-modal-body {
  background: #1a1a1a;
}

.NutritonConfirmationModal .ant-modal-header {
  background: #1a1a1a;
  border: 0px;
  /* border-radius: 25px; */
}
.NutritonConfirmationModal .ant-modal-title {
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
.NutritionFoodIcon {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.NutritionImageIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ff1e1e;
  width: 25%;
  padding: 15px 12px 15px 12px;
  border-radius: 10px;
  background: #592a29;
}

/* Nutrition Confirmation Modal Css End */
/* Nutrition About You Modal Css Start */
.NutritionAboutYouModal .ant-modal-header {
  background: #1a1a1a;
  border: 0;
}
.NutritionAboutYouModal .ant-modal-title {
  color: #ffffff;
}
.NutritionAboutYouModal .ant-modal-content {
  background: #1a1a1a;
}
.NutritionAboutYou {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

/* Nutrition About You Modal Css End */
/* Nutrition Activity Modal Css Start */
.NutritionActivityModal .ant-modal {
  top: 0px;
}
.NutritionActivityModal .ant-modal-header {
  background: #1a1a1a;
  border: 0;
}
.NutritionActivityModal .ant-modal-title {
  color: #ffffff;
}
.NutritionActivityModal .ant-modal-content {
  background: #1a1a1a;
}
.subModal .ant-modal-header {
  background: #1a1a1a;
  border: 0;
}
.subModal .ant-modal-title {
  color: #ffff;
}
.subModal .ant-modal-content {
  background: #1a1a1a;
}
.slider {
  width: 100%;
}
.slider .ant-slider-dot {
  height: 20px;
  width: 20px;
  top: -5px;
  margin-left: -10px;
}
.slider .ant-slider-dot-active {
  border: 0px;
  background: linear-gradient(180deg, #ff5858 0%, #ff5b37 100%);
}
.slider .ant-slider-rail {
  height: 9px;
}
.slider .ant-slider-mark-text {
  color: #ffffff;
  margin-top: 10px;
}
/* Nutrition Activity Modal Css End */
/* Nutrition Detail Screen Css Start */
.nutritionContainerDiv {
  display: flex;
  flex-direction: column;
  background: black;
}
.nutritionDiv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.nutritionDetailsDiv {
  display: flex;
  flex-direction: row;
  background: "black";
  width: 94%;
  justify-content: space-evenly;
}
.DishDetailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #1a1a1a;
}
.DishImageAndNameDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95%;
  padding: 30px 30px 0px 30px;
  background: #1a1a1a;
  border-radius: 20px;
}
.dishImage {
  width: 60%;
  border-radius: 20px;
}
.DishNameAndDescriptionDiv {
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
.caloriesDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  background: #1a1a1a;
  padding: 10px;
  margin-top: 10px;
  border-radius: 20px;
}
.aminoAcidsDiv {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.InstructionsDiv {
  display: flex;
  flex-direction: column;
  width: 95%;
  background: #1a1a1a;
  padding: 0px 30px;
  margin-top: 10px;
  border-radius: 20px;
}
.IngredientsDiv {
  display: flex;
  flex-direction: column;
  width: 95%;
  justify-content: flex-start;
  align-items: flex-start;
}
.IngredientsDescriptionDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  background: #1a1a1a;
  padding: 10px 30px 30px 30px;
  border-radius: 20px;
}
.verticalLineDiv {
  width: 100%;
  border: 0.5px solid rgba(255, 255, 255, 0.15);
}
.IngredientsNameAndQuantityDiv {
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
}
.MoreMealsDiv {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  border-radius: 20px;
  background: #1a1a1a;
  margin-top: 10px;
}
.mealsImgAndNameDiv {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  padding-top: 10px;
}
.mealsImg {
  width: 280px;
  border-radius: 10px;
  align-self: left;
}
.MoreMeals {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ViewMoreMealText {
  color: white;
  text-align: left;
}
.MealNameText {
  color: white;
  font-size: 25px;
  font-weight: 800;
  text-shadow: rgb(0, 0, 0) 4px 1px;
  margin-top: 50px;
  margin-left: 110px;
  display: flex;
  background: black;
}
.AminoAcidsText {
  color: grey;
  margin: 2px;
}
.AminoAcidsNumberText {
  color: white;
  margin: 0px;
  font-size: 20px;
}
.InstructionDetailsText {
  color: white;
  text-align: left;
  white-space: pre-line;
  font-size: 18px;
}
.InstructionHeadingText {
  color: white;
  text-align: left;
}
.IngredientHeadingText {
  color: white;
  text-align: left;
  font-size: 20px;
  margin-top: 15px;
}
.IngredientNameText {
  color: white;
  width: 70%;
  text-align: left;
  margin: 2px;
}
.IngredientQuantityText {
  color: red;
  margin: 2px;
}

/* Media Queries Of Nutrition */

/* @media only screen and (max-width: 1600px) {
}
@media only screen and (max-width: 1400px) {
}
@media only screen and(max-width:1200px) {
} */
@media only screen and (max-width: 929px) {
  .dishImage {
    width: 100%;
  }
  .nutritionDiv {
    flex-direction: column;
    padding: 10px;
  }
  .nutritionDetailsDiv {
    flex-direction: column;
    width: 100%;
  }
  .DishDetailsDiv {
    width: 100%;
    border-radius: 15px;
  }
  .DishImageAndNameDiv {
    padding: 5px;
    flex-direction: column;
  }
  .DishNameAndDescriptionDiv {
    width: 100%;
  }
  .caloriesDiv {
    width: 100%;
    padding: 0px;
    align-items: center;
  }
  .InstructionsDiv {
    padding: 5px;
  }
  .IngredientsDiv {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }
  .IngredientsDescriptionDiv {
    width: 100%;
    padding: 10px;
  }
  .MoreMealsDiv {
    padding: 10px;
  }
  .MoreMeals {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mealsImg {
    width: 180px;
  }
  .ViewMoreMealText {
    font-size: 18px;
  }
  .MealNameText {
    font-size: 18px;
    margin-left: 20px;
  }
  .AminoAcidsText {
    font-size: 10px;
    margin-left: 0px;
  }

  .AminoAcidsNumberText {
    font-size: 13px;
  }
  .aminoAcidsDiv {
    width: 100%;
    padding: 0px;
  }
  .InstructionDetailsText {
    color: white;
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
  }
  .InstructionHeadingText {
    font-size: 18px;
  }
  .IngredientNameText {
    font-size: 13px;
  }
  .IngredientQuantityText {
    font-size: 13px;
  }
  .IngredientHeadingText {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .dishImage {
    width: 100%;
  }
  .nutritionDiv {
    flex-direction: column;
    padding: 10px;
  }
  .nutritionDetailsDiv {
    flex-direction: column;
    width: 100%;
  }
  .DishDetailsDiv {
    width: 100%;
    border-radius: 15px;
  }
  .DishImageAndNameDiv {
    padding: 5px;
    flex-direction: column;
  }
  .DishNameAndDescriptionDiv {
    width: 100%;
  }
  .caloriesDiv {
    width: 100%;
    padding: 0px;
    align-items: center;
  }
  .InstructionsDiv {
    padding: 5px;
  }
  .IngredientsDiv {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }
  .IngredientsDescriptionDiv {
    width: 100%;
    padding: 10px;
  }
  .MoreMealsDiv {
    padding: 10px;
  }
  .MoreMeals {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mealsImg {
    width: 180px;
  }
  .ViewMoreMealText {
    font-size: 18px;
  }
  .MealNameText {
    font-size: 18px;
    margin-left: 20px;
  }
  .AminoAcidsText {
    font-size: 10px;
    margin-left: 0px;
  }

  .AminoAcidsNumberText {
    font-size: 13px;
  }
  .aminoAcidsDiv {
    width: 100%;
    padding: 0px;
  }
  .InstructionDetailsText {
    color: white;
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
  }
  .InstructionHeadingText {
    font-size: 18px;
  }
  .IngredientNameText {
    font-size: 13px;
  }
  .IngredientQuantityText {
    font-size: 13px;
  }
  .IngredientHeadingText {
    font-size: 18px;
  }
}
@media only screen and (max-width: 666px) {
  .dishImage {
    width: 100%;
  }
  .nutritionDiv {
    flex-direction: column;
    padding: 10px;
  }
  .nutritionDetailsDiv {
    flex-direction: column;
    width: 100%;
  }
  .DishDetailsDiv {
    width: 100%;
    border-radius: 15px;
  }
  .DishImageAndNameDiv {
    padding: 5px;
    flex-direction: column;
  }
  .DishNameAndDescriptionDiv {
    width: 100%;
  }
  .caloriesDiv {
    width: 100%;
    padding: 0px;
    align-items: center;
  }
  .InstructionsDiv {
    padding: 5px;
  }
  .IngredientsDiv {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }
  .IngredientsDescriptionDiv {
    width: 100%;
    padding: 10px;
  }
  .MoreMealsDiv {
    padding: 10px;
  }
  .MoreMeals {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mealsImg {
    width: 180px;
  }
  .ViewMoreMealText {
    font-size: 18px;
  }
  .MealNameText {
    font-size: 18px;
    margin-left: 20px;
  }
  .AminoAcidsText {
    font-size: 10px;
    margin-left: 0px;
  }

  .AminoAcidsNumberText {
    font-size: 13px;
  }
  .aminoAcidsDiv {
    width: 100%;
    padding: 0px;
  }
  .InstructionDetailsText {
    color: white;
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
  }
  .InstructionHeadingText {
    font-size: 18px;
  }
  .IngredientNameText {
    font-size: 13px;
  }
  .IngredientQuantityText {
    font-size: 13px;
  }
  .IngredientHeadingText {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .nutritionDiv {
    flex-direction: column;
    padding: 10px;
  }
  .nutritionDetailsDiv {
    flex-direction: column;
    width: 100%;
  }
  .DishDetailsDiv {
    width: 100%;
    border-radius: 15px;
  }
  .DishImageAndNameDiv {
    padding: 5px;
    flex-direction: column;
  }
  .DishNameAndDescriptionDiv {
    width: 100%;
  }
  .caloriesDiv {
    width: 100%;
    padding: 0px;
    align-items: center;
  }
  .InstructionsDiv {
    padding: 5px;
  }
  .IngredientsDiv {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }
  .IngredientsDescriptionDiv {
    width: 100%;
    padding: 10px;
  }
  .MoreMealsDiv {
    padding: 10px;
  }
  .MoreMeals {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mealsImg {
    width: 180px;
  }
  .ViewMoreMealText {
    font-size: 15px;
  }
  .MealNameText {
    font-size: 15px;
    margin-left: 20px;
  }
  .AminoAcidsText {
    font-size: 10px;
    margin-left: 0px;
  }

  .AminoAcidsNumberText {
    font-size: 10px;
  }
  .aminoAcidsDiv {
    width: 100%;
    padding: 0px;
  }
  .InstructionDetailsText {
    color: white;
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
  }
  .InstructionHeadingText {
    font-size: 15px;
  }
  .IngredientNameText {
    font-size: 10px;
  }
  .IngredientQuantityText {
    font-size: 10px;
  }
  .IngredientHeadingText {
    font-size: 15px;
  }
}
@media only screen and (max-width: 330px) {
  .nutritionDiv {
    flex-direction: column;
    padding: 10px;
  }
  .nutritionDetailsDiv {
    flex-direction: column;
    width: 100%;
  }
  .DishDetailsDiv {
    width: 100%;
    border-radius: 15px;
  }
  .DishImageAndNameDiv {
    padding: 5px;
    flex-direction: column;
  }
  .DishNameAndDescriptionDiv {
    width: 100%;
  }
  .caloriesDiv {
    width: 100%;
    padding: 0px;
    align-items: center;
  }
  .InstructionsDiv {
    padding: 5px;
  }
  .IngredientsDiv {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }
  .IngredientsDescriptionDiv {
    width: 100%;
    padding: 10px;
  }
  .MoreMealsDiv {
    padding: 10px;
  }
  .MoreMeals {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mealsImg {
    width: 180px;
  }
  .ViewMoreMealText {
    font-size: 15px;
  }
  .MealNameText {
    font-size: 15px;
    margin-left: 20px;
  }
  .AminoAcidsText {
    font-size: 10px;
    margin-left: 0px;
  }

  .AminoAcidsNumberText {
    font-size: 10px;
  }
  .aminoAcidsDiv {
    width: 100%;
    padding: 0px;
  }
  .InstructionDetailsText {
    color: white;
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
  }
  .InstructionHeadingText {
    font-size: 15px;
  }
  .IngredientNameText {
    font-size: 10px;
  }
  .IngredientQuantityText {
    font-size: 10px;
  }
  .IngredientHeadingText {
    font-size: 15px;
  }
}
/* Nutrition Detail Screen Css End */
