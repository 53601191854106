.main-container-div {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url("../assets/images/girlJumping.webp");
  background-size: contain;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.main-right-container-div {
  width: 80%;
  /* padding: 50px; */
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  min-height: 100vh;
  z-index: 0;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: 50% 50%;
}
.main-left-container-div {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  background: #fff;
  align-items: center;
  padding: 30px;
  min-height: 100vh;
  position: relative;
}
.main-left-heading-one {
  color: rgba(239, 53, 17, 1);
}
.main-left-heading-two {
  margin: 40px 0px 40px 0px;
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 92.6%;
}
.main-left-heading-three {
  color: #434343;
  text-align: center;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  width: 75%;
  line-height: normal;
}
.input-div {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  border: 2px solid #fff;
  background: #fff;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
  margin-left: 19px;
}
.inputfield,
.inputfield:focus {
  background-color: transparent;
  width: 100%;
  align-self: center;
  margin-left: 1vw;
  padding: 5px;
  border: none;
  /* border-left: 1px solid #707070; */
  font-size: 20px;
  outline: none !important;
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  color: #6f6f6f;
  font-size: 16px;
}
.continueButton-new {
  color: #fff;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 71%;
  margin-top: 20px;
  background: linear-gradient(63.69deg, #eb3d30 28.36%, #ec6736 80.63%);
  border-radius: 12px;
  padding: 15px;
  margin-left: 14px;
  border: 0;
}
.enter-number-and-otp-text {
  color: #3f3f3f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
.termsAndConditionBtn {
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 7px;
  /* padding: 8px; */
  border-color: #fff;
  margin-top: 40px;
  width: 34%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.resend-text {
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  border: none;
  margin-top: 15px;
  /* margin-left: 9vw; */
  /* margin-top: 20px; */
}
.otpinputDiv {
  background-color: transparent;
  color: #6f6f6f;
  border: 1px solid #6f6f6f;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 4.5vw !important;
  height: 4.5vw !important;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25) !important;
}
.otp-box {
  width: 26vw;
  justify-content: center !important;
  margin-left: 40px !important;
  /* border: 1px solid green; */
}
.package-text {
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 11.9px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 10px;
  /* width: 20%; */
  /* margin-left: 15px; */
}
.error-text {
  color: rgba(239, 53, 17, 1);
  text-align: center;
  font-family: Manrope;
  font-size: 11.9px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.dropdown-ln,
.dropdown-ln:hover {
  color: #000;
  border: 1px solid white;
  border-radius: 5px;
  padding: 2px;
  /* background-color: #ff1e1e; */
  font-weight: 600;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  width: 15%;
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fff;
}
.ant-modal-close {
  color: #0f0f0f;
}
.ant-modal-content {
  border-radius: 20px;
  width: 100%;
}

.crossProductContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 16px;
  border: 1px solid #707070;
  border-radius: 9px;
  cursor: pointer;
}
.crossProductImages {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 30%;
}
.crossProductContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 65%;
}
.productLogo {
  width: 40%;
  height: 40%;
}
.jazzlogo {
  width: 40%;
  height: 40%;
}
.contentTitle {
  font-size: 18px;
  font-weight: 700;
  font-family: "Poppins";
  margin-bottom: 0px;
}
.contentDescription {
  font-size: 14px;
  /* font-weight: 600; */
  font-family: "Poppins";
  text-align: left;
}

@media screen and (max-width: 480px) {
  .main-container-div {
    flex-direction: column;
    position: relative;
    background-image: url("../assets/images/girl-dancing.webp");
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 15px;
    /* min-height: 215px; */
    height: 100vh;
    /* padding-bottom: 150px; */
    background-position: 17% 50%;
  }
  .main-left-container-div {
    width: 95%;
    z-index: 1;
    margin-top: 20px;
    /* position: absolute; */
    padding: 0px;
    min-height: 0;
    margin-top: 130px;
    padding: 10px;
  }
  .main-right-container-div {
    position: absolute;
    display: none;
    /* z-index: -1; */
  }
  .main-left-heading-two {
    font-size: 20px;
    margin: 20px 0px 20px 0px;
  }
  .main-left-heading-three {
    font-size: 12px;
  }
  .continueButton-new {
    margin-top: 10px;
    width: 90%;
    margin-left: 0;
  }
  .termsAndConditionBtn {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50%;
  }
  .input-div {
    width: 90%;
    margin-left: 5px;
    padding: 4px;
  }
  .otpinputDiv {
    width: 13.5vw !important;
    height: 13.5vw !important;
  }
  .otp-box {
    margin-left: 20px !important;
  }
  .dropdown-ln,
  .dropdown-ln:hover {
    top: -130px;
    right: 0px;
    width: 20%;
  }
  .crossProductContainer {
    flex-direction: column;
    padding: 8px;
  }
  .crossProductImages {
    width: 65%;
  }
  .crossProductContent {
    width: 100%;
    align-items: center;
  }
  .productLogo {
    width: 50%;
    height: 50%;
  }
  .jazzlogo {
    width: 50%;
    height: 50%;
  }
  .contentTitle {
    font-size: 14px;
  }
  .contentDescription {
    font-size: 12px;
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .main-container-div {
    flex-direction: column;
    position: relative;
    /* background-image: url("../assets/images/girlJumping.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 15px;
    min-height: 215px;
    padding-bottom: 140px;
    background-position: 20% 50%;
  }
  .main-left-container-div {
    width: 100%;
    z-index: 1;
    margin-top: 20px;
    /* position: absolute; */
    padding: 0px;
    min-height: 0;
    margin-top: 50px;
    padding: 10px;
  }
  .main-right-container-div {
    position: absolute;
    display: none;
    /* z-index: -1; */
  }
  .main-left-heading-two {
    font-size: 20px;
    margin: 20px 0px 20px 0px;
  }
  .main-left-heading-three {
    font-size: 12px;
  }
  .continueButton-new {
    margin-top: 10px;
    width: 90%;
    margin-left: 0;
  }
  .termsAndConditionBtn {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50%;
  }
  .input-div {
    width: 90%;
    margin-left: 5px;
    padding: 4px;
  }
  .otpinputDiv {
    width: 13.5vw !important;
    height: 13.5vw !important;
  }
  .otp-box {
    margin-left: 20px !important;
  }
}
