.Login-page-containerSA {
  display: flex;
  /* align-items: center !important; */
  width: 100% !important;
  /* flex-direction: column-reverse; */
  justify-content: center;
  padding: 0px !important;
  overflow: hidden !important;
  background-image: url("../assets/images/background_optimized.webp");
  /* background-size: cover; */
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.containerLeftSA {
  overflow: hidden;
  width: 60%;
  /* height: 100vh; */
  /* background-image: linear-gradient(
      194.92deg,
      rgba(0, 0, 0, 0) 45.34%,
      #000000 102.5%
    ),
    url("../assets/images/background_optimized.webp"); */
  /* background-size: cover; */
  background-size: auto 100%;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  /* border: 1px solid red; */
}
.containerRightSA {
  height: 100vh;
  width: 40%;
  overflow: hidden;
  /* border: 1px solid red; */
  background: linear-gradient(337.1deg, #000000 -1.26%, #4f4f4f 130.31%);
  border-radius: 40px 0px 0px 40px;

  /* align-items: flex-start;
  justify-content: flex-start; */
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 6vw;
  padding-top: 50px;
  padding-right: 3vw;
}
.foreground-div {
  /* position: absolute;
  transform: rotate(0deg);
  transform: translate(49%, -20%) rotate(41deg);
  width: 200px;
  height: 75%;
  right: 0px;
  top: 0px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  ); */

  transform: translate(60%, -71%) rotate(-50deg);
  width: 100%;
  height: 100%;
  right: 0px;
  top: 0px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
}

.redirect-button {
  border: 0px;
  width: 70%;
  margin-top: 20px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 12px;
}

.title-label {
  text-align: left;
}

.mysvg {
  width: 15%;
  height: 15%;
  margin-bottom: 30px;
}

.welcome-text-sa {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 41px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
}

.secondary-text-sa {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
}

.icon-check {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 880px) {
  .welcome-text-sa {
    font-size: 18px;
  }
  .secondary-text-sa {
    font-size: 13px;
  }
  .icon-check {
    width: 20px;
    height: 20px;
  }
  .foreground-div {
    transform: translate(79%, -71%) rotate(-55deg);
  }
  .mysvg {
    width: 20%;
    height: 20%;
  }
  .title-label {
    text-align: center;
  }
}

@media screen and (max-width: 330px) {
  .Login-page-containerSA {
    display: flex;
    /* align-items: center !important; */
    height: 100vh !important;
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    padding: 0px !important;
    overflow: hidden !important;
    background-image: url("../assets/images/portrait_background.webp");
    background-size: cover !important;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .title-label {
    text-align: center;
  }
  .foreground-div {
    transform: translate(79%, -71%) rotate(-55deg);
  }
  .mysvg {
    width: 20%;
    height: 20%;
  }
  .containerLeftSA {
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    /* border-radius: none; */
    width: 100%;
    background-image: none;

    margin-bottom: 100px !important;
    position: relative;
    align-items: center;
    /* height: 20% !important; */
  }
  .containerRightSA {
    display: flex;
    width: 100% !important;
    /* justify-content: flex-start !important; */
    align-items: center !important;
    border-radius: 0px;
    height: 100% !important;
    /* border-radius: 10px; */
    /* height: 100%; */
    /* background: #000; */
    padding: 0px !important;
    margin-top: 25px !important;
    opacity: 1;
    background: none;
  }
}

@media screen and (max-width: 666px) {
  .Login-page-containerSA {
    display: flex;
    /* align-items: center !important; */
    height: 100vh !important;
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    padding: 0px !important;
    overflow: hidden !important;
    background-image: url("../assets/images/portrait_background.webp");
    background-size: cover !important;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .title-label {
    text-align: center;
  }
  .welcome-text-sa {
    font-size: 18px;
  }
  .foreground-div {
    transform: translate(79%, -71%) rotate(-55deg);
  }
  .secondary-text-sa {
    font-size: 13px;
  }
  .mysvg {
    width: 20%;
    height: 20%;
  }
  .icon-check {
    width: 20px;
    height: 20px;
  }
  .containerLeftSA {
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    /* border-radius: none; */
    width: 100%;
    background-image: none;

    margin-bottom: 100px !important;
    position: relative;
    align-items: center;
    /* height: 40% !important; */
  }
  .containerRightSA {
    display: flex;
    width: 100% !important;
    /* justify-content: flex-start !important; */
    align-items: center !important;
    height: 100% !important;
    border-radius: 0px;
    /* border-radius: 10px; */
    /* height: 100%; */
    /* background: #000; */
    padding: 0px !important;
    margin-top: 5px !important;
    opacity: 1;
    background: none;
  }
}

@media screen and (max-width: 480px) {
  .Login-page-containerSA {
    display: flex;
    /* align-items: center !important; */
    height: 100vh !important;
    width: 100% !important;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0px !important;
    overflow: hidden !important;
    background-image: url("../assets/images/portrait_background.webp");
    background-size: cover !important;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .title-label {
    text-align: center;
  }
  .mysvg {
    width: 20%;
    height: 20%;
  }
  .foreground-div {
    transform: translate(79%, -71%) rotate(-55deg);
  }
  .containerRightSA {
    /* height: 100vh; */
    /* overflow: scroll; */
    width: 100% !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
    border-radius: 0px;
    /* border-radius: 10px; */
  }
}

@media screen and (max-width: 780px) {
  .containerRightSA {
    width: 100%;
  }
}
