.main-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.confirmation-box {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: start;
  align-items: center;
}
.main-text {
  color: #000;
  font-size: 40px;
  font-family: "Poppins";
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: center;
  font-weight: 900;
}
.secondary-text {
  color: #000;
  font-size: 20px;
  font-family: "Poppins";
  text-align: center;
  margin-top: 0px;
  font-weight: 500;
}
.back-button {
  background: linear-gradient(119.19deg, #ef3511 11.69%, #ff7253 73.27%);
  width: 30%;
  padding: 10px;
  color: #fff;
  border: none;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .back-button {
    width: 50%;
  }
  .secondary-text {
    width: 100%;
  }
  .confirmation-box {
    width: 100%;
  }
}
