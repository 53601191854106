@import url(https://fonts.googleapis.com/css2?family=Itim&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url(/static/media/Manrope-Regular.a9b9e09f.ttf) format("truetype");
  font-display: auto;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(/static/media/Montserrat-Regular.24785cc0.ttf) format("truetype");
  font-display: auto;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(/static/media/Poppins-Regular.35d26b78.ttf) format("truetype");
  font-display: auto;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(/static/media/Inter-Regular.9ca9ba81.ttf) format("truetype");
  font-display: auto;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
@layer base {
  html,
  body {
    /* display: flex; */
    /* height: 100%; */
    /* max-height: 100vh; */
    /* max-width: 100vw; */
    /* overflow-x: hidden; */
    /* scroll-behavior: smooth; */
    /* scroll-snap-type: y mandatory; */
    /* scroll-padding: 20px; */
  }
  .panel {
    scroll-snap-align: center;
    padding-top: 30px;
  }
}

.App {
  text-align: center;
  font-family: "Inter", sans-serif;
  /* background-color: rgba(0, 0, 0, 1); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site-layout-content {
  min-height: 650px;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  /* transform: skew(0, 165deg); */
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 20vw;
}
.video-class {
  width: 600px;
  height: 350px;
  /* border: 1px solid green; */
  margin-top: 100px;
  margin-right: 100px;
  margin-left: 10px;
  border-radius: 30px;
  box-shadow: rgba(256, 256, 256, 0.4) 26px 7px 131px 22px;
}

.header-content {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  width: 70vw;
}
.ant-drawer-title {
  margin: 0;
  color: #ff1e1e;
  font-weight: 500;
  font-size: 6vw;
  line-height: 22px;
  background-color: #000;
}
.rings {
  width: 5%;
  position: absolute;
  left: 0;
}

.player-wrapper {
  position: relative;
  padding-top: 20.8%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 10vw;
  margin-left: 50px;
  margin-top: 30px;
  /* margin-bottom: -10px; */
}

/* .react-player__preview {
  border-radius: 20px !important;
} */
.btnDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: -45px;
}
.bton-simple,
.bton-simple:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  border-radius: 9px;
  height: 4vw;
  width: 12vw;
  font-size: 1.2vw;
  background-color: #fff;
}
.icon-div {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: -280px;
  margin-bottom: -20px;
  display: flex;
  flex-direction: column;
}
.rounded {
  margin-bottom: 10px;
  height: 64px;
  width: 64px;

  transform: rotate(-60deg);
  border-top-left-radius: -2px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 12px;
  /* transform: rotate(
-80deg); */
  height: 12px;
  /* border: 1px solid black; */
  border-radius: 50px;
  background-color: #ff1e1e;
  /* align-self: center; */
  /* float: right; */
  margin-left: 24px;
  margin-top: 23px;
}

.react-player {
  position: absolute;
  top: 0;
  height: 320px !important;
  width: 90% !important;
  left: 0;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 6px 10px 70px black;
  margin-top: 80px;
  margin-left: 50px;
}
.play-icon {
  width: 20%;
}
.ant-image-preview-operations-operation:not(:first-child) {
  display: none;
}

/* .ant-card-cover img {
  border-radius: 2px 2px 0 0;
  height: 26vw;
  border-radius: 20px;
} */

.workout-card > .ant-card-cover img {
  border-radius: 2px 2px 0 0;
  height: 250px;
  width: 200px;
  /* border-radius: 10px; */
}

/* .play-icon {
  position: absolute;
  top: 7.6vw;
  right: 14.6vw;
  width: 23%;
  align-self: center;
} */

.videoCard {
  width: 600px;
  height: 400px;
  background-color: rgb(255, 255, 255);
  top: -85px;
  transform: skewY(10deg);
  float: right;
  margin-right: 139px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  border-radius: 20px;
  border: 0px;
}

.banner-text {
  position: absolute;
  left: 4.06%;
  top: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.home-workout-tags-div {
  display: flex;
  /* border: 1px solid yellow; */
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.layout1 {
  color: #fff;
  display: flex;
  flex-direction: row;
  padding-left: 200px;
  background-color: #000;
  height: 210px;
  padding-top: 35px;
  justify-content: space-between;
  /* transform: skewY(15deg); */
}
.title {
  /* width: 50%; */
  width: 100%;

  font-style: normal;
  font-weight: bold;
  /* font-size: 35px; */
  font-size: 30px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: left;
  /* line-height: 50.5px; */
  color: #ffffff;
  margin-bottom: 20px;
  margin-top: -20px;
}

.indicator {
  position: absolute;
  border: 0px;
  width: 14.1%;
  left: 0px;
  z-index: -1;
  bottom: 0px;
  height: 30px;
  transform: skew(0deg, 0deg);
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.indicator2 {
  position: absolute;
  right: 0px;
  border: 0px;
  width: 10.1%;
  z-index: -1;
  bottom: -27px;
  height: 30px;
  transform: skew(-1deg, -1deg);
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.title-secondary {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  /* line-height: 30px; */
  word-wrap: break-word;
  letter-spacing: -0.02em;
  /* text-transform: capitalize; */
  color: #a9a9a9;
  /* width: 100%; */
  width: 90%;
  margin-bottom: 50px;
  /* width: 30%; */
}
.title-tertiary {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  text-align: left;
  letter-spacing: -0.02em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #ff1e1e;
}

.logo {
  float: left;

  font-family: "Gotham";
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  color: #ff1e1e;
  margin: 18px 24px 16px 0;
}

.logo-right {
  float: right;
  font-family: "Gotham";
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  color: #ff1e1e;
  margin: 18px 24px 16px 0;
}
/* .ant-row-rtl .logo {
  float: left;
  margin: 30px 0 16px 24px;
} */

.ant-layout-header {
  background-color: rgba(0, 0, 0, 1);
  height: 100px;
  width: 100%;
  z-index: 1;
  align-self: center;
}

.ant-menu.ant-menu-dark {
  background-color: rgba(0, 0, 0, 1);
  display: flex;

  /* justify-content: center; */
  justify-content: space-evenly;
  margin-top: 20px;
  z-index: 1;
  text-transform: uppercase;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  /* line-height: 20px; */
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu.ant-menu-dark .ant-menu-item {
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}

.workout-section {
  margin-top: 30px;
  margin-bottom: -50px;
  padding-left: 15vw;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.workout-heading1 {
  font-weight: 500;
  margin-top: -30px;
  font-size: 1.2vw;
  line-height: 137%;
  text-transform: uppercase;
  color: #ff5858;
  text-align: left;
}

.workout-heading2 {
  font-style: normal;
  font-weight: 700;
  font-size: 5vh;
  width: 55%;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: left;
  margin-bottom: 0px;
}

.workout-heading3 {
  font-style: normal;
  font-style: 500;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: left;
  margin-bottom: 30px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  height: 400px;
  width: 90%;
  margin-bottom: 21px;
  border: 0px solid rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  box-shadow: 2px;
  justify-self: center;
  align-self: center;
  box-shadow: 0px 30px 40px grey;
  margin-right: 10vw;
}

.line {
  border-right: 1px solid rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 0.3);
  /* border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; */
  position: relative;
}

.line:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.line:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.myText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.myDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 20px;
  padding-top: 60px;
}

.categories {
  padding-left: 14vw;
  margin-top: 12vw;
  margin-bottom: 5vw;
}

.training-plans {
  display: flex;
  /* text-align: flex-start; */
  margin-top: 30;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-left: 15vw; */
}

.arrow {
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 2px;
  right: 85%;
}
.sidebar-menu-text,
.sidebar-menu-text:active,
.sidebar-menu-text:hover {
  color: white;
  font-size: 4vw;
  font-weight: 600;
}
.hidden-sidebar {
  display: none;
}
@media only screen and (max-width: 470px) {
  .hide-header {
    display: none;
  }
  .hidden-sidebar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* border: 1px solid green; */
  }
  .rings {
    display: none !important;
  }
}
@media only screen and (max-width: 370px) {
  .title {
    width: 100% !important;
    font-size: 20px !important;
    line-height: 25px !important;

    /* border: 1px solid green; */
  }
  .title-secondary {
    width: 100% !important;
    font-size: 16px !important;
    line-height: 25px !important;
  }
}
@media only screen and (max-width: 533px) {
  .wrapper {
    width: 90% !important;
  }
}

@media only screen and (min-height: 1300px) {
  .react-player {
    height: 390px !important;
  }
}
@media only screen and (min-height: 1400px) {
  .react-player {
    height: 355px !important;
  }
}
@media only screen and (min-height: 1205px) {
  .react-player {
    height: 370px !important;
  }
}
@media only screen and (min-height: 600px) {
  .react-player {
    height: 370px !important;
  }
}
@media only screen and (min-height: 900px) {
  .workout-heading2 {
    font-style: normal;
    font-weight: 700;
    font-size: 3vh;
    width: 70%;
    line-height: 70px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: left;
    margin-bottom: 0px;
  }
  .react-player {
    margin-left: 70px !important;
    /* width: 95% !important; */
    height: 325px !important;
  }
}
@media only screen and (min-height: 775px) {
  .react-player {
    margin-left: 70px !important;
    /* width: 95% !important; */
    height: 300px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .wrapper {
    height: 800px;
    width: 47%;
  }

  .line {
    border-right: 0px solid;
    border-bottom: 1px solid #fff;
  }
  .line:first-child {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 20px;
  }
  .line:last-child {
    border-bottom-left-radius: 20px;
  }
}

@media only screen and (min-width: 1600px) {
  .site-layout-content {
    /* transform: skew(0, 0deg); */
    /* height: 65vh; */
  }
  .layout1 {
    transform: skew(0, 0deg);
  }
  .subheader-download-links {
    width: 180px !important;
  }
}

@media only screen and (min-width: 1500px) {
  .site-layout-content {
    /* transform: skew(0, 0deg); */
    height: 65vh;
  }
  .catagories-div {
    width: 80% !important;
  }

  .icon-div {
    margin-top: 10px;
    transform: skew(0, 0deg);
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-left: 100px;
    right: 0;
    /* top: 10; */
  }
  .header-btn,
  .header-btn:hover,
  .header-btn:focus {
    width: 20vw !important;
  }
}

.carousel-image {
  width: 100%;
  height: 550px;
  border-radius: 25px;
}
.Next-Button,
.Next-Button:hover,
.Next-Button:focus {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  font-size: 20px;
  outline: none;
  border: none;
  color: white;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deactivate-Button,
.deactivate-Button:hover,
.deactivate-Button:focus {
  background: transparent;
  font-size: 1vw;
  border-radius: 15px;
  padding: 20px;
  width: 42%;
  outline: none;
  border: 1px solid #ff1e1e;
  color: #ff1e1e;
  text-transform: capitalize;

  display: flex;
  justify-content: center;
  align-items: center;
}
.training-plan-scroll-div {
  margin-top: 700px;
}
@media only screen and (max-width: 950px) {
  .video-class {
    margin-left: 10px !important;
  }
  .carousel-image {
    height: 320px !important;
  }
  .training-plan-scroll-div {
    margin-top: 500px !important;
  }
}
@media only screen and (max-width: 790px) {
  .video-class {
    margin-left: 40px !important;
    width: 600px;
  }
}
@media only screen and (max-width: 665px) {
  .video-class {
    margin-left: 80px !important;
    border-radius: 3px;
    /* justify-self: center; */
    /* align-self: center; */
    width: 100vw;
    margin-top: 10px;
  }
  .deactivate-Button,
  .deactivate-Button:hover,
  .deactivate-Button:focus {
    font-size: 3vw;
    width: 85%;
    margin-left: 30px;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 322px) {
  .video-class {
    margin-left: 70px !important;
    width: 95vw !important;
    height: 300px !important;
    margin-top: 60px;
  }
  .deactivate-Button,
  .deactivate-Button:hover,
  .deactivate-Button:focus {
    font-size: 3vw;
    width: 85%;
    margin-left: 30px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .site-layout-content {
    width: 100%;
    height: 800px;
    background: rgba(0, 0, 0, 1);
    transform: skew(0, 165deg);
    margin-bottom: 20vw;
  }
  .header-content {
    width: 80vw;
  }
  .video-class {
    justify-self: center;
    align-self: center;
    display: flex;
    margin-left: 170px;
    /* margin-top: 150px; */
  }

  .layout1 {
    color: #fff;
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    background-color: #000;
    padding-top: 35px;
    justify-content: space-between;
    transform: skewY(15deg);
  }

  .title {
    width: 80%;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    text-align: left;
    line-height: 40.5px;
    color: #ffffff;
    margin-bottom: 12px;
  }
  .title-secondary {
    font-size: 16px;
    text-align: left;
    line-height: 30px;
    word-wrap: break-word;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #ffffff;
    width: 70%;
  }
  .title-tertiary {
    font-size: 12px;
    line-height: 30px;
    text-align: left;
    letter-spacing: -0.02em;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #ff1e1e;
  }
  .player-wrapper {
    position: relative;
    padding-top: 23.25%;
    width: 60%;
    height: 400px;
    margin-right: 5vw;
    margin-top: 40px;
    margin-bottom: 100px;
  }
  .react-player {
    margin-left: 200px;
  }

  .workout-heading1 {
    font-size: 16px;
    margin-top: -22px;
  }

  /* .react-player__preview {
    border-radius: 20px !important;
  } */
  .icon-div {
    z-index: 1;
    position: absolute;
    display: block;
    /* left: 40%; */
    /* transform: skewY(
13deg); */

    right: 0;
    bottom: -430px;
  }
  .rounded {
    margin-bottom: 10px;
    height: 50px;
    width: 50px;
    transform: rotate(-60deg);
    border-top-left-radius: -2px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
  }

  .dot {
    width: 6px;
    /* transform: rotate( 
-80deg); */
    height: 6px;
    border: 1px solid #ff1e1e;
    border-radius: 50px;
    background-color: #ff1e1e;
    /* align-self: center; */
    /* float: right; */
    margin-left: 21px;
    margin-top: 20px;
  }

  .arrow {
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 2px;
    right: 80%;
  }
}
.cliped {
  height: 500px;
  width: 500px;
  background: red;
  -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%, 98% 100%, 95% 50%, 30% 80%);
          clip-path: polygon(0 50%, 100% 0, 100% 100%, 98% 100%, 95% 50%, 30% 80%);
}

.trainingCardText1 {
  font-style: normal;
  font-weight: 800;
  font-size: 3vh;
  text-align: left;
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 0px;
  /* margin-top: 5px; */
  /* width: 20vw; */
  /* height: 20vw; */
}

.trainingCardText2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  text-align: left;
  /* or 150% */

  letter-spacing: -0.02em;

  /* Primary/Grey */

  color: #707070;
}

.content-card {
  min-height: 4vw;
  background-color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  transform: skewY(-13deg);
  /* border: 1px solid blue; */
}
.catagories-div {
  transform: skewY(13deg);
  margin-top: -40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  width: 80vw;
  /* border: 1px solid red; */
  padding: 60px;
  justify-self: center;
  align-self: center;
  /* grid-template-columns: auto auto auto auto; */
  justify-content: center;
  align-items: center;
  width: 100%;
}

.training-indicator {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4vh;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  width: 51vw;
  color: rgb(255, 255, 255);
  float: left;
  transform: skewY(-13deg);
  margin-top: 5.6vw;
}

.slideShow {
  position: relative;
  transform: skewY(13deg);
  /* border: 1px solid green; */
  /* box-shadow: inset 0 0 12px black; */
  width: 80vw;
  justify-self: center;
  align-self: center;
  display: flex;
  overflow-y: hidden;
}
/* ::-webkit-scrollbar {
  width: 7px;
} */
/* ::-webkit-scrollbar-track {
  background-color: #000;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
} */

.workout-card > .ant-card-body {
  padding: 0px;
}

.cards-section {
  justify-content: center;
  /* border: 1px solid #ff1e1e; */
  /* animation: slide infinite 10s alternate; */
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.cards-section:hover {
  animation-play-state: paused;
}
/* .img-shadow {
  -webkit-mask-image: linear-gradient(to top, transparent 5%, black 100%);
  mask-image: linear-gradient(to top, transparent 5%, black 100%);
} */

/* .img-shadow {
  position: relative;
  max-width: 100%;
  float: left;
  border-radius: 0px 0px 15px 15px;
}

.img-shadow::before {
  content: "";
  position: absolute;
  border-radius: 15px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 -90px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 -90px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 -90px 5px rgba(0, 0, 0, 0.2);
}

.img-shadow img {
  float: left;
} */
.header-btn,
.header-btn:hover,
.header-btn:focus {
  background-color: #ff1e1e;
  color: #fff;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px 80px;
  border: none;
  border-radius: 9px;
  height: 3vw;
  width: 8vw;
  font-size: 1vw;
  font-weight: 700;
  margin-top: 0px;
  width: 20vw !important;
}

.workout-card {
  height: 300px;
  background-color: transparent;
  position: relative;
  /* border: 1px solid red; */
  width: 250px;
  border-radius: 15px;

  margin-right: 20px;
  margin-bottom: 20px;
  z-index: 1;
}
.trainCardPic {
  width: 24vw;
  height: 18vw;
}
.trainCardDiv {
  display: flex;
  flex-direction: row;
  padding-left: 0vw;
  padding-top: 50;
  justify-content: center;
  /* border: 1px solid green; */
}

.linear-gradient {
  position: absolute;
  bottom: 0px;
  width: 302px;
}
.trainCardTextDiv {
  width: 22vw;
}

.small-signup-btn,
.small-signup-btn:active,
.small-signup-btn:visited {
  border-radius: 5px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 11px;
  /* border: 1px solid red; */
  place-self: center;
  align-items: center;
  justify-content: center;
  margin-left: 30vw;
  padding: 10px 15px;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  text-decoration: none;
  color: #fff;
  display: flex;
  height: 40%;
}
.new-categories-img-name {
  background-image: linear-gradient(90deg, #ff7c2a, #bf330f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 35px;
  text-align: left;
  position: absolute;
}
.card-heading {
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  position: absolute;
  bottom: -28px;
  left: 2px;
  /* bottom: 0px;
  left: 25px; */
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #ffffff;
  /* text-shadow: 1px 1px #000; */
  margin-bottom: 10px;
  background-image: linear-gradient(90deg, #ff7c2a, #bf330f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 750px) {
  .react-player {
    margin-top: -30px !important;
  }
}
@media screen and (max-width: 430px) {
  .react-player {
    margin-left: -65px;
  }
  .player-wrapper {
    margin-left: -5wv;
  }
  .icon-div {
    display: none;
  }
  .carousel-image {
    height: 180px !important;
    margin-bottom: 50px;
  }

  /* .workout-heading1 {
    margin-top: 11vh;
  } */
}

@media screen and (max-width: 360px) {
  .video-class {
    justify-self: center;
    align-self: center;
    border-radius: 3px;
    display: flex;
    width: 95%;
    height: 400px;
  }
}
@media screen and (max-width: 450px) {
  .carousel-image {
    height: 180px !important;
  }
  .carousel-image-class {
    width: 90% !important;
    left: 5% !important;
  }
}
@media screen and (max-width: 610px) {
  .icon-div {
    position: absolute;
    margin-top: 150px;
  }
  .new-catgories-container {
    flex-direction: column !important;
    margin-left: 0 !important;
  }
  .new-cat-left-container {
    width: 100% !important;
    padding-left: 5px !important ;
    padding-right: 5px !important ;
    margin-top: -80px !important;
  }
  .CTA-new-cat {
    margin: 0 !important;
  }
  .new-categories-tiles-div {
    flex-direction: column !important;
  }
  .new-categories-column {
    flex-direction: row !important;
    margin-top: 10px !important;
    margin-bottom: -80px !important;
    justify-content: space-between !important;
    /* : space-between !important; */
  }
  .new-cat-right-container {
    flex-direction: column !important;
    width: 90% !important;
    padding-left: 5px;
    margin-bottom: 140px;
    /* place-self: center !important; */
  }
  .new-img-style-category {
    width: 120px !important ;
    height: 180px !important;
    border-radius: 0px !important;
  }
  .new-categories-description {
    font-size: 16px !important;
    margin-top: -20px !important;
  }
  .new-workout-card {
    width: 10% !important;
  }
  .new-img-style-category {
    width: 100px !important;
    height: 120px !important;
    /* margin-left: -0px !important; */
    margin-right: -20px !important;
  }
  .card-heading {
    font-size: 10px !important;
    bottom: 59px !important;
    left: 2px;
  }
}

@media screen and (max-width: 433px) {
  /* .training-plans {
    margin-top: -150px;
  } */

  .player-wrapper {
    padding-top: 28%;
  }
}
@media screen and (max-width: 768px) {
  .workout-section {
    padding-left: 4vw;
    margin-bottom: 150px;
  }
  .header-btn,
  .header-btn:hover,
  .header-btn:focus {
    width: 30vw !important;
    font-size: 18px;
  }
  /* .hidden {
    display: none;
  } */
  .categories {
    padding-left: 4vw;
  }
  .training-plans {
    padding-left: 4vw;
  }

  .workout-heading2 {
    font-style: normal;
    font-weight: 700;
    font-size: 3vh;
    width: 100%;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: left;
    margin-bottom: 45px;
  }
  .trainCardDiv {
    flex-direction: column;
    justify-self: center;
    align-self: center;
  }
  .trainCardPic {
    width: 75vw;
    height: 200px;
  }
  .trainCardTextDiv {
    width: 75vw;
  }
  .layout1 {
    padding-left: 20px;
  }
  .player-wrapper {
    position: relative;
    padding-top: 50%;
    width: 80%;
    height: 400px;
    margin-right: 5vw;
  }

  .header-btn,
  .header-btn:hover,
  .header-btn:focus {
    width: 80vw !important;
    height: 40px;
  }
  .rounded {
    margin-bottom: 10px;
    margin-right: 10px;
    height: 50px;
    flex-direction: row;
    width: 50px;
    transform: rotate(0deg);
    border-top-left-radius: -2px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
  }
}
.weight-dropdown .ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: rgb(6, 183, 62);
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #171717;
}
.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100%;
}
/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
.container-div-workout-videos-homepage {
  width: 100%;
  margin-top: 40px;
  background-color: #000000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.heading-div-workout {
  width: 89%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.workout-home-videos {
  display: flex;
  flex-wrap: nowrap;
  width: 93%;
  overflow-x: scroll;

  /* border: 1px solid red; */
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
  padding-left: 30px;
}
.workout-home-videos::-webkit-scrollbar {
  display: none;
}

/* .workout-home-videos::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 10px;
  width: 2px;
}
.workout-home-videos::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
} */
.all-goals-div {
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-self: center;
  align-self: center;
}
.goal-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border: 2px solid grey;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  background-color: transparent;
}
.goal-card:hover,
.goal-card-active {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border: 2px solid #ff603b;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  background-color: #582a29;
}
.carousel-image-class {
  width: 70%;
  position: absolute;
  left: 15%;
  top: 20%;
}
.header-menu-item {
  font-weight: 600;
  font-size: 10px;
  color: white;
}
.trending-videos-like-blogs-container {
  margin-top: 20px;
  display: flex;
  /* padding-bottom: 20px; */
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 60px;
  width: 84%;
}
.left-trending-video {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.right-trending-video {
  width: 45%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 500px;
  margin-left: 10px;
}
.right-trending-video::-webkit-scrollbar {
  display: none;
}

/* .right-trending-video::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 10px;
}
.right-trending-video::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
} */

.trending-thumbnail-big {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
.eye-and-clock-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.scrollbar-trending-image {
  width: 45%;
  border-radius: 10px;
}
.trending-scrollbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}
.homescreen-title-div {
  width: 84%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.homescreen-title-text {
  color: #ff5858;
  justify-self: flex-start;
  align-self: flex-start;
  display: flex;
  margin-top: 30px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
}
.trending-video-title {
  color: #fff;
  justify-self: flex-start;
  align-self: flex-start;
  display: flex;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
}
.trending-title-sidebar {
  color: #fff;
  justify-self: flex-start;
  align-self: flex-start;
  display: flex;
  text-align: left;
  margin-left: 6px;
  font-size: 12px;
  font-weight: 600;
}
.sideScroll-trending-tags {
  display: flex;
  background: #1a1a1a;
  color: rgba(255, 255, 255, 0.65);
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  border-radius: 12px;
  /* width: 22%; */
  text-align: center;
  font-weight: 500;
  font-size: 85%;
}
@media only screen and (max-width: 470px) {
  .sideScroll-trending-tags {
    display: none;
  }
  .workout-home-videos {
    padding: 0px;
  }
}
@media only screen and (max-width: 850px) {
  .homescreen-title-div {
    width: 90%;
  }
  .trending-videos-like-blogs-container {
    width: 90%;
    height: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .sideScroll-trending-tags {
    display: none;
  }
  .left-trending-video {
    width: 100% !important;
  }
  .right-trending-video {
    width: 100% !important;
    margin-left: 0px;
    margin-top: 30px;
    /* margin-bottom: 50px; */
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    overflow-x: scroll !important;
    height: 100% !important;
    justify-content: space-between;
    align-items: center;
  }

  .featured-trending-vid {
    width: 100%;
  }
  /* .right-trending-video {
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    overflow: scroll !important;
    margin-bottom: -300px !important;
  } */
  .trending-scrollbar {
    flex-direction: column !important;
    display: flex;
    flex-wrap: nowrap !important;
    width: 100%;
    align-items: center;
    /* margin-left: 10px; */
    padding-right: 10px;
  }
  .scrollbar-trending-image {
    width: 16.875em;
    height: 150px;
    object-fit: cover;
  }
  .clock-img-class {
    width: 20px;
    height: auto;
  }
  .duration-view-text {
    font-size: 10px;
  }
  .trending-title-sidebar {
    width: 90%;
    /* margin-left: -5px !important; */
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
  .eye-and-clock-div {
    /* margin: 0; */
    justify-content: flex-start !important;
    align-items: center !important;
  }
}
.new-cat-right-container {
  display: flex;
  flex-direction: row;
}
.new-cat-left-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 120px;
}
.new-catgories-container {
  display: flex;
  flex-direction: row;
  margin-top: 200px;
  margin-left: 14%;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid red; */
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: -200px;
}
.new-categories-title {
  font-size: 22px;
  font-weight: 700;
  color: #ff5858;
  text-align: center;
  margin-left: -55px;
  margin-bottom: 20px;
}
.new-categories-description {
  font-size: 28px;
  font-weight: 600;
  width: 90%;
  margin-top: 50px;
  color: black;
  text-align: center;
}
.new-categories-tiles-div {
  /* border: 1px solid green; */
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.new-categories-column {
  display: flex;
  flex-direction: column;

  /* border: 1px solid yellow; */
}
.new-workout-card {
  width: 150px;
  margin-bottom: 20px;
  margin-right: 45px;
  height: 200px;
}
.new-img-style-category {
  width: 185px;
  height: 210px;
  border-radius: 0px;
}
.CTA-div-new-cat {
  position: absolute;
  left: 250px;
  top: 400px;
}
.background-style-div {
  position: absolute;
  height: 200px;
  background-color: black;
  width: 100%;
}

/* Nutrition Confirmation Modal Css Start */
/* .NutritonConfirmationModal .ant-scrolling-effect {
  width: 100%;
} */
/* .body {
  background: #000;
} */
.NutritonConfirmationModal .ant-modal-wrap {
  background: #000;
}
.NutritonConfirmationModal .ant-modal-content {
  border-radius: 15px;
}

.NutritonConfirmationModal .ant-modal-body {
  background: #1a1a1a;
}

.NutritonConfirmationModal .ant-modal-header {
  background: #1a1a1a;
  border: 0px;
  /* border-radius: 25px; */
}
.NutritonConfirmationModal .ant-modal-title {
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
.NutritionFoodIcon {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.NutritionImageIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ff1e1e;
  width: 25%;
  padding: 15px 12px 15px 12px;
  border-radius: 10px;
  background: #592a29;
}

/* Nutrition Confirmation Modal Css End */
/* Nutrition About You Modal Css Start */
.NutritionAboutYouModal .ant-modal-header {
  background: #1a1a1a;
  border: 0;
}
.NutritionAboutYouModal .ant-modal-title {
  color: #ffffff;
}
.NutritionAboutYouModal .ant-modal-content {
  background: #1a1a1a;
}
.NutritionAboutYou {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

/* Nutrition About You Modal Css End */
/* Nutrition Activity Modal Css Start */
.NutritionActivityModal .ant-modal {
  top: 0px;
}
.NutritionActivityModal .ant-modal-header {
  background: #1a1a1a;
  border: 0;
}
.NutritionActivityModal .ant-modal-title {
  color: #ffffff;
}
.NutritionActivityModal .ant-modal-content {
  background: #1a1a1a;
}
.subModal .ant-modal-header {
  background: #1a1a1a;
  border: 0;
}
.subModal .ant-modal-title {
  color: #ffff;
}
.subModal .ant-modal-content {
  background: #1a1a1a;
}
.slider {
  width: 100%;
}
.slider .ant-slider-dot {
  height: 20px;
  width: 20px;
  top: -5px;
  margin-left: -10px;
}
.slider .ant-slider-dot-active {
  border: 0px;
  background: linear-gradient(180deg, #ff5858 0%, #ff5b37 100%);
}
.slider .ant-slider-rail {
  height: 9px;
}
.slider .ant-slider-mark-text {
  color: #ffffff;
  margin-top: 10px;
}
/* Nutrition Activity Modal Css End */
/* Nutrition Detail Screen Css Start */
.nutritionContainerDiv {
  display: flex;
  flex-direction: column;
  background: black;
}
.nutritionDiv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.nutritionDetailsDiv {
  display: flex;
  flex-direction: row;
  background: "black";
  width: 94%;
  justify-content: space-evenly;
}
.DishDetailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #1a1a1a;
}
.DishImageAndNameDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95%;
  padding: 30px 30px 0px 30px;
  background: #1a1a1a;
  border-radius: 20px;
}
.dishImage {
  width: 60%;
  border-radius: 20px;
}
.DishNameAndDescriptionDiv {
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
.caloriesDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  background: #1a1a1a;
  padding: 10px;
  margin-top: 10px;
  border-radius: 20px;
}
.aminoAcidsDiv {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.InstructionsDiv {
  display: flex;
  flex-direction: column;
  width: 95%;
  background: #1a1a1a;
  padding: 0px 30px;
  margin-top: 10px;
  border-radius: 20px;
}
.IngredientsDiv {
  display: flex;
  flex-direction: column;
  width: 95%;
  justify-content: flex-start;
  align-items: flex-start;
}
.IngredientsDescriptionDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  background: #1a1a1a;
  padding: 10px 30px 30px 30px;
  border-radius: 20px;
}
.verticalLineDiv {
  width: 100%;
  border: 0.5px solid rgba(255, 255, 255, 0.15);
}
.IngredientsNameAndQuantityDiv {
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
}
.MoreMealsDiv {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  border-radius: 20px;
  background: #1a1a1a;
  margin-top: 10px;
}
.mealsImgAndNameDiv {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  padding-top: 10px;
}
.mealsImg {
  width: 280px;
  border-radius: 10px;
  align-self: left;
}
.MoreMeals {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ViewMoreMealText {
  color: white;
  text-align: left;
}
.MealNameText {
  color: white;
  font-size: 25px;
  font-weight: 800;
  text-shadow: rgb(0, 0, 0) 4px 1px;
  margin-top: 50px;
  margin-left: 110px;
  display: flex;
  background: black;
}
.AminoAcidsText {
  color: grey;
  margin: 2px;
}
.AminoAcidsNumberText {
  color: white;
  margin: 0px;
  font-size: 20px;
}
.InstructionDetailsText {
  color: white;
  text-align: left;
  white-space: pre-line;
  font-size: 18px;
}
.InstructionHeadingText {
  color: white;
  text-align: left;
}
.IngredientHeadingText {
  color: white;
  text-align: left;
  font-size: 20px;
  margin-top: 15px;
}
.IngredientNameText {
  color: white;
  width: 70%;
  text-align: left;
  margin: 2px;
}
.IngredientQuantityText {
  color: red;
  margin: 2px;
}

/* Media Queries Of Nutrition */

/* @media only screen and (max-width: 1600px) {
}
@media only screen and (max-width: 1400px) {
}
@media only screen and(max-width:1200px) {
} */
@media only screen and (max-width: 929px) {
  .dishImage {
    width: 100%;
  }
  .nutritionDiv {
    flex-direction: column;
    padding: 10px;
  }
  .nutritionDetailsDiv {
    flex-direction: column;
    width: 100%;
  }
  .DishDetailsDiv {
    width: 100%;
    border-radius: 15px;
  }
  .DishImageAndNameDiv {
    padding: 5px;
    flex-direction: column;
  }
  .DishNameAndDescriptionDiv {
    width: 100%;
  }
  .caloriesDiv {
    width: 100%;
    padding: 0px;
    align-items: center;
  }
  .InstructionsDiv {
    padding: 5px;
  }
  .IngredientsDiv {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }
  .IngredientsDescriptionDiv {
    width: 100%;
    padding: 10px;
  }
  .MoreMealsDiv {
    padding: 10px;
  }
  .MoreMeals {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mealsImg {
    width: 180px;
  }
  .ViewMoreMealText {
    font-size: 18px;
  }
  .MealNameText {
    font-size: 18px;
    margin-left: 20px;
  }
  .AminoAcidsText {
    font-size: 10px;
    margin-left: 0px;
  }

  .AminoAcidsNumberText {
    font-size: 13px;
  }
  .aminoAcidsDiv {
    width: 100%;
    padding: 0px;
  }
  .InstructionDetailsText {
    color: white;
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
  }
  .InstructionHeadingText {
    font-size: 18px;
  }
  .IngredientNameText {
    font-size: 13px;
  }
  .IngredientQuantityText {
    font-size: 13px;
  }
  .IngredientHeadingText {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .dishImage {
    width: 100%;
  }
  .nutritionDiv {
    flex-direction: column;
    padding: 10px;
  }
  .nutritionDetailsDiv {
    flex-direction: column;
    width: 100%;
  }
  .DishDetailsDiv {
    width: 100%;
    border-radius: 15px;
  }
  .DishImageAndNameDiv {
    padding: 5px;
    flex-direction: column;
  }
  .DishNameAndDescriptionDiv {
    width: 100%;
  }
  .caloriesDiv {
    width: 100%;
    padding: 0px;
    align-items: center;
  }
  .InstructionsDiv {
    padding: 5px;
  }
  .IngredientsDiv {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }
  .IngredientsDescriptionDiv {
    width: 100%;
    padding: 10px;
  }
  .MoreMealsDiv {
    padding: 10px;
  }
  .MoreMeals {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mealsImg {
    width: 180px;
  }
  .ViewMoreMealText {
    font-size: 18px;
  }
  .MealNameText {
    font-size: 18px;
    margin-left: 20px;
  }
  .AminoAcidsText {
    font-size: 10px;
    margin-left: 0px;
  }

  .AminoAcidsNumberText {
    font-size: 13px;
  }
  .aminoAcidsDiv {
    width: 100%;
    padding: 0px;
  }
  .InstructionDetailsText {
    color: white;
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
  }
  .InstructionHeadingText {
    font-size: 18px;
  }
  .IngredientNameText {
    font-size: 13px;
  }
  .IngredientQuantityText {
    font-size: 13px;
  }
  .IngredientHeadingText {
    font-size: 18px;
  }
}
@media only screen and (max-width: 666px) {
  .dishImage {
    width: 100%;
  }
  .nutritionDiv {
    flex-direction: column;
    padding: 10px;
  }
  .nutritionDetailsDiv {
    flex-direction: column;
    width: 100%;
  }
  .DishDetailsDiv {
    width: 100%;
    border-radius: 15px;
  }
  .DishImageAndNameDiv {
    padding: 5px;
    flex-direction: column;
  }
  .DishNameAndDescriptionDiv {
    width: 100%;
  }
  .caloriesDiv {
    width: 100%;
    padding: 0px;
    align-items: center;
  }
  .InstructionsDiv {
    padding: 5px;
  }
  .IngredientsDiv {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }
  .IngredientsDescriptionDiv {
    width: 100%;
    padding: 10px;
  }
  .MoreMealsDiv {
    padding: 10px;
  }
  .MoreMeals {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mealsImg {
    width: 180px;
  }
  .ViewMoreMealText {
    font-size: 18px;
  }
  .MealNameText {
    font-size: 18px;
    margin-left: 20px;
  }
  .AminoAcidsText {
    font-size: 10px;
    margin-left: 0px;
  }

  .AminoAcidsNumberText {
    font-size: 13px;
  }
  .aminoAcidsDiv {
    width: 100%;
    padding: 0px;
  }
  .InstructionDetailsText {
    color: white;
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
  }
  .InstructionHeadingText {
    font-size: 18px;
  }
  .IngredientNameText {
    font-size: 13px;
  }
  .IngredientQuantityText {
    font-size: 13px;
  }
  .IngredientHeadingText {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .nutritionDiv {
    flex-direction: column;
    padding: 10px;
  }
  .nutritionDetailsDiv {
    flex-direction: column;
    width: 100%;
  }
  .DishDetailsDiv {
    width: 100%;
    border-radius: 15px;
  }
  .DishImageAndNameDiv {
    padding: 5px;
    flex-direction: column;
  }
  .DishNameAndDescriptionDiv {
    width: 100%;
  }
  .caloriesDiv {
    width: 100%;
    padding: 0px;
    align-items: center;
  }
  .InstructionsDiv {
    padding: 5px;
  }
  .IngredientsDiv {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }
  .IngredientsDescriptionDiv {
    width: 100%;
    padding: 10px;
  }
  .MoreMealsDiv {
    padding: 10px;
  }
  .MoreMeals {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mealsImg {
    width: 180px;
  }
  .ViewMoreMealText {
    font-size: 15px;
  }
  .MealNameText {
    font-size: 15px;
    margin-left: 20px;
  }
  .AminoAcidsText {
    font-size: 10px;
    margin-left: 0px;
  }

  .AminoAcidsNumberText {
    font-size: 10px;
  }
  .aminoAcidsDiv {
    width: 100%;
    padding: 0px;
  }
  .InstructionDetailsText {
    color: white;
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
  }
  .InstructionHeadingText {
    font-size: 15px;
  }
  .IngredientNameText {
    font-size: 10px;
  }
  .IngredientQuantityText {
    font-size: 10px;
  }
  .IngredientHeadingText {
    font-size: 15px;
  }
}
@media only screen and (max-width: 330px) {
  .nutritionDiv {
    flex-direction: column;
    padding: 10px;
  }
  .nutritionDetailsDiv {
    flex-direction: column;
    width: 100%;
  }
  .DishDetailsDiv {
    width: 100%;
    border-radius: 15px;
  }
  .DishImageAndNameDiv {
    padding: 5px;
    flex-direction: column;
  }
  .DishNameAndDescriptionDiv {
    width: 100%;
  }
  .caloriesDiv {
    width: 100%;
    padding: 0px;
    align-items: center;
  }
  .InstructionsDiv {
    padding: 5px;
  }
  .IngredientsDiv {
    width: 100%;
    margin-top: 10px;
    padding: 0px;
  }
  .IngredientsDescriptionDiv {
    width: 100%;
    padding: 10px;
  }
  .MoreMealsDiv {
    padding: 10px;
  }
  .MoreMeals {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mealsImg {
    width: 180px;
  }
  .ViewMoreMealText {
    font-size: 15px;
  }
  .MealNameText {
    font-size: 15px;
    margin-left: 20px;
  }
  .AminoAcidsText {
    font-size: 10px;
    margin-left: 0px;
  }

  .AminoAcidsNumberText {
    font-size: 10px;
  }
  .aminoAcidsDiv {
    width: 100%;
    padding: 0px;
  }
  .InstructionDetailsText {
    color: white;
    text-align: left;
    white-space: pre-line;
    font-size: 12px;
  }
  .InstructionHeadingText {
    font-size: 15px;
  }
  .IngredientNameText {
    font-size: 10px;
  }
  .IngredientQuantityText {
    font-size: 10px;
  }
  .IngredientHeadingText {
    font-size: 15px;
  }
}
/* Nutrition Detail Screen Css End */

.nutrition-heading {
  font-size: 2vw;
  font-weight: bold;
  font-family: Montserrat;
  text-align: center;
  line-height: 68px;
  color: #000;
  margin-top: -5px;
  /* margin-bottom: 5px; */
}
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
/*  */
.footer-small-text {
  color: #000;
  margin-bottom: 2vh;
  text-align: left;
  margin-right: 5px;
}
.dark-footer-small-text {
  color: #fff;
  margin-bottom: 2vh;
  text-align: left;
  margin-right: 5px;
}
.ipad-div {
  display: flex;
  width: 50%;
  justify-content: center;
  margin-left: -150px;
  /* padding-left: 60px; */
}
.react-horizontal-scrolling-menu--wrapper {
  align-items: center;
  justify-content: center;
}
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100% !important;
}
.myMealCard {
  margin-right: 20px;
  width: 22vw;
  height: 15vw;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: flex-end;
  justify-self: center;
  border-radius: 10px;
}

.scroll-wrapper {
  font-family: Montserrat;
}

/* .anticon svg {
  display: inline-block;
  width: 75px;
  height: 50px;
} */
.myTrainCard {
  width: 30vw;
  height: 15vw;
  border-radius: 12px;
  display: flex;
  /* flex-direction: row; */
  /* overflow: hidden; */
  align-items: flex-end;
  justify-self: center;
}
.carousal-pic {
  width: 30vw;
  height: 20vw;
  border-radius: 12px;
}
.mealsDiv {
  display: flex;
  flex-direction: row;
  justify-content: "center";
  align-items: center;
  margin-top: 70px;
  margin-left: 10vw;
  margin-right: 10vw;
}
.nutrition-description {
  font-family: Montserrat;
  font-size: 2.2vh;
  line-height: 30px;
  justify-self: center;
  text-align: center;
  font-weight: normal;
  color: #8b8b8b;
  margin-top: -40px;
  margin-left: 20%;
  margin-right: 20%;
}
.trainingCardText1 {
  display: flex;
  left: 0;
  margin-left: 5%;
  white-space: nowrap;
  margin-bottom: -3%;
  background-image: linear-gradient(90deg, #ff7c2a, #bf330f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5vw;
  font-weight: 500;
  font-family: Montserrat;
  white-space: nowrap;
}
.footer-title {
  font-weight: 700;
  font-size: 1em;
  color: #000;
  margin-bottom: 4vh;
  text-align: left;
  justify-self: flex-start;
  /* margin-left: 10px; */
}
.dark-footer-title {
  font-weight: 700;
  font-size: 1em;
  color: #fff;
  margin-bottom: 4vh;
  text-align: left;
  justify-self: flex-start;
  /* margin-left: 10px; */
}
.trainingCardText2 {
  display: flex;
  left: 0;
  margin-left: 2%;
  margin-top: 10px;
  white-space: nowrap;
  margin-bottom: 1%;
  /* background-image: linear-gradient(90deg, #ff1e1e, rgba(0, 0, 0, 0)); */
  background-image: linear-gradient(90deg, #ff7c2a, #bf330f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
  line-height: 0.9em;
  font-weight: 700;
  font-family: Montserrat;
  white-space: initial;
}
.bottom-left {
  position: absolute;
  bottom: 0px;
  padding-left: 20px;
  width: 27vw;
}

.btoncolor,
.btoncolor:hover,
.btoncolor:focus {
  background-color: #ff1e1e;
  color: #fff;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  border-radius: 9px;
  height: 4vw;
  width: 17vw;
  font-size: 1.2vw;
}
.footer-div {
  /* width: 30vw; */
}

.bton,
.bton:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  border-radius: 9px;
  height: 4vw;
  width: 15vw;
  font-size: 1.2vw;
  background-color: #fff;
}
.meal {
  display: flex;
  left: 0;
  margin-left: 5%;
  white-space: nowrap;
  margin-bottom: 3%;
  color: #fff;
  font-weight: 700;
  text-shadow: 1px 1px #000;
  font-size: 20px;
}
.tags-div {
  display: flex;
  flex-direction: row;
  /* padding-left: 15px; */
  margin-top: -15px;
  justify-content: left;
  width: 100%;
}
.tag {
  display: flex;
  background: #1a1a1a;
  color: rgba(255, 255, 255, 0.65);
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  border-radius: 12px;
  /* width: 22%; */
  text-align: center;
  font-weight: 500;
  font-size: 85%;
}

.card-div {
  margin-right: 5px;
  margin-left: 5px;
  /* max-height: 100vh; */
  /* margin-bottom: -15vh; */
  /* border: 1px solid blue; */
}
.train-description {
  display: -webkit-box;
  /* max-width: 25vw; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
}
.small-remove {
  padding-left: 45px;
  margin-left: 10px;
  /* border: 1px solid green; */
}
.ipad-image {
  width: 65%;
  margin-bottom: 38px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  transform: skew(0deg, -8deg);
}
.small-remove-text {
  /* margin-left: 10px; */
  margin-right: 20px;
  padding-left: 25px;
  /* border: 1px solid green; */
}
.small-remove-text2 {
  /* margin-left: 10px; */

  padding-left: 55px;
  /* border: 1px solid green; */
}
.mobile-img {
  width: 100%;
  height: auto;
}
.btonDiv,
.btonDiv:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.tp-heading-small {
  display: flex;
  justify-self: flex-start;
  color: #ff5858;
  /* margin-left: -4%; */
  font-size: 1.5em;
  width: 89%;
}
.tp-heading-big {
  margin-left: -4%;
  width: 85%;
  margin-top: -2px;
  margin-bottom: 30px;
  color: white;
  font-size: 30px;
  display: flex;
  font-weight: 700;
}
@media screen and (max-width: 430px) {
  .tp-heading-small {
    margin-top: 50px;
    margin-left: 0;
    font-size: 18px;
  }
  .tp-heading-big {
    margin-left: 0;
    font-size: 20px !important;
    text-align: left;
  }
}

.getworkout-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 70vh;
  background-color: #000;
  width: 100%;
  color: #fff;
  transform: skew(0deg, 8deg);
}
.set-on-responsive {
  margin-left: 70px;
  width: 50%;
}
.set-on-responsive1 {
  /* margin-bottom: 60px; */
  width: 50%;
  padding-right: 25px;
  margin-left: 90px;
}
@media screen and (max-width: 800px) {
  .getworkout-div {
    flex-direction: column-reverse;
  }
  .playstore-class {
    margin-left: -20px !important;
  }
  .nutrition-heading {
    margin-top: 70px;
    font-size: 2em;
  }
  .btonDiv,
  .btonDiv:hover {
    margin-bottom: 50px;
  }
  .ipad-image {
    width: 45%;
    margin-bottom: 0px;
  }
  .ipad-div {
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    width: 100%;
    margin-left: 0px !important;
  }
  .set-on-responsive {
    margin-left: 0px !important;
    margin-bottom: 100px;
    width: 100%;
    padding-left: 14%;
    justify-content: flex-start;
    align-self: center;
    align-items: flex-start;
    justify-self: center;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: -70px;
  }
  .minus-margin {
    margin-left: 15px !important;
    width: 80% !important;
  }
  .set-on-responsive1 {
    margin-bottom: 70px;
    margin-left: -15px !important;
  }
}

@media screen and (max-width: 520px) {
  .tags-div {
    flex-direction: row;
    width: 100%;
    padding-left: 0px;
  }
  .tag {
    /* width: 100%; */
    display: flex;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
    font-size: 75%;
  }
}

@media screen and (max-width: 325px) {
  .set-on-responsive1 {
    padding-top: 100px !important;
  }
}
@media screen and (max-width: 880px) {
  .mealsDiv {
    flex-direction: column;
  }
  .set-on-responsive1 {
    width: 100%;
    margin-bottom: 70px;
    margin-left: -15px !important;
  }
  .mobile-img {
    display: none;
    /* visibility: hidden; */
  }
  .nutrition-description {
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    margin-top: 10px;
  }
  .nutrition-heading {
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    line-height: 1em;
  }
  .train-description {
    /* width: 45vw; */
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 85%;
    margin: 0px;
    font-size: 80%;
  }

  .meal {
    font-size: 12px;
  }
  .trainingCardText1 {
    font-size: 12px;
  }
  .trainingCardText1 {
    font-size: 22px;
  }
  .myMealCard {
    width: 45vw;
    height: 200px;
    margin-bottom: 10px;
  }
  .myTrainCard {
    width: 45vw;
    height: 180px;
  }
}
@media screen and (max-width: 400px) {
  .footer-small-text {
    color: #000;
    margin-bottom: 2vh;
    text-align: left;
    margin-right: 5px;
  }

  .dark-footer-small-text {
    color: #fff;
    margin-bottom: 2vh;
    text-align: left;
    margin-right: 5px;
  }
  .small-remove {
    padding-left: 0;
  }

  .footer-div {
    /* display: none; */
    flex-direction: column;
    /* border: #000; */
  }
  .flexDir {
    flex-direction: column;
    margin: 0 auto;
    font-size: 24px;
    padding-left: 25%;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
  }
  .flexDir2 {
    flex-direction: column;
    margin: 0 auto;
    font-size: 24px;
    /* padding-left: 25%; */
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
  }
}

.footer-right-div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  justify-self: flex-start;
  width: 50vw;
  /* padding: 20px; */
}
@media screen and (max-width: 415px) {
  .spacing-top-minus-on-mobile {
    margin-top: 10px !important;
  }
  .flexDir {
    flex-direction: column;
    margin: 0 auto;
    font-size: 24px;
    padding-left: 25%;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
  }
  .small-remove {
    padding-left: 0px;
  }
}
@media screen and (max-width: 470px) {
  .flexDirCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-right-div {
    width: 80vw;
  }
  .copyright-div {
    width: 80vw;
    font-size: 12px;
    justify-content: flex-start;
  }
  .footer-div {
    flex-direction: row;
    width: 80vw;
  }
}
@media screen and (max-width: 580px) {
  .meal {
    font-size: 12px;
  }

  .carousal-pic {
    width: 70vw;
    height: 40vw;
  }
  .bottom-left {
    width: 60vw;
  }
  .trainingCardText1 {
    font-size: 14px;
    margin-bottom: -10px;
    font-weight: 700;
  }
  .trainingCardText2 {
    font-size: 13px;
    width: 100%;
  }
  .btonDiv,
  .btonDiv:hover {
    flex-direction: column;
  }
  .bton,
  .bton:hover {
    justify-self: center;
    width: 150px;
    font-size: 10px;
  }
  .btoncolor,
  .btoncolor:active,
  .btoncolor:hover,
  .btoncolor:focus {
    justify-self: center;
    margin-right: -1px;
    margin-bottom: 5px;
    width: 150px;
    font-size: 10px;
  }
  .mealsDiv {
    flex-direction: column;
  }
  .myMealCard {
    width: 50vw;
    height: 30vw;
    margin-bottom: 10px;
  }
  .myTrainCard {
    width: 68vw;
    height: 35vw;
  }
  .nutrition-description {
    font-family: Montserrat;
    font-size: 18px;
    line-height: 28px;
    justify-self: center;
    text-align: center;
    font-weight: normal;
    color: #8b8b8b;
    margin-top: -20px;
    margin-left: 20%;
    margin-right: 20%;
  }
  .nutrition-heading {
    font-size: 24px;
    /* text-align: left; */
    margin-top: 10px;
  }
}

.outer-container {
  /* width: 100%; */
  display: flex;
  /* border: 1px solid green; */
  /* margin-top: -10vh; */
  /* padding-top: 40; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-self: center;
  align-self: center;
  align-content: center;
  margin-bottom: 10vh;
  margin-top: -10vh;
}
.steps {
  /* border: 1px solid green; */
  /* margin-left: -25px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #ff1e1e;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #000000;
}
.player {
  /* border: 1px solid green; */
  /* border-radius: 10px; */
  justify-self: center;
  align-items: center;
  width: 100;
  /* border-radius: 40px !important; */
  height: 100;
  margin: 0 auto;
}

.left-div {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin-top: -200px;
  /* margin: 0 auto; */
  padding-left: 12vw;
}

.right-div {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-right: 40px;
}
.small-heading {
  color: #ff5858;
  font-size: 1.2vw;
  font-family: Montserrat;
}
.big-heading {
  color: black;
  font-weight: 700;
  font-size: 30px;
  width: 550px;
  font-family: Montserrat;
}
.left-align {
  text-align: left;

  /* border: 1px solid yellow; */
}
.para-text {
  font-family: Montserrat;
  width: 550px;
  font-size: 16px;
  color: black;
  text-transform: capitalize;
  /* font-weight: 300; */
}
.mobile-vid {
  width: 295px;
  height: 595px;
  border-radius: 40px;
  margin-left: 4em;
  box-shadow: 6px 10px 50px black;
}
.picture {
  filter: drop-shadow(0 0 0.75rem rgb(75, 10, 23));
}
@media screen and (max-width: 1200px) {
  .small-heading {
    font-size: 16px;
    margin-top: 8vh;
  }
}
@media screen and (max-width: 750px) {
  .small-heading {
    margin-top: 600px !important;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}
@media screen and (max-width: 780px) {
  .small-heading {
    margin-top: 300px !important;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}
@media screen and (max-width: 1100px) {
  .outer-container {
    margin-top: 100px;
  }
}
@media screen and (max-width: 1100px) {
  .outer-container {
    margin-top: 100px;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}
@media screen and (max-width: 460px) {
  .outer-container {
    margin-top: -130px !important;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}
@media screen and (max-width: 950px) {
  .outer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}

@media screen and (min-width: 420px) {
  .small-heading {
    margin-top: 200px;
  }
  .mobile-vid {
    width: 70%;
    height: auto;
  }
}
@media screen and (max-width: 450px) {
  .small-heading {
    margin-top: 430px !important;
  }
  .mobile-vid {
    width: 150%;
    margin-left: -1em;
  }
}
@media screen and (max-width: 370px) {
  .small-heading {
    margin-top: 300px !important;
  }
}
@media screen and (max-width: 650px) {
  .outer-container {
    flex-direction: column;
    /* border: 1px solid green; */
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .left-align {
    display: flex;
    flex-direction: column;
    /* margin: 0 auto; */
    width: 86vw;
  }
  .big-heading {
    width: 90vw;
    /* border: 1px solid yellow; */
    margin-left: -15px;
    font-size: 20px;
  }
  .small-heading {
    width: 90vw;
    /* border: 1px solid yellow; */
    /* margin-top: 20vh; */

    margin-left: -15px;
    font-size: 15px;
  }
  .picture {
    margin: 0 auto;
    margin-left: 40px;
  }
  .para-text {
    width: 90vw;
    /* border: 1px solid yellow; */
    margin-left: -15px;
    font-size: 20px;
  }
}
@media screen and (max-width: 435px) {
  .small-heading {
    /* margin-top: 500px !important; */
  }
}
@media screen and (max-width: 545px) {
  .big-heading {
    width: 70vw;
  }
}

.containerLeft {
  overflow: hidden;
  width: 60%;
  /* height: 100vh; */
  background-image: linear-gradient(
      194.92deg,
      rgba(0, 0, 0, 0) 45.34%,
      #000000 102.5%
    ),
    url(/static/media/sigma.25212cf6.png);
  /* background-size: cover; */
  background-size: auto 100%;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  /* border: 1px solid red; */
}
.myDropdown {
  float: right;
  align-self: center;
  justify-self: center;
  display: flex;
}
.storeimg {
  width: 12.2vw;
  height: 50px;
}
.marketCard {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  margin: 0 auto;
  border-radius: 10px;
}
.terms {
  margin: 0 auto;
  /* margin-top: 5vh; */
}
.bgClr {
  /* background-color: rgba(225, 30, 30, 0.09); */
  text-align: left;
  font-size: 20px;
  /* border-radius: 10px; */
}
.ant-input-affix-wrapper-inputfeild {
  outline: none;
}

.dropdown,
.dropdown:hover {
  color: #fff;
  border: 1px solid white;
  border-radius: 5px;
  padding: 3px 8px;
  /* background-color: #ff1e1e; */
  font-weight: 600;
  justify-content: center;
  align-items: center;
}
.menu_item {
  background: #fff;
  color: #000 !important;
  width: 150px;
  border: none;
}
.containerRight {
  height: 100vh;
  width: 40%;
  /* overflow: scroll; */
  /* border: 1px solid red; */
  background: linear-gradient(337.1deg, #000000 -1.26%, #4f4f4f 130.31%);

  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 6vw;
  padding-top: 50px;
  padding-right: 3vw;
}
.ant-spin {
  color: #ff1e1e;
}
.titletext {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 3.2vh;
  line-height: 39px;
  letter-spacing: -0.02em;
  text-align: center;
}
.welcome_arabic {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  text-align: right;
  width: 25vw;
  font-size: 7vh;
  line-height: 59px;
  /* text-align: center; */
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #ffffff;
}
.welcome {
  /* H1/Bold */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 4vh;
  line-height: 59px;
  text-align: center;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #ffffff;
}
.ttext {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2vh;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-top: -20px;
  text-align: left;
  color: #707070;
  width: 25vw;
}
.ttext_arabic {
  width: 25vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5vh;
  /* line-height: 24px; */
  letter-spacing: 0.02em;
  margin-top: -20px;
  text-align: right;
  font-weight: 600;
  /* color: #707070; */
  color: #fff;
}
.inputdiv {
  width: 25vw;
  height: 5vw;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 20px;
}
.inputdiv_arabic {
  width: 25vw;
  height: 5vw;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 20px;
}

.loading-div {
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 20px;
  margin-right: 90px;
}
.flagstyle {
  max-width: 100%;
  width: 30px;
  height: auto;
  justify-self: center;
  align-self: center;
  margin-top: -2px;
  border-radius: 1px;
}
.inputfeild,
.inputfeild:focus {
  background-color: transparent;
  height: 6vh;
  width: 15vw;
  align-self: center;
  /* justify-self: flex-end; */
  margin-left: 2vw;
  padding: 5px;
  padding-bottom: 0px;
  padding-top: 0px;
  border: none;
  border-left: 1px solid #707070;
  font-size: 1.3vw;
  outline: none !important;
  color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.otpinput {
  background-color: transparent;
  color: #707070;
  border: 1px solid #707070;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 4.5vw !important;
  height: 4.5vw !important;
}

.indonesiaInput {
  background-color: transparent;
  color: #707070;
  border: 1px solid #707070;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 45px !important;
  height: 45px !important;
  font-size: 20px;
  /* height: 4.5vw !important; */
  /* width: 4.5vw !important;
  height: 4.5vw !important; */
}

/* .indonesiaOTP {
  /* border: 1px solid green; */
/* } */
.otp {
  width: 25vw;
  /* border: 1px solid green; */
}
.resend {
  color: #ff1e1e;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 9vw; */
  /* margin-top: 20px; */
}
.resend_arabic {
  color: #ff1e1e;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  /* margin-left: 9vw; */
  /* margin-top: 20px; */
}
.icon-spacing {
  margin-right: 50px;
  filter: invert(100%) sepia(0%) saturate(3658%) hue-rotate(356deg)
    brightness(96%) contrast(96%);
}

.subs-otpinput {
  background-color: transparent;
  color: #707070;
  border: 1px solid #707070;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 4.5vw !important;
  height: 4.5vw !important;
}
.subs-otp {
  width: 25vw;
  /* border: 1px solid green; */
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  margin-top: 50px;
  margin-left: -20px;
}

.inputdiv > .ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
}
.inputdiv_arabic > .ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background-color: #222222;
  color: white;
}
.btn-login_arabic:hover,
.btn-login_arabic:focus,
.btn-login_arabic {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 12px;
  border: none;
  color: #fff;
  width: 25vw;
  height: 54px;
  margin-top: 30px;
  font-weight: 600;
  font-size: 24px;
}
.btn-login:hover,
.btn-login:focus,
.btn-login {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 12px;
  border: none;
  color: #fff;
  width: 25vw;
  height: 54px;
  margin-top: 30px;
  font-weight: 600;
}
.terms {
  margin: 0 auto;
}
@media screen and (max-width: 880px) {
  .containerLeft {
    background-image: none;
    background: #000000;
  }

  .subs-otpinput {
    background-color: transparent;
    color: #707070;
    border: 1px solid #707070;
    /* box-sizing: border-box; */
    border-radius: 9px;
    width: 12vw !important;
    height: 10vw !important;
    margin-right: 8px !important;
  }
  .subs-otp {
    width: auto;
    /* border: 1px solid green; */
  }
}
.Login-page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #000000;
  /* overflow: hidden; */
  /* height: 100vh; */
}
@media screen and (max-width: 666px) {
  .containerLeft {
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    /* border-radius: none; */
    width: 100%;
    position: relative;
    align-items: center;
  }
  .Login-page-container {
    display: flex;
    align-items: center !important;
    flex-direction: column-reverse;
    justify-content: center;
    overflow: scroll !important;
  }
  .containerRight {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    /* border-radius: 10px; */
    /* height: 100%; */
    background-color: #000;
  }
  .margin-top-minus-on-small {
    margin: 20px !important;
    padding-bottom: 30px;
  }

  .terms {
    justify-self: flex-start;
  }
}
@media screen and (max-width: 780px) {
  .containerRight {
    width: 100%;
  }
  .ttext {
    width: 300px;
  }
  .myDropdown {
    float: none;
    justify-self: center;
    align-self: center;
  }

  .loading-div {
    display: flex;
    align-items: center;
    align-self: center;
    margin-top: 20px;
    /* width: 14%; */
    margin-right: 0px;
    justify-content: center;
  }
  .inputdiv {
    width: 300px;
    height: 54px;
  }
  .inputdiv_arabic {
    width: 300px;
    height: 54px;
  }
  .welcome_arabic {
    width: 300px;
    height: 54px;
  }
  .ttext_arabic {
    width: 300px;
    height: 54px;
  }
  .social-icons {
    margin-left: 0px;
    margin-left: 0px;
    width: 90%;
    justify-content: center;
  }
  .icon-spacing {
    margin-right: 0px;
  }
  .inputfeild {
    font-size: 14px;
    width: 200px;
  }
  .btn-login,
  .btn-login:hover {
    width: 300px;
    height: 54px;
    font-size: 14px;
  }
  .btn-login_arabic,
  .btn-login_arabic:hover,
  .btn-login_arabic:focus {
    width: 280px;
    height: 54px;
    font-size: 14px;
  }
  .storeimg {
    width: 150px;
    margin-bottom: 10px;
  }
  .otp {
    width: 300px;
    width: 300px;
    height: 54px;
    font-size: 14px;
  }
  .otpinput {
    width: 60px !important;
    height: 54px !important;
  }
  .indonesiaInput {
    /* padding: 20px; */
    width: 40px !important;
    height: 40px !important;
  }
  .titletext {
    font-size: 25px;
  }
}

@media screen and (max-width: 480px) {
  .containerRight {
    /* height: 100vh; */
    /* overflow: scroll; */
    width: 100% !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* border-radius: 10px; */
  }
  .Login-page-container {
    height: 100%;
    overflow: scroll;
  }
  .twitter-icon-color {
    filter: invert(53%) sepia(0%) saturate(3658%) hue-rotate(356deg)
      brightness(102%) contrast(91%);
  }
  .center-images-on-resize {
    justify-content: center;
    align-items: center;
    place-self: center;
    padding-left: 22vw;
  }
  .myDropdown {
    float: none;
  }
  .ttext {
    width: auto !important;
    text-align: center !important;
    font-size: 18px;
    text-align: center;
  }
  .inputdiv {
    width: 80vw;
    height: 54px;
  }
  .inputdiv_arabic {
    width: 80vw;
    height: 54px;
  }
  .welcome_arabic {
    width: 80vw;
    height: 54px;
  }
  .ttext_arabic {
    width: 80vw;
    height: 54px;
  }
  .outerContainer {
    flex-direction: column-reverse;
  }
  .inputfeild {
    font-size: 14px;
    width: 200px;
  }
  .btn-login,
  .btn-login:hover {
    width: 80vw;
    height: 54px;
    font-size: 14px;
  }
  .otpinput {
    width: 10vw !important;
    height: 12vw !important;
  }
  .otp {
    width: 75vw;
    height: 54px;
    justify-content: center;
  }
  .titletext {
    font-size: 25px;
  }
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

.container {
  min-height: 500px;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  transform: skew(0, 168deg);
  margin-bottom: 20vw;
}

.input-columns {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.ccv {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.child1 {
  color: #fff;
  display: flex;
  flex-direction: row;
  padding-left: 200px;
  background-color: #000;
  height: 300px;
  padding-top: 35px;
  justify-content: space-between;
  transform: skewY(12deg);
}

.train-wrapper {
  /* min-width: 100vw; */
  /* min-height: 600px; */
  /* border: 1px solid red; */
  position: relative;
  justify-self: center;
  align-self: center;
  /* margin-top: -85vh; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 10px;
}
.Bgcard {
  margin-top: 50px;
  padding-top: 5vh;
  width: 350px;
  /* height: 100%; */
  min-height: 70vh;
  margin-bottom: 10px;
  margin-right: 15px;
  border: 1px solid #707070;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 42px 96px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 0px;
  padding-left: 10px;
  padding-right: 10px;
  /* background-image: url("/src/assets/images/bubbels.png"); */
}

.heading1 {
  font-weight: bold;
  font-size: 34px;
  margin-bottom: -10px;
  margin-top: -10px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.heading2 {
  margin-top: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #fff;
}

.content-text1 {
  font-weight: 800;
  font-size: 20px;
  /* line-height: 59px; */
  text-align: center;
  letter-spacing: -0.02em;
  color: #ff1e1e;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90% !important;
}

.type {
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
  /* line-height: 59px; */
  text-align: center;
  letter-spacing: -0.02em;
  color: #fff;
  text-transform: capitalize;
}
.Stype {
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 59px; */
  text-align: center;
  letter-spacing: -0.02em;
  color: #fff;
  text-transform: capitalize;
}

.paragraph {
  font-size: 14px;
  margin-bottom: 4vh; /* line-height: 57px; */
  width: 70%;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.02em;
  color: #cecbbc;
}
/* 
.Bgcard:hover {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.Bgcard:hover .paragraph {
  color: #fff;
}
.Bgcard:hover .content-text1 {
  color: #fff;
}
.Bgcard:hover .type {
  color: #fff;
}
.Bgcard:hover .Stype {
  color: #fff;
}

.Bgcard:hover .check > p {
  color: #fff;
}
.Bgcard:hover .check > img {
  filter: brightness(0) invert(1);
} */

.check {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.check > img {
  margin-right: 10px;
  margin-bottom: 10px;
  height: 25px;
  width: 25px;
  /* font-size: 100px; */
  fill: #ff1e1e;
  color: yellowgreen;
}

.check > p {
  font-style: normal;
  /* font-weight: 600; */
  font-size: 13x;
  line-height: 15px;
  /* or 150% */

  letter-spacing: -0.02em;

  /* Primary/Grey */

  color: #cecbbc;
}

.subscribe-btn {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  margin-bottom: 25px;
  border-radius: 12px;
  border: 0px;
  color: #fff;
  font-weight: 600;
  height: 60px;
  font-size: 16px;
  width: 80%;
  letter-spacing: -0.02em;
}

.Bgcard:hover .subscribe-btn {
  background: #fff;
  color: #ff1e1e;
}

.crossLine {
  position: absolute;
  right: 0px;
  border: 0px;
  width: 60%;
  bottom: -30px;
  height: 30px;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}
@media screen and (max-width: 666px) {
  .Bgcard {
    margin-top: 20px !important;
    padding-top: 0px !important;
    /* background-color: #000; */
    display: flex;
    justify-content: flex-start;
  }
  .content-text1 {
    font-size: 2.3vh !important;
    width: 90% !important;
    color: gainsboro !important;
    flex-direction: row;
  }
  .Stype {
    margin: 0px !important;
    padding-left: 10px !important;
    color: gainsboro !important;
  }
  .paragraph {
    margin: 0px !important;
    color: white !important;
    width: 100% !important;
    /* font-weight: 900; */
    border-radius: 10px;
    margin-bottom: 8px !important;
    background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  }
  .country {
    margin: 0px !important;
  }
  .check-list {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    /* margin-left: 130px !important; */
    /* width: 100% !important; */
    align-items: center;
    /* justify-content: space-evenly; */
    width: 100% !important;
    /* flex-direction: row; */
  }
}
@media screen and (max-width: 330px) {
  .content-text1 {
    font-size: 3.2vh !important;
    width: 100% !important;
    color: gainsboro !important;
    flex-direction: row;
  }
  .paragraph {
    font-size: 3vh !important;
    width: 70% !important;
    /* color: white !important; */
    /* font-weight: 900; */
    /* border-radius: 10px;
    margin-bottom: 8px !important; */
    /* background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%); */
  }
  .Stype {
    font-size: 3vh !important;
    color: gainsboro !important;
    /* width: 100% !important; */
  }
  .check-list > p {
    font-size: 100px !important;
    width: 70% !important;
    color: gainsboro !important;
  }
  .Bgcard {
    padding: 0px !important;
    width: 100% !important;
    background: rgba(255, 255, 255, 0.2);
  }
}
@media screen and (max-width: 480px) {
  .content-text1 {
    font-size: 2.7vh !important;
    margin: 0px !important;
    width: 100% !important;
    color: gainsboro !important;
    flex-direction: row;
  }
  .paragraph {
    font-size: 2.5vh !important;
    color: white !important;
    width: 97% !important;
    /* font-weight: 900; */
    /* font-weight: 900; */
    border-radius: 10px;
    margin-bottom: 8px !important;
    background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
    text-align: center;
  }
  .Stype {
    font-size: 2.4vh !important;
    color: gainsboro !important;
  }
  .check-list > p {
    font-size: 1.5vh !important;
    color: gainsboro !important;
    /* width: 95% !important; */
    text-align: left;
  }
  .check-list > img {
    margin-left: 6px !important;
    margin-right: 5px !important;
  }
  .Bgcard {
    padding: 0px !important;
    width: 100% !important;
    background: rgba(255, 255, 255, 0);
  }
}

@media screen and (max-width: 1120px) {
  .container {
    background-color: #fff;
  }
  .input-columns {
    flex-direction: column;
  }
  .ccv {
    width: 100%;
  }
  .child1 {
    height: -webkit-max-content;
    height: max-content;
  }
  .train-wrapper {
    flex-direction: column;
    flex: 1 1;
    margin-top: -10px;
    /* margin-top: 10px; */
  }
  .check {
    width: 100%;
  }
  .Bgcard {
    margin-right: 0px;
    width: 100%;
    padding: 20px;
    margin-left: -5px;
  }
  .crossLine {
    display: none;
  }
  .content-text1 {
    font-size: 2vh;
  }
}

/*
  * {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
  } */

.container {
  min-height: 500px;
  width: 100% !important;
  background: rgba(0, 0, 0, 1);
  transform: skew(0, 168deg);
  margin-bottom: 20vw;
  margin-top: 55px;
  max-width: 100%;
}

.alert-modal .ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  color: black;
}

.input-columns {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.ccv {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.terms-div {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 400px;
}

.child1 {
  color: #fff;
  display: flex;
  flex-direction: row;
  padding-left: 200px;
  background-color: #000;
  height: 300px;
  padding-top: 35px;
  justify-content: space-between;
  transform: skewY(12deg);
  width: 100%;
}

.train-wrapper {
  /* min-width: 100vw; */
  /* min-height: 600px; */
  /* border: 1px solid red; */
  position: relative;
  justify-self: center;
  align-self: center;
  /* margin-top: -85vh; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 10px;
}
.train-card {
  padding-top: 10vh;
  /* width: 350px; */
  max-width: 350px;
  min-height: 60vh;
  margin-bottom: 10px;
  margin-right: 10px;
  border: 1px solid #707070;
  background: #ffffff;
  box-shadow: 0px 42px 96px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

/* @media screen and (min-width: 1121px) {
  .train-card:first-child {
    margin-left: 15px;
  }

  .train-card:last-child {
    margin-right: 7px;
  }
} */

.train-card > .ant-btn[disabled] {
  color: #fff;
  border-color: #d9d9d9;
  background: grey;
  text-shadow: none;
}

.heading1 {
  font-weight: bold;
  font-size: 34px;
  margin-bottom: -10px;
  margin-top: -10px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.heading2 {
  margin-top: 0px;
  font-weight: bold;
  font-size: 14px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #fff;
}

.content-text1 {
  font-weight: 800;
  font-size: 1.5vw;
  /* line-height: 59px; */
  text-align: center;
  letter-spacing: -0.02em;
  color: #ff1e1e;
  margin-bottom: 5px;
}

.content-text2 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 59px; */
  text-align: center;
  letter-spacing: -0.02em;
  color: #000;
}

.terms-parent {
  display: flex;
  width: 90%;
  margin-right: 20px;
}

.terms-text {
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 59px; */
  align-self: flex-start;
  letter-spacing: -0.02em;
  color: #000;
}

.content-paragraph {
  font-size: 12px;
  margin-bottom: 7vh; /* line-height: 57px; */
  width: 70%;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.02em;
  color: #707070;
}

.train-card:hover {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.train-card:hover .content-paragraph {
  color: #fff;
}
.train-card:hover .content-text1 {
  color: #fff;
}
.train-card:hover .content-text2 {
  color: #fff;
}

.train-card:hover .check-list > p {
  color: #fff;
}
.train-card:hover .check-list > img {
  filter: brightness(0) invert(1);
}

.check-list {
  display: none;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.check-list > img {
  margin-right: 10px;
  margin-bottom: 10px;
  fill: #ff1e1e;
  color: yellowgreen;
}

.check-list > p {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  /* or 150% */

  letter-spacing: -0.02em;

  /* Primary/Grey */

  color: #707070;
}

.subscribe-btn {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  margin-bottom: 25px;
  border-radius: 12px;
  border: 0px;
  color: #fff;
  font-weight: 600;
  margin-top: auto;
  height: 60px;
  font-size: 16px;
  width: 80%;
  letter-spacing: -0.02em;
}

.train-card:hover .subscribe-btn {
  background: #fff;
  color: #ff1e1e;
}

.crossLine {
  position: absolute;
  right: 0px;
  border: 0px;
  width: 60%;
  bottom: -30px;
  height: 30px;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.dropdown-arrow {
  color: #ff1e1e;
  margin-left: 10px;
  margin-top: -2px;
  font-size: 15px;
  display: inline;
}

.conditions-div {
  padding-left: 15px;
}

.dropdown-terms-arrow {
  color: #ff1e1e;
  margin-left: 10px;
  margin-top: 3px;
  font-size: 15px;
}

.dropdown-terms-arrow:hover {
  font-size: 20px;
  margin-bottom: 10px;
}

.train-card:hover .dropdown-arrow {
  color: #fff;
}

@media screen and (max-width: 1120px) {
  .container {
    background-color: #fff;
  }
  .input-columns {
    flex-direction: column;
  }
  .ccv {
    width: 100%;
  }
  .child1 {
    height: -webkit-max-content;
    height: max-content;
  }
  .train-wrapper {
    flex-direction: column;
    flex: 1 1;
    margin-top: -10px;
    /* margin-top: 10px; */
  }
  .check-list {
    width: 60%;
    display: none;
  }
  .dropdown-arrow {
    display: inline;
  }
  .dropdown-terms-arrow {
    display: inline;
  }
  .terms-div {
    align-self: center;
    align-items: center;
    margin-left: 0px;
  }
  .train-card {
    margin-right: 0px;
    /* width: 100%; */
    width: 350px;
    padding: 40px 20px 40px 20px;
    margin-left: -15px;
    min-height: 0px;
  }
  .crossLine {
    display: none;
  }
  .content-text1 {
    font-size: 26px;
  }
  .terms-parent {
    justify-content: center;
  }
  .conditions-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70%;
    padding-left: 0px;
  }
  .terms-text {
    align-self: center;
  }
}

/*
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

.container1 {
  width: 60%;
  /* height: 60vh; */
  justify-content: center;
  align-content: center;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 85px;
  margin-bottom: 30px;
  /* margin-left: 50px; */
}
.change {
  color: red;
  font-size: 2vh;
  margin-left: 90px;
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
}
.leftDiv {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  width: 60vw;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 40px 30px 40px;
  left: 0;
}
.rightDiv {
  align-self: flex-start;
  background: #f5f5f5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  width: 40vw;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  right: 0;
  text-align: left;
}
.heading {
  font-weight: bold;
  font-size: 3.5vh;
  line-height: 49px;
  white-space: nowrap;
  letter-spacing: -0.02em;
  font-weight: 800;
  color: #000000;
}
.btn1 {
  width: 25vw;
  height: 65px;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  box-shadow: 0px 29px 43px rgba(255, 30, 30, 0.19);
  border-radius: 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 2.2vh;
  border: 0px;
}

.part {
  display: "flex";
  flex-direction: "column";
  justify-content: space-evenly;
}

.part > .ant-btn:focus {
  color: #fff;
  border: 0px;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.ant-btn:focus .payment-icons {
  color: #f5f5f5;
}

.ant-btn:focus .left-icon {
  filter: brightness(0) invert(1);
}

.payment-icons {
  color: #ff1e1e;
}

.payment-icons:hover {
  color: #f5f5f5;
}
.payment-icons:focus {
  color: #f5f5f5;
}

.btn1:hover {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  color: #f5f5f5;
}
/* .btn-clear:hover {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  color: #f5f5f5;
  filter: brightness(0) invert(1);
} */
.btn-clear {
  width: 25vw;
  height: 65px;
  background: #fff;
  box-shadow: 0px 29px 43px rgba(255, 30, 30, 0.19);
  border-radius: 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  border: 0px;
  font-size: 2.2vh;
  font-weight: 700;
}
.tick {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background-color: #fff;
  position: absolute;
  right: 0;
  padding-top: 2px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
}
.left-icon {
  height: 2.5vh;
  width: 2.5vh;
  margin-right: 10px;
}
.subHeading-small {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5vh;
  line-height: 49px;
  /* margin-top: 25px; */
  letter-spacing: -0.02em;

  color: #000000;
}

.alert-modal {
  width: 50% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}
.subHeading-big {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 3vh;
  line-height: 59px;
  letter-spacing: -0.02em;
  /* margin-top: -20px; */
  margin-bottom: -5px;
  color: #ff1e1e;
  text-align: center;
}

.input-field {
  border-radius: 12px;
  margin-bottom: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
}
.input-heading {
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 150% */
  font-weight: 500;
  margin-bottom: 2px;
  letter-spacing: -0.02em;

  color: #000000;
}
.checkbox {
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 150% */
  font-weight: 500;
  margin-bottom: 2px;
  letter-spacing: -0.02em;

  color: #707070;
}
.ant-checkbox-checked::after {
  border: 1px solid #ff1e1e !important;
}

.proceed-btn,
.proceed-btn:hover,
.proceed-btn:active,
.proceed-btn:focus {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  margin-bottom: 25px;
  border-radius: 12px;
  border: 0px;
  color: #fff;
  font-weight: 600;
  height: 60px;
  font-size: 16px;
  margin-top: 20px;
  letter-spacing: -0.02em;
}

/* .ant-checkbox-checked {
  border: 1px solid #ff1e1e !important;
} */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff1e1e;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ff1e1e;
}
.para {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #707070;
}
.total {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: red;
}
.endDiv {
  height: 20px;
  width: 24vw;
  border-top: 1px solid grey;
  justify-self: flex-end;
  display: flex;
  align-self: center;
  margin-top: auto;
  justify-content: space-between;
  padding-top: 10px;
}
@media screen and (max-width: 1160px) {
  .container1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90% !important;
    /* margin-top: 100px; */
  }
  .rightDiv {
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .leftDiv {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .btn1 {
    width: 250px;
  }
  .btn-clear {
    width: 300px;
  }
  .heading {
    font-size: 16px;
  }
  .change {
    font-size: 13px;
  }
  .alert-modal {
    width: 80% !important;
  }
}

/*
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

.mini-header {
  /* border: 1px solid green; */
  display: flex;
  padding: 50px;
  color: #fff;
  background: #000;
}
.mini-header-text {
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin-left: 7.5%;
}
.container-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.skew-div {
  min-height: 40vh;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  transform: skew(0, -15deg);
}
.team-div {
  width: 80vw;
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
  justify-self: center;
  align-self: center;
  margin-top: 8vh;
}
.small-bullet-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.jobs-div {
  /* display: flex;
  flex-direction: row; */
  display: grid;
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.job-card {
  /* border: 1px solid green; */
  margin: 10px;
  width: 310px;
  /* height: 100px; */
  background: #ffffff;
  box-shadow: 0px 22px 72px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 20px;
}
.small-text-card {
  text-align: left;
  color: #000;
  font-weight: 500;
  font-family: Montserrat;
  text-transform: capitalize;
}
.opening-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8vh;
  margin-bottom: 8vh;
}
.days-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: #000;
  margin-top: -3vh;
  /* border: 1px solid red; */
}
.job-card-heading {
  font-size: 30px;
  font-weight: 600;
  display: flex;
  color: #000;
  text-align: left;
  width: 100%;
  line-height: 1.2em;
}
.job-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  color: #000;
  width: 100%;
  margin-bottom: 60px;
}

.unorderd-list {
  text-align: left;
  font-size: 16px;
  color: #000;
  /* font-weight: 500; */
  font-family: Montserrat;
  /* border: 1px solid green; */
}
.small-text-black {
  display: flex;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  color: #000;
}
.apply-now-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.apply-now-txt {
  color: #ff1e1e;
  font-weight: 600;
  font-family: Montserrat;
  font-size: 16px;
  text-align: left;
}
.text-box-div {
  width: 80vw;
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
  justify-self: center;
  align-self: center;
  margin-top: 8vh;
}
.article-text-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pic {
  /* max-width: 100%; */
  width: 100%;
  max-height: 100%;
  /* height: auto; */
}
.btn-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.job-overview {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.text-small-heading {
  display: flex;
  color: #000;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
}
.text-small-description {
  display: flex;
  color: #000;
  text-align: left;
  width: 80%;
  /* margin-bottom: 22vh; */
  font-size: 16px;
}
.apply-button,
.apply-button:hover,
.apply-button:focus {
  background-color: #ff1e1e;
  color: #fff;
  width: 200;
  font-weight: 600;
  margin-bottom: 20px;
  height: 50px;
  border: none;
}
.job-details-div {
  display: flex;
  flex-direction: column;
  align-self: flex-start !important;
}
.blog-details-image-div {
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-self: center;
  height: 60vh;
  margin-top: -4em;
}
.blog-author-div {
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* justify-self: center; */
  /* align-self: center; */
}
.blog-main-content {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 100%;
  /* border: 1px solid red; */
}
.blog-main-text {
  text-align: justify;
  color: #000;
  overflow-wrap: break-word;
}
.blog-main-content p {
  margin: 2px;
}
/* .blog-main-content div {
  margin: 0px 0px 0px 0px;
} */
.blog-mini-title {
  font-size: 2em;
  color: #ff1e1e;
  font-weight: 500;
  margin-top: 10px;
  text-align: left;
}
.blog-author {
  font-size: 1em;
  text-align: left;
}
.job-title-text {
  font-size: 2.5em;
  width: 70%;
  color: #000;
  text-align: left;
  font-weight: 600;
  margin-top: -5px;
  margin-left: 60px;
}
.blog-title {
  font-size: 2.5em;
  width: 70%;
  color: #000;
  text-align: left;
  font-weight: 600;
  margin-top: -5px;
}
.blog-content-div {
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-self: center;
  /* border: 1px solid red; */
}
.details-title {
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: -2px;
}
.details-description {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  white-space: nowrap;
  color: #000;
  /* margin-bottom: -10px; */
}
.job-details {
  width: 20vw;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.img-div {
  width: 100vw;
  /* margin-top: 20px; */
  display: flex;
  object-fit: contain;
  flex-direction: row;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-self: center;
  height: auto;
  margin-bottom: 50px;
  /* border: 3px solid red; */
}
.left-text-div {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.grid-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.right-text-div {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.big-text {
  font-size: 35px;
  font-weight: 600;
  display: flex;
  color: #000;
  text-align: left;
  width: 90%;
  line-height: 1.2em;
}
.small-text-jobs {
  color: #ff5858;
  display: flex;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  margin-left: 60px;
}
.small-text {
  color: #ff5858;
  display: flex;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-align: left;
}
.paragraph-text {
  font-size: 14px;
  text-align: left;
  color: #000;
  display: flex;
  font-family: Montserrat;
}
.member-cards-div {
  position: relative;
  /* z-index: 1; */
  margin-top: -40vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
}
.hide-on-small {
  display: flex;
}
.member-card {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 350px;
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 22px 72px rgba(0, 0, 0, 0.12);
}
.member-info-div {
  background: white;
  width: 100%;
  padding: 30px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}
.name-text {
  font-size: 28px;
  font-weight: 700;
  font-family: Montserrat;
  text-transform: uppercase;
}
.member-description {
  text-align: left;
  color: #000;
}
.member-description2 {
  text-align: left;
  color: #000;
}
.profile-pic-div {
  width: 40%;
}
.blog-upper-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 30px;
  /* border: 1px solid green; */
}
.main-container-div {
  background-color: #fff;
}
.blog-container-div {
  display: flex;
  flex-direction: column;
  width: 85%;
  justify-self: center;
  align-self: center;
  /* border: 1px solid red; */
}
.upper-left-div {
  width: 50%;
  /* border: 1px solid green; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
}
.mini-blog-img {
  /* margin-left: -5%; */
  width: 50%;
  height: 100%;
  /* border: 1px solid black; */
  border-radius: 10px;
  object-fit: cover;
}
.upper-small-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: none;
}
.sub-heading {
  color: #868686;
  font-weight: 400;
  font-family: Montserrat;
  font-size: 14px;
  text-align: left;
}
.upper-right-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: hidden;
  margin-left: 10px;
  width: 50%;
  height: 60vh;
}
.content-column-class {
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
  width: 100%;
}
.navbar-blogs {
  width: 100%;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  color: black;
  /* border-bottom: 1px solid; */
}

.mini-blog-div {
  /* padding: 10px; */
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 10%;
  margin-bottom: 5%;
}
.sub-mini-div {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.mini-div-text {
  text-align: left;
  color: black;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
}
.title-featured-blogs {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-self: flex-start;
  align-self: flex-start;
  margin-left: 15%;
  margin-bottom: -20px;
}

.blog-card-div {
  display: flex;
  flex-direction: column;
  white-space: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
  width: 23vw;
  margin-right: 40px;
  cursor: pointer;
}
.blog-pic {
  height: auto;
  width: 100%;
  border-radius: 10px;
}
.author-name-text {
  color: #868686;
  font-size: 1em;
  white-space: nowrap;
}
.blog-lower-div {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 430px) {
  .mini-blog-img {
    width: 100% !important;
    height: 145px !important;
  }
  .blog-category-title {
    font-size: 15px !important;
  }
  .view-more-div {
    padding-right: 0 !important;
    font-size: 15px !important;
  }
  .author-name-text {
    font-size: 12px;
    white-space: nowrap;
  }
  .blog-card-div {
    width: 34vw;
  }
  .mini-div-text {
    font-size: 12px;
  }
  .mini-blog-div {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .sub-mini-div {
    width: 100% !important;
  }
  .mini-div-text {
    width: 100%;
  }
}
/* .featured-main-img {
  border: 1px solid grey;
} */
@media screen and (max-width: 1000px) {
  .blog-upper-div {
    flex-direction: column;
    margin-left: 20px;
  }
  .blog-pic {
    height: 150px;
  }
  .upper-small-div {
    border-bottom: 1px solid grey;
    margin-bottom: 10px;
  }
  .hide-on-small-size {
    display: none !important;
  }
  .apply-now-txt {
    text-align: left;
  }
  .upper-left-div {
    width: 100%;
  }
  .upper-right-div {
    width: 100%;
    border-bottom: 1px solid grey;

    margin: 0;
  }
  .mini-blog-img {
    width: 300px;
    height: auto;
    object-fit: cover;
  }
  .featured-main-img {
    width: 100%;
    height: auto;
  }
  .text-small-heading {
    width: 100% !important;
    font-size: 15px !important;
  }
  .sub-mini-div {
    width: 500px;
    height: 150px;
  }
}
@media screen and (max-width: 1100px) {
  .hide-on-small {
    display: none;
  }
  .profile-pic-div {
    display: none;
  }
}
.job-upper-left-div {
  /* border: 1px solid green; */
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.featured-main-img {
  width: 100%;
}
.mini-div-text {
  width: 70%;
}

@media screen and (max-width: 800px) {
  .name-text {
    font-size: 18px;
  }
  .jobs-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    width: 100%;
  }
  .job-left-right-container {
    flex-direction: column !important;
  }
  .job-upper-left-div {
    width: 100% !important;
  }
  .job-upper-right-div {
    width: 100% !important;
    justify-content: flex-start !important;
  }
  .member-description2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .all-jobs-div {
    overflow: scroll;
    flex-wrap: nowrap !important;
    padding: 0 !important;
  }
  .job-card {
    width: 230px !important;
    /* height: 260px; */
    padding: 15px !important;
  }
  .job-card-heading {
    font-size: 18px;
  }
  .days-text {
    font-size: 12px;
  }
  .job-description {
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .headings-and-search-div {
    width: 95%;
  }
  .job-title-text {
    font-size: 17px;
    margin-left: 0px;
    width: 100%;
  }
  .small-text-jobs {
    margin-left: 0px !important;
  }
  .mini-header-text {
    margin-left: 0 !important;
    font-size: 18px;
  }
  .mini-header {
    padding-left: 20px !important;
  }
}
@media screen and (max-width: 650px) {
  .big-text {
    font-size: 22px;
    /* border: 1px solid red; */
  }
  .blog-details-image-div {
    height: auto;
  }
  .blog-mini-title {
    display: flex;
    font-size: 1.2em;
    width: 100%;
  }
  .blog-title {
    font-size: 1.5em;
    width: 100%;
  }
  .blog-pic {
    height: 120px;
  }
  .article-text-div {
    flex-direction: column;
  }
  .left-text-div {
    width: 100%;
  }
  .right-text-div {
    width: 100%;
  }
}
.job-left-right-container {
  display: flex;
  flex-direction: row;
}
.job-upper-right-div {
  /* border: 1px solid red; */
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40%;
}
.blog-card-and-title-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin-bottom: 30px; */
}
.blog-card-row-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: wrap;
  overflow: hidden;
  width: 100%;
}

.upper-right-div::-webkit-scrollbar {
  width: 7px;
}

.upper-right-div::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}
.upper-right-div::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
}
.blog-category-title {
  font-size: 2em;
  font-weight: 600;
}
.view-more-div {
  font-size: 1.5em;
  font-weight: 600;
  padding-right: 30px;
  cursor: pointer;
}
.title-row-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.jobs-display-flexbox-div {
  display: flex;
  width: 80%;
  justify-self: center;
  align-self: center;
  margin-top: 50px;
}
.all-jobs-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 80px;
  align-items: center;
  flex-wrap: wrap;
}
.headings-and-search-div {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-self: center;
  align-self: center;
  margin-top: 50px;
}

.background-workout-div {
  height: 450px;
  width: 100%;
  transform: skew(0, -4deg);
  /* margin-bottom: 20vw; */
  margin-top: -10vh;
  background: linear-gradient(141.23deg, #000000 6.35%, #4f4f4f 139.03%);
}
.workout-image-section {
  width: 55%;
}
.workout-details-image-div {
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  flex-direction: row;
  /* border: 1px solid yellow; */
}
.share-and-fav-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  /* border: 1px solid yellow; */
}
.circular-div {
  background: #1a1a1a;
  border-radius: 50%;
  padding-left: 18px;
  padding-right: 18px;
  /* border: 1px solid white; */
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
  /* box-shadow: 0 0 2px 0px white inset, 0 0 2px 0px white; */
}

.circular-div:hover {
  background-color: red;
}
.circular-div-colored {
  border-radius: 50%;
  padding-left: 18px;
  padding-right: 18px;
  background-color: red;
  /* box-shadow: 0 0 2px 0px white inset, 0 0 2px 0px white; */
  cursor: pointer;
  margin-right: 5px;
  /* margin-left: 5px; */
}

.start-workout-btn,
.start-workout-btn:hover,
.start-workout-btn:active,
.start-workout-btn:focus {
  background: linear-gradient(110.19deg, #ff1e1e 36.55%, #ff6f1e 103.92%);
  color: white;
  margin-right: 10px;
  font-weight: 500;
  font-size: 20px;
  border-radius: 50px;
  height: 55px;
  width: 60%;
  border: none;
  outline: 0;
  justify-content: center;
  align-items: center;
}
.trending-videos-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 84%;
}
.trending-lable {
  font-size: 1.5em;
  justify-self: flex-start;
  align-self: flex-start;
  font-weight: 700;
}
.workout-details-paragraph {
  width: 84%;
  margin-top: 20px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  margin-bottom: 20px;
  /* border: 1px solid yellow; */
}
.workout-details-title-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.workout-details-title-text {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  width: 100%;
  color: white;
  margin-bottom: -1px;
  display: -webkit-box;
  /* max-width: 25vw; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.workoutDetails-details-div {
  width: 80%;
  /* margin-left: 5%; */
  padding-left: 10px;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  /* border: 1px solid yellow; */
}
.workout-tag {
  display: flex;
  background: #1a1a1a;
  color: rgba(255, 255, 255, 0.65);
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  border-radius: 12px;
  /* width: 22%; */
  text-align: center;
  font-weight: 500;
  font-size: 85%;
}
.workout-tags-div {
  display: flex;
  /* border: 1px solid yellow; */
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-self: start;
  /* justify-content: space-between; */
}
.workout-img-and-details-div {
  display: flex;
  flex-direction: row;
  width: 84%;
  /* border: 1px solid yellow; */
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 10px;
}
.indicator-workout {
  position: absolute;
  border: 0px;
  width: 50%;
  left: 0px;
  z-index: 1;
  bottom: 0px;
  height: 30px;
  transform: skew(0deg, 0deg);
  background: linear-gradient(276.14deg, #ff1e1e -32.94%, #ff0101 100%);
}
.indicator2-workout {
  position: absolute;
  right: 0px;
  border: 0px;
  width: 50%;
  z-index: 1;
  bottom: -1px;
  height: 30px;
  transform: skew(-1deg, -0.5deg);
  background: linear-gradient(276.14deg, #ff0101 -32.94%, #ff1e1e 100%);
}
.container-div-workout {
  background-color: #000;
}
.workout-page-content-div-container {
  background-color: #000000;
  height: 100%;
  width: 100%;
  color: white;
  /* transform: skew(0, -4deg); */
  margin-top: -2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
}
.workout-page-content-div {
  /* transform: skew(0, 4deg); */
  padding-bottom: 100px;
  margin-top: 50px;
  width: 98%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.filter-cats {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  flex-wrap: wrap;
  width: 100%;
  justify-self: center;
  justify-content: center;
  /* align-items: center; */
  align-self: center;
}
.videos-div {
  width: 100%;
  /* border: 1px solid yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search-and-sort-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* padding-left: 50px; */
  margin-bottom: 20px;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  color: white;
  font-size: 18px;
}
.search-div {
  width: 40vw;
  border: 1px solid #949191;
  margin-right: 1vw;
}
.filter-button {
  border-radius: 5px;
  background-color: rgba(255, 30, 30, 1);
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12;
  height: 60;
  justify-content: center;
  border: rgba(255, 30, 30, 1);
  margin-right: 3;
  margin-left: 70;
  cursor: pointer;
  width: 20%;
}
.filter-section {
  width: 250px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  text-align: left;
  margin-bottom: 20px;
  /* border: 1px solid blue; */
}

.filters-div {
  width: 80vw;
  /* border: 1px solid pink; */
  /* background: linear-gradient(141.23deg, #4f4f4f 6.35%, #000000 139.03%); */
  /* height: 100px; */
  margin-left: 80px;
  margin-top: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
}
.dont-show-filter-div {
  display: none;
}
.filters-div {
  display: flex;
  /* border: 1px solid green; */
  flex-wrap: wrap;
}

.dropdown-style {
  border: 1px solid #949191;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 5px 14px;
  display: flex;
  /* margin-left: 80px; */
}
.trending-videos-grid {
  margin-top: 20px;
  display: grid;
  /* border: 1px solid yellow; */
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: 100%;
  /* margin-left: 80px; */
  margin-bottom: 150px;
}
.trending-videos-grid-x {
  margin-top: 20px;
  display: grid;
  /* border: 1px solid yellow; */
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: 100%;
  /* margin-left: 80px; */
  margin-bottom: 150px;
}
.workout-videos-grid {
  margin-top: 20px;
  display: grid;
  /* border: 1px solid yellow; */
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: 80vw;
  margin-left: 80px;
  /* margin-bottom: 150px; */
}
.catagory-and-view-more {
  display: flex;
  flex-direction: row;
  width: 96%;
  /* border: 1px solid green; */
  justify-content: space-between;
  background: #000000;
  padding: 10px;
}
.workout-video-card,
.workout-video-card:hover,
.workout-video-card:focus {
  display: flex;
  flex-direction: column;
  width: 96%;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 3px;
}

.workout-video-card-x,
.workout-video-card-x:hover,
.workout-video-card-x:focus {
  display: flex;
  flex-direction: column;
  width: 96%;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 3px;
}
.workout-video-card-s,
.workout-video-card-s:hover,
.workout-video-card-s:focus {
  display: flex;
  flex-direction: column;
  width: 320px;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 15px;
}
.workout-video-card-f,
.workout-video-card-f:hover,
.workout-video-card-f:focus {
  display: flex;
  flex-direction: column;
  width: 310px;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 3px;
}

.remove-link-color {
  text-decoration: none;
  color: red;
  margin-left: -20%;
}
.banner-image {
  margin-top: -300px;
  padding-bottom: 100px;
  width: 80%;
  height: 570px;
}
.circular-btns-div {
  display: flex;
  flex-direction: row;
  height: 55px;
}
.workout-catagory-text {
  margin-left: 5px;
  font-size: 18px;
  text-align: right;
  font-weight: 600;
  margin-top: 10px;
  color: #ff0101;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.workout-title-text {
  font-size: 17px;
  text-align: left;
  font-weight: 600;
  margin-top: 15px;
  width: 90%;
  margin-bottom: -1px;
  color: white;
  display: -webkit-box;
  /* max-width: 25vw; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.duration-and-views-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2px;
  align-items: flex-start;
  margin-top: 5px;
}
.duration-and-views-text {
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  justify-self: center;
  align-self: center;
}
.filter-big-title {
  font-size: 28px;
  font-weight: 600;
  text-align: left;
}
.filter-small-title {
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  margin-top: -10px;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
  margin-top: 3px;
}
.clock-n-eye {
  filter: invert(18%) sepia(91%) saturate(5407%) hue-rotate(360deg)
    brightness(110%) contrast(128%);
}
.saturate {
  filter: saturate(3);
}
.grayscale {
  filter: grayscale(100%);
}
.contrast {
  filter: contrast(160%);
}
.brightness {
  filter: brightness(0.25);
}
.blur {
  filter: blur(3px);
}
.invert {
  filter: invert(100%);
}
.sepia {
  filter: sepia(100%);
}
.huerotate {
  filter: hue-rotate(180deg);
}
.rss.opacity {
  filter: opacity(50%);
}
.white-workout-container {
  width: 100vw;
  height: auto;
  transform: skew(0, 5deg);
  /* border: 1px solid green; */
  background-color: #fff;
  /* border-bottom: 2px solid black; */
}

.white-workout-content {
  transform: skew(0, -5deg);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.getworkout-div-white {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 50vh; */
  background-color: #fff;
  width: 100%;
  color: #000;
  transform: skew(0deg, -5deg);
}

.workout-left-section {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  justify-content: flex-start;
  justify-self: center;
  align-self: center;
  align-items: flex-start;
  padding: 50px;
  padding-left: 100px;
  width: 50%;
}
.workout-right-section {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 50px;
  justify-content: flex-end;
  align-items: flex-end;
  /* border: 1px solid red; */
  width: 50%;
}
.white-workout-title {
  font-size: 25px;
  font-weight: 700;
  width: 60%;
  /* border: 1px solid red; */
  text-align: left;
  /* padding-left: 40px; */
}
.white-workout-text {
  font-size: 18px;
  font-weight: 500;
  width: 60%;
  margin-top: -10px;
  text-align: left;
  /* padding-left: 40px; */
}
.all-workout-catagories-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-self: center;

  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  /* border: 1px solid red; */
}
.workout-category-and-video-div {
  display: flex;
  width: 100%;
  /* border: 1px solid yellow; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.workout-catagory-videos {
  display: flex;
  width: 95%;
  /* border: 1px solid red; */
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-self: flex-start;
  background: #000000;
}
.workout-catagory-videos::-webkit-scrollbar {
  height: 1px;
}

.workout-category-videos {
  display: flex;
  width: 95%;
  /* border: 1px solid red; */
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  /* overflow-x: scroll; */
  overflow-y: hidden;
  justify-self: flex-start;
  background: #000000;
}
.workout-category-videos::-webkit-scrollbar {
  height: 1px;
}

/* .workout-catagory-videos::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 10px;
  width: 2px;
}
.workout-catagory-videos::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
} */
.blogs-viewmore {
  display: flex;
  background-color: white;
  flex-direction: column;
  /* padding-bottom: 100px; */
  margin-top: 20px;
  color: white;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
  width: 99.8%;
  /* border: 1px solid green; */
}
.category-name-videos-container-div {
  display: flex;
  background-color: black;
  flex-direction: column;
  /* padding-bottom: 100px; */
  margin-top: 20px;
  color: white;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
  width: 85%;
  /* border: 1px solid green; */
}
.category-name-div {
  display: flex;
  width: 100%;
  justify-self: center;
  align-self: center;
  /* border: 1px solid yellow; */
}

.category-videos-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-self: center;
  align-self: center;

  /* border: 1px solid red; */
}
.dont-show {
  display: none;
  font-size: 0px;
}
.show {
  border: 1px solid green;
}
.react-workout-player {
  width: 100%;
  height: 50%;
}
.red-color {
  color: red;
}
.bottom-drawer .ant-drawer-title {
  margin: 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 22px;
  background-color: rgb(0, 0, 0);
}
.bottom-drawer .ant-drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  color: rgba(255, 255, 255, 0.85);
  background: rgb(0, 0, 0);
}
.bottom-drawer .ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}

.apply-filter-btn,
.apply-filter-btn:hover,
.apply-filter-btn:active,
.apply-filter-btn:focus {
  border-radius: 5px;
  background-color: rgba(255, 30, 30, 1);
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12;
  height: 60;
  justify-content: center;
  border: rgba(255, 30, 30, 1);
  margin-right: 3;
  margin-left: -70px;
  cursor: pointer;
}
.post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  margin: 20px auto;
  padding: 15px 10px;
  background: #fff;
}

small {
  border: 2px solid #777;
  border-radius: 7px;
  padding: 5px 12px;
  color: #777;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationItem {
  background: #fff;
  border: 2px solid #666;
  padding: 10px 15px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: blue;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
}

.paginationItem.active {
  border: 1px solid #888;
  color: #888;
  pointer-events: none;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}
.dataContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.react-player__preview {
  background-size: contain !important;
  background-repeat: no-repeat;
  width: 100% !important;
}

@media only screen and (max-width: 1150px) {
  .filter-button {
    font-size: 12px;
    width: 15% !important;
    margin-left: 40px !important;
  }
  .workout-catagory-videos {
    justify-content: flex-start;
  }
  /* .workout-video-card,
  .workout-video-card:hover,
  .workout-video-card:focus {
    width: 350px;
    padding: 5px;
  } */
  .workout-category-videos {
    justify-content: flex-start;
  }
  /* .workout-video-card,
  .workout-video-card:hover,
  .workout-video-card:focus {
    width: 350px;
    padding: 5px;
  } */
  .workout-img-and-details-div {
    width: 90%;
  }
  /* .workout-tag {
    width: 22%;
    font-size: 80%;
  } */
  .share-and-fav-div {
    flex-wrap: nowrap;
  }
  .start-workout-btn,
  .start-workout-btn:hover,
  .start-workout-btn:active,
  .start-workout-btn:focus {
    margin-right: 6px;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    width: 60%;
  }
  .workout-details-paragraph {
    width: 90%;
  }
  .trending-videos-div {
    width: 90%;
  }
  .trending-videos-grid {
    width: 100%;
  }
  .trending-videos-grid-x {
    width: 100%;
  }
  .react-player__preview {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .filter-button {
    font-size: 12px;
    width: 15% !important;
    margin-left: 35px !important;
  }
  .workout-img-and-details-div {
    width: 90%;
  }
  /* .workout-tag {
    width: 22%;
    font-size: 66%;
  } */
  .share-and-fav-div {
    flex-wrap: nowrap;
  }
  .start-workout-btn,
  .start-workout-btn:hover,
  .start-workout-btn:active,
  .start-workout-btn:focus {
    margin-right: 6px;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    width: 60%;
  }
  .workout-details-paragraph {
    width: 90%;
  }
  .trending-videos-div {
    width: 90%;
  }
  .trending-videos-grid {
    width: 100%;
  }
  .trending-videos-grid-x {
    width: 100%;
  }
  .react-player__preview {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100% !important;
  }
}

@media only screen and (max-width: 800px) {
  .workout-img-and-details-div {
    width: 90%;
  }
  /* .workout-tag {
    width: 22%;
    font-size: 55%;
  } */
  .share-and-fav-div {
    flex-wrap: nowrap;
  }
  .start-workout-btn,
  .start-workout-btn:hover,
  .start-workout-btn:active,
  .start-workout-btn:focus {
    margin-right: 6px;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    width: 60%;
  }
  .workout-details-paragraph {
    width: 90%;
  }
  .trending-videos-div {
    width: 90%;
  }
  .trending-videos-grid {
    width: 100%;
  }
  .trending-videos-grid-x {
    width: 100%;
  }
  .react-player__preview {
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 100% !important;
  }
  .getworkout-div-white {
    flex-direction: column-reverse;
  }
  .ipad-image-hide {
    display: none;
  }
  .duration-and-views-div {
    width: 100%;
    margin-top: 10px;
  }
  .indicator-workout {
    display: none;
  }
  .indicator2-workout {
    display: none;
  }
  .banner-image {
    margin-top: -100px;
    margin-bottom: -50px;
    width: 100%;
    height: auto;
  }
  .background-workout-div {
    height: 200px;
  }
  .filter-button {
    font-size: 12px;
    width: 25% !important;
    margin-top: 40px !important;
    margin-left: 25px !important;
  }
  .icon-in-filter {
    font-size: 12px;
  }
  .search-and-sort-div {
    padding-left: 0px;
    margin-bottom: 10px;
    margin-top: -15px;
  }
  .workout-catagory-text {
    font-size: 13px;
    padding-left: 0px;
  }
  .workout-title-text {
    font-size: 14px;
  }
  .duration-and-views-text {
    font-size: 12px;
  }
  .workout-catagory-videos {
    width: 95%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow: scroll;
    justify-self: center;
    align-self: center;
  }
  .workout-category-videos {
    width: 95%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow: scroll;
    justify-self: center;
    align-self: center;
  }

  .workout-page-content-div {
    justify-self: center;
    width: 100%;
  }
  .workout-category-and-video-div {
    width: 100%;
  }
  .hide-this {
    display: none;
  }
  .filters-div {
    width: 100%;
    margin: 0;
  }
  .filter-cats {
    width: 100%;
    padding: 10px;
    justify-content: space-between;
  }
  .filter-section {
    width: 180px;
  }
  .filter-heading {
    font-size: 18px;
  }
  .workout-video-card,
  .workout-video-card:focus,
  .workout-video-card:hover {
    width: 220px;
    padding: 5px;
  }
  .workout-video-card-s,
  .workout-video-card-s:hover,
  .workout-video-card-s:focus {
    flex-direction: column;
    width: 220px;
  }
  /* .workout-image-section {
    width: 100%;
  } */
}
@media only screen and (max-width: 666px) {
  .workout-video-card,
  .workout-video-card:focus,
  .workout-video-card:hover {
    width: 220px;
    padding: 5px;
  }
  .workout-video-card-x,
  .workout-video-card-x:focus,
  .workout-video-card-x:hover {
    width: 380px;
    padding: 5px;
  }
  .workout-video-card-f,
  .workout-video-card-f:focus,
  .workout-video-card-f:hover {
    width: 220px;
    padding: 5px;
  }
  .filter-button {
    font-size: 12px;
    width: 25% !important;
  }
  .workout-img-and-details-div {
    flex-direction: column;
  }
  .workoutDetails-details-div {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }
  .trending-videos-grid {
    grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
  }
  .trending-videos-grid-x {
    grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
  }
  .workout-image-section {
    width: 100%;
  }
  /* .workout-tag {
    width: 20%;
  } */
}
@media only screen and (max-width: 470px) {
  .remove-link-color {
    margin-left: 0;
  }
  .filter-button {
    font-size: 12px;
    width: 30% !important;
  }
  .workout-video-card,
  .workout-video-card:focus,
  .workout-video-card:hover {
    width: 220px;
    padding: 5px;
  }
  .workout-video-card-x,
  .workout-video-card-x:focus,
  .workout-video-card-x:hover {
    width: 300px;
    padding: 5px;
  }
  .workout-video-card-f,
  .workout-video-card-f:focus,
  .workout-video-card-f:hover {
    width: 220px;
    padding: 5px;
  }
  .background-workout-div {
    height: 300px;
  }
  .workout-title-text {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .duration-and-views-text {
    font-size: 12px;
    white-space: nowrap;
  }
  /* .workout-tag {
    font-size: 70%;
    font-weight: bold;
  } */

  .workout-img-and-details-div {
    width: 95%;
    flex-direction: column;
  }
  .workout-details-image-div {
    width: 100%;
  }
  .workout-image-section {
    width: 100%;
  }

  .workoutDetails-details-div {
    width: 100%;
  }
  .workout-details-title-text {
    width: 100%;
    white-space: nowrap;
    overflow: scroll;
  }
  .workout-tags-div {
    width: 100%;
  }
  .duration-and-views-div {
    margin-top: 10px;
    /* margin-top: 10px; */
  }
  .workout-details-paragraph {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .start-workout-btn,
  .start-workout-btn:hover,
  .start-workout-btn:active,
  .start-workout-btn:focus {
    font-size: 12px;
    height: 40px;
    width: 100%;
    border-radius: 9px;
  }
  .circular-btns-div {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .trending-videos-grid {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    flex-wrap: wrap;
  }
  .trending-videos-grid-x {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    flex-wrap: nowrap;
  }
  .trending-videos-div {
    width: 100%;
  }
  .react-player__preview {
    height: 360px !important;
  }
}

@media only screen and (max-width: 350px) {
  .filter-button {
    font-size: 12px;
    width: 35% !important;
    margin-left: 20px !important;
  }
  /* .workout-tag {
    width: 30%;
    font-size: 60% !important;
    font-weight: bold;
  } */
  .home-workout-tags-div {
    justify-content: center;
  }
}

/* @media only screen and (max-width: 1600px) {
  .catagory-and-view-more {
    width: 96%;
  }
  .workout-catagory-videos {
    width: 100%;
    flex: auto;
    justify-content: flex-start;
  }
  .workout-video-card {
    width: 350px;
  }
} */
/* @media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1200px) {
} */
/* @media only screen and (max-width: 1024px) {
  .filter-button {
    width: 15% !important;
  }
} */
/* @media only screen and (max-width: 768px) {
  .filter-button {
    width: 25% !important;
    margin-top: 30px !important;
    margin-left: 25px !important;
  }
} */
/* @media only screen and (max-width: 480px) {
  .filter-button {
    width: 35% !important;
  }
} */
/* @media only screen and (max-width: 320px) {
  .filter-button {
    width: 35% !important;
  }
} */

.skeleton {
  display: flex;
  width: 95%;
  z-index: 0;
  /* height: 140px; */
}
.Workoutskeleton {
  display: flex;
  height: 165px;
  width: 95%;
  z-index: 0;
}
.Singleskeleton {
  display: flex;
  width: 95%;
  z-index: 0;
  height: 350px;
  border-radius: 15px;
}
.img-and-details-div {
  width: 84%;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  display: flex;
  padding-top: 20px;
}
.img-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
}
.details-div {
  flex-direction: column;
  display: flex;
  width: 70%;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.paragraph-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}
.workoutVideosContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  /* overflow: scroll; */
}
.singleWorkoutVideosDiv {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.WorkoutImgDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* // marginTop: "20px", */
  margin-bottom: 20px;
}
.WorkoutDetailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .workoutVideosContainer {
    width: 100%;
  }
  .singleWorkoutVideosDiv {
    width: 35%;
  }
}

@media only screen and (max-width: 840px) {
  .singleWorkoutVideosDiv {
    width: 40%;
  }
}

@media only screen and (max-width: 666px) {
  .img-and-details-div {
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: 70px;
  }
  .img-div {
    justify-content: flex-start;
    width: 100%;
    height: 250px;
  }
  .details-div {
    width: 100%;
  }
  .paragraph-div {
    width: 90%;
  }
  .singleWorkoutVideosDiv {
    width: 45%;
  }
  .Singleskeleton {
    height: 250px;
  }
}
@media only screen and (max-width: 480px) {
  .Singleskeleton {
    height: 250px;
  }
  .singleWorkoutVideosDiv {
    width: 50%;
  }
  .Workoutskeleton {
    height: 100px;
  }
}

@media only screen and (max-width: 320px) {
  .Singleskeleton {
    height: 180px;
    width: 100%;
  }
  .img-and-details-div {
    margin-top: 40px;
  }
  .img-div {
    margin-bottom: 20px;
  }
}

.training-plans-container {
  background-color: black;
  color: white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.training-plans-container2 {
  background-color: black;
  color: white;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
}
.training-img-and-description-div {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 83%;
  /* border: 1px solid green; */
  color: white;
  align-items: flex-start;
}
.training-plan-thumbnail-div {
  width: 55%;
  display: flex;
  margin-right: 10px;
  height: 100%;
}
.remove-margin-tp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.training-plan-details-div {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
}
.share-and-fav-training-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.circular-training-div {
  border-radius: 50%;
  padding-left: 18px;
  padding-right: 18px;
  /* width: 10%; */
  border: 1px solid white;
  cursor: pointer;
  /* background: linear-gradient(80.19deg, #ff1e1e 36.55%, #ff6f1e 103.92%); */
  margin-right: 5px;
  margin-left: 5px;
}
.add-training-plan-btn,
.add-training-plan-btn:hover,
.add-training-plan-btn:focus,
.add-training-plan-btn:active {
  background: linear-gradient(110.19deg, #ff1e1e 36.55%, #ff6f1e 103.92%);
  color: white;
  margin-right: 30px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 50px;
  height: 55px;
  width: 100%;
  border: none;
  outline: 0;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
}
.training-plan-description {
  width: 83%;
  text-align: justify;
  font-size: 1.2em;
  color: white;
  justify-self: center;
  align-self: center;
  margin-bottom: 20px;
}
.training-plan-small-title-text {
  color: white;
  font-weight: 600;
  font-size: 2em;
  text-align: left;
  /* margin-left: 140px; */
}
.more-training-plans-div {
  display: flex;
  width: 83%;
  justify-self: center;
  margin-bottom: 150px;
  align-self: center;
  /* border: 1px solid yellow; */
  flex-direction: column;
}
.other-training-plans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  /* margin-top: 20px; */
  width: 100%;
  align-self: center;
  /* margin-left: 20px; */
}
.red-text {
  color: #ff1e1e;
  font-size: 1.5em;
  text-align: left;
  /* margin-top: 30px; */
  margin-bottom: 0px;
}
.training-plan-title-text {
  color: white;
  font-weight: 700;
  font-size: 2em;
  text-align: left;
}
.views-and-time-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2px;
  margin-top: -10px;
  align-items: flex-start;
}
.views-and-time-text {
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  justify-self: center;
  align-self: center;
}
.training-plans-tags-div {
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  /* border: 1px solid yellow; */
  flex-direction: row;
  flex-wrap: wrap;
}
.training-plans-banner-div {
  height: 350px;
  width: 100%;
  transform: skew(0, -4deg);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding-top: 90px;
  margin-bottom: 15px;
  margin-top: -10vh;
  background: linear-gradient(141.23deg, #000000 6.35%, #4f4f4f 139.03%);
}
.training-plans-banner-content-div {
  transform: skew(0, 4deg);
}
.search-and-date-container-div {
  width: 84%;
  display: flex;
  height: 70px;
  margin-left: 0;
  align-items: center;
}
.search-and-date-div {
  width: 80%;
  justify-self: center;
  align-self: center;
  /* height: 90px; */
  flex-direction: row;
  display: flex;
  /* border: 1px solid green; */
  /* justify-content: center; */
  align-items: center;
}
.search-training-plans-div {
  width: 60%;
  border: 1px solid #949191;
  height: 100%;
  margin-right: 1vw;
}
.days-type-div {
  height: 100%;
  display: flex;
  width: 43%;
  justify-self: center;
  align-self: center;
  /* border: 1px solid red; */
  justify-content: space-evenly;
  align-items: center;

  margin-left: 20px;
}
.dropdown-style2 {
  border: 1px solid #949191;
  height: 43px;
  justify-content: center;
  align-items: center;
  padding: 5px 14px;
  margin-right: 10px;
  width: 30%;
  /* flex-wrap: nowrap; */
  display: flex;
}

.display-all-training-plans-outer-div {
  display: flex;
  width: 100%;
  height: 10px;
  /* border: 1px solid yellow; */
  justify-self: center;
  align-items: center;
  justify-content: center;
}
.display-all-training-plans-div {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  /* height: 10px; */
  /* margin-left: 10%;
  margin-right: 3%; */
  /* border: 1px solid red; */
  justify-self: center;
  align-self: center;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 140px;
}
.add-tp-container {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
}
.content-container-tp {
  width: 80%;
  /* border: 1px solid green; */
  display: flex;
  justify-self: center;
  align-self: center;
  margin-top: 15px;
}
.training-text-and-bell-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
.navbar-tp {
  width: 80%;
  justify-self: center;
  align-self: center;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #ff1e1e;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 3px solid rgb(255, 0, 0);
}
.planner-and-calander-div {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-self: center;
  align-self: center;
  margin-bottom: 70px;
}
.planner-div {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  /* height: 65vh; */
  overflow: hidden;
}
.calander-div {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.add-trainings-btn,
.add-trainings-btn:focus,
.add-trainings-btn:hover,
.add-trainings-btn:visited {
  width: 30%;
  justify-self: center;
  align-self: center;
  height: 40px;
  color: white;
  background: #ff0101;
  border: none;
  border-radius: 6px;
}
.site-calendar-demo-card {
  width: 100%;
  z-index: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: 65px;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;

  border-radius: 0;
  transition: background 0.3s;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
  background-color: #000000;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
  color: #fff;
  font-weight: 700;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
  color: #ff1e1e;
  font-weight: 600;
  font-size: 20px;
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value {
  color: #ff1e1e;
}
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover {
  background: #374044;
}
.ant-picker-calendar-date-today,
.ant-picker-calendar-date-today {
  background-color: #303336;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
  background: #000;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #000;
  color: #fff;
  /* border: 1px solid #d9d9d9; */
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  line-height: 30px;
  background: rgb(0, 0, 0);
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000;
  color: white;
}
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  border-color: #ffffff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #ffffff;
  background: #fff;
  border-color: #ffffff;
}
.ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border-right-color: #ffffff;
}
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-calendar-date-today
  .ant-picker-calendar-date-value {
  color: rgb(255 255 255 / 85%);
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: #374044;
}
.datepicker-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ff1e1e;
}

.custom-modal-styles .ant-modal-header {
  padding: 16px 24px;
  color: rgb(255 0 0 / 85%);
  background: black;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.custom-modal-styles .ant-modal-title {
  margin: 0;
  color: #ff1e1e;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 1px;
  word-wrap: break-word;
}
.custom-modal-styles > .ant-modal-content {
  position: relative;
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid #342d2d;
  border-radius: 10px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: auto;
}
.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  color: #fff;
  border-color: #ff1818;
  background: #ff1e1e;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  display: flex;
  padding: 0;
  color: rgb(0 0 0 / 85%);
  font-size: 24px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum", "tnum";
  padding: 8px 11px 8px;
  position: relative;
  align-items: center;
  background: #000000;
  border: 1px solid #979797;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
  border-radius: 8px;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: white;
  font-size: 18px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-calendar-header {
  display: none;
}
.display-false {
  display: none;
}

.my-subscribed-plan-card-div {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  width: 105%;
  margin-left: -3%;
}
.subed-plan-container-div {
  width: 100%;
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.subed-plan-title {
  color: #ff1e1e;
  font-size: 18px;
  font-weight: 700;
  margin-top: 5px;
}
.subed-plan-name {
  font-size: 18px;
  font-weight: 700;
  color: white;
  margin-top: -20px;
  margin-bottom: 0px;
}
.modal-confirm-button,
.modal-confirm-button:hover,
.modal-confirm-button:focus,
.modal-confirm-button:active {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  width: 100%;
  height: 40px;
  justify-self: center;
  align-self: center;
  color: white;
  font-size: 18px;
  letter-spacing: 2px;
  border-radius: 8px;
  border: 1px solid #f83f3f;
  /* outline: none ; */
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: transparent;
  color: #979797;
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  font-size: 18px;
  border-radius: 8px 0px 0px 8px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: transparent;
  color: #979797;
  height: 45px;
  font-size: 18px;
  border-radius: 0px 8px 8px 0px;
}
.modal-cancel-button,
.modal-cancel-button:hover,
.modal-cancel-button:focus,
.modal-cancel-button:active {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 48%;
  height: 35px;

  justify-self: center;
  align-self: center;
  color: black;
  font-size: 18px;
  letter-spacing: 2px;

  /* outline: none ; */
}
.btns-div-confirm-cancle {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 40px;
  margin-bottom: -30px;
}
.modal-heading-custom {
  color: white;
  justify-self: center;
  display: flex;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  color: white;
}
.select-time-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.select-hrs {
  width: 30%;
}
.Capsule-card-div {
  display: flex;
  flex-direction: row;
  border: 1px solid #2e2e2e;
  border-radius: 14px;
  height: 80px;
  margin-bottom: 10px;
  /* cursor: pointer; */
  background-color: #2e2e2e;
}
.setActivity-style a {
  color: #d9d9d9;
  background-color: transparent;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  width: 102%;
  display: flex;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #979797;
}
.training-plan-card,
.training-plan-card:hover,
.training-plan-card:focus {
  display: flex;
  flex-direction: column;
  width: 24%;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 5px;
}
.trainingplan-card-image {
  width: 130%;
  height: 200px;
}
@media only screen and (max-width: 1300px) {
  .search-and-date-container-div {
    width: 85%;
  }
  .display-all-training-plans-div {
    width: 86%;
  }
}
@media only screen and (max-width: 1156px) {
  .search-and-date-container-div {
    width: 87%;
  }
  .display-all-training-plans-div {
    width: 88%;
  }
}

@media only screen and (max-width: 1024px) {
  .training-plan-title-text {
    font-size: 1.5em;
  }
  .training-plans-tags-div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .other-training-plans {
    width: 100%;
    justify-content: flex-start;
  }
  .training-plan-card,
  .training-plan-card:focus,
  .training-plan-card:hover {
    width: 32%;
    padding: 10px;
  }
  .training-plan-thumbnail-div {
    height: 100%;
  }
  .display-all-training-plans-div {
    width: 87%;
  }
  .search-and-date-container-div {
    width: 85%;
  }
}

@media only screen and (max-width: 840px) {
  .search-and-date-div {
    flex-direction: column;
    margin-bottom: 40px;
  }
  .add-trainings-btn,
  .add-trainings-btn:focus,
  .add-trainings-btn:hover,
  .add-trainings-btn:visited {
    width: 100%;
  }
  .search-training-plans-div {
    width: 80%;
  }
  .training-plans-banner-div {
    height: 200px;
  }
  .dropdown-style2 {
    padding: 5px 35px;
    font-size: 12px;
  }

  .days-type-div {
    width: 80%;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 10px;
  }
  .training-text-and-bell-div {
    flex-direction: row;
    justify-content: space-between;
  }
  .navbar-tp {
    display: none;
  }
  .planner-and-calander-div {
    flex-direction: column-reverse;
    width: 100%;
    justify-content: center;
    /* align-items: center ; */
  }
  .calander-div {
    margin-left: 10%;
    width: 80%;
  }
  .planner-div {
    width: 100%;
    margin: 0;
    border-right: 0;
    /* padding: 20px; */
  }
  .Capsule-card-div {
    width: 100%;
    padding-right: 20px;
    /* height: 60px; */
  }
  .training-planner-heading {
    font-size: 16px;
    margin-right: 50px;
  }
  .add-activity-modal {
    font-size: 10px;
    margin-top: -20px;
  }
  .capsule-text {
    font-size: 12px;
  }
  .edit-modal-icon {
    margin-top: 15px;
  }
  .edit-modal-icon-activity {
    margin: 0 auto;
  }
  .training-img-and-description-div {
    flex-direction: column;
    width: 90%;
    margin-top: 30px;
  }
  .training-plan-thumbnail-div {
    width: 100%;
    margin: 0;
    height: 100%;
    justify-self: center;
    /* margin-left: -20px;
    margin-top: 20px; */
  }
  .training-plan-details-div {
    width: 100%;
    margin: 0px;
  }
  .more-training-plans-div {
    width: 90%;
  }
}

@media screen and (max-width: 666px) {
  .trainingplan-card-image {
    width: 100%;
    height: auto;
  }
  .remove-margin-tp {
    margin-top: 10px;
  }
  .training-plan-card,
  .training-plan-card:focus,
  .training-plan-card:hover {
    margin-left: 5px;
    width: 100%;
    height: auto;
  }
  .display-all-training-plans-div {
    flex-direction: row;
    justify-content: space-around;
    /* flex-wrap: nowrap ; */
    overflow: scroll;
    width: 80%;
    height: auto;
    margin: 10px;
    margin-bottom: 50px;
  }
  .search-and-date-container-div {
    width: 80%;
  }
  .training-img-and-description-div {
    flex-direction: column;
    width: 90%;
    margin-top: 30px;
  }
  .training-plan-thumbnail-div {
    width: 100%;
    margin: 0;
    height: 100%;
    justify-self: center;
    /* margin-left: -20px;
    margin-top: 20px; */
  }
  .training-plan-details-div {
    width: 100%;
    margin: 0px;
  }
  .more-training-plans-div {
    width: 90%;
  }
}
@media screen and (max-width: 470px) {
  .search-and-date-container-div {
    width: 90%;
  }
  .display-all-training-plans-div {
    width: 90%;
  }
  .training-img-and-description-div {
    flex-direction: column;
    width: 90%;
    margin-top: 30px;
  }
  .other-training-plans {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: -50px;
    overflow-x: scroll;
  }
  .training-plan-thumbnail-div {
    width: 100%;
    margin: 0;
    height: 100%;
    justify-self: center;
    /* margin-left: -20px;
    margin-top: 20px; */
  }
  .training-plan-details-div {
    width: 100%;
    margin: 0px;
  }
  .training-plan-title-text {
    font-size: 18px;
    /* margin-top: -270px;
    margin-bottom: 270px;
    margin-left: -30px; */
  }

  .training-plans-tags-div {
    flex-wrap: nowrap;
    /* margin-top: -55px;
    margin-left: -30px; */
  }
  /* .views-and-time-div {
    margin-top: -270px;
    margin-bottom: 270px;
    margin-left: -30px;
  } */
  .training-plan-description {
    /* margin-top: -180px; */
    /* margin-bottom: 100px; */
    width: 90%;
    font-size: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .add-training-plan-btn,
  .add-training-plan-btn:active,
  .add-training-plan-btn:focus,
  .add-training-plan-btn:hover {
    /* margin-left: -30px; */
    /* justify-self: center ;
    align-self: center ; */
    height: 50px;
    /* margin-top: 80px; */
    width: 100%;
    border-radius: 5px;
    font-size: 14px;
  }
  .red-text {
    display: none;
  }
  .workout-tag {
    white-space: nowrap;
    font-size: 65%;
    font-weight: bold;
  }
  .training-plan-small-title-text {
    font-size: 20px;
    margin-left: -0px;
    margin-bottom: -15px;
  }
  .training-plan-card,
  .training-plan-card:focus,
  .training-plan-card:hover {
    /* padding: 15px; */
    /* padding-right: 0px;
    padding-right: 0px; */
    margin: 0px;
  }
  .more-training-plans-div {
    width: 90%;
  }
  .share-and-fav-training-div {
    justify-content: space-between;
  }
}

.skeleton {
  display: flex;
  width: 95%;
  z-index: 0;
  /* height: 140px; */
}
.skeleton-1 {
  display: flex;
  width: 95%;
  z-index: 0;
  height: 350px;
  border-radius: 15px;
}
.Trimg-and-details-div {
  width: 83%;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  display: flex;
  margin-top: 50px;
}
.Trdetails-div {
  flex-direction: column;
  display: flex;
  width: 70%;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
}
.Trimg-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
}

.TrVideosContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.TrVideosAndDetails {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.TrVideoImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.TrDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.trainingPlanCardSkeleton {
  display: flex;
  width: 100%;
  z-index: 0;
  justify-content: flex-start;
  /* border-radius: 15px; */
}
.trainingPlanCardDiv {
  display: flex;
  width: 70%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
}

@media only screen and (max-width: 1024px) {
  .TrVideosAndDetails {
    width: 30%;
  }
}

@media only screen and (max-width: 840px) {
  .trainingPlanCardSkeleton {
    justify-content: center;
  }
  .trainingPlanCardDiv {
    width: 100%;
  }
  .TrVideosAndDetails {
    width: 32%;
  }
  .Trimg-and-details-div {
    flex-direction: column;
  }
  .Trdetails-div {
    align-self: center;
    width: 100%;
  }
  .Trimg-div {
    width: 100%;
  }
}
@media only screen and (max-width: 666px) {
  .TrVideosAndDetails {
    width: 100%;
    /* height: 200px; */
  }
  /* .skeleton {
    height: 200px;
  } */
  .Trimg-and-details-div {
    width: 95%;
  }
}
@media only screen and (max-width: 480px) {
  .skeleton-1 {
    height: 250px !important;
  }
}

.homeSkeleton {
  display: flex;
  width: 95%;
  z-index: 0;
}
.leftTrendingVideoSkeleton {
  display: flex;
  width: 95%;
  z-index: 0;
  height: 350px;
}
.rightTrendingVideoSkeleton {
  display: flex;
  width: 95%;
  z-index: 0;
  height: 140px;
}
.rightTrendingSkeleton {
  display: flex;
  width: 90%;
  z-index: 0;
}
.HomeTrendingContainer {
  display: flex;
  width: 86%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 10%; */
  padding-top: 2%;
}
.leftTrendingVideoDiv {
  display: flex;
  width: 56%;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  flex-direction: column;
}
.leftTrVideoContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.leftTrVideoDetailsDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rightTrendingVideoDiv {
  display: flex;
  width: 44%;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}
.VideoContainer {
  display: flex;
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 10px;
}
.rightVideoDetailsDiv {
  width: 86%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 1040px) {
  .HomeTrendingContainer {
    width: 90%;
    /* padding-left: 8%; */
  }
  .leftTrendingVideoSkeleton {
    height: 250px;
  }
  .rightTrendingVideoSkeleton {
    height: 120px;
  }
  .rightTrendingVideoDiv {
    width: 45%;
  }
}

@media only screen and (max-width: 840px) {
  .HomeTrendingContainer {
    flex-direction: column;
    width: 90%;
  }
  .leftTrVideoContainer {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .leftTrendingVideoDiv {
    width: 100%;
    margin-bottom: 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .leftTrVideoDetailsDiv {
    align-items: flex-start;
  }
  .rightTrendingVideoDiv {
    width: 100%;
    flex-direction: row;
  }
  .VideoContainer {
    flex-direction: column;
  }
  .rightVideoDetailsDiv {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 666px) {
  .leftTrendingVideoSkeleton {
    height: 250px;
  }
  .rightTrendingVideoSkeleton {
    height: 140px;
  }
}
@media only screen and (max-width: 480px) {
  .leftTrendingVideoSkeleton {
    height: 200px;
  }
  .rightTrendingVideoSkeleton {
    height: 100px;
  }
}
@media only screen and (max-width: 320px) {
  .leftTrendingVideoSkeleton {
    height: 170px;
  }
  .rightTrendingVideoSkeleton {
    height: 80px;
  }
}

/* All Exercise Screen Css Start */
body {
  height: 100%;
  margin: 0;
  /* background: #000000; */
}
.exercise-container-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: black;
  width: 100%;

  /* position: fixed; */
}
.exercises-container-div {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  background: black;
  width: 100%;

  /* position: fixed; */
}
.exerciseTitleDiv {
  width: 95%;
  background: #000;
}
.exerciseCategories {
  display: flex;
  width: 95%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(141.23deg, #000000 6.35%, #4f4f4f 139.03%);
  margin: 0;
  padding: 50px;
  color: white;
  background: #000000;
  height: auto;
}
.categoriesTitle {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.categoryTitleText {
  margin-left: 2%;
  font-size: 18px;
  text-align: left;
  font-weight: 600;
  margin-top: 10px;
  color: #ff0101;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.exercise-category-and-video-div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background: #000000;
  height: auto;
}
.category-and-view-more {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.exercise-category-text {
  color: #ff0101;
}
.exercise-category-videos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
}
.exercise-category-videos::-webkit-scrollbar {
  display: none;
}

/* .exercise-category-videos::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 10px;
  width: 2px;
}
.exercise-category-videos::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
} */
.filterDiv {
  width: 95%;
  display: flex;
  justify-content: flex-start;
}
.filter-button {
  border-radius: 5px;
  background-color: rgba(255, 30, 30, 1);
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 12;
  height: 60;
  justify-content: center;
  border: rgba(255, 30, 30, 1);
  margin-right: 3;
  margin-left: 50px;
  cursor: pointer;
  width: 10%;
  display: flex;
  margin-top: 20px;
}
.filters-div {
  width: 80vw;
  /* border: 1px solid pink; */
  /* background: linear-gradient(141.23deg, #4f4f4f 6.35%, #000000 139.03%); */
  /* height: 100px; */
  margin-left: 80px;
  margin-top: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  display: flex;
  /* border: 1px solid green; */
  flex-wrap: wrap;
}
.dont-show-filter-div {
  display: none;
}

/* All Exercise Screen Css End */

/* Single Exercise Screen Css Start */

.single-exercise-container {
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 50px;
}
.exercise-img-and-details-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 91%;
}
.exerciseDetails-div {
  width: 42%;
  /* margin-left: 5%; */
  /* padding-left: 20px; */
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* border: 1px solid yellow; */
}
.exerciseImageDiv {
  width: 55%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.exercise-image {
  width: 280px;
  height: 350px;
  background: white;
  /* marginLeft: 50px; */
  padding-bottom: 50px;
}

.exercise-details-title-text {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  width: 60%;
  margin-bottom: -1px;
  color: white;
  display: -webkit-box;
  /* max-width: 25vw; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.exercise-duration-and-views-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 2px;
  align-items: flex-start;
  margin-top: 5px;
  padding-top: 5px;
}

.exercise-duration-and-views-text {
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  justify-self: center;
  align-self: center;
}
.exercise-clock-n-eye {
  filter: invert(18%) sepia(91%) saturate(5407%) hue-rotate(360deg)
    brightness(110%) contrast(128%);
}

.exercise-tags-div {
  display: flex;
  /* border: 1px solid yellow; */
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-self: start;
  padding-top: 10px;
  padding-bottom: 10px;
  /* justify-content: space-between; */
}
.exercise-tag {
  display: flex;
  background: #1a1a1a;
  color: rgba(255, 255, 255, 0.65);
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  border-radius: 12px;
  /* width: 22%; */
  text-align: center;
  font-weight: 500;
  font-size: 85%;
}
.exercise-description-text {
  text-align: left;
  font-family: "Montserrat", sans-serif;
}
.exercise-share-and-fav-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  /* border: 1px solid yellow; */
}

/* Single Exercise Screen Css End */

/* Exercise Video Card Css Start */

.exercise-title-text {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 0px;
}
.exercise-video-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 270px;
}
.duration-and-views-text {
  color: white;
}
.exerciseVideoImg {
  border-radius: 10px;
  margin-left: 2px;
  background-size: cover;
  width: 260px;
  height: 5%;
  /* // borderBottom: "1px solid grey ", */
  background-color: white;
}

/* Exercise Video Card Css Ends */

/* Exercise Circuit Card Css Starts */

.circuits {
  width: 91%;
  background: #000000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding-bottom: 60%; */
  border-radius: 8px;
  height: 20%;
  margin-top: 20px;
}

.circuitCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100px;
  width: 100%;
  margin-top: 20px;
}

.circuit-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0px;
}
.circuit-img-and-time-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.SubCircuits-img-and-time-details {
  width: 95%;
  height: 100px;
  background: white;
  /* //   borderRightColor: "red", */
  border-radius: 5px;
  border-left: 5px solid red;
  background: rgba(60, 60, 60, 0.4);
  margin-top: 15px;
  margin-bottom: 20px;
  justify-content: space-around;
  align-items: center;
  animation: fadein 0.2s;
}
.circuit-number-and-rounds-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  margin: 0;
}
.circuit-phase-name {
  font-weight: 900;
  font-size: 15px;
}
.subCircuits {
  display: flex;
  flex-direction: column;
  /* justifyContent: "space-evenly", */
  align-items: center;
  height: 100%;
  width: 40%;
  position: relative;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Exercise Player Css Start */

.congratulationsAndContinueDiv {
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
}

.congratulationsDiv {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #1a1a1a;
  height: 100%;
  padding: 30px;
  color: "white";
  border-radius: 16px;
}
.exerciseVideoDiv {
  background: black;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 50px 20px 50px;
  width: 100%;
  /* position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; */
}
.videoGifDiv {
  width: 100%;
  height: 20%;
  background: white;
  opacity: 0.7;
  display: flex;
  justify-content: center;
}
.videoGif {
  opacity: 0.7;
  width: 50%;
  height: 100%;
  background-color: white;
  justify-content: center;
  display: flex;
  object-fit: cover;
}
.exerciseNameDiv {
  display: flex;
  position: absolute;
  top: 130px;
  left: 115px;
  text-transform: uppercase;
}
.exerciseAndRoundsDiv {
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 630px;
}
.crossButton {
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  top: 80px;
  right: 29px;
  cursor: pointer;
  width: 8%;
  display: flex;
  justify-content: center;
}
.timerDiv {
  top: 11%;
  position: absolute;
  left: 83%;
}
.ExerciseNameDiv {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 80px;
}
.timerImage {
  width: 20%;
}
.controlButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 20%;
  position: absolute;
  top: 630px;
}
.playButton {
  width: 30px !important;
  height: 30px !important;
}
.nextButton {
  color: gray;
  background: gray;
  cursor: pointer;
  border: none;
  width: 20%;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pauseAndPlayButton {
  /* // color: "black; */
  background: gray;
  cursor: pointer;
  border-radius: 50%;
  width: 30%;
  padding: 30px;
  border: none;
}
.reactPlayerDiv {
  background: #ffffff;
  width: 100%;
  height: 780px;
  display: flex;
}
.backButton {
  color: gray;
  background: gray;
  cursor: pointer;
  border: none;
  width: 20%;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exerciseNameCongDiv {
  margin-top: 15px;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #1a1a1a;
  height: 100%;
  padding: 20px;
  color: "white";
  border-radius: 16px;
  text-transform: uppercase;
}
.timeAndCaloriesDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  background: black;
  width: 54%;
  margin-top: 15px;
  border-radius: 16px;
}
.timeDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background: #1a1a1a;
  width: 45%;
  border-radius: 16px;
}
.CaloriesDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background: #1a1a1a;
  width: 45%;
  border-radius: 16px;
}
.continue-Button {
  border: 1px solid red;
  background: rgb(255, 30, 30);
  display: flex;
  justify-self: center;
  padding: 15px;
  margin-top: 17px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: white;
  width: 50%;
  border-radius: 10px;
  height: 100px;
  line-height: 20px;
}
.continueButton:hover {
  background: rgb(255, 30, 30);
  border: 1px solid red;
  color: white;
}
/* Exercise Player Css End */

@media only screen and (max-width: 820px) {
  .hide-this {
    display: none !important;
  }
}
@media only screen and (max-width: 1024px) {
  .single-exercise-container {
    padding: 30px;
  }
  .exercise-img-and-details-div {
    width: 90%;
  }
  .exercise-share-and-fav-div {
    flex-wrap: nowrap;
    align-items: center;
  }
  .exercise-details-title-text {
    width: 100%;
  }
  .exercise-description-text {
    width: 100%;
    text-align: left;
  }
  .circuits {
    width: 90%;
  }
  .circuit-number-and-rounds-div {
    width: 65%;
  }
}
@media only screen and (max-width: 840px) {
  .exercise-img-and-details-div {
    flex-direction: column;
    width: 90%;
  }
  .exerciseImageDiv {
    width: 100%;
  }
  .exerciseDetails-div {
    width: 100%;
    margin-top: 15px;
  }
  .exercise-tag {
    width: 22%;
  }
  .circuits {
    margin: 50px;
    width: 90%;
  }
  .circuit-content {
    width: 100%;
  }
  .circuitCard {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .circuit-img-and-time-details {
    width: 100%;
    height: 100%;
  }
  .subCircuits {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .exercise-img-and-details-div {
    flex-direction: column;
    width: 90%;
  }
  .exerciseImageDiv {
    width: 100%;
  }
  .exerciseDetails-div {
    width: 100%;
    margin-top: 15px;
  }
  .exercise-tag {
    width: 22%;
  }
  .circuits {
    margin: 50px;
    width: 100%;
  }
  .circuit-content {
    width: 100%;
  }
  .circuitCard {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .circuit-img-and-time-details {
    width: 100%;
    height: 100%;
  }
  .subCircuits {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 666px) {
  .single-exercise-container {
    flex-direction: column;
    width: 100%;
  }
  .exerciseDetails-div {
    width: 100%;
  }
  .exercise-img-and-details-div {
    flex-direction: column;
    width: 100%;
  }
  .exerciseImageDiv {
    width: 100%;
  }
  .exercise-image {
    width: 100%;
    padding-bottom: 0px;
  }
  .exercise-details-title-text {
    width: 100%;
  }
  .exercise-share-and-fav-div {
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
  }
  .exercise-tag {
    width: 30%;
  }
  .filter-button {
    width: 30vw;
  }

  /* Exercise Circuits Css Start */

  .circuits {
    margin: 50px;
    width: 100%;
  }
  .circuit-content {
    width: 100%;
  }
  .circuitCard {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .circuit-img-and-time-details {
    width: 100%;
    height: 100%;
  }
  .subCircuits {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 480px) {
  /* All Exercise Screen Css Start */
  .category-and-view-more {
    width: 100%;
  }
  .filter-button {
    width: 40vw;
    margin-left: 20px;
    margin-top: 50px;
  }
  .exerciseCategories {
    padding: 15px;
  }

  /* All Exercise Screen Css End */

  /* Exercise Video Card Css Start */

  .exercise-video-card {
    width: 230px;
  }
  .exerciseVideoImg {
    width: 92%;
  }
  /* Exercise Video Card Css End */

  /* Single Exercise Details Css Start */

  .single-exercise-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 25px;
    flex-wrap: nowrap;
    width: 100%;
  }
  .exercise-img-and-details-div {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  .exerciseDetails-div {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 7px;
  }
  .exerciseImageDiv {
    width: 100%;
  }
  .exercise-image {
    width: 100%;
    height: 20%;
    border-radius: 15px;
    padding-bottom: 0px;
  }
  .exercise-details-title-text {
    width: 100%;
  }
  .exercise-share-and-fav-div {
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
  }
  .exercise-tag {
    width: 30%;
  }

  /* Single Exercise Details Css End */

  /* Exercise Circuits Css Start */

  .circuits {
    margin: 50px;
    width: 100%;
  }
  .circuit-content {
    width: 100%;
  }
  .circuitCard {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .circuit-img-and-time-details {
    width: 100%;
    height: 100%;
  }
  .subCircuits {
    width: 100%;
    height: 100%;
  }
  .circuit-number-and-rounds-div {
    width: 100%;
  }
  /*  */
  /* Exercise Player Css Start */

  .exerciseAndRoundsDiv {
    top: 60%;
    width: 85%;
  }
  .crossButton {
    top: 14%;
    right: 14%;
  }
  .timerDiv {
    width: 85%;
    position: absolute;
    /* border: 1px solid white; */
    top: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 2px white;
    padding: 8px;
    left: 8%;
  }
  .ExerciseNameDiv {
    top: 65%;
  }
  .timerText {
    color: white !important;
    font-size: 16px !important;
  }
  .timer1Text {
    color: white !important;
    font-size: 35px !important;
    margin: 0;
  }
  .timerImage {
    filter: invert(0%) sepia(0%) saturate(7469%) hue-rotate(281deg)
      brightness(88%) contrast(106%) !important;
  }
  .reactPlayerDiv {
    height: 53% !important;
    width: 100% !important;
    margin-top: 7px;
    background: black;
    /* margin-top: 2%; */
  }
  .videoGif {
    height: 100%;
    /* padding-top: 50px; */
    width: 100%;
    align-self: center;
  }
  .videoGifDiv {
    height: 53%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
  }
  .controlButtons {
    width: 85%;
    top: 83%;
  }
  .exerciseVideoDiv {
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 80px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .exerciseNameDiv {
    width: 85%;
    top: 9%;
    left: 25px;
    justify-content: center;
  }

  /* Exercise Player Congratulations Css start */
  .congratulationsAndContinueDiv {
    justify-content: flex-start;
    padding: 0px;
  }
  .congratulationsDiv {
    margin-top: 80px;
    padding: 30px;
    height: 28%;
    width: 90%;
  }
  .timeAndCaloriesDiv {
    width: 100%;
  }
  .exerciseNameCongDiv {
    height: 11%;
    width: 90%;
    padding: 20px;
  }
  .CaloriesDiv > p {
    font-size: 11px;
  }
  .timeDiv > p {
    font-size: 11px;
  }
  .continueButton {
    height: 49px;
    width: 75%;
  }
  .exercise-category-videos::-webkit-scrollbar {
    display: none;
  }
  .continue-Button {
    border: 1px solid red;
    background: rgb(255, 30, 30);
    display: flex;
    justify-self: center;
    padding: 15px;
    margin-top: 17px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: white;
    width: 90%;
    border-radius: 10px;
    height: 55px;
    line-height: 20px;
  }
  /* Exercise Player Congratulations Css End */
}

@media only screen and (max-width: 340px) {
  .single-exercise-container {
    width: 100%;
    padding: 7px;
  }
  .exercise-img-and-details-div {
    flex-direction: column;
  }
  .exercise-image {
    height: 250px;
  }
  .exercise-tag {
    width: 30%;
    font-size: 12px;
  }
  .circuit-content {
    width: 100%;
  }
  .circuits {
    width: 100%;
  }
  .circuit-number-and-rounds-div {
    width: 100%;
  }
  .subCircuits {
    width: 100%;
  }
  /* Exercise Player Css Start */

  .exerciseAndRoundsDiv {
    top: 60%;
    width: 85%;
  }
  .crossButton {
    top: 16%;
    right: 19%;
  }
  .ExerciseNameDiv {
    top: 65%;
  }
  .timerDiv {
    padding: 0px;
  }
  .timerText {
    color: white !important;
    font-size: 12px !important;
  }

  .timerImage {
    filter: invert(0%) sepia(0%) saturate(7469%) hue-rotate(281deg)
      brightness(88%) contrast(106%) !important;
  }
  .reactPlayerDiv {
    height: 53% !important;
    width: 100% !important;
    background: black;
    margin-top: 10px;
  }
  .timer1Text {
    font-size: 27px !important ;
  }
  .videoGif {
    height: 100%;
    width: 100%;
    /* padding: 25px; */
  }
  .videoGifDiv {
    height: 53%;
    margin-top: 15px;
    margin-left: 10px;
    width: 90%;
  }
  .controlButtons {
    width: 90%;
    top: 80%;
    /* height: 30px; */
    /* background: black; */
    /* padding-bottom: 20px; */
  }
  .playButton {
    width: 25px !important;
    height: 25px !important;
  }
  .exerciseVideoDiv {
    padding: 25px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 70px;
    /* position: absolute; */
    height: 110vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    /* padding-bottom: 25px; */
  }
  .exerciseNameDiv {
    width: 90%;
    top: 12.5%;
    justify-content: center;
    left: 16px;
  }
  /* Exercise Player Css End */

  /* Exercise Player Congratulations Css start */
  .congratulationsAndContinueDiv {
    justify-content: flex-start;
    padding: 20px;
    position: relative;
    height: 100vh;
  }
  .congratulationsDiv {
    margin-top: 5px;
    padding: 20px;
    height: 28%;
    width: 100%;
    justify-content: center;
  }
  .congratulationsDiv > h1 {
    font-size: 15px;
  }
  .congratulationsDiv > p {
    font-size: 11px;
    width: 100%;
  }
  .timeAndCaloriesDiv {
    width: 100%;
    justify-content: space-between;
    margin-top: 0;
  }
  .exerciseNameCongDiv {
    height: 11%;
    width: 100%;
    padding: 20px;
  }
  .exerciseNameCongDiv > p {
    font-size: 12px;
  }
  .CaloriesDiv {
    width: 45%;
  }
  .timeDiv {
    width: 45%;
  }
  .CaloriesDiv > p {
    font-size: 8px;
  }
  .timeDiv > p {
    font-size: 8px;
  }
  .continueButton {
    height: 49px;
    width: 75%;
    margin-top: 2px;
  }

  /* Exercise Player Congratulations Css End */
}

.Settings-container {
  width: 100%;
  background-color: black;
  display: flex;
}

.ttext {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2vh;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-top: -20px;
  text-align: left;
  color: #707070;
  width: 25vw;
}
.flagstyle {
  max-width: 100%;
  width: 30px;
  height: auto;
  justify-self: center;
  align-self: center;
  margin-top: -2px;
  border-radius: 1px;
}
.myDropdown {
  float: right;
  align-self: center;
  justify-self: center;
  display: flex;
}

.inputfeild,
.inputfeild:focus {
  background-color: transparent;
  height: 6vh;
  width: 15vw;
  align-self: center;
  /* justify-self: flex-end; */
  margin-left: 2vw;
  padding: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
  border-left: 1px solid #707070;
  font-size: 1.3vw;
  outline: none !important;
  color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputdivSettings {
  width: 25vw;
  /* height: 5vw; */
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
}

.inputdivSettings > .ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
}

.modal-div-deactive {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 90%;
}

.modal-class .ant-modal-header {
  padding: 16px 24px;
  color: #ffffff;
  /* color: rgba(0, 0, 0, 0.85); */
  background: #171717;
  border-bottom: 0px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.modal-class .ant-modal-title {
  color: #ffffff;
}

.modal-class .ant-modal-footer {
  padding: 10px 16px;
  display: flex;
  text-align: right;
  background: #171717;
  justify-content: space-between;
  border-top: 0px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}

.sider-styles {
  background-color: black;
  color: white;
  margin-top: 50px;
  margin-left: 30px;
  margin-right: -90px;
}

.sider-styles .ant-menu-root {
  box-shadow: none;
  background-color: black;
  color: white;
  font-size: 18px;
  font-weight: 700;
  border: none;
  margin-top: 50px;
  margin-left: 60px;
}
.sider-styles .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: black;
}

.sider-styles .ant-menu-light .ant-menu-item-active,
.ant-menu-item-selected {
  color: #ff1e1e;
}

.sider-styles .ant-menu-item-selected {
  color: #ff1e1e;
}
.content-classname {
  /* border: 1px solid red; */
  width: 100%;
}
.about-you-modal-div {
  display: flex;
  color: white;
  width: 500px;
  border: 1px solid grey;
  margin-left: 300px;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  justify-self: center;
  align-self: center;
}
.goals-modal-div {
  display: flex;
  color: white;
  width: 500px;
  border: 1px solid grey;
  margin-left: 300px;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
@media screen and (max-width: 1600px) {
  .modal-div-deactive {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    width: 85%;
  }
}

@media screen and (max-width: 780px) {
  .inputdivSettings {
    width: 300px;
    height: 54px;
  }
  .myDropdown {
    float: none;
    justify-self: center;
    align-self: center;
  }
  .inputfeild {
    font-size: 14px;
    width: 200px;
  }
}

@media only screen and (max-width: 470px) {
  .sider-styles {
    display: none !important;
  }
  .myDropdown {
    float: none;
  }
  .inputfeild {
    font-size: 14px;
    width: 200px;
  }
  .display-true {
    display: flex !important;
  }
  .content-text-and-content-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ttext {
    width: auto !important;
    text-align: center !important;
    font-size: 18px;
    text-align: center;
  }
  /* style={{
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }} */
  .content-classname {
    width: 100%;
    /* overflow: scroll; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding: 5% !important;
  }
  .about-you-modal-div {
    margin: 0 !important;
    width: 300px !important;
  }
  .dropdown-remove-margin {
    margin-right: 25px !important;
  }
  .goals-modal-div {
    width: 100% !important;
    margin-left: 10px !important;
    /* border: 1px solid red; */
    margin: 0 !important;
  }
  .all-goals-div {
    width: 100% !important;
  }
  .goal-card-text {
    font-size: 10px !important;
  }
  .subscription-plan-text {
    font-size: 12px !important;
  }
  .inputdivSettings {
    width: 80vw;
    height: 54px;
  }
}

.containerLeftMk {
  overflow: hidden;
  width: 60%;
  /* height: 100vh; */
  /* background-image: linear-gradient(
      194.92deg,
      rgba(0, 0, 0, 0) 45.34%,
      #000000 102.5%
    ),
    url("../assets/images/background_optimized.webp"); */
  /* background-size: cover; */
  background-size: auto 100%;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  /* border: 1px solid red; */
}
.titleDivMk {
  flex-direction: row;
  align-items: center;
  width: 25vw;
  padding: 0px;
  margin: 0px;
  /* // border: "1px solid green", */
}
.termsAndCancelDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}
.myDropdownMk {
  float: right;
  align-self: center;
  justify-self: center;
  display: flex;
}
.storeimgMk {
  width: 12.2vw;
  height: 50px;
}
.marketCardMk {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  margin: 0 auto;
  border-radius: 10px;
}
.termsMk {
  margin: 0 auto;
  /* margin-top: 5vh; */
}
.bgClrMk {
  /* background-color: rgba(225, 30, 30, 0.09); */
  text-align: left;
  font-size: 20px;
  /* border-radius: 10px; */
}
.ant-input-affix-wrapper-inputfeildMk {
  outline: none;
}

.dropdown,
.dropdown:hover {
  color: #fff;
  border: 1px solid white;
  border-radius: 5px;
  padding: 3px 8px;
  /* background-color: #ff1e1e; */
  font-weight: 600;
  justify-content: center;
  align-items: center;
}
.menu_item {
  background: #fff;
  color: #000 !important;
  width: 150px;
  border: none;
}
.containerRightMk {
  height: 100vh;
  width: 40%;
  overflow: hidden;
  /* border: 1px solid red; */
  background: linear-gradient(337.1deg, #000000 -1.26%, #4f4f4f 130.31%);

  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 6vw;
  padding-top: 50px;
  padding-right: 3vw;
}
.ant-spin {
  color: #ff1e1e;
}
.titletextMk {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 3.2vh;
  line-height: 39px;
  letter-spacing: -0.02em;
  text-align: center;
}
.welcome_arabicMk {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  text-align: right;
  width: 25vw;
  font-size: 7vh;
  line-height: 59px;
  /* text-align: center; */
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #ffffff;
}

.welcome_arabicIQMk {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  text-align: right;
  width: 25vw;
  font-size: 7vh;
  line-height: 30px;
  /* text-align: center; */
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #ffffff;
}

.welcomeMk {
  /* H1/Bold */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 3.5vh;
  line-height: 59px;
  text-align: center;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #ffffff;
}
.welcomeIQMk {
  /* H1/Bold */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 3.5vh;
  line-height: 30px;
  text-align: center;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #ffffff;
}

.ttextMk {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2vh;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-top: -20px;
  text-align: left;
  color: #707070;
  width: 25vw;
}
.ttext_arabicMk {
  width: 25vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5vh;
  /* line-height: 24px; */
  letter-spacing: 0.02em;
  margin-top: -20px;
  text-align: right;
  font-weight: 600;
  /* color: #707070; */
  color: #fff;
}
.inputdivMk {
  width: 25vw;
  height: 5vw;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 20px;
}
.inputdiv_arabicMk {
  width: 25vw;
  height: 5vw;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 20px;
}

.loading-divMk {
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 20px;
  margin-right: 90px;
}
.flagstyleMk {
  max-width: 100%;
  width: 30px;
  height: auto;
  justify-self: center;
  align-self: center;
  margin-top: -2px;
  border-radius: 1px;
}
.inputfeildMk,
.inputfeildMk:focus {
  background-color: transparent;
  height: 6vh;
  width: 15vw;
  align-self: center;
  /* justify-self: flex-end; */
  margin-left: 2vw;
  padding: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
  border-left: 1px solid #707070;
  font-size: 1.3vw;
  outline: none !important;
  color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.otpinputMk {
  background-color: transparent;
  color: #707070;
  border: 1px solid #707070;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 4.5vw !important;
  height: 4.5vw !important;
}

.indonesiaInputMk {
  background-color: transparent;
  color: #707070;
  border: 1px solid #707070;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 45px !important;
  height: 45px !important;
  font-size: 20px;
  /* height: 4.5vw !important; */
  /* width: 4.5vw !important;
    height: 4.5vw !important; */
}

/* .indonesiaOTPMk {
  border: 1px solid green;
} */
.otpMk {
  width: 25vw;
  /* border: 1px solid green; */
}
.resendMk {
  color: #ff1e1e;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 9vw; */
  /* margin-top: 20px; */
}
.resend_arabicMk {
  color: #ff1e1e;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  /* margin-left: 9vw; */
  /* margin-top: 20px; */
}
.icon-spacingMk {
  margin-right: 50px;
  filter: invert(100%) sepia(0%) saturate(3658%) hue-rotate(356deg)
    brightness(96%) contrast(96%);
}

.subs-otpinputMk {
  background-color: transparent;
  color: #707070;
  border: 1px solid #707070;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 4.5vw !important;
  height: 4.5vw !important;
}
.subs-otpMk {
  width: 25vw;
  /* border: 1px solid green; */
}

.social-iconsMk {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  margin-top: 50px;
  margin-left: -20px;
}

.list-iconsMk {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  margin-top: 50px;
  margin-left: -20px;
}

.margin-top-minus-on-smallMk {
  margin-top: 50px;
  justify-content: center;
  display: flex;
}

.iraq-failure {
  display: none;
  margin-top: 50px;
  justify-content: center;
}

.inputdiv > .ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
}
.inputdiv_arabic > .ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background-color: #222222;
  color: white;
}
.btn-login_arabicMk:hover,
.btn-login_arabicMk:focus,
.btn-login_arabicMk {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 12px;
  border: none;
  color: #fff;
  width: 25vw;
  height: 54px;
  margin-top: 30px;
  font-weight: 600;
  font-size: 24px;
}
.btn-loginMk:hover,
.btn-loginMk:focus,
.btn-loginMk {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 12px;
  border: none;
  color: #fff;
  width: 25vw;
  height: 54px;
  margin-top: 30px;
  font-weight: 600;
}
.termsMk {
  margin: 0 auto;
}
/* @media screen and (max-width: 880px) {
} */
.Login-page-containerMk {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-image: url(/static/media/background_optimized.469cd5b1.webp);
  /* background-size: cover; */
  background-size: auto 100%;
  background-repeat: no-repeat;
  /* background-color: #000000; */
  overflow: hidden;
  /* height: 100vh; */
}
@media screen and (max-width: 330px) {
  .containerLeftMk {
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    /* border-radius: none; */
    width: 100%;
    background-image: none;

    margin-bottom: 100px !important;
    position: relative;
    align-items: center;
    height: 20% !important;
  }
  .Login-page-containerMk {
    display: flex;
    /* align-items: center !important; */
    width: 100% !important;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0px !important;
    overflow: hidden !important;
    background-image: url(/static/media/background_portrait.e19ad465.webp);
    background-size: cover !important;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .titleDivMk {
    flex-direction: row;
    width: 100% !important;
    display: flex;
    justify-content: space-around;
  }
  /* .titletextMk {
    margin-right: 20px !important;
  } */
  .containerRightMk {
    display: flex;
    width: 100% !important;
    justify-content: flex-start !important;
    align-items: center !important;
    height: 20% !important;
    /* border-radius: 10px; */
    /* height: 100%; */
    /* background: #000; */
    padding: 0px !important;
    margin-top: 25px !important;
    opacity: 1;
    background: none;
  }
  .margin-top-minus-on-smallMk {
    display: none;
  }
  .iraq-failure {
    display: flex;
  }

  .otpMk {
    margin-bottom: 20px !important;
    height: 40px !important;
  }
  .titleDivMk {
    margin-top: 20px !important;
  }
  .inputdivMk {
    margin-bottom: 5px !important;
    height: 50vh !important;
    margin-top: 0px !important;
  }
  .inputfeildMk {
    margin: 0px !important;
    padding: 0px !important;
  }
  .btn-loginMk {
    margin: 0px !important;
    height: 50px !important;
  }
  .termsMk {
    justify-self: flex-start;
  }
  .social-iconsMk {
    display: none;
  }
  .list-iconsMk {
    display: flex;
  }
  .margin-top-minus-on-smallMk {
    display: none;
  }

  .iraq-failure {
    display: flex;
  }

  .welcomeMk {
    font-size: 14px !important;
    margin-bottom: 1px !important;
  }

  .welcomeIQMk {
    font-size: 14px !important;
    margin-bottom: 1px !important;
  }
  .ttextMk {
    font-size: 11px !important;
    margin-bottom: 3px !important;
  }
  .marketCardMk {
    width: 80vw !important;
  }
}
@media screen and (max-width: 666px) {
  .containerLeftMk {
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    /* border-radius: none; */
    width: 100%;
    background-image: none;

    margin-bottom: 100px !important;
    position: relative;
    align-items: center;
    height: 40% !important;
  }
  .Login-page-containerMk {
    display: flex;
    /* align-items: center !important; */
    width: 100% !important;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0px !important;
    overflow: hidden !important;
    background-image: url(/static/media/background_portrait.e19ad465.webp);
    background-size: cover !important;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .titleDivMk {
    flex-direction: row;
    width: 100% !important;
    display: flex;
    justify-content: space-around;
  }
  /* .titletextMk {
    margin-right: 20px !important;
  } */
  .containerRightMk {
    display: flex;
    width: 100% !important;
    justify-content: flex-start !important;
    align-items: center !important;
    height: 20% !important;
    /* border-radius: 10px; */
    /* height: 100%; */
    /* background: #000; */
    padding: 0px !important;
    margin-top: 5px !important;
    opacity: 1;
    background: none;
  }
  .margin-top-minus-on-smallMk {
    display: none;
  }

  .iraq-failure {
    display: flex;
  }

  .otpMk {
    margin-bottom: 24px !important;
  }
  .titleDivMk {
    margin-top: 20px !important;
  }
  .inputdivMk {
    margin-bottom: 5px !important;
    height: 50px !important;
    margin-top: 0px !important;
  }
  .btn-loginMk {
    margin: 5px !important;
    height: 50px !important;
  }
  .termsMk {
    justify-self: flex-start;
  }
  .social-iconsMk {
    display: none;
  }
  .list-iconsMk {
    display: none;
  }
  .margin-top-minus-on-smallMk {
    display: none;
  }

  .iraq-failure {
    display: flex;
  }

  .welcomeMk {
    font-size: 3vh;
  }
  .welcomeIQMk {
    font-size: 3vh;
  }
  .ttextMk {
    font-size: 2vh;
  }
}
@media screen and (max-width: 780px) {
  .containerRightMk {
    width: 100%;
  }
  .ttextMk {
    width: 300px;
  }
  .myDropdownMk {
    float: none;
    justify-self: center;
    align-self: center;
  }

  .loading-divMk {
    display: flex;
    align-items: center;
    align-self: center;
    margin-top: 20px;
    /* width: 14%; */
    margin-right: 0px;
    justify-content: center;
  }
  .inputdivMk {
    width: 300px;
    height: 54px;
  }
  .inputdiv_arabicMk {
    width: 300px;
    height: 54px;
  }
  .welcome_arabicMk {
    width: 300px;
    height: 54px;
  }
  .welcome_arabicIQMk {
    width: 300px;
    height: 54px;
  }
  .ttext_arabicMk {
    width: 300px;
    height: 54px;
  }
  .social-iconsMk {
    margin-left: 0px;
    margin-left: 0px;
    width: 90%;
    justify-content: center;
  }
  .list-iconsMk {
    display: flex;
    margin-left: 0px;
    margin-left: 0px;
    width: 90%;
    justify-content: center;
  }
  .icon-spacingMk {
    margin-right: 0px;
  }
  .inputfeildMk {
    font-size: 14px;
    width: 200px;
  }
  .btn-loginMk,
  .btn-loginMk:hover {
    width: 300px;
    height: 54px;
    font-size: 14px;
  }
  .btn-login_arabicMk,
  .btn-login_arabicMk:hover,
  .btn-login_arabicMk:focus {
    width: 280px;
    height: 54px;
    font-size: 14px;
  }
  .storeimgMk {
    width: 150px;
    margin-bottom: 10px;
  }
  .otpMk {
    width: 300px;
    width: 300px;
    height: 54px;
    font-size: 14px;
  }
  .otpinputMk {
    width: 60px !important;
    height: 54px !important;
  }
  .indonesiaInputMk {
    /* padding: 20px; */
    width: 40px !important;
    height: 40px !important;
  }
  .titletextMk {
    font-size: 25px;
  }
}

@media screen and (max-width: 480px) {
  .containerRightMk {
    /* height: 100vh; */
    /* overflow: scroll; */
    width: 100% !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* border-radius: 10px; */
  }
  .Login-page-containerMk {
    height: 100%;
    overflow: hidden;
    background-image: url(/static/media/background_portrait.e19ad465.webp);
    background-size: cover !important;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .twitter-icon-color {
    filter: invert(53%) sepia(0%) saturate(3658%) hue-rotate(356deg)
      brightness(102%) contrast(91%);
  }
  .center-images-on-resizeMk {
    justify-content: center;
    align-items: center;
    place-self: center;
    padding-left: 22vw;
  }
  .myDropdown {
    float: none;
  }
  .ttextMk {
    width: 80vw;
    font-size: 18px;
    text-align: center;
  }
  .inputdivMk {
    width: 80vw;
    height: 54px;
  }
  .inputdiv_arabicMk {
    width: 80vw;
    height: 54px;
  }
  .welcome_arabicMk {
    width: 80vw;
    height: 54px;
  }
  .welcome_arabicIQMk {
    width: 80vw;
    height: 54px;
  }
  .ttext_arabicMk {
    width: 80vw;
    height: 54px;
  }
  .outerContainerMk {
    flex-direction: column-reverse;
  }
  .inputfeildMk {
    font-size: 14px;
    width: 200px;
  }
  .btn-loginMk,
  .btn-loginMk:hover {
    width: 80vw;
    height: 54px;
    font-size: 14px;
  }
  .otpinputMk {
    width: 10vw !important;
    height: 12vw !important;
  }
  .otpMk {
    width: 75vw;
    height: 54px;
    justify-content: center;
  }
  .titletextMk {
    font-size: 25px;
  }
  .marketCardMk {
    width: 80vw !important;
  }
  .termsAndCancelDiv {
    width: 100%;
  }
}

/* * {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
  } */

.main-container-div {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url(/static/media/girlJumping.fd8bf0ff.webp);
  background-size: contain;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.main-right-container-div {
  width: 80%;
  /* padding: 50px; */
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  min-height: 100vh;
  z-index: 0;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: 50% 50%;
}
.main-left-container-div {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  background: #fff;
  align-items: center;
  padding: 30px;
  min-height: 100vh;
  position: relative;
}
.main-left-heading-one {
  color: rgba(239, 53, 17, 1);
}
.main-left-heading-two {
  margin: 40px 0px 40px 0px;
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 92.6%;
}
.main-left-heading-three {
  color: #434343;
  text-align: center;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  width: 75%;
  line-height: normal;
}
.input-div {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  border: 2px solid #fff;
  background: #fff;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
  margin-left: 19px;
}
.inputfield,
.inputfield:focus {
  background-color: transparent;
  width: 100%;
  align-self: center;
  margin-left: 1vw;
  padding: 5px;
  border: none;
  /* border-left: 1px solid #707070; */
  font-size: 20px;
  outline: none !important;
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  color: #6f6f6f;
  font-size: 16px;
}
.continueButton-new {
  color: #fff;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 71%;
  margin-top: 20px;
  background: linear-gradient(63.69deg, #eb3d30 28.36%, #ec6736 80.63%);
  border-radius: 12px;
  padding: 15px;
  margin-left: 14px;
  border: 0;
}
.enter-number-and-otp-text {
  color: #3f3f3f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
.termsAndConditionBtn {
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 7px;
  /* padding: 8px; */
  border-color: #fff;
  margin-top: 40px;
  width: 34%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.resend-text {
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  border: none;
  margin-top: 15px;
  /* margin-left: 9vw; */
  /* margin-top: 20px; */
}
.otpinputDiv {
  background-color: transparent;
  color: #6f6f6f;
  border: 1px solid #6f6f6f;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 4.5vw !important;
  height: 4.5vw !important;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25) !important;
}
.otp-box {
  width: 26vw;
  justify-content: center !important;
  margin-left: 40px !important;
  /* border: 1px solid green; */
}
.package-text {
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 11.9px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 10px;
  /* width: 20%; */
  /* margin-left: 15px; */
}
.error-text {
  color: rgba(239, 53, 17, 1);
  text-align: center;
  font-family: Manrope;
  font-size: 11.9px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.dropdown-ln,
.dropdown-ln:hover {
  color: #000;
  border: 1px solid white;
  border-radius: 5px;
  padding: 2px;
  /* background-color: #ff1e1e; */
  font-weight: 600;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  width: 15%;
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fff;
}
.ant-modal-close {
  color: #0f0f0f;
}
.ant-modal-content {
  border-radius: 20px;
  width: 100%;
}

.crossProductContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 16px;
  border: 1px solid #707070;
  border-radius: 9px;
  cursor: pointer;
}
.crossProductImages {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 30%;
}
.crossProductContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 65%;
}
.productLogo {
  width: 40%;
  height: 40%;
}
.jazzlogo {
  width: 40%;
  height: 40%;
}
.contentTitle {
  font-size: 18px;
  font-weight: 700;
  font-family: "Poppins";
  margin-bottom: 0px;
}
.contentDescription {
  font-size: 14px;
  /* font-weight: 600; */
  font-family: "Poppins";
  text-align: left;
}

@media screen and (max-width: 480px) {
  .main-container-div {
    flex-direction: column;
    position: relative;
    background-image: url(/static/media/girl-dancing.7ef0d9d6.webp);
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 15px;
    /* min-height: 215px; */
    height: 100vh;
    /* padding-bottom: 150px; */
    background-position: 17% 50%;
  }
  .main-left-container-div {
    width: 95%;
    z-index: 1;
    margin-top: 20px;
    /* position: absolute; */
    padding: 0px;
    min-height: 0;
    margin-top: 130px;
    padding: 10px;
  }
  .main-right-container-div {
    position: absolute;
    display: none;
    /* z-index: -1; */
  }
  .main-left-heading-two {
    font-size: 20px;
    margin: 20px 0px 20px 0px;
  }
  .main-left-heading-three {
    font-size: 12px;
  }
  .continueButton-new {
    margin-top: 10px;
    width: 90%;
    margin-left: 0;
  }
  .termsAndConditionBtn {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50%;
  }
  .input-div {
    width: 90%;
    margin-left: 5px;
    padding: 4px;
  }
  .otpinputDiv {
    width: 13.5vw !important;
    height: 13.5vw !important;
  }
  .otp-box {
    margin-left: 20px !important;
  }
  .dropdown-ln,
  .dropdown-ln:hover {
    top: -130px;
    right: 0px;
    width: 20%;
  }
  .crossProductContainer {
    flex-direction: column;
    padding: 8px;
  }
  .crossProductImages {
    width: 65%;
  }
  .crossProductContent {
    width: 100%;
    align-items: center;
  }
  .productLogo {
    width: 50%;
    height: 50%;
  }
  .jazzlogo {
    width: 50%;
    height: 50%;
  }
  .contentTitle {
    font-size: 14px;
  }
  .contentDescription {
    font-size: 12px;
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .main-container-div {
    flex-direction: column;
    position: relative;
    /* background-image: url("../assets/images/girlJumping.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 15px;
    min-height: 215px;
    padding-bottom: 140px;
    background-position: 20% 50%;
  }
  .main-left-container-div {
    width: 100%;
    z-index: 1;
    margin-top: 20px;
    /* position: absolute; */
    padding: 0px;
    min-height: 0;
    margin-top: 50px;
    padding: 10px;
  }
  .main-right-container-div {
    position: absolute;
    display: none;
    /* z-index: -1; */
  }
  .main-left-heading-two {
    font-size: 20px;
    margin: 20px 0px 20px 0px;
  }
  .main-left-heading-three {
    font-size: 12px;
  }
  .continueButton-new {
    margin-top: 10px;
    width: 90%;
    margin-left: 0;
  }
  .termsAndConditionBtn {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50%;
  }
  .input-div {
    width: 90%;
    margin-left: 5px;
    padding: 4px;
  }
  .otpinputDiv {
    width: 13.5vw !important;
    height: 13.5vw !important;
  }
  .otp-box {
    margin-left: 20px !important;
  }
}

.skeleton {
  display: flex;
  width: 95%;
  z-index: 0;
}
.ExerciseSkeleton {
  display: flex;
  width: 95%;
  height: 250px;
}
.skeleton-1 {
  display: flex;
  width: 95%;
  z-index: 0;
  height: 350px;
  border-radius: 15px;
}
.Eximg-and-details-div {
  width: 90%;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  display: flex;
}
.Eximg-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.Exdetails-div {
  flex-direction: column;
  display: flex;
  width: 70%;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
}
.ExerciseVideosContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 15px; */
}
.SingleExerciseDiv {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.ExerciseImageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* // marginTop: "20px", */
  margin-bottom: 20px;
}
.ExerciseDetailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ExCircuitSkeleton {
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (max-width: 1240px) {
  .SingleExerciseDiv {
    width: 25%;
  }
  .ExerciseSkeleton {
    height: 250px;
  }
}

@media only screen and (max-width: 1156px) {
  .SingleExerciseDiv {
    width: 25%;
  }
  .ExerciseSkeleton {
    height: 250px;
  }
}

@media only screen and (max-width: 1024px) {
  .SingleExerciseDiv {
    width: 35%;
  }
  .ExerciseSkeleton {
    height: 250px;
  }
}
@media only screen and (max-width: 840px) {
  .SingleExerciseDiv {
    width: 40%;
  }
  .ExerciseSkeleton {
    height: 250px;
  }
}
@media only screen and (max-width: 767px) {
  .SingleExerciseDiv {
    width: 40%;
  }
  .ExerciseSkeleton {
    height: 200px;
  }
}
@media only screen and (max-width: 666px) {
  .SingleExerciseDiv {
    width: 40%;
  }
  .ExerciseSkeleton {
    height: 200px;
  }
  .Eximg-and-details-div {
    flex-direction: column;
    width: 100%;
  }
  .ExCircuitSkeleton {
    width: 96%;
    margin-top: 20px;
  }
  .Exdetails-div {
    width: 95%;
  }
  .Eximg-div {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .SingleExerciseDiv {
    width: 100%;
  }
  .ExCircuitSkeleton {
    width: 100%;
    margin-top: 20px;
  }
  .ExerciseSkeleton {
    height: 180px;
  }
}
@media only screen and (max-width: 320px) {
  .SingleExerciseDiv {
    width: 100%;
  }
  .ExerciseSkeleton {
    height: 180px;
  }
}

.mainContainerDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}
.calenderAndMealsDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 2 1;
  /* height: 100vh; */
  justify-content: center;
  align-items: center;
  background: #000;
}
.NutritionFavDiv {
  display: flex;
  width: 75%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.calenderDiv {
  display: flex;
  flex-direction: column;
  width: 85%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
  background-color: rgb(22, 22, 22);
  border-radius: 8px;
  padding: 30px;
}
.CaloriesAndDateDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.Calories-Div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
}
.DateDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.weekListDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}
.singleDateDiv {
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
}
.MealsDiv {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
  flex-wrap: wrap;
  margin-left: 80px;
}
.mealCardDiv {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 30px;
}
.titleAndViewMoreDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.dishImageAndContentDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
}
.macroNutrientsDiv {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.nameAndGramDiv {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.nutritionNameText {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.calorieImg {
  width: 3%;
  margin-right: 15px;
}
.horizontalLine {
  display: flex;
  width: 100%;
  border: 2px solid grey;
  margin: 30px 0px;
}
.recommendedMealsText {
  color: rgb(255, 255, 255);
  width: 85%;
  text-align: left;
  padding: 10px;
  margin-top: 20px;
}
.mealImg {
  width: 90%;
  border-radius: 10px;
  cursor: pointer;
}
@media screen and (max-width: 950px) {
  .calenderDiv {
    margin-top: 15px;
    padding: 15px;
    width: 90%;
  }
  .recommendedMealsText {
    font-size: 15px;
  }
  .mealImg {
    width: 100%;
  }
  .horizontalLine {
    margin: 10px 0px;
  }
  .calorieImg {
    width: 5%;
  }
  .DateDiv {
    display: none;
  }
  .weekListDiv {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .singleDateDiv {
    margin-right: 25px;
  }
  .MealsDiv {
    flex-direction: column;
    margin-left: 20px;
  }
  .mealCardDiv {
    width: 95%;
  }
  .dishImageAndContentDiv {
    padding: 10px;
    width: 100%;
  }
  .MealsDiv {
    width: 95%;
  }
  .nutritionNameText {
    width: 100%;
    margin: 2px;
  }
}

@media screen and (max-width: 767px) {
  .calenderDiv {
    margin-top: 15px;
    padding: 15px;
    width: 90%;
  }
  .recommendedMealsText {
    font-size: 15px;
  }
  .mealImg {
    width: 100%;
  }
  .horizontalLine {
    margin: 10px 0px;
  }
  .calorieImg {
    width: 6%;
  }
  .DateDiv {
    display: none;
  }
  .weekListDiv {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .singleDateDiv {
    margin-right: 25px;
  }
  .MealsDiv {
    flex-direction: column;
    margin-left: 20px;
  }
  .mealCardDiv {
    width: 95%;
  }
  .dishImageAndContentDiv {
    padding: 10px;
    width: 100%;
  }
  .MealsDiv {
    width: 95%;
  }
  .nutritionNameText {
    width: 100%;
    margin: 2px;
  }
  .NutritionFavDiv {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 666px) {
  .calenderDiv {
    margin-top: 15px;
    padding: 15px;
    width: 90%;
  }
  .recommendedMealsText {
    font-size: 15px;
  }
  .mealImg {
    width: 100%;
  }
  .horizontalLine {
    margin: 10px 0px;
  }
  .calorieImg {
    width: 10%;
  }
  .DateDiv {
    display: none;
  }
  .weekListDiv {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .singleDateDiv {
    margin-right: 25px;
  }
  .MealsDiv {
    flex-direction: column;
    margin-left: 0px;
  }
  .mealCardDiv {
    width: 95%;
  }
  .dishImageAndContentDiv {
    padding: 10px;
    width: 100%;
  }
  .MealsDiv {
    width: 95%;
  }
  .nutritionNameText {
    width: 100%;
    margin: 2px;
  }
  .NutritionFavDiv {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 480px) {
  .calenderDiv {
    margin-top: 15px;
    padding: 15px;
    width: 90%;
  }
  .recommendedMealsText {
    font-size: 15px;
    width: 90%;
  }
  .mealImg {
    width: 100%;
  }
  .horizontalLine {
    margin: 10px 0px;
  }
  .calorieImg {
    width: 10%;
  }
  .DateDiv {
    display: none;
  }
  .weekListDiv {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .singleDateDiv {
    margin-right: 25px;
  }
  .MealsDiv {
    flex-direction: column;
    margin-left: 0px;
  }
  .mealCardDiv {
    width: 95%;
  }
  .dishImageAndContentDiv {
    padding: 10px;
    width: 100%;
  }
  .MealsDiv {
    width: 92%;
  }
  .nutritionNameText {
    width: 100%;
    margin: 2px;
  }
  .NutritionFavDiv {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 320px) {
  .NutritionFavDiv {
    overflow-x: scroll;
  }
  .calenderDiv {
    margin-top: 15px;
    padding: 15px;
    width: 90%;
  }
  .recommendedMealsText {
    font-size: 15px;
  }
  .mealImg {
    width: 100%;
  }
  .horizontalLine {
    margin: 10px 0px;
  }
  .calorieImg {
    width: 10%;
  }
  .DateDiv {
    display: none;
  }
  .weekListDiv {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .singleDateDiv {
    margin-right: 25px;
  }
  .MealsDiv {
    flex-direction: column;
    margin-left: 0px;
  }
  .mealCardDiv {
    width: 95%;
  }
  .dishImageAndContentDiv {
    padding: 10px;
    width: 100%;
  }
  .MealsDiv {
    width: 95%;
  }
  .nutritionNameText {
    width: 100%;
    margin: 2px;
  }
}

p {
  font-family: "Inter var", sans-serif;
}
label {
  font-family: "SF Pro Display", "Montserrat", sans-serif;
}
h1 {
  font-family: "Inter var", sans-serif;
}
.AboutYouContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  /* justify-content: center;
    align-items: center; */
}
.leftImageContainer {
  background-image: linear-gradient(
      180deg,
      rgba(49, 49, 49, 0.38) 0%,
      #0c0c0c 94.09%
    ),
    url("https://fitflexapp.com/static/media/sigma.25212cf6.png");
  width: 60%;
  background-size: auto 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.RightDiv {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* background: linear-gradient(180deg, #1e1e1e 0%, #0c0c0c 94.09%); */
  background: #1e1e1e;
  padding: 20px;
}
.RightDivHeadings {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.RightDivTitle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 10px;
}
.RightDivTitleText {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0;
}
.RightDivSc {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 7px;
}
.RightDivScText {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #878787;
}
.InputsDiv {
  flex-direction: column;
  display: flex;
  width: 95%;
  margin-left: 20px;
}
.imgAndInputDiv {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.titleAndInputDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  margin-left: 5px;
}
.nameDiv {
  background: #0e0e0e;
  border-radius: 12px;
  width: 90%;
  border: 0;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nameInput {
  background: #161616;
  border-radius: 12px;
  width: 100%;
  border: 0;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  color: #fff;
  /* margin-top: 30px; */
  /* pointer-events: none; */
}
.birthDiv {
  background: #0e0e0e;
  border-radius: 12px;
  width: 44%;
  border: 0;
  padding: 11.5px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
input.ant-input {
  /* background: #161616; */
  /* font-size: 14px; */
  /* padding: 16px 12px 4px 11px; */
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #878787;
  opacity: 1; /* Firefox */
  font-size: 16px;
  /* font-family: "Poppins"; */
  font-weight: 500;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #878787;
  font-size: 16px;
  font-weight: 500;
  /* font-family: "Poppins"; */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #878787;
  font-size: 16px;
  font-weight: 500;
  /* font-family: "Poppins"; */
}
.genderAndBirthDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.genderInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #0e0e0e;
  border-radius: 12px;
  width: 44%;
  padding: 10px;
  padding-right: 25px;
  margin-right: 10px;
}
.birthInput {
  border: 0;
  background: rgb(22, 22, 22);
  border-radius: 8px;
  padding: 6px 8px;
  width: 95%;
  font-family: "Inter";
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  /* margin-left: 4px; */
}
.ant-picker-input > Input {
  font-size: 14px;
}
.heightInput {
  background: #0e0e0e;
  border-radius: 12px;
  width: 90%;
  border: 0;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.weightInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #0e0e0e;
  border-radius: 12px;
  width: 44%;
  padding: 8px;
  padding-right: 10px;
  margin-right: 10px;
}
.heightSlider {
  display: flex;
  width: 15%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: height 0.3s ease-in-out;
}
.physicalActivityDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  background: #0e0e0e;
  border-radius: 12px;
  margin-left: 20px;
  padding: 20px;
  margin-top: 20px;
}
.mealActivityDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  background: #0e0e0e;
  border-radius: 12px;
  margin-left: 20px;
  padding: 20px;
}
.GoalTitle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 10px;
}
.GoalTitleText {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #878787;
  margin-bottom: 4px;
}
.GoalDiv {
  width: 92%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.GoalCard {
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0e0e0e;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: #fff;
  padding-top: 30px;
  padding-right: 35px;
  padding-left: 35px;
  padding-bottom: 30px;
  /* padding-bottom: 35px; */
  margin-right: 10px;
}
.GoalCardText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
}
.continueButton {
  width: 85%;
  margin-left: 20px;
  background: linear-gradient(63.69deg, #eb3d30 28.36%, #ec6736 80.63%);
  border-radius: 12px;
  border: 0;
  color: #fff;
  height: 48px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}

/* Float label Css Start */
.floating-label-input {
  position: relative;
  /* display: flex; */
}

.floating-label-input label {
  position: absolute;
  top: 10px;
  left: 35px;
  font-size: 16px;
  padding: 0 5px;
  transition: all 0.2s;
  color: #fff;
  display: block;
  z-index: 2;
}
.floating-label-input:hover label {
  /* apply the :hover selector to the parent container */
  transform: translateY(-12px);
  font-size: 12px;
  color: #fff;
  z-index: 2;
}

.floating-label-input input:focus + label,
.floating-label-input select:focus + label,
.floating-label-input .ant-picker-input:focus + label,
.floating {
  top: 0;
  font-size: 12px;
  color: #fff;
  z-index: 1;
}

/* .floating-label-input .floating {
    top: -20px;
    font-size: 12px;
    color: #fff;
    z-index: 1;
  } */
.floating-label-input .floating-x {
  position: absolute;
  top: 30px;
  left: 40px;
  font-size: 16px;
  padding: 0 5px;
  transition: all 0.2s;
  color: #fff;
  display: block;
  z-index: 2;
}
/* Float Label Css End */

@media screen and (max-width: 840px) {
  .leftImageContainer {
    display: none;
  }
  .RightDiv {
    width: 100%;
    padding: 30px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .InputsDiv {
    margin-left: 10px;
    margin-top: 60px;
    /* justify-content: center;
      align-items: center; */
  }
  .nameInput {
    width: 100%;
  }
  .heightInput {
    width: 100%;
    padding: 20px;
  }
  .weightInput {
    width: 50%;
    margin-right: 2px;
    padding: 20px;
  }
  .GoalDiv {
    width: 100%;
  }
  .continueButton {
    width: 94%;
    margin-left: 10px;
    margin-top: 120px;
  }
  .genderInput {
    width: 50%;
    margin-bottom: 0px;
  }
  .birthDiv {
    width: 47%;
  }
  .nameDiv {
    width: 100%;
    padding: 20px;
  }
  .genderDiv {
    padding: 20px;
  }
  .birthDiv {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .leftImageContainer {
    display: none;
  }
  .RightDiv {
    width: 100%;
    padding: 30px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .InputsDiv {
    margin-left: 10px;
    margin-top: 30px;
    /* justify-content: center;
      align-items: center; */
  }
  .nameInput {
    width: 100%;
  }
  .heightInput {
    width: 100%;
    padding: 20px;
  }
  .weightInput {
    width: 50%;
    margin-right: 2px;
    padding: 20px;
  }
  .GoalDiv {
    width: 100%;
  }
  .continueButton {
    width: 94%;
    margin-left: 10px;
    margin-top: 120px;
  }
  .genderInput {
    width: 50%;
    margin-bottom: 0px;
  }
  .birthDiv {
    width: 47%;
  }
  .nameDiv {
    width: 100%;
    padding: 20px;
  }
  .genderDiv {
    padding: 20px;
  }
  .birthDiv {
    padding: 20px;
  }
  .mealActivityDiv {
    margin-left: 0;
    width: 100%;
  }
  .physicalActivityDiv {
    margin-left: 0px;
    width: 100%;
  }
  .GoalDiv {
    margin-left: 10px;
    padding-bottom: 0px;
  }
  .GoalTitle {
    padding-left: 0px;
  }
  .GoalTitleText {
    font-size: 18px;
  }
  .GoalCard {
    width: 25%;
    height: 25vh;
  }
}

@media screen and (max-width: 666px) {
  .leftImageContainer {
    display: none;
  }
  .RightDiv {
    width: 100%;
    padding: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .InputsDiv {
    margin-left: 10px;
    /* justify-content: center;
      align-items: center; */
  }
  .nameInput {
    width: 100%;
  }
  .heightInput {
    width: 100%;
  }
  .weightInput {
    width: 50%;
    margin-right: 2px;
  }
  .GoalDiv {
    width: 100%;
  }
  .continueButton {
    width: 94%;
    margin-left: 10px;
    margin-top: 50px;
  }
  .genderInput {
    width: 50%;
    margin-bottom: 0px;
  }
  .birthDiv {
    width: 47%;
  }
  .nameDiv {
    width: 100%;
  }
  .mealActivityDiv {
    margin-left: 0;
    width: 100%;
  }
  .physicalActivityDiv {
    margin-left: 0px;
    width: 100%;
  }
  .GoalDiv {
    margin-left: 10px;
    padding-bottom: 0px;
  }
  .GoalTitle {
    padding-left: 0px;
  }
  .GoalTitleText {
    font-size: 18px;
  }
  .GoalCard {
    width: 32%;
  }
}

@media screen and (max-width: 480px) {
  .leftImageContainer {
    display: none;
  }
  .RightDiv {
    width: 100%;
    padding: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .InputsDiv {
    margin-left: 10px;
    margin-top: 0px;
    /* justify-content: center;
      align-items: center; */
  }
  .nameInput {
    width: 100%;
  }
  .heightInput {
    width: 100%;
    padding: 14px;
    margin-top: 0px;
  }
  .weightInput {
    width: 50%;
    margin-right: 2px;
  }
  .GoalDiv {
    width: 100%;
  }
  .continueButton {
    width: 94%;
    margin-left: 10px;
    margin-top: 12px;
  }
  .genderInput {
    width: 50%;
    margin-bottom: 0px;
  }
  .birthDiv {
    width: 47%;
  }
  .nameDiv {
    width: 100%;
    padding: 12px;
    margin-top: 0px;
  }
  /* .genderDiv {
    padding: 10px;
  } */
  .birthDiv {
    padding: 11.5px;
    margin-top: 10px;
  }
  .genderInput {
    margin-top: 0px;
  }
  .mealActivityDiv {
    margin-left: 0;
    width: 100%;
  }
  .physicalActivityDiv {
    margin-left: 0px;
    width: 100%;
  }
  .GoalDiv {
    margin-left: 10px;
    padding-bottom: 10px;
  }
  .GoalTitle {
    padding-left: 0px;
  }
  .GoalTitleText {
    font-size: 18px;
  }
  .GoalCard {
    width: 32%;
  }
}
@media screen and (max-width: 320px) {
  .leftImageContainer {
    display: none;
  }
  .RightDiv {
    width: 100%;
    padding: 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .InputsDiv {
    margin-left: 10px;
    /* justify-content: center;
      align-items: center; */
  }
  .nameInput {
    width: 100%;
  }
  .heightInput {
    width: 100%;
  }
  .weightInput {
    width: 50%;
    margin-right: 2px;
  }
  .GoalDiv {
    width: 100%;
  }
  .continueButton {
    width: 94%;
    margin-left: 10px;
    margin-top: 50px;
  }
  .genderInput {
    width: 50%;
    margin-bottom: 0px;
  }
  .birthDiv {
    width: 45%;
  }
  .nameDiv {
    width: 100%;
  }
}

.Cs-Container-Div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #000;
  min-height: 100vh;
  overflow: hidden;
}
.skeletonBtn {
  width: 50%;
}
.fitflexLogoImage {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg)
    brightness(200%) contrast(103%);
}
.Left-Cs-Div {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Left-Cs-Text-Div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;
}
.Cs-text {
  color: #fff;
  text-align: left;
  margin-bottom: 2px;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
}
.Cs-Trial-Btn {
  border-radius: 20px;
  color: #ff5858;
  font-weight: 600;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
}
.Cs-Trial-Btn :hover {
  color: #ff5858;
}
.Right-Cs-Div {
  display: flex;
  width: 40%;
  align-items: flex-start;
  justify-content: flex-start;
}

@media screen and (max-width: 840px) {
  .Left-Cs-Div {
    width: 45%;
  }
  .skeletonBtn {
    width: 55%;
  }
}

@media screen and (max-width: 768px) {
  .Left-Cs-Div {
    width: 40%;
  }
}

@media screen and (max-width: 666px) {
  .Cs-Container-Div {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15px;
  }
  .Left-Cs-Div {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .Left-Cs-Text-Div {
    align-items: center;
  }
  .Right-Cs-Div {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .Cs-Container-Div {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15px;
  }
  .Left-Cs-Div {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .Left-Cs-Text-Div {
    align-items: center;
  }
  .Right-Cs-Div {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 320px) {
  .Cs-Container-Div {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 15px;
  }
  .Left-Cs-Div {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .Left-Cs-Text-Div {
    align-items: center;
  }
  .Right-Cs-Div {
    width: 100%;
    justify-content: center;
  }
}

.MainContainer {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.LandingPageContainer {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 60%;
}
.heading-one-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 116%;
  color: #db3832;
  text-transform: uppercase;
  margin: 10px 0px;
}
.heading-two-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 116%;
  text-transform: uppercase;
  margin: 10px 0px;
}
.trial-one-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 116%;
  /* text-align: center; */
  color: #1e1e1e;
  text-transform: uppercase;
  margin: 2px 0px;
}
.trial-two-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 116%;
  text-align: center;
  color: #ffffff;
  background: #ff1e1e;
  border-radius: 22px;
  padding: 5px;
  /* width: 17%; */
  padding: 5px 10px 5px 10px;
  margin: 2px 0px;
  text-transform: uppercase;
}
.subscribeUserContainer {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 40%;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 40px;
  margin: 20px 0px 0px 0px;
}
.subscribe-one-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 116%;
  color: #db3832;
}
.subscribe-input-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 116%;
  color: #878787;
}
.subscribe-input {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #878787;
  border-bottom: 1px solid #878787;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 60%;
}
.subscribe-otp-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 116%;
  margin-bottom: 0;
  margin-top: 20px;

  color: #878787;
}
.subscribe-otp-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  width: 55%;
  margin-top: 15px;
  color: #1e1e1e;
}
.subscribe-otp-input {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  border-bottom: 1px solid #878787;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  color: #000000 !important;
  width: 20%;
}
.checkbox {
  flex: 1 1;
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  align-items: center;
  width: 100%;
}
.checkbox-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #878787;
}
.subscribe-contine-button {
  background: #db3832;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  width: 100%;
  border: 0px;
  margin: 20px 0px 0px 0px;
  color: #ffffff;
}
.final-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 116%;
  letter-spacing: 0.01em;
  width: 50%;
  text-align: center;
  color: #878787;
  margin-top: 15px;
}
.modal {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  /* height: 100%; */
  /* overflow: auto; */
  /* background-color: rgba(0, 0, 0, 0.4); */
}
#modal-btn {
  background: #fff;
  font-family: "Poppins";
  border: 0;
  font-size: 12px;
  line-height: 16px;
  display: inline;
  color: #db3832;
  cursor: pointer;
}
.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 20%;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  border: 0;
}
.modal-Heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 116%;
  /* or 21px */

  color: #db3832;
}
.modal-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */

  color: #878787;
}
@media screen and (min-width: 1100px) {
  .MainContainer {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .LandingPageContainer {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 60%;
  }
  .heading-one-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 116%;
    color: #db3832;
    text-transform: uppercase;
    margin: 10px 0px;
  }
  .heading-two-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    text-transform: uppercase;
    margin: 10px 0px;
  }
  .trial-one-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 116%;
    /* text-align: center; */
    color: #1e1e1e;
    text-transform: uppercase;
    margin: 2px 0px;
  }
  .trial-two-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 116%;
    text-align: center;
    color: #ffffff;
    background: #ff1e1e;
    border-radius: 22px;
    padding: 5px;
    /* width: 17%; */
    padding: 5px 10px 5px 10px;
    margin: 2px 0px;
    text-transform: uppercase;
  }
  .subscribeUserContainer {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 40%;
    background: #ffffff;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 40px;
    margin: 20px 0px 0px 0px;
  }
  .subscribe-one-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 116%;
    color: #db3832;
  }
  .subscribe-input-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 116%;
    color: #878787;
  }
  .subscribe-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: #878787;
    border-bottom: 1px solid #878787;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    width: 65%;
  }
  .subscribe-otp-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 116%;
    margin-bottom: 0;
    margin-top: 20px;

    color: #878787;
  }
  .subscribe-otp-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
    width: 60%;
    margin-top: 15px;
  }
  .subscribe-otp-input {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    border-bottom: 1px solid #878787;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    color: #1e1e1e;
    width: 20%;
  }
  .checkbox {
    flex: 1 1;
    margin: 20px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
    width: 100%;
  }
  .checkbox-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #878787;
  }
  .subscribe-contine-button {
    background: #db3832;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    width: 100%;
    border: 0px;
    margin: 20px 0px 0px 0px;
    color: #ffffff;
  }
  .final-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 116%;
    letter-spacing: 0.01em;
    width: 50%;
    text-align: center;
    color: #878787;
  }
  .modal-content {
    width: 20%;
    text-align: left;
  }
  /* .modal {
    top: 110px;
  } */
}
@media screen and (max-width: 950px) {
  .MainContainer {
    width: 100%;
    /* padding:20px; */
  }
  .LandingPageContainer {
    width: 60%;
    padding: 20px;
  }
  .heading-one-text {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-two-text {
    font-size: 24px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 85%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 17px;
  }
  .subscribe-input {
    width: 65%;
  }
  .subscribe-otp-div {
    width: 65%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    margin-bottom: 20px;
  }
  .subscribe-otp-text {
    font-size: 14px;
  }
  .subscribe-input-text {
    font-size: 14px;
  }
  .modal-content {
    width: 40%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}
@media screen and (max-width: 840px) {
  .MainContainer {
    width: 100%;
    /* padding:20px; */
  }
  .LandingPageContainer {
    width: 60%;
    padding: 20px;
  }
  .heading-one-text {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-two-text {
    font-size: 24px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 85%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 17px;
  }
  .subscribe-input {
    width: 65%;
  }
  .subscribe-otp-div {
    width: 65%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    margin-bottom: 20px;
  }
  .subscribe-otp-text {
    font-size: 14px;
  }
  .subscribe-input-text {
    font-size: 14px;
  }
  .modal-content {
    width: 40%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}
@media screen and (max-width: 768px) {
  .MainContainer {
    width: 100%;
    /* padding:20px; */
  }
  .LandingPageContainer {
    width: 60%;
    padding: 20px;
  }
  .heading-one-text {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-two-text {
    font-size: 24px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 85%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 17px;
  }
  .subscribe-input {
    width: 65%;
  }
  .subscribe-otp-div {
    width: 65%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    margin-bottom: 20px;
  }
  .subscribe-otp-text {
    font-size: 14px;
  }
  .subscribe-input-text {
    font-size: 14px;
  }
  .modal-content {
    width: 40%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}
@media screen and (max-width: 666px) {
  .MainContainer {
    width: 100%;
    /* padding:20px; */
  }
  .LandingPageContainer {
    width: 100%;
    padding: 20px;
  }
  .heading-one-text {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-two-text {
    font-size: 24px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 85%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 17px;
  }
  .subscribe-input {
    width: 85%;
  }
  .subscribe-otp-div {
    width: 80%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 17px;
  }
  .subscribe-otp-text {
    font-size: 14px;
  }
  .subscribe-input-text {
    font-size: 14px;
  }
  .final-text {
    width: 100%;
  }
  .modal-content {
    width: 80%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}

@media screen and (max-width: 480px) {
  .MainContainer {
    width: 100%;
    /* padding:20px; */
  }
  .LandingPageContainer {
    width: 100%;
    padding: 20px;
  }
  .heading-one-text {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-two-text {
    font-size: 24px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 95%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 17px;
  }
  .subscribe-input {
    width: 85%;
  }
  .subscribe-otp-div {
    width: 80%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 17px;
  }
  .subscribe-otp-text {
    font-size: 14px;
  }
  .subscribe-input-text {
    font-size: 14px;
  }
  .modal-content {
    width: 80%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}

@media screen and (max-width: 320px) {
  .MainContainer {
    width: 100%;
  }
  .LandingPageContainer {
    width: 100%;
  }
  .heading-one-text {
    font-size: 20px;
  }
  .heading-two-text {
    font-size: 20px;
  }
  /* .trial-two-text{
          width: 48%;
      } */
  .subscribeUserContainer {
    width: 85%;
    padding: 20px;
  }
  .subscribe-one-text {
    font-size: 15px;
  }
  .subscribe-input {
    width: 85%;
  }
  .subscribe-otp-div {
    width: 80%;
  }
  .checkbox {
    width: 100%;
  }
  .subscribe-contine-button {
    padding: 10px;
    width: 100%;
    font-size: 15px;
  }
  .modal-content {
    width: 80%;
    text-align: left;
  }
  .modal {
    top: 110px;
  }
}

.Login-page-containerSA {
  display: flex;
  /* align-items: center !important; */
  width: 100% !important;
  /* flex-direction: column-reverse; */
  justify-content: center;
  padding: 0px !important;
  overflow: hidden !important;
  background-image: url(/static/media/background_optimized.469cd5b1.webp);
  /* background-size: cover; */
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.containerLeftSA {
  overflow: hidden;
  width: 60%;
  /* height: 100vh; */
  /* background-image: linear-gradient(
      194.92deg,
      rgba(0, 0, 0, 0) 45.34%,
      #000000 102.5%
    ),
    url("../assets/images/background_optimized.webp"); */
  /* background-size: cover; */
  background-size: auto 100%;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  /* border: 1px solid red; */
}
.containerRightSA {
  height: 100vh;
  width: 40%;
  overflow: hidden;
  /* border: 1px solid red; */
  background: linear-gradient(337.1deg, #000000 -1.26%, #4f4f4f 130.31%);
  border-radius: 40px 0px 0px 40px;

  /* align-items: flex-start;
  justify-content: flex-start; */
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 6vw;
  padding-top: 50px;
  padding-right: 3vw;
}
.foreground-div {
  /* position: absolute;
  transform: rotate(0deg);
  transform: translate(49%, -20%) rotate(41deg);
  width: 200px;
  height: 75%;
  right: 0px;
  top: 0px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  ); */

  transform: translate(60%, -71%) rotate(-50deg);
  width: 100%;
  height: 100%;
  right: 0px;
  top: 0px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
}

.redirect-button {
  border: 0px;
  width: 70%;
  margin-top: 20px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 12px;
}

.title-label {
  text-align: left;
}

.mysvg {
  width: 15%;
  height: 15%;
  margin-bottom: 30px;
}

.welcome-text-sa {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 41px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
}

.secondary-text-sa {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
}

.icon-check {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 880px) {
  .welcome-text-sa {
    font-size: 18px;
  }
  .secondary-text-sa {
    font-size: 13px;
  }
  .icon-check {
    width: 20px;
    height: 20px;
  }
  .foreground-div {
    transform: translate(79%, -71%) rotate(-55deg);
  }
  .mysvg {
    width: 20%;
    height: 20%;
  }
  .title-label {
    text-align: center;
  }
}

@media screen and (max-width: 330px) {
  .Login-page-containerSA {
    display: flex;
    /* align-items: center !important; */
    height: 100vh !important;
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    padding: 0px !important;
    overflow: hidden !important;
    background-image: url(/static/media/portrait_background.53eccc1c.webp);
    background-size: cover !important;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .title-label {
    text-align: center;
  }
  .foreground-div {
    transform: translate(79%, -71%) rotate(-55deg);
  }
  .mysvg {
    width: 20%;
    height: 20%;
  }
  .containerLeftSA {
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    /* border-radius: none; */
    width: 100%;
    background-image: none;

    margin-bottom: 100px !important;
    position: relative;
    align-items: center;
    /* height: 20% !important; */
  }
  .containerRightSA {
    display: flex;
    width: 100% !important;
    /* justify-content: flex-start !important; */
    align-items: center !important;
    border-radius: 0px;
    height: 100% !important;
    /* border-radius: 10px; */
    /* height: 100%; */
    /* background: #000; */
    padding: 0px !important;
    margin-top: 25px !important;
    opacity: 1;
    background: none;
  }
}

@media screen and (max-width: 666px) {
  .Login-page-containerSA {
    display: flex;
    /* align-items: center !important; */
    height: 100vh !important;
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    padding: 0px !important;
    overflow: hidden !important;
    background-image: url(/static/media/portrait_background.53eccc1c.webp);
    background-size: cover !important;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .title-label {
    text-align: center;
  }
  .welcome-text-sa {
    font-size: 18px;
  }
  .foreground-div {
    transform: translate(79%, -71%) rotate(-55deg);
  }
  .secondary-text-sa {
    font-size: 13px;
  }
  .mysvg {
    width: 20%;
    height: 20%;
  }
  .icon-check {
    width: 20px;
    height: 20px;
  }
  .containerLeftSA {
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    /* border-radius: none; */
    width: 100%;
    background-image: none;

    margin-bottom: 100px !important;
    position: relative;
    align-items: center;
    /* height: 40% !important; */
  }
  .containerRightSA {
    display: flex;
    width: 100% !important;
    /* justify-content: flex-start !important; */
    align-items: center !important;
    height: 100% !important;
    border-radius: 0px;
    /* border-radius: 10px; */
    /* height: 100%; */
    /* background: #000; */
    padding: 0px !important;
    margin-top: 5px !important;
    opacity: 1;
    background: none;
  }
}

@media screen and (max-width: 480px) {
  .Login-page-containerSA {
    display: flex;
    /* align-items: center !important; */
    height: 100vh !important;
    width: 100% !important;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 0px !important;
    overflow: hidden !important;
    background-image: url(/static/media/portrait_background.53eccc1c.webp);
    background-size: cover !important;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .title-label {
    text-align: center;
  }
  .mysvg {
    width: 20%;
    height: 20%;
  }
  .foreground-div {
    transform: translate(79%, -71%) rotate(-55deg);
  }
  .containerRightSA {
    /* height: 100vh; */
    /* overflow: scroll; */
    width: 100% !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
    border-radius: 0px;
    /* border-radius: 10px; */
  }
}

@media screen and (max-width: 780px) {
  .containerRightSA {
    width: 100%;
  }
}

.main-container-ar-div {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url(/static/media/manJumping.655d047f.webp);
  background-size: contain;
  background-position: -4% 50%;
  background-repeat: no-repeat;
}

.main-right-container-ar-div {
  width: 80%;
  /* padding: 50px; */
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  min-height: 100vh;
  z-index: 0;
  background-repeat: no-repeat;
  object-fit: cover;
  /* background-position: 50% 50%; */
}
.main-left-container-ar-div {
  width: 52%;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  background: #fff;
  align-items: center;
  padding: 60px;
  min-height: 100vh;
  position: relative;
}
.main-left-heading-ar-one {
  color: rgba(239, 53, 17, 1);
}
.main-left-heading-ar-two {
  margin: 40px 0px 40px 0px;
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 35px;
  font-style: normal;
  font-weight: 800;
  line-height: 92.6%;
}
.main-left-heading-ar-three {
  color: #434343;
  text-align: center;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  width: 75%;
  line-height: normal;
}
.input-ar-div {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  border: 2px solid #fff;
  background: #fff;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
  margin-left: 19px;
}
.inputfield-ar,
.inputfield-ar:focus {
  background-color: transparent;
  width: 100%;
  align-self: center;
  margin-left: 1vw;
  padding: 5px;
  border: none;
  /* border-left: 1px solid #707070; */
  font-size: 20px;
  outline: none !important;
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  color: #6f6f6f;
  font-size: 16px;
}
.continueButton-ar-new {
  color: #fff;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 71%;
  margin-top: 20px;
  background: linear-gradient(63.69deg, #eb3d30 28.36%, #ec6736 80.63%);
  border-radius: 12px;
  padding: 15px;
  margin-left: 14px;
  border: 0;
}
.enter-number-and-otp-ar-text {
  color: #3f3f3f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
.termsAndConditionBtn-ar {
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 7px;
  /* padding: 8px; */
  border-color: #fff;
  margin-top: 40px;
  width: 33%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.resend-ar-text {
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  border: none;
  margin-top: 15px;
  /* margin-left: 9vw; */
  /* margin-top: 20px; */
}
.otpinputDiv-ar {
  background-color: transparent;
  color: #6f6f6f;
  border: 1px solid #6f6f6f;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 4.5vw !important;
  height: 4.5vw !important;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25) !important;
}
.otp-ar-box {
  width: 26vw;
  justify-content: center !important;
  margin-left: 40px !important;
  /* border: 1px solid green; */
}
.package-ar-text {
  color: #6f6f6f;
  text-align: center;
  font-family: Manrope;
  font-size: 11.9px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 10px;
  /* width: 20%; */
  /* margin-left: 15px; */
}
.error-ar-text {
  color: rgba(239, 53, 17, 1);
  text-align: center;
  font-family: Manrope;
  font-size: 11.9px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.dropdown-ar-ln,
.dropdown-ar-ln:hover {
  color: #000;
  border: 1px solid white;
  border-radius: 5px;
  padding: 2px;
  /* background-color: #ff1e1e; */
  font-weight: 600;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  width: 15%;
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fff;
}
.ant-modal-close {
  color: #0f0f0f;
}
.ant-modal-content {
  border-radius: 20px;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .main-container-ar-div {
    background-position: -27% 50%;
  }
}

@media screen and (max-width: 480px) {
  .main-container-ar-div {
    flex-direction: column;
    position: relative;
    background-image: url(/static/media/girl-dancing.7ef0d9d6.webp);
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 15px;
    min-height: 215px;
    padding-bottom: 215px;
    background-position: 20% 50%;
  }
  .main-left-container-ar-div {
    width: 95%;
    z-index: 1;
    margin-top: 20px;
    /* position: absolute; */
    padding: 0px;
    min-height: 0;
    margin-top: 130px;
    padding: 10px;
  }
  .main-right-container-ar-div {
    position: absolute;
    display: none;
    /* z-index: -1; */
  }
  .main-left-heading-ar-two {
    font-size: 20px;
    margin: 20px 0px 20px 0px;
  }
  .main-left-heading-ar-three {
    font-size: 12px;
  }
  .continueButton-ar-new {
    margin-top: 10px;
    width: 90%;
    margin-left: 0;
  }
  .termsAndConditionBtn-ar {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 45%;
  }
  .input-ar-div {
    width: 90%;
    margin-left: 5px;
    padding: 4px;
  }
  .otpinputDiv-ar {
    width: 12.5vw !important;
    height: 12.5vw !important;
  }
  .otp-ar-box {
    margin-left: 5px !important;
  }
  .dropdown-ar-ln,
  .dropdown-ar-ln:hover {
    top: -130px;
    right: 0px;
    width: 20%;
  }
}
@media screen and (max-width: 320px) {
  .main-container-ar-div {
    flex-direction: column;
    position: relative;
    /* background-image: url("../assets/images/girlJumping."); */
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    padding: 15px;
    min-height: 215px;
    padding-bottom: 140px;
    background-position: 20% 50%;
  }
  .main-left-container-ar-div {
    width: 100%;
    z-index: 1;
    margin-top: 20px;
    /* position: absolute; */
    padding: 0px;
    min-height: 0;
    margin-top: 50px;
    padding: 10px;
  }
  .main-right-container-ar-div {
    position: absolute;
    display: none;
    /* z-index: -1; */
  }
  .main-left-heading-ar-two {
    font-size: 20px;
    margin: 20px 0px 20px 0px;
  }
  .main-left-heading-ar-three {
    font-size: 12px;
  }
  .continueButton-ar-new {
    margin-top: 10px;
    width: 90%;
    margin-left: 0;
  }
  .termsAndConditionBtn-ar {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 45%;
  }
  .input-ar-div {
    width: 90%;
    margin-left: 5px;
    padding: 4px;
  }
  .otpinputDiv-ar {
    width: 12.5vw !important;
    height: 12.5vw !important;
  }
  .otp-ar-box {
    margin-left: 20px !important;
  }
}

.main-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.confirmation-box {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: start;
  align-items: center;
}
.main-text {
  color: #000;
  font-size: 40px;
  font-family: "Poppins";
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: center;
  font-weight: 900;
}
.secondary-text {
  color: #000;
  font-size: 20px;
  font-family: "Poppins";
  text-align: center;
  margin-top: 0px;
  font-weight: 500;
}
.back-button {
  background: linear-gradient(119.19deg, #ef3511 11.69%, #ff7253 73.27%);
  width: 30%;
  padding: 10px;
  color: #fff;
  border: none;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .back-button {
    width: 50%;
  }
  .secondary-text {
    width: 100%;
  }
  .confirmation-box {
    width: 100%;
  }
}

.startGamediv {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-image: url(/static/media/startGame.acc37185.png);
  background-size: 100% 100%;
  background-position: 75%;
  background-repeat: no-repeat;
  /* object-fit: cover; */
}
.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-image: url(/static/media/mainBg.d12c8983.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.selectLevelImg {
  width: 30%;
  margin-top: 20px;
}
.levels-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
}
.level-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  background-image: url(/static/media/level-img.9ba254ad.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20%;
  height: 15vh;
  margin-top: 30px;
  color: #737373;
  font-size: 40px;
  font-family: "Itim", cursive;
}
.questionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  background: #fff;
  height: 80vh;
  border-radius: 10px;
  padding-bottom: 50px;
  position: relative;
  margin-top: 60px;
}
.question-text {
  color: #515151;
  font-size: 30px;
  margin: 10px;
  font-family: "Itim", cursive;
  z-index: 1;
  /* margin-top: 140px; */
}

.multipleChoiceImg {
  position: absolute;
  top: 0;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  width: 15%;
  margin-bottom: 100px;
}
.answer-container {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.answer-button {
  width: 40%;
  color: #515151;
  font-family: "Itim", cursive;
  font-size: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px 2px;
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;
}
.answer-button:hover {
  background: #fd866d;
  color: #fff;
}

.congrats-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  background: #fff;
  /* height: 50vh; */
  border-radius: 10px;
  opacity: 0.8;
  color: #515151;
  position: relative;
}
.congrats-primary-text {
  font-size: 25px;
  font-family: "Itim", cursive;
  margin: 5px;
}
.congrats-secondary-text {
  font-size: 35px;
  font-family: "Itim", cursive;
  margin: 0px;
}
.congrats-last-text {
  font-size: 25px;
  font-family: "Itim", cursive;
  margin: 2px;
}

.button-container {
  width: 20%;
  color: #515151;
  font-family: "Itim", cursive;
  font-size: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px 2px;
  background: #fff;
  border-radius: 10px;
  margin-top: 50px;
  cursor: pointer;
}

.skipAndHintButton-container {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 50px;
}
.skipAndHintButton {
  width: 35%;
  font-family: "Itim", cursive;
  background: linear-gradient(96.12deg, #ef3511 1.04%, #ff7253 100%);
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 7px;
  position: relative;
  font-size: 20px;
  cursor: pointer;
}

.successImg {
  margin-top: 30px;
}
.star-img {
  position: absolute;
  left: 50%;
  top: 0;
  right: 50%;
  width: 15%;
  transform: translate(-50%, -50%);
}
.lockimg {
  width: 30%;
}

@media screen and (max-width: 480px) {
  .startGamediv {
    background-image: url(/static/media/startGameMobile.b874984a.png);
  }
  .mainContainer {
    background-image: url(/static/media/mainBgMobile.a8a3ded9.png);
    overflow-y: scroll;
  }
  .levels-container {
    width: 80%;
    margin-bottom: 20px;
  }
  .level-box {
    width: 30%;
    background: #fff;
  }
  .selectLevelImg {
    width: 90%;
    margin-top: 20px;
  }
  .questionContainer {
    margin-top: 40px;
    width: 90%;
    height: 100%;
    padding-bottom: 0;
  }
  .multipleChoiceImg {
    width: 30%;
  }
  .question-text {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .answer-container {
    flex-direction: column;
    width: 100%;
  }
  .answer-button {
    font-size: 18px;
    width: 90%;
    margin-left: 0;
  }
  .skipAndHintButton-container {
    margin-top: 10px;
    width: 80%;
  }
  .congrats-div {
    width: 90%;
  }
  .button-container {
    width: 90%;
  }
  .successImg {
    width: 70%;
  }
  .congrats-primary-text {
    margin: 0px;
  }
  .congrats-secondary-text {
    margin-bottom: 40px;
  }
  .star-img {
    transform: translate(5%, 280%);
    /* bottom: auto; */
    top: auto;
    left: auto;
    right: auto;
    width: 35%;
  }
  .lock {
    width: 50%;
  }
}

