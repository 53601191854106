.nutrition-heading {
  font-size: 2vw;
  font-weight: bold;
  font-family: Montserrat;
  text-align: center;
  line-height: 68px;
  color: #000;
  margin-top: -5px;
  /* margin-bottom: 5px; */
}
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
/*  */
.footer-small-text {
  color: #000;
  margin-bottom: 2vh;
  text-align: left;
  margin-right: 5px;
}
.dark-footer-small-text {
  color: #fff;
  margin-bottom: 2vh;
  text-align: left;
  margin-right: 5px;
}
.ipad-div {
  display: flex;
  width: 50%;
  justify-content: center;
  margin-left: -150px;
  /* padding-left: 60px; */
}
.react-horizontal-scrolling-menu--wrapper {
  align-items: center;
  justify-content: center;
}
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100% !important;
}
.myMealCard {
  margin-right: 20px;
  width: 22vw;
  height: 15vw;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: flex-end;
  justify-self: center;
  border-radius: 10px;
}

.scroll-wrapper {
  font-family: Montserrat;
}

/* .anticon svg {
  display: inline-block;
  width: 75px;
  height: 50px;
} */
.myTrainCard {
  width: 30vw;
  height: 15vw;
  border-radius: 12px;
  display: flex;
  /* flex-direction: row; */
  /* overflow: hidden; */
  align-items: flex-end;
  justify-self: center;
}
.carousal-pic {
  width: 30vw;
  height: 20vw;
  border-radius: 12px;
}
.mealsDiv {
  display: flex;
  flex-direction: row;
  justify-content: "center";
  align-items: center;
  margin-top: 70px;
  margin-left: 10vw;
  margin-right: 10vw;
}
.nutrition-description {
  font-family: Montserrat;
  font-size: 2.2vh;
  line-height: 30px;
  justify-self: center;
  text-align: center;
  font-weight: normal;
  color: #8b8b8b;
  margin-top: -40px;
  margin-left: 20%;
  margin-right: 20%;
}
.trainingCardText1 {
  display: flex;
  left: 0;
  margin-left: 5%;
  white-space: nowrap;
  margin-bottom: -3%;
  background-image: linear-gradient(90deg, #ff7c2a, #bf330f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5vw;
  font-weight: 500;
  font-family: Montserrat;
  white-space: nowrap;
}
.footer-title {
  font-weight: 700;
  font-size: 1em;
  color: #000;
  margin-bottom: 4vh;
  text-align: left;
  justify-self: flex-start;
  /* margin-left: 10px; */
}
.dark-footer-title {
  font-weight: 700;
  font-size: 1em;
  color: #fff;
  margin-bottom: 4vh;
  text-align: left;
  justify-self: flex-start;
  /* margin-left: 10px; */
}
.trainingCardText2 {
  display: flex;
  left: 0;
  margin-left: 2%;
  margin-top: 10px;
  white-space: nowrap;
  margin-bottom: 1%;
  /* background-image: linear-gradient(90deg, #ff1e1e, rgba(0, 0, 0, 0)); */
  background-image: linear-gradient(90deg, #ff7c2a, #bf330f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
  line-height: 0.9em;
  font-weight: 700;
  font-family: Montserrat;
  white-space: initial;
}
.bottom-left {
  position: absolute;
  bottom: 0px;
  padding-left: 20px;
  width: 27vw;
}

.btoncolor,
.btoncolor:hover,
.btoncolor:focus {
  background-color: #ff1e1e;
  color: #fff;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  border-radius: 9px;
  height: 4vw;
  width: 17vw;
  font-size: 1.2vw;
}
.footer-div {
  /* width: 30vw; */
}

.bton,
.bton:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  border-radius: 9px;
  height: 4vw;
  width: 15vw;
  font-size: 1.2vw;
  background-color: #fff;
}
.meal {
  display: flex;
  left: 0;
  margin-left: 5%;
  white-space: nowrap;
  margin-bottom: 3%;
  color: #fff;
  font-weight: 700;
  text-shadow: 1px 1px #000;
  font-size: 20px;
}
.tags-div {
  display: flex;
  flex-direction: row;
  /* padding-left: 15px; */
  margin-top: -15px;
  justify-content: left;
  width: 100%;
}
.tag {
  display: flex;
  background: #1a1a1a;
  color: rgba(255, 255, 255, 0.65);
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  border-radius: 12px;
  /* width: 22%; */
  text-align: center;
  font-weight: 500;
  font-size: 85%;
}

.card-div {
  margin-right: 5px;
  margin-left: 5px;
  /* max-height: 100vh; */
  /* margin-bottom: -15vh; */
  /* border: 1px solid blue; */
}
.train-description {
  display: -webkit-box;
  /* max-width: 25vw; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
}
.small-remove {
  padding-left: 45px;
  margin-left: 10px;
  /* border: 1px solid green; */
}
.ipad-image {
  width: 65%;
  margin-bottom: 38px;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  transform: skew(0deg, -8deg);
}
.small-remove-text {
  /* margin-left: 10px; */
  margin-right: 20px;
  padding-left: 25px;
  /* border: 1px solid green; */
}
.small-remove-text2 {
  /* margin-left: 10px; */

  padding-left: 55px;
  /* border: 1px solid green; */
}
.mobile-img {
  width: 100%;
  height: auto;
}
.btonDiv,
.btonDiv:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.tp-heading-small {
  display: flex;
  justify-self: flex-start;
  color: #ff5858;
  /* margin-left: -4%; */
  font-size: 1.5em;
  width: 89%;
}
.tp-heading-big {
  margin-left: -4%;
  width: 85%;
  margin-top: -2px;
  margin-bottom: 30px;
  color: white;
  font-size: 30px;
  display: flex;
  font-weight: 700;
}
@media screen and (max-width: 430px) {
  .tp-heading-small {
    margin-top: 50px;
    margin-left: 0;
    font-size: 18px;
  }
  .tp-heading-big {
    margin-left: 0;
    font-size: 20px !important;
    text-align: left;
  }
}

.getworkout-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 70vh;
  background-color: #000;
  width: 100%;
  color: #fff;
  transform: skew(0deg, 8deg);
}
.set-on-responsive {
  margin-left: 70px;
  width: 50%;
}
.set-on-responsive1 {
  /* margin-bottom: 60px; */
  width: 50%;
  padding-right: 25px;
  margin-left: 90px;
}
@media screen and (max-width: 800px) {
  .getworkout-div {
    flex-direction: column-reverse;
  }
  .playstore-class {
    margin-left: -20px !important;
  }
  .nutrition-heading {
    margin-top: 70px;
    font-size: 2em;
  }
  .btonDiv,
  .btonDiv:hover {
    margin-bottom: 50px;
  }
  .ipad-image {
    width: 45%;
    margin-bottom: 0px;
  }
  .ipad-div {
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    width: 100%;
    margin-left: 0px !important;
  }
  .set-on-responsive {
    margin-left: 0px !important;
    margin-bottom: 100px;
    width: 100%;
    padding-left: 14%;
    justify-content: flex-start;
    align-self: center;
    align-items: flex-start;
    justify-self: center;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: -70px;
  }
  .minus-margin {
    margin-left: 15px !important;
    width: 80% !important;
  }
  .set-on-responsive1 {
    margin-bottom: 70px;
    margin-left: -15px !important;
  }
}

@media screen and (max-width: 520px) {
  .tags-div {
    flex-direction: row;
    width: 100%;
    padding-left: 0px;
  }
  .tag {
    /* width: 100%; */
    display: flex;
    margin-bottom: 5px;
    text-align: center;
    justify-content: center;
    font-size: 75%;
  }
}

@media screen and (max-width: 325px) {
  .set-on-responsive1 {
    padding-top: 100px !important;
  }
}
@media screen and (max-width: 880px) {
  .mealsDiv {
    flex-direction: column;
  }
  .set-on-responsive1 {
    width: 100%;
    margin-bottom: 70px;
    margin-left: -15px !important;
  }
  .mobile-img {
    display: none;
    /* visibility: hidden; */
  }
  .nutrition-description {
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    margin-top: 10px;
  }
  .nutrition-heading {
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    line-height: 1em;
  }
  .train-description {
    /* width: 45vw; */
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 85%;
    margin: 0px;
    font-size: 80%;
  }

  .meal {
    font-size: 12px;
  }
  .trainingCardText1 {
    font-size: 12px;
  }
  .trainingCardText1 {
    font-size: 22px;
  }
  .myMealCard {
    width: 45vw;
    height: 200px;
    margin-bottom: 10px;
  }
  .myTrainCard {
    width: 45vw;
    height: 180px;
  }
}
@media screen and (max-width: 400px) {
  .footer-small-text {
    color: #000;
    margin-bottom: 2vh;
    text-align: left;
    margin-right: 5px;
  }

  .dark-footer-small-text {
    color: #fff;
    margin-bottom: 2vh;
    text-align: left;
    margin-right: 5px;
  }
  .small-remove {
    padding-left: 0;
  }

  .footer-div {
    /* display: none; */
    flex-direction: column;
    /* border: #000; */
  }
  .flexDir {
    flex-direction: column;
    margin: 0 auto;
    font-size: 24px;
    padding-left: 25%;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
  }
  .flexDir2 {
    flex-direction: column;
    margin: 0 auto;
    font-size: 24px;
    /* padding-left: 25%; */
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
  }
}

.footer-right-div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  justify-self: flex-start;
  width: 50vw;
  /* padding: 20px; */
}
@media screen and (max-width: 415px) {
  .spacing-top-minus-on-mobile {
    margin-top: 10px !important;
  }
  .flexDir {
    flex-direction: column;
    margin: 0 auto;
    font-size: 24px;
    padding-left: 25%;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
  }
  .small-remove {
    padding-left: 0px;
  }
}
@media screen and (max-width: 470px) {
  .flexDirCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-right-div {
    width: 80vw;
  }
  .copyright-div {
    width: 80vw;
    font-size: 12px;
    justify-content: flex-start;
  }
  .footer-div {
    flex-direction: row;
    width: 80vw;
  }
}
@media screen and (max-width: 580px) {
  .meal {
    font-size: 12px;
  }

  .carousal-pic {
    width: 70vw;
    height: 40vw;
  }
  .bottom-left {
    width: 60vw;
  }
  .trainingCardText1 {
    font-size: 14px;
    margin-bottom: -10px;
    font-weight: 700;
  }
  .trainingCardText2 {
    font-size: 13px;
    width: 100%;
  }
  .btonDiv,
  .btonDiv:hover {
    flex-direction: column;
  }
  .bton,
  .bton:hover {
    justify-self: center;
    width: 150px;
    font-size: 10px;
  }
  .btoncolor,
  .btoncolor:active,
  .btoncolor:hover,
  .btoncolor:focus {
    justify-self: center;
    margin-right: -1px;
    margin-bottom: 5px;
    width: 150px;
    font-size: 10px;
  }
  .mealsDiv {
    flex-direction: column;
  }
  .myMealCard {
    width: 50vw;
    height: 30vw;
    margin-bottom: 10px;
  }
  .myTrainCard {
    width: 68vw;
    height: 35vw;
  }
  .nutrition-description {
    font-family: Montserrat;
    font-size: 18px;
    line-height: 28px;
    justify-self: center;
    text-align: center;
    font-weight: normal;
    color: #8b8b8b;
    margin-top: -20px;
    margin-left: 20%;
    margin-right: 20%;
  }
  .nutrition-heading {
    font-size: 24px;
    /* text-align: left; */
    margin-top: 10px;
  }
}
