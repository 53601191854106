.Settings-container {
  width: 100%;
  background-color: black;
  display: flex;
}

.ttext {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2vh;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-top: -20px;
  text-align: left;
  color: #707070;
  width: 25vw;
}
.flagstyle {
  max-width: 100%;
  width: 30px;
  height: auto;
  justify-self: center;
  align-self: center;
  margin-top: -2px;
  border-radius: 1px;
}
.myDropdown {
  float: right;
  align-self: center;
  justify-self: center;
  display: flex;
}

.inputfeild,
.inputfeild:focus {
  background-color: transparent;
  height: 6vh;
  width: 15vw;
  align-self: center;
  /* justify-self: flex-end; */
  margin-left: 2vw;
  padding: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
  border-left: 1px solid #707070;
  font-size: 1.3vw;
  outline: none !important;
  color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputdivSettings {
  width: 25vw;
  /* height: 5vw; */
  display: flex;
  flex-direction: row;
  padding: 10px 20px 10px 20px;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
}

.inputdivSettings > .ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
}

.modal-div-deactive {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 90%;
}

.modal-class .ant-modal-header {
  padding: 16px 24px;
  color: #ffffff;
  /* color: rgba(0, 0, 0, 0.85); */
  background: #171717;
  border-bottom: 0px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.modal-class .ant-modal-title {
  color: #ffffff;
}

.modal-class .ant-modal-footer {
  padding: 10px 16px;
  display: flex;
  text-align: right;
  background: #171717;
  justify-content: space-between;
  border-top: 0px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}

.sider-styles {
  background-color: black;
  color: white;
  margin-top: 50px;
  margin-left: 30px;
  margin-right: -90px;
}

.sider-styles .ant-menu-root {
  box-shadow: none;
  background-color: black;
  color: white;
  font-size: 18px;
  font-weight: 700;
  border: none;
  margin-top: 50px;
  margin-left: 60px;
}
.sider-styles .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: black;
}

.sider-styles .ant-menu-light .ant-menu-item-active,
.ant-menu-item-selected {
  color: #ff1e1e;
}

.sider-styles .ant-menu-item-selected {
  color: #ff1e1e;
}
.content-classname {
  /* border: 1px solid red; */
  width: 100%;
}
.about-you-modal-div {
  display: flex;
  color: white;
  width: 500px;
  border: 1px solid grey;
  margin-left: 300px;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  justify-self: center;
  align-self: center;
}
.goals-modal-div {
  display: flex;
  color: white;
  width: 500px;
  border: 1px solid grey;
  margin-left: 300px;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}
@media screen and (max-width: 1600px) {
  .modal-div-deactive {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    width: 85%;
  }
}

@media screen and (max-width: 780px) {
  .inputdivSettings {
    width: 300px;
    height: 54px;
  }
  .myDropdown {
    float: none;
    justify-self: center;
    align-self: center;
  }
  .inputfeild {
    font-size: 14px;
    width: 200px;
  }
}

@media only screen and (max-width: 470px) {
  .sider-styles {
    display: none !important;
  }
  .myDropdown {
    float: none;
  }
  .inputfeild {
    font-size: 14px;
    width: 200px;
  }
  .display-true {
    display: flex !important;
  }
  .content-text-and-content-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ttext {
    width: auto !important;
    text-align: center !important;
    font-size: 18px;
    text-align: center;
  }
  /* style={{
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }} */
  .content-classname {
    width: 100%;
    /* overflow: scroll; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding: 5% !important;
  }
  .about-you-modal-div {
    margin: 0 !important;
    width: 300px !important;
  }
  .dropdown-remove-margin {
    margin-right: 25px !important;
  }
  .goals-modal-div {
    width: 100% !important;
    margin-left: 10px !important;
    /* border: 1px solid red; */
    margin: 0 !important;
  }
  .all-goals-div {
    width: 100% !important;
  }
  .goal-card-text {
    font-size: 10px !important;
  }
  .subscription-plan-text {
    font-size: 12px !important;
  }
  .inputdivSettings {
    width: 80vw;
    height: 54px;
  }
}
