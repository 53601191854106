.container1 {
  width: 60%;
  /* height: 60vh; */
  justify-content: center;
  align-content: center;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 85px;
  margin-bottom: 30px;
  /* margin-left: 50px; */
}
.change {
  color: red;
  font-size: 2vh;
  margin-left: 90px;
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
}
.leftDiv {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  width: 60vw;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 40px 30px 40px;
  left: 0;
}
.rightDiv {
  align-self: flex-start;
  background: #f5f5f5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  width: 40vw;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  right: 0;
  text-align: left;
}
.heading {
  font-weight: bold;
  font-size: 3.5vh;
  line-height: 49px;
  white-space: nowrap;
  letter-spacing: -0.02em;
  font-weight: 800;
  color: #000000;
}
.btn1 {
  width: 25vw;
  height: 65px;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  box-shadow: 0px 29px 43px rgba(255, 30, 30, 0.19);
  border-radius: 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 2.2vh;
  border: 0px;
}

.part {
  display: "flex";
  flex-direction: "column";
  justify-content: space-evenly;
}

.part > .ant-btn:focus {
  color: #fff;
  border: 0px;
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
}

.ant-btn:focus .payment-icons {
  color: #f5f5f5;
}

.ant-btn:focus .left-icon {
  filter: brightness(0) invert(1);
}

.payment-icons {
  color: #ff1e1e;
}

.payment-icons:hover {
  color: #f5f5f5;
}
.payment-icons:focus {
  color: #f5f5f5;
}

.btn1:hover {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  color: #f5f5f5;
}
/* .btn-clear:hover {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  color: #f5f5f5;
  filter: brightness(0) invert(1);
} */
.btn-clear {
  width: 25vw;
  height: 65px;
  background: #fff;
  box-shadow: 0px 29px 43px rgba(255, 30, 30, 0.19);
  border-radius: 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  border: 0px;
  font-size: 2.2vh;
  font-weight: 700;
}
.tick {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background-color: #fff;
  position: absolute;
  right: 0;
  padding-top: 2px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
}
.left-icon {
  height: 2.5vh;
  width: 2.5vh;
  margin-right: 10px;
}
.subHeading-small {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5vh;
  line-height: 49px;
  /* margin-top: 25px; */
  letter-spacing: -0.02em;

  color: #000000;
}

.alert-modal {
  width: 50% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}
.subHeading-big {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 3vh;
  line-height: 59px;
  letter-spacing: -0.02em;
  /* margin-top: -20px; */
  margin-bottom: -5px;
  color: #ff1e1e;
  text-align: center;
}

.input-field {
  border-radius: 12px;
  margin-bottom: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
}
.input-heading {
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 150% */
  font-weight: 500;
  margin-bottom: 2px;
  letter-spacing: -0.02em;

  color: #000000;
}
.checkbox {
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 150% */
  font-weight: 500;
  margin-bottom: 2px;
  letter-spacing: -0.02em;

  color: #707070;
}
.ant-checkbox-checked::after {
  border: 1px solid #ff1e1e !important;
}

.proceed-btn,
.proceed-btn:hover,
.proceed-btn:active,
.proceed-btn:focus {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  margin-bottom: 25px;
  border-radius: 12px;
  border: 0px;
  color: #fff;
  font-weight: 600;
  height: 60px;
  font-size: 16px;
  margin-top: 20px;
  letter-spacing: -0.02em;
}

/* .ant-checkbox-checked {
  border: 1px solid #ff1e1e !important;
} */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff1e1e;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ff1e1e;
}
.para {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #707070;
}
.total {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: red;
}
.endDiv {
  height: 20px;
  width: 24vw;
  border-top: 1px solid grey;
  justify-self: flex-end;
  display: flex;
  align-self: center;
  margin-top: auto;
  justify-content: space-between;
  padding-top: 10px;
}
@media screen and (max-width: 1160px) {
  .container1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 90% !important;
    /* margin-top: 100px; */
  }
  .rightDiv {
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .leftDiv {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .btn1 {
    width: 250px;
  }
  .btn-clear {
    width: 300px;
  }
  .heading {
    font-size: 16px;
  }
  .change {
    font-size: 13px;
  }
  .alert-modal {
    width: 80% !important;
  }
}

/*
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
