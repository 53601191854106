.mini-header {
  /* border: 1px solid green; */
  display: flex;
  padding: 50px;
  color: #fff;
  background: #000;
}
.mini-header-text {
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin-left: 7.5%;
}
.container-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.skew-div {
  min-height: 40vh;
  width: 100%;
  background: rgba(0, 0, 0, 1);
  transform: skew(0, -15deg);
}
.team-div {
  width: 80vw;
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
  justify-self: center;
  align-self: center;
  margin-top: 8vh;
}
.small-bullet-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.jobs-div {
  /* display: flex;
  flex-direction: row; */
  display: grid;
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.job-card {
  /* border: 1px solid green; */
  margin: 10px;
  width: 310px;
  /* height: 100px; */
  background: #ffffff;
  box-shadow: 0px 22px 72px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 20px;
}
.small-text-card {
  text-align: left;
  color: #000;
  font-weight: 500;
  font-family: Montserrat;
  text-transform: capitalize;
}
.opening-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8vh;
  margin-bottom: 8vh;
}
.days-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: #000;
  margin-top: -3vh;
  /* border: 1px solid red; */
}
.job-card-heading {
  font-size: 30px;
  font-weight: 600;
  display: flex;
  color: #000;
  text-align: left;
  width: 100%;
  line-height: 1.2em;
}
.job-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  color: #000;
  width: 100%;
  margin-bottom: 60px;
}

.unorderd-list {
  text-align: left;
  font-size: 16px;
  color: #000;
  /* font-weight: 500; */
  font-family: Montserrat;
  /* border: 1px solid green; */
}
.small-text-black {
  display: flex;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  color: #000;
}
.apply-now-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.apply-now-txt {
  color: #ff1e1e;
  font-weight: 600;
  font-family: Montserrat;
  font-size: 16px;
  text-align: left;
}
.text-box-div {
  width: 80vw;
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
  justify-self: center;
  align-self: center;
  margin-top: 8vh;
}
.article-text-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pic {
  /* max-width: 100%; */
  width: 100%;
  max-height: 100%;
  /* height: auto; */
}
.btn-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.job-overview {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.text-small-heading {
  display: flex;
  color: #000;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
}
.text-small-description {
  display: flex;
  color: #000;
  text-align: left;
  width: 80%;
  /* margin-bottom: 22vh; */
  font-size: 16px;
}
.apply-button,
.apply-button:hover,
.apply-button:focus {
  background-color: #ff1e1e;
  color: #fff;
  width: 200;
  font-weight: 600;
  margin-bottom: 20px;
  height: 50px;
  border: none;
}
.job-details-div {
  display: flex;
  flex-direction: column;
  align-self: flex-start !important;
}
.blog-details-image-div {
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-self: center;
  height: 60vh;
  margin-top: -4em;
}
.blog-author-div {
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* justify-self: center; */
  /* align-self: center; */
}
.blog-main-content {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: 100%;
  /* border: 1px solid red; */
}
.blog-main-text {
  text-align: justify;
  color: #000;
  overflow-wrap: break-word;
}
.blog-main-content p {
  margin: 2px;
}
/* .blog-main-content div {
  margin: 0px 0px 0px 0px;
} */
.blog-mini-title {
  font-size: 2em;
  color: #ff1e1e;
  font-weight: 500;
  margin-top: 10px;
  text-align: left;
}
.blog-author {
  font-size: 1em;
  text-align: left;
}
.job-title-text {
  font-size: 2.5em;
  width: 70%;
  color: #000;
  text-align: left;
  font-weight: 600;
  margin-top: -5px;
  margin-left: 60px;
}
.blog-title {
  font-size: 2.5em;
  width: 70%;
  color: #000;
  text-align: left;
  font-weight: 600;
  margin-top: -5px;
}
.blog-content-div {
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-self: center;
  /* border: 1px solid red; */
}
.details-title {
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: -2px;
}
.details-description {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  white-space: nowrap;
  color: #000;
  /* margin-bottom: -10px; */
}
.job-details {
  width: 20vw;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.img-div {
  width: 100vw;
  /* margin-top: 20px; */
  display: flex;
  object-fit: contain;
  flex-direction: row;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-self: center;
  height: auto;
  margin-bottom: 50px;
  /* border: 3px solid red; */
}
.left-text-div {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.grid-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.right-text-div {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.big-text {
  font-size: 35px;
  font-weight: 600;
  display: flex;
  color: #000;
  text-align: left;
  width: 90%;
  line-height: 1.2em;
}
.small-text-jobs {
  color: #ff5858;
  display: flex;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  margin-left: 60px;
}
.small-text {
  color: #ff5858;
  display: flex;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-align: left;
}
.paragraph-text {
  font-size: 14px;
  text-align: left;
  color: #000;
  display: flex;
  font-family: Montserrat;
}
.member-cards-div {
  position: relative;
  /* z-index: 1; */
  margin-top: -40vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
}
.hide-on-small {
  display: flex;
}
.member-card {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 350px;
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 22px 72px rgba(0, 0, 0, 0.12);
}
.member-info-div {
  background: white;
  width: 100%;
  padding: 30px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}
.name-text {
  font-size: 28px;
  font-weight: 700;
  font-family: Montserrat;
  text-transform: uppercase;
}
.member-description {
  text-align: left;
  color: #000;
}
.member-description2 {
  text-align: left;
  color: #000;
}
.profile-pic-div {
  width: 40%;
}
.blog-upper-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 30px;
  /* border: 1px solid green; */
}
.main-container-div {
  background-color: #fff;
}
.blog-container-div {
  display: flex;
  flex-direction: column;
  width: 85%;
  justify-self: center;
  align-self: center;
  /* border: 1px solid red; */
}
.upper-left-div {
  width: 50%;
  /* border: 1px solid green; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
}
.mini-blog-img {
  /* margin-left: -5%; */
  width: 50%;
  height: 100%;
  /* border: 1px solid black; */
  border-radius: 10px;
  object-fit: cover;
}
.upper-small-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: none;
}
.sub-heading {
  color: #868686;
  font-weight: 400;
  font-family: Montserrat;
  font-size: 14px;
  text-align: left;
}
.upper-right-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: hidden;
  margin-left: 10px;
  width: 50%;
  height: 60vh;
}
.content-column-class {
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
  width: 100%;
}
.navbar-blogs {
  width: 100%;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  color: black;
  /* border-bottom: 1px solid; */
}

.mini-blog-div {
  /* padding: 10px; */
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 10%;
  margin-bottom: 5%;
}
.sub-mini-div {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.mini-div-text {
  text-align: left;
  color: black;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
}
.title-featured-blogs {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-self: flex-start;
  align-self: flex-start;
  margin-left: 15%;
  margin-bottom: -20px;
}

.blog-card-div {
  display: flex;
  flex-direction: column;
  white-space: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
  width: 23vw;
  margin-right: 40px;
  cursor: pointer;
}
.blog-pic {
  height: auto;
  width: 100%;
  border-radius: 10px;
}
.author-name-text {
  color: #868686;
  font-size: 1em;
  white-space: nowrap;
}
.blog-lower-div {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 430px) {
  .mini-blog-img {
    width: 100% !important;
    height: 145px !important;
  }
  .blog-category-title {
    font-size: 15px !important;
  }
  .view-more-div {
    padding-right: 0 !important;
    font-size: 15px !important;
  }
  .author-name-text {
    font-size: 12px;
    white-space: nowrap;
  }
  .blog-card-div {
    width: 34vw;
  }
  .mini-div-text {
    font-size: 12px;
  }
  .mini-blog-div {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .sub-mini-div {
    width: 100% !important;
  }
  .mini-div-text {
    width: 100%;
  }
}
/* .featured-main-img {
  border: 1px solid grey;
} */
@media screen and (max-width: 1000px) {
  .blog-upper-div {
    flex-direction: column;
    margin-left: 20px;
  }
  .blog-pic {
    height: 150px;
  }
  .upper-small-div {
    border-bottom: 1px solid grey;
    margin-bottom: 10px;
  }
  .hide-on-small-size {
    display: none !important;
  }
  .apply-now-txt {
    text-align: left;
  }
  .upper-left-div {
    width: 100%;
  }
  .upper-right-div {
    width: 100%;
    border-bottom: 1px solid grey;

    margin: 0;
  }
  .mini-blog-img {
    width: 300px;
    height: auto;
    object-fit: cover;
  }
  .featured-main-img {
    width: 100%;
    height: auto;
  }
  .text-small-heading {
    width: 100% !important;
    font-size: 15px !important;
  }
  .sub-mini-div {
    width: 500px;
    height: 150px;
  }
}
@media screen and (max-width: 1100px) {
  .hide-on-small {
    display: none;
  }
  .profile-pic-div {
    display: none;
  }
}
.job-upper-left-div {
  /* border: 1px solid green; */
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.featured-main-img {
  width: 100%;
}
.mini-div-text {
  width: 70%;
}

@media screen and (max-width: 800px) {
  .name-text {
    font-size: 18px;
  }
  .jobs-div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: scroll;
    width: 100%;
  }
  .job-left-right-container {
    flex-direction: column !important;
  }
  .job-upper-left-div {
    width: 100% !important;
  }
  .job-upper-right-div {
    width: 100% !important;
    justify-content: flex-start !important;
  }
  .member-description2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .all-jobs-div {
    overflow: scroll;
    flex-wrap: nowrap !important;
    padding: 0 !important;
  }
  .job-card {
    width: 230px !important;
    /* height: 260px; */
    padding: 15px !important;
  }
  .job-card-heading {
    font-size: 18px;
  }
  .days-text {
    font-size: 12px;
  }
  .job-description {
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .headings-and-search-div {
    width: 95%;
  }
  .job-title-text {
    font-size: 17px;
    margin-left: 0px;
    width: 100%;
  }
  .small-text-jobs {
    margin-left: 0px !important;
  }
  .mini-header-text {
    margin-left: 0 !important;
    font-size: 18px;
  }
  .mini-header {
    padding-left: 20px !important;
  }
}
@media screen and (max-width: 650px) {
  .big-text {
    font-size: 22px;
    /* border: 1px solid red; */
  }
  .blog-details-image-div {
    height: auto;
  }
  .blog-mini-title {
    display: flex;
    font-size: 1.2em;
    width: 100%;
  }
  .blog-title {
    font-size: 1.5em;
    width: 100%;
  }
  .blog-pic {
    height: 120px;
  }
  .article-text-div {
    flex-direction: column;
  }
  .left-text-div {
    width: 100%;
  }
  .right-text-div {
    width: 100%;
  }
}
.job-left-right-container {
  display: flex;
  flex-direction: row;
}
.job-upper-right-div {
  /* border: 1px solid red; */
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40%;
}
.blog-card-and-title-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin-bottom: 30px; */
}
.blog-card-row-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: wrap;
  overflow: hidden;
  width: 100%;
}

.upper-right-div::-webkit-scrollbar {
  width: 7px;
}

.upper-right-div::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}
.upper-right-div::-webkit-scrollbar-thumb {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 10px;
}
.blog-category-title {
  font-size: 2em;
  font-weight: 600;
}
.view-more-div {
  font-size: 1.5em;
  font-weight: 600;
  padding-right: 30px;
  cursor: pointer;
}
.title-row-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.jobs-display-flexbox-div {
  display: flex;
  width: 80%;
  justify-self: center;
  align-self: center;
  margin-top: 50px;
}
.all-jobs-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 80px;
  align-items: center;
  flex-wrap: wrap;
}
.headings-and-search-div {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-self: center;
  align-self: center;
  margin-top: 50px;
}
