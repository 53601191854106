.containerLeft {
  overflow: hidden;
  width: 60%;
  /* height: 100vh; */
  background-image: linear-gradient(
      194.92deg,
      rgba(0, 0, 0, 0) 45.34%,
      #000000 102.5%
    ),
    url("../assets/images/sigma.png");
  /* background-size: cover; */
  background-size: auto 100%;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  /* border: 1px solid red; */
}
.myDropdown {
  float: right;
  align-self: center;
  justify-self: center;
  display: flex;
}
.storeimg {
  width: 12.2vw;
  height: 50px;
}
.marketCard {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  margin: 0 auto;
  border-radius: 10px;
}
.terms {
  margin: 0 auto;
  /* margin-top: 5vh; */
}
.bgClr {
  /* background-color: rgba(225, 30, 30, 0.09); */
  text-align: left;
  font-size: 20px;
  /* border-radius: 10px; */
}
.ant-input-affix-wrapper-inputfeild {
  outline: none;
}

.dropdown,
.dropdown:hover {
  color: #fff;
  border: 1px solid white;
  border-radius: 5px;
  padding: 3px 8px;
  /* background-color: #ff1e1e; */
  font-weight: 600;
  justify-content: center;
  align-items: center;
}
.menu_item {
  background: #fff;
  color: #000 !important;
  width: 150px;
  border: none;
}
.containerRight {
  height: 100vh;
  width: 40%;
  /* overflow: scroll; */
  /* border: 1px solid red; */
  background: linear-gradient(337.1deg, #000000 -1.26%, #4f4f4f 130.31%);

  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 6vw;
  padding-top: 50px;
  padding-right: 3vw;
}
.ant-spin {
  color: #ff1e1e;
}
.titletext {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 3.2vh;
  line-height: 39px;
  letter-spacing: -0.02em;
  text-align: center;
}
.welcome_arabic {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  text-align: right;
  width: 25vw;
  font-size: 7vh;
  line-height: 59px;
  /* text-align: center; */
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #ffffff;
}
.welcome {
  /* H1/Bold */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 4vh;
  line-height: 59px;
  text-align: center;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #ffffff;
}
.ttext {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2vh;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-top: -20px;
  text-align: left;
  color: #707070;
  width: 25vw;
}
.ttext_arabic {
  width: 25vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5vh;
  /* line-height: 24px; */
  letter-spacing: 0.02em;
  margin-top: -20px;
  text-align: right;
  font-weight: 600;
  /* color: #707070; */
  color: #fff;
}
.inputdiv {
  width: 25vw;
  height: 5vw;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 20px;
}
.inputdiv_arabic {
  width: 25vw;
  height: 5vw;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border: 1px solid #707070;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 20px;
}

.loading-div {
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 20px;
  margin-right: 90px;
}
.flagstyle {
  max-width: 100%;
  width: 30px;
  height: auto;
  justify-self: center;
  align-self: center;
  margin-top: -2px;
  border-radius: 1px;
}
.inputfeild,
.inputfeild:focus {
  background-color: transparent;
  height: 6vh;
  width: 15vw;
  align-self: center;
  /* justify-self: flex-end; */
  margin-left: 2vw;
  padding: 5px;
  padding-bottom: 0px;
  padding-top: 0px;
  border: none;
  border-left: 1px solid #707070;
  font-size: 1.3vw;
  outline: none !important;
  color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.otpinput {
  background-color: transparent;
  color: #707070;
  border: 1px solid #707070;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 4.5vw !important;
  height: 4.5vw !important;
}

.indonesiaInput {
  background-color: transparent;
  color: #707070;
  border: 1px solid #707070;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 45px !important;
  height: 45px !important;
  font-size: 20px;
  /* height: 4.5vw !important; */
  /* width: 4.5vw !important;
  height: 4.5vw !important; */
}

/* .indonesiaOTP {
  /* border: 1px solid green; */
/* } */
.otp {
  width: 25vw;
  /* border: 1px solid green; */
}
.resend {
  color: #ff1e1e;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 9vw; */
  /* margin-top: 20px; */
}
.resend_arabic {
  color: #ff1e1e;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  /* margin-left: 9vw; */
  /* margin-top: 20px; */
}
.icon-spacing {
  margin-right: 50px;
  filter: invert(100%) sepia(0%) saturate(3658%) hue-rotate(356deg)
    brightness(96%) contrast(96%);
}

.subs-otpinput {
  background-color: transparent;
  color: #707070;
  border: 1px solid #707070;
  /* box-sizing: border-box; */
  border-radius: 9px;
  width: 4.5vw !important;
  height: 4.5vw !important;
}
.subs-otp {
  width: 25vw;
  /* border: 1px solid green; */
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  margin-top: 50px;
  margin-left: -20px;
}

.inputdiv > .ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
}
.inputdiv_arabic > .ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background-color: #222222;
  color: white;
}
.btn-login_arabic:hover,
.btn-login_arabic:focus,
.btn-login_arabic {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 12px;
  border: none;
  color: #fff;
  width: 25vw;
  height: 54px;
  margin-top: 30px;
  font-weight: 600;
  font-size: 24px;
}
.btn-login:hover,
.btn-login:focus,
.btn-login {
  background: linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%);
  border-radius: 12px;
  border: none;
  color: #fff;
  width: 25vw;
  height: 54px;
  margin-top: 30px;
  font-weight: 600;
}
.terms {
  margin: 0 auto;
}
@media screen and (max-width: 880px) {
  .containerLeft {
    background-image: none;
    background: #000000;
  }

  .subs-otpinput {
    background-color: transparent;
    color: #707070;
    border: 1px solid #707070;
    /* box-sizing: border-box; */
    border-radius: 9px;
    width: 12vw !important;
    height: 10vw !important;
    margin-right: 8px !important;
  }
  .subs-otp {
    width: auto;
    /* border: 1px solid green; */
  }
}
.Login-page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #000000;
  /* overflow: hidden; */
  /* height: 100vh; */
}
@media screen and (max-width: 666px) {
  .containerLeft {
    display: flex;
    flex-direction: column;
    /* overflow: scroll; */
    /* border-radius: none; */
    width: 100%;
    position: relative;
    align-items: center;
  }
  .Login-page-container {
    display: flex;
    align-items: center !important;
    flex-direction: column-reverse;
    justify-content: center;
    overflow: scroll !important;
  }
  .containerRight {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    /* border-radius: 10px; */
    /* height: 100%; */
    background-color: #000;
  }
  .margin-top-minus-on-small {
    margin: 20px !important;
    padding-bottom: 30px;
  }

  .terms {
    justify-self: flex-start;
  }
}
@media screen and (max-width: 780px) {
  .containerRight {
    width: 100%;
  }
  .ttext {
    width: 300px;
  }
  .myDropdown {
    float: none;
    justify-self: center;
    align-self: center;
  }

  .loading-div {
    display: flex;
    align-items: center;
    align-self: center;
    margin-top: 20px;
    /* width: 14%; */
    margin-right: 0px;
    justify-content: center;
  }
  .inputdiv {
    width: 300px;
    height: 54px;
  }
  .inputdiv_arabic {
    width: 300px;
    height: 54px;
  }
  .welcome_arabic {
    width: 300px;
    height: 54px;
  }
  .ttext_arabic {
    width: 300px;
    height: 54px;
  }
  .social-icons {
    margin-left: 0px;
    margin-left: 0px;
    width: 90%;
    justify-content: center;
  }
  .icon-spacing {
    margin-right: 0px;
  }
  .inputfeild {
    font-size: 14px;
    width: 200px;
  }
  .btn-login,
  .btn-login:hover {
    width: 300px;
    height: 54px;
    font-size: 14px;
  }
  .btn-login_arabic,
  .btn-login_arabic:hover,
  .btn-login_arabic:focus {
    width: 280px;
    height: 54px;
    font-size: 14px;
  }
  .storeimg {
    width: 150px;
    margin-bottom: 10px;
  }
  .otp {
    width: 300px;
    width: 300px;
    height: 54px;
    font-size: 14px;
  }
  .otpinput {
    width: 60px !important;
    height: 54px !important;
  }
  .indonesiaInput {
    /* padding: 20px; */
    width: 40px !important;
    height: 40px !important;
  }
  .titletext {
    font-size: 25px;
  }
}

@media screen and (max-width: 480px) {
  .containerRight {
    /* height: 100vh; */
    /* overflow: scroll; */
    width: 100% !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* border-radius: 10px; */
  }
  .Login-page-container {
    height: 100%;
    overflow: scroll;
  }
  .twitter-icon-color {
    filter: invert(53%) sepia(0%) saturate(3658%) hue-rotate(356deg)
      brightness(102%) contrast(91%);
  }
  .center-images-on-resize {
    justify-content: center;
    align-items: center;
    place-self: center;
    padding-left: 22vw;
  }
  .myDropdown {
    float: none;
  }
  .ttext {
    width: auto !important;
    text-align: center !important;
    font-size: 18px;
    text-align: center;
  }
  .inputdiv {
    width: 80vw;
    height: 54px;
  }
  .inputdiv_arabic {
    width: 80vw;
    height: 54px;
  }
  .welcome_arabic {
    width: 80vw;
    height: 54px;
  }
  .ttext_arabic {
    width: 80vw;
    height: 54px;
  }
  .outerContainer {
    flex-direction: column-reverse;
  }
  .inputfeild {
    font-size: 14px;
    width: 200px;
  }
  .btn-login,
  .btn-login:hover {
    width: 80vw;
    height: 54px;
    font-size: 14px;
  }
  .otpinput {
    width: 10vw !important;
    height: 12vw !important;
  }
  .otp {
    width: 75vw;
    height: 54px;
    justify-content: center;
  }
  .titletext {
    font-size: 25px;
  }
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */
